// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.PzlGqJM1hGoHKQ8AQLGM{font-weight:500;font-size:24px}.SaMhc6GQRQO6oCrxZwiF{font-weight:500;font-size:20px}.lXzOHQTveUQxsJ17tfdi{font-weight:500;font-size:12px}.ow0XHspzyMklHggvU0kf{font-size:18px}.QPZEOALC3lT7kzZNpvle{font-size:18px;font-weight:500}.bLjssHipnPszMMGwW05_{font-size:16px}.nJJdLj8iICZVAzfN56b8,.ihMJN_22bB5agGSCWHnz{font-size:16px;font-weight:500}.gnCOvIX6Gz3pbraS_rEz,.jyEU304MRPfDZLbfDPnx{font-size:14px}.AzNNsozXVFEHJ7Q7KdkB{font-size:14px;font-weight:500}.CLxQasYClC8_B_XGhKZo{font-size:14px;font-weight:400}.TMEmp2EsiazcV3Ic26N9{font-size:12px}.XjPIM9P6svYCNmRgtRkZ{font-size:12px;font-weight:500}.KFYbfcRm8EGxYVVsCck3{width:100%;height:100%;padding:20px;box-sizing:border-box;display:flex;flex-direction:column}.xjSrNhvz_cKBxbmRYojJ{display:flex;flex-direction:row;align-items:center;height:60px;margin-bottom:8px}.FTtE9Gzye7nzDuyjxkSN{display:flex;flex-direction:column;margin-left:16px}.jyEU304MRPfDZLbfDPnx{color:#5f718c}.VKLcK4KCfEPSeByKCkap{flex:1 1 0}.P92ZRNTTYZRHxLAqSRut{margin-top:16px;display:flex;flex-direction:row}._hym0BXKPXmc_ArYjMJL{flex-shrink:0;width:12px}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/components/devices/register/modal-register-tag-details.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,4CACC,cAAA,CACA,eAAA,CAGD,4CACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,UAAA,CACA,WAAA,CAEA,YAAA,CACA,qBAAA,CAEA,YAAA,CACA,qBAAA,CAGD,sBACC,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,WAAA,CAEA,iBAAA,CAGD,sBACC,YAAA,CACA,qBAAA,CACA,gBAAA,CAOD,sBAEC,aDrBgB,CCwBjB,sBACC,UAAA,CAGD,sBACC,eAAA,CAEA,YAAA,CACA,kBAAA,CAGD,sBACC,aAAA,CACA,UAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `PzlGqJM1hGoHKQ8AQLGM`;
export var h2 = `SaMhc6GQRQO6oCrxZwiF`;
export var h3 = `lXzOHQTveUQxsJ17tfdi`;
export var p0 = `ow0XHspzyMklHggvU0kf`;
export var p0m = `QPZEOALC3lT7kzZNpvle`;
export var p1 = `bLjssHipnPszMMGwW05_`;
export var p1m = `nJJdLj8iICZVAzfN56b8`;
export var headerTitle = `ihMJN_22bB5agGSCWHnz`;
export var p2 = `gnCOvIX6Gz3pbraS_rEz`;
export var headerSubtitle = `jyEU304MRPfDZLbfDPnx`;
export var p2m = `AzNNsozXVFEHJ7Q7KdkB`;
export var p2n = `CLxQasYClC8_B_XGhKZo`;
export var p3 = `TMEmp2EsiazcV3Ic26N9`;
export var p3m = `XjPIM9P6svYCNmRgtRkZ`;
export var container = `KFYbfcRm8EGxYVVsCck3`;
export var header = `xjSrNhvz_cKBxbmRYojJ`;
export var headerText = `FTtE9Gzye7nzDuyjxkSN`;
export var spacer = `VKLcK4KCfEPSeByKCkap`;
export var buttonBar = `P92ZRNTTYZRHxLAqSRut`;
export var buttonBarSpacer = `_hym0BXKPXmc_ArYjMJL`;
export default ___CSS_LOADER_EXPORT___;
