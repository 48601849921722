import mapboxgl from "mapbox-gl";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useResizeDetector } from "react-resize-detector";
import { mapboxStyle } from "../../constants";
import {
	DeviceCategory,
	EventCategory,
	LocationType,
	MeasurementSystem,
	VelavuAPI,
	VelavuDevice,
	VelavuEvent,
	VelavuSite,
} from "velavu-js-api";
import AnalyticsStatType, {
	getNumericDeviceStat,
} from "../../data/analytics-stat-type";
import { DateRange, useDateRange } from "../../data/date-range";
import IconCalendar from "../../dynamicicons/icon-calendar";
import DateRangeSelector from "../../elements/date-range-selector";
import FlatButton from "../../elements/flat-button";
import LoadingDots from "../../elements/loading-dots";
import VelavuSwitch from "../../elements/velavu-switch";
import { useRemoteSync } from "../../helper/api-helper";
import { interpolateWeightedLine } from "../../helper/geo-helper";
import { makeStyleable } from "../../helper/icon-helper";
import { formatDateRange, formatUnit } from "../../helper/language-helper";
import {
	addImagesToMapboxStyle,
	useMapReadyEmitter,
} from "../../helper/map-helper";
import { clamp } from "../../helper/math-helper";
import useMapboxInjectableDevices from "../../injectable/mapbox/mapbox-injectable-devices";
import useMapboxInjectableHotspot, {
	HotspotPoint,
} from "../../injectable/mapbox/mapbox-injectable-hotspot";
import useMapboxInjectableLocationHistory, {
	InterpolatedLocationHistoryEntry,
} from "../../injectable/mapbox/mapbox-injectable-location-history";
import SignInContext from "../../sign-in-context";
import { mapAnalyticsUnitRange, UnitRange } from "./analytics";
import AnalyticsCardHeader from "./analytics-card-header";
import * as styles from "./analytics-map.module.scss";
import AnalyticsTimeSlider from "./analytics-time-slider";
import DeviceMarker, { deviceToDeviceMarker } from "../../data/device-marker";
import useMapboxInjectableSiteIndicators from "../../injectable/mapbox/mapbox-injectable-site-indicators";
import useMapboxInjectableSiteFloorplans from "../../injectable/mapbox/mapbox-injectable-site-floorplans";
import useMapboxInjectableSiteBoundaries from "../../injectable/mapbox/mapbox-injectable-site-boundaries";
import { useElementSize } from "../../helper/hook-helper";

export default function AnalyticsMap(props: {
	sites: VelavuSite[];
	stat: AnalyticsStatType;
	measurementSystem: MeasurementSystem;

	assets: VelavuDevice[];
	anchors: VelavuDevice[];

	dateRange: DateRange;
	setDateRange: (range: DateRange) => void;
}) {
	const userContext = useContext(SignInContext);
	const mapRef = useRef<HTMLDivElement | null>(null);
	const [mapboxMap, setMapboxMap] = useState<mapboxgl.Map | undefined>(
		undefined,
	);
	const mapInstance = useMapReadyEmitter(mapboxMap, async (map) => {
		await addImagesToMapboxStyle(map);
	});

	const mapContainerDimensions = useElementSize(mapRef);

	const { stat: propsStat } = props;

	//Reset the slider time when the date range changes
	const [sliderTime, setSliderTime] = useState<Date>(props.dateRange.end);
	useEffect(() => {
		setSliderTime(props.dateRange.end);
	}, [setSliderTime, props.dateRange]);

	const [showPoints, setShowPoints] = useState(false);

	useEffect(() => {
		//Wait until Mapbox is ready
		if (mapboxMap !== undefined || !userContext.isMapboxRegistered) return;

		//Find the center of all sites
		const centerPosition: [number, number] = [0, 0];
		if (props.sites.length > 0) {
			for (const site of props.sites) {
				const coordinates = site.coordinates;

				centerPosition[0] += coordinates[0];
				centerPosition[1] += coordinates[1];
			}

			centerPosition[0] /= props.sites.length;
			centerPosition[1] /= props.sites.length;
		}

		//Create the map
		const map = new mapboxgl.Map({
			container: mapRef.current!,
			style: mapboxStyle,
			center: centerPosition,
			zoom: 2,
		});
		setMapboxMap(map);
	}, [mapboxMap, props.sites, userContext.isMapboxRegistered, setMapboxMap]);

	//Subscribe to resize updates
	const {
		width: mapWidth,
		height: mapHeight,
		ref: mapResizeRef,
	} = useResizeDetector();
	useEffect(() => {
		mapboxMap?.resize();
	}, [mapboxMap, mapWidth, mapHeight]);

	const unitRange = mapAnalyticsUnitRange(
		props.stat,
		props.measurementSystem,
	);
	const statColorRange = mapStatColors(props.stat);

	const availableAssets = props.assets;

	const availableAnchors = useMemo(
		() => props.anchors.filter((anchor) => anchor.location !== undefined),
		[props.anchors],
	);

	const locationDataAssets = useRemoteSync(
		props.assets.map((device) => device.asset!.id),
		props.dateRange,
		(id, range) =>
			VelavuAPI.events
				.getAllEvents({
					assetID: id,
					category: EventCategory.Location,
					since: range.start,
					until: range.end,
				})
				//TODO: Handle pagination
				.then((result) => result.data),
	);

	const locationDataAnchors = useRemoteSync(
		props.anchors.map((device) => device.id),
		props.dateRange,
		(id, range) =>
			VelavuAPI.events
				.getAllEvents({
					deviceID: id,
					category: EventCategory.Location,
					since: range.start,
					until: range.end,
				})
				//TODO: Handle pagination
				.then((result) => result.data),
	);

	const interpolatedLocationHistoryEntries = useMemo(() => {
		type LocationDataAssociate = {
			device: VelavuDevice | undefined;
			resourceArray: VelavuEvent<EventCategory.Location>[];
		};

		return [
			...Object.entries(locationDataAssets).map(
				([deviceID, resourceArray]): LocationDataAssociate => ({
					device: availableAssets.find(
						(device) => device.asset!.id === deviceID,
					),
					resourceArray,
				}),
			),
			...Object.entries(locationDataAnchors).map(
				([deviceID, resourceArray]): LocationDataAssociate => ({
					device: availableAnchors.find(
						(device) => device.id === deviceID,
					),
					resourceArray,
				}),
			),
		]
			.map(
				({
					device,
					resourceArray,
				}): InterpolatedLocationHistoryEntry | undefined => {
					if (propsStat !== AnalyticsStatType.Location)
						return undefined;

					//Skip empty entries
					if (resourceArray.length === 0) return undefined;

					//Skip entries without a device
					if (device === undefined) return undefined;

					//Sort the resources in ascending order
					const sortedResourceArray = [...resourceArray].sort(
						(a, b) =>
							new Date(a.timestamp).getTime() -
							new Date(b.timestamp).getTime(),
					);

					const interpolationResult = interpolateWeightedLine(
						sliderTime.getTime(),
						sortedResourceArray.map((resource) => ({
							coordinates: resource.data.coordinates,
							value: new Date(resource.timestamp).getTime(),
							extra: resource.id,
						})),
					);

					return {
						device: device,
						locationArray: sortedResourceArray,
						interpolationResult: interpolationResult,
					};
				},
			)
			.filter(
				(it): it is InterpolatedLocationHistoryEntry =>
					it !== undefined,
			);
	}, [
		locationDataAssets,
		locationDataAnchors,
		sliderTime,
		availableAssets,
		availableAnchors,
		propsStat,
	]);

	const mapHotspots = useMemo(
		() =>
			[...availableAssets, ...availableAnchors]
				.map((device): HotspotPoint | undefined => {
					const location = device.location?.coordinates;
					const strength = inverseLerpUnit(
						getNumericDeviceStat(props.stat, device),
						unitRange,
					);

					if (location === undefined || strength === undefined)
						return undefined;
					return { location, strength };
				})
				.filter((it) => it !== undefined) as HotspotPoint[],
		[availableAssets, availableAnchors, props.stat, unitRange],
	);

	/* Get the final display objects to render to the map.
	 * When the user is viewing location history, devices can be moved
	 * to represent their location at the inspected time.
	 * For other statistic types, their latest location is used.
	 */
	const [displayAssets, displayAnchors] = useMemo((): [
		VelavuDevice[],
		VelavuDevice[],
	] => {
		if (props.stat !== AnalyticsStatType.Location) {
			return [availableAssets, availableAnchors];
		} else {
			const [assetEntries, anchorEntries] =
				interpolatedLocationHistoryEntries.reduce<
					[
						InterpolatedLocationHistoryEntry[],
						InterpolatedLocationHistoryEntry[],
					]
				>(
					(accumulator, entry) => {
						if (entry.device.category === DeviceCategory.Anchor) {
							accumulator[1].push(entry);
						} else {
							accumulator[0].push(entry);
						}

						return accumulator;
					},
					[[], []],
				);

			return [
				assetEntries.map((entry) =>
					applyDeviceInternalLocation(
						entry.device,
						entry.interpolationResult.coordinates,
					),
				),
				anchorEntries.map((entry) =>
					applyDeviceInternalLocation(
						entry.device,
						entry.interpolationResult.coordinates,
					),
				),
			];
		}
	}, [
		props.stat,
		availableAssets,
		availableAnchors,
		interpolatedLocationHistoryEntries,
	]);

	const displayDevices = useMemo(() => {
		return [...displayAssets, ...displayAnchors]
			.map((device) =>
				deviceToDeviceMarker(device, mapContainerDimensions),
			)
			.filter((it): it is DeviceMarker => it !== undefined);
	}, [displayAssets, displayAnchors, mapContainerDimensions]);

	useEffect(() => {
		if (mapboxMap === undefined) return;

		const bounds = new mapboxgl.LngLatBounds();
		[...displayAssets, ...displayAnchors]
			.map((asset) => asset.location?.coordinates)
			.filter((coordinates) => coordinates !== undefined)
			.forEach((coordinates) => bounds.extend(coordinates!));

		if (!bounds.isEmpty()) {
			mapboxMap.fitBounds(bounds, { maxZoom: 20, padding: 50 });
		}
	}, [displayAssets, displayAnchors, mapboxMap]);

	useMapboxInjectableHotspot(mapInstance, mapHotspots, statColorRange ?? []);

	const mapboxLayerIDSiteIndicators = useMapboxInjectableSiteIndicators(
		mapInstance,
		{
			sites: props.sites,
			mapDimensions: mapContainerDimensions,
		},
	);

	const mapboxLayerIDDevices = useMapboxInjectableDevices({
		mapInstance: mapInstance,
		devicesSource: displayDevices,
		belowLayer: mapboxLayerIDSiteIndicators,
	});
	const mapboxLayerIDSiteBoundaries = useMapboxInjectableSiteBoundaries(
		mapInstance,
		{
			sites: props.sites,
			belowLayer: mapboxLayerIDDevices,
		},
	);
	useMapboxInjectableSiteFloorplans(mapInstance, {
		sites: props.sites,
		belowLayer: mapboxLayerIDSiteBoundaries,
	});
	useMapboxInjectableLocationHistory(
		mapInstance,
		interpolatedLocationHistoryEntries,
		showPoints,
	);

	return (
		<>
			<AnalyticsCardHeader title={mapStatName(props.stat)}>
				{unitRange !== undefined && (
					<div className={styles.heatmapBar}>
						<span className={styles.heatmapBarLabel}>
							{formatUnit(props.stat, unitRange.min)}
						</span>
						<span
							style={{
								background: `linear-gradient(to right, ${statColorRange?.join(
									", ",
								)})`,
							}}
							className={styles.heatmapBarSlider}
						/>
						<span className={styles.heatmapBarLabel}>
							{formatUnit(props.stat, unitRange.max)}
						</span>
					</div>
				)}

				{props.stat === AnalyticsStatType.Location && (
					<>
						<VelavuSwitch
							label="Show points"
							toggled={showPoints}
							onChange={setShowPoints}
						/>

						<div className={styles.rangePickerWrapper}>
							<FlatButton
								className={styles.rangePickerButton}
								icon={makeStyleable(IconCalendar)}
								label={formatDateRange(props.dateRange)}
							/>

							<div className={styles.rangePickerPopover}>
								<DateRangeSelector
									range={props.dateRange}
									onUpdate={props.setDateRange}
								/>
							</div>
						</div>
					</>
				)}
			</AnalyticsCardHeader>

			{props.stat === AnalyticsStatType.Location && (
				<AnalyticsTimeSlider
					range={props.dateRange}
					value={sliderTime}
					onChange={setSliderTime}
				/>
			)}

			<div ref={mapResizeRef} className={styles.mapContainer}>
				<div ref={mapRef} className={styles.map} />
			</div>
		</>
	);
}

function mapStatName(stat: AnalyticsStatType): string | undefined {
	switch (stat) {
		case AnalyticsStatType.Temperature:
			return "Temperature heatmap";
		case AnalyticsStatType.Humidity:
			return "Humidity heatmap";
		case AnalyticsStatType.Battery:
			return "Battery heatmap";
		case AnalyticsStatType.Location:
			return "Location history";
	}
}

function mapStatColors(stat: AnalyticsStatType): string[] | undefined {
	switch (stat) {
		case AnalyticsStatType.Temperature:
			return ["#3A58E2", "#FFE588", "#E98B97"];
		case AnalyticsStatType.Humidity:
			return ["#E98B97", "#FFE588", "#3A58E2"];
		case AnalyticsStatType.Battery:
			return ["#E98B97", "#FFE588", "#8BE993"];
		default:
			return undefined;
	}
}

function applyDeviceInternalLocation(
	device: VelavuDevice,
	coordinates: [number, number],
): VelavuDevice {
	return {
		...device,
		location: {
			location_type: LocationType.Internal,
			timestamp: new Date().toISOString(),
			coordinates: coordinates,
		},
	};
}

function inverseLerpUnit(
	value: number | undefined,
	range: UnitRange | undefined,
) {
	if (value === undefined || range === undefined) return undefined;
	return clamp((value - range.min) / (range.max - range.min), 0, 1);
}

export function LoadingMap(props: { stat: AnalyticsStatType }) {
	const unitRange = mapAnalyticsUnitRange(props.stat);
	const statColorRange = mapStatColors(props.stat);
	const [dateRange, setDateRange] = useDateRange(7);

	return (
		<>
			<AnalyticsCardHeader title={mapStatName(props.stat)}>
				{unitRange !== undefined && (
					<div className={styles.heatmapBar}>
						<span className={styles.heatmapBarLabel}>
							{formatUnit(props.stat, unitRange.min)}
						</span>
						<span
							style={{
								background: `linear-gradient(to right, ${statColorRange?.join(
									", ",
								)})`,
							}}
							className={styles.heatmapBarSlider}
						/>
						<span className={styles.heatmapBarLabel}>
							{formatUnit(props.stat, unitRange.max)}
						</span>
					</div>
				)}

				{props.stat === AnalyticsStatType.Location && (
					<div className={styles.rangePickerWrapper}>
						<FlatButton
							className={styles.rangePickerButton}
							icon={makeStyleable(IconCalendar)}
							label={formatDateRange(dateRange)}
						/>

						<div className={styles.rangePickerPopover}>
							<DateRangeSelector
								range={dateRange}
								onUpdate={setDateRange}
							/>
						</div>
					</div>
				)}
			</AnalyticsCardHeader>
			<div className={styles.empty}>
				<LoadingDots size={40} color={"#a3b3cc"} />
			</div>
		</>
	);
}
