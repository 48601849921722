import React, { CSSProperties } from "react";

export default function IconBatteryWarn(props: {
	className?: string;
	style?: CSSProperties;
	color?: string;
	size?: number;
}) {
	return (
		<svg
			className={props.className}
			style={props.style}
			xmlns="http://www.w3.org/2000/svg"
			width={props.size ?? 24}
			height={props.size ?? 24}
			viewBox="0 0 24 24"
		>
			<path
				d="M9 22C7.34315 22 6 20.6569 6 19V7C6 5.34315 7.34315 4 9 4C9 2.89543 9.89543 2 11 2H13C14.1046 2 15 2.89543 15 4C16.6569 4 18 5.34314 18 7V19C18 20.6569 16.6569 22 15 22H9ZM12 7C11.4477 7 11 7.44772 11 8V13C11 13.5523 11.4477 14 12 14C12.5523 14 13 13.5523 13 13V8C13 7.44772 12.5523 7 12 7ZM12 16C11.4477 16 11 16.4477 11 17C11 17.5523 11.4477 18 12 18C12.5523 18 13 17.5523 13 17C13 16.4477 12.5523 16 12 16Z"
				fill={props.color ?? "black"}
			/>
		</svg>
	);
}
