// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.mGiFCaGzOmSsXoRmFOcj{font-weight:500;font-size:24px}.syHkxXdL0USm8zsfABsF{font-weight:500;font-size:20px}.PTwi_heX0nmgBtvJOVpE{font-weight:500;font-size:12px}.KyGfZ_c09BN_I4av6SPj{font-size:18px}.LVQ4AY8b9U626peaI8K5{font-size:18px;font-weight:500}.OW8fyfVFxDebY0QUW6Ww{font-size:16px}.kW6a0p_bOXOMNe1NsOgT{font-size:16px;font-weight:500}.do6XhDfKsq4DhJx38dx4{font-size:14px}.iadqt9du8qlKOH6oD_wD{font-size:14px;font-weight:500}.pdA66cY9mJ1fODBxSJk_{font-size:14px;font-weight:400}.dSHioZ28eidhRNchPf6C{font-size:12px}.VGsqt7oKtJSfg04eb884{font-size:12px;font-weight:500}.EHSItQEiPq2x18lQ_9f2{height:64px;padding-left:24px;padding-right:24px;display:flex;flex-direction:row;align-items:center}.oTbT7SVBcQrM89MEkQC3{flex-grow:1}.zQs_tL1nYgfd0bjSRxA4{flex:1 1 0;border-bottom-left-radius:20px;border-bottom-right-radius:20px}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/components/analytics/analytics-sidebar-sites.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,WAAA,CAEA,iBAAA,CACA,kBAAA,CAEA,YAAA,CACA,kBAAA,CACA,kBAAA,CAGD,sBACC,WAAA,CAGD,sBACC,UAAA,CAEA,8BAAA,CACA,+BAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `mGiFCaGzOmSsXoRmFOcj`;
export var h2 = `syHkxXdL0USm8zsfABsF`;
export var h3 = `PTwi_heX0nmgBtvJOVpE`;
export var p0 = `KyGfZ_c09BN_I4av6SPj`;
export var p0m = `LVQ4AY8b9U626peaI8K5`;
export var p1 = `OW8fyfVFxDebY0QUW6Ww`;
export var p1m = `kW6a0p_bOXOMNe1NsOgT`;
export var p2 = `do6XhDfKsq4DhJx38dx4`;
export var p2m = `iadqt9du8qlKOH6oD_wD`;
export var p2n = `pdA66cY9mJ1fODBxSJk_`;
export var p3 = `dSHioZ28eidhRNchPf6C`;
export var p3m = `VGsqt7oKtJSfg04eb884`;
export var header = `EHSItQEiPq2x18lQ_9f2`;
export var selector = `oTbT7SVBcQrM89MEkQC3`;
export var list = `zQs_tL1nYgfd0bjSRxA4`;
export default ___CSS_LOADER_EXPORT___;
