import React from "react";
import { VelavuRoute } from "velavu-js-api";
import Divider from "../../../../elements/divider";
import AssetRoutesDetail from "./asset-routes-detail";
import AssetRoutesItem from "./asset-routes-item";
import * as styles from "./asset-routes.module.scss";
import { NoTripIcon } from "../../../../img/icons/icons";

interface RoutesProps {
	routes?: VelavuRoute[];
	hoveredRouteID?: string;
	onHoverRoute: (route?: VelavuRoute) => void;
	selectedRoute?: VelavuRoute;
	onSelectRoute: (route: VelavuRoute | undefined) => void;
	onDeleteRoute: (route: VelavuRoute) => void;
}

function NoTrips() {
	return (
		<div className={styles.emptyContent}>
			<NoTripIcon />
			<span className={styles.heading}>No trip results</span>
			<span className={styles.info}>Try adjusting the date range.</span>
		</div>
	);
}

export default function AssetRoutes(props: RoutesProps) {
	if (!props.routes) {
		return (
			<div className={styles.empty}>
				<span>Checking routes…</span>
			</div>
		);
	} else if (props.routes.length === 0) {
		return <NoTrips />;
	} else {
		return (
			<>
				{props.routes.map((route, index) => (
					<React.Fragment key={route.id}>
						{index > 0 && <Divider />}
						<AssetRoutesItem
							route={route}
							isHovered={props.hoveredRouteID === route.id}
							onHover={() => props.onHoverRoute(route)}
							onHoverExit={() =>
								props.hoveredRouteID === route.id &&
								props.onHoverRoute(undefined)
							}
							isSelected={props.selectedRoute?.id === route.id}
							onSelect={props.onSelectRoute}
						/>
						{props.selectedRoute?.id === route.id && (
							<AssetRoutesDetail
								route={props.selectedRoute}
								onDeleteRoute={() =>
									props.selectedRoute &&
									props.onDeleteRoute(props.selectedRoute)
								}
							/>
						)}
					</React.Fragment>
				))}
			</>
		);
	}
}
