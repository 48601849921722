// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.p2YVwx7QD1bn_t3Udvr1{font-weight:500;font-size:24px}.dIXuAGL23WtsP6X7JT77{font-weight:500;font-size:20px}.DleuW4YveZE4IRUFAiwe{font-weight:500;font-size:12px}.SI1ePuzl7uqDYvbmje3g{font-size:18px}.SorftlKOCubyHbLrOoSQ{font-size:18px;font-weight:500}.cmRWs1Iugzk3u4AOOy4a{font-size:16px}.Tho85bvJN_ONom3pnGap{font-size:16px;font-weight:500}.NwGORWOUoekKMKljYKZH{font-size:14px}.IRgaCoO9lgYGKjoXAbE1{font-size:14px;font-weight:500}.Ey5oT4UugDneVbd4lGuj{font-size:14px;font-weight:400}.K9Ao8HIj5CVrS8Zhxl6e{font-size:12px}.Tn5fYZeSPNXVtluCm90g{font-size:12px;font-weight:500}.cP_ZrK0RN3TbRFw1PTzf{transform:translate(2px, 2px);position:absolute}.fXaV2yAVJB4cIs09PEqN{position:relative}.fXaV2yAVJB4cIs09PEqN .DNGEdgBQOWJgKxeQTAo0{position:absolute;top:0;left:0;opacity:0;transition:opacity 250ms ease;z-index:-1}.fXaV2yAVJB4cIs09PEqN:hover .DNGEdgBQOWJgKxeQTAo0{opacity:1}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/injectable/mapbox/mapbox-injectable-draw-walls.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,6BAAA,CACA,iBAAA,CAGD,sBACC,iBAAA,CAEA,4CACC,iBAAA,CACA,KAAA,CACA,MAAA,CAEA,SAAA,CACA,6BAAA,CACA,UAAA,CAED,kDACC,SAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `p2YVwx7QD1bn_t3Udvr1`;
export var h2 = `dIXuAGL23WtsP6X7JT77`;
export var h3 = `DleuW4YveZE4IRUFAiwe`;
export var p0 = `SI1ePuzl7uqDYvbmje3g`;
export var p0m = `SorftlKOCubyHbLrOoSQ`;
export var p1 = `cmRWs1Iugzk3u4AOOy4a`;
export var p1m = `Tho85bvJN_ONom3pnGap`;
export var p2 = `NwGORWOUoekKMKljYKZH`;
export var p2m = `IRgaCoO9lgYGKjoXAbE1`;
export var p2n = `Ey5oT4UugDneVbd4lGuj`;
export var p3 = `K9Ao8HIj5CVrS8Zhxl6e`;
export var p3m = `Tn5fYZeSPNXVtluCm90g`;
export var completeLineContainer = `cP_ZrK0RN3TbRFw1PTzf`;
export var completeLineButton = `fXaV2yAVJB4cIs09PEqN`;
export var hoverIndicator = `DNGEdgBQOWJgKxeQTAo0`;
export default ___CSS_LOADER_EXPORT___;
