// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.ETuCl5kzABW9DiWySsRC{font-weight:500;font-size:24px}.bQmZ52GxzT6neMoQe7te{font-weight:500;font-size:20px}.Fw3XZMBNdz2LNON4aDTc{font-weight:500;font-size:12px}.HAKmDzLznhjzeV8wQQZM{font-size:18px}.CSZkmHp8AFJexNoHkhjS{font-size:18px;font-weight:500}.okP59Tc_1aUFKI7fgb9Z{font-size:16px}.rgZz7_5HrmiVMaa0nlwr{font-size:16px;font-weight:500}.lmrEsUkdH0zwlZfrnfRo{font-size:14px}.DOpLCLlXrstBHVMvnFZD,.oEw84Y70aA10kPkzisqJ{font-size:14px;font-weight:500}.d4xR7SwYFsWFEG71KyBR{font-size:14px;font-weight:400}.neXeAI5SQcG9y5E7uR3E{font-size:12px}.eDbw2PkI7kkdQpGwsQUI{font-size:12px;font-weight:500}.UKLSJBq4Apr2iR2JmSvz{position:absolute;left:100%;top:50%;transform:translateY(-50%);display:flex;flex-direction:row;align-items:center;z-index:100}.oEw84Y70aA10kPkzisqJ{background-color:#00255d;border-radius:8px;padding:16px;color:#fff;white-space:pre;box-shadow:0 2px 8px rgba(40,41,61,.04),0 16px 24px rgba(96,97,112,.16);display:flex;flex-direction:row;align-items:center}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/components/sidebar/sidebar-tooltip.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,4CACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,iBAAA,CACA,SAAA,CACA,OAAA,CACA,0BAAA,CAEA,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,WAAA,CAGD,sBACC,wBDLW,CCMX,iBAAA,CACA,YAAA,CAEA,UAAA,CAEA,eAAA,CACA,uEACC,CAGD,YAAA,CACA,kBAAA,CACA,kBAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `ETuCl5kzABW9DiWySsRC`;
export var h2 = `bQmZ52GxzT6neMoQe7te`;
export var h3 = `Fw3XZMBNdz2LNON4aDTc`;
export var p0 = `HAKmDzLznhjzeV8wQQZM`;
export var p0m = `CSZkmHp8AFJexNoHkhjS`;
export var p1 = `okP59Tc_1aUFKI7fgb9Z`;
export var p1m = `rgZz7_5HrmiVMaa0nlwr`;
export var p2 = `lmrEsUkdH0zwlZfrnfRo`;
export var p2m = `DOpLCLlXrstBHVMvnFZD`;
export var tooltipContainer = `oEw84Y70aA10kPkzisqJ`;
export var p2n = `d4xR7SwYFsWFEG71KyBR`;
export var p3 = `neXeAI5SQcG9y5E7uR3E`;
export var p3m = `eDbw2PkI7kkdQpGwsQUI`;
export var tooltip = `UKLSJBq4Apr2iR2JmSvz`;
export default ___CSS_LOADER_EXPORT___;
