// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.T27etQdzio67Zm0K5T9_{font-weight:500;font-size:24px}.iNyhVF0D4MCaT0GbX_Qg{font-weight:500;font-size:20px}.vgNZxEe4mTd27ZCth_BB{font-weight:500;font-size:12px}.okfVhJa1WNFNAnNMREfS{font-size:18px}.uKcRfOZqw0pYAHfwp3iu{font-size:18px;font-weight:500}.JUaJfSqCKFeqOuV6S7Tg{font-size:16px}.NPEpD9oElXhQMyanWATn,.cCxEvyD9xq8_7Q2BLrQ_{font-size:16px;font-weight:500}.PP_OZh6E9o0zPNk8I7Bq,.IhtNyhpm2UePwFQT7gxJ{font-size:14px}.snxd55M_Gm1IxTYssCbx{font-size:14px;font-weight:500}.YbxgQSZEi5jVXDEbtezG{font-size:14px;font-weight:400}.u_3ts7hJ18EU8nxqHmth{font-size:12px}.YckXyxPUSXHBqemY0kRB{font-size:12px;font-weight:500}.IhtNyhpm2UePwFQT7gxJ{padding:12px;border-radius:8px;display:flex;flex-direction:column;align-items:flex-start}.R2zGnuood0jeRPpL4I2V{color:#3a58e2;background-color:#f0f5fd}.Mc4mAH77OIMqevaHb3NC{color:#00b283;background-color:#f0fcf8}.FCEvf_zBt63pZ6C2gQa4{color:#da3e52;background-color:#fceff1}.kRHbI77ETeluaJgs4Iub{margin-top:10px}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/elements/velavu-notice.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,4CACC,cAAA,CACA,eAAA,CAGD,4CACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,YAAA,CACA,iBAAA,CAEA,YAAA,CACA,qBAAA,CACA,sBAAA,CAKD,sBACC,aAAA,CACA,wBAAA,CAGD,sBACC,aAAA,CACA,wBAAA,CAGD,sBACC,aAAA,CACA,wBAAA,CAOD,sBACC,eAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `T27etQdzio67Zm0K5T9_`;
export var h2 = `iNyhVF0D4MCaT0GbX_Qg`;
export var h3 = `vgNZxEe4mTd27ZCth_BB`;
export var p0 = `okfVhJa1WNFNAnNMREfS`;
export var p0m = `uKcRfOZqw0pYAHfwp3iu`;
export var p1 = `JUaJfSqCKFeqOuV6S7Tg`;
export var p1m = `NPEpD9oElXhQMyanWATn`;
export var title = `cCxEvyD9xq8_7Q2BLrQ_`;
export var p2 = `PP_OZh6E9o0zPNk8I7Bq`;
export var container = `IhtNyhpm2UePwFQT7gxJ`;
export var p2m = `snxd55M_Gm1IxTYssCbx`;
export var p2n = `YbxgQSZEi5jVXDEbtezG`;
export var p3 = `u_3ts7hJ18EU8nxqHmth`;
export var p3m = `YckXyxPUSXHBqemY0kRB`;
export var containerInfo = `R2zGnuood0jeRPpL4I2V`;
export var containerConfirm = `Mc4mAH77OIMqevaHb3NC`;
export var containerError = `FCEvf_zBt63pZ6C2gQa4`;
export var bodyMargin = `kRHbI77ETeluaJgs4Iub`;
export default ___CSS_LOADER_EXPORT___;
