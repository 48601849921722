// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.kRPX0vQvy4o9zyDwYUf7{font-weight:500;font-size:24px}.gKZvyeUu6fby97pjf5AC{font-weight:500;font-size:20px}.rve6zmAOynegQA0EmdWg{font-weight:500;font-size:12px}.keDbis5WX8HNOiuC9qMd{font-size:18px}.u5ciaKj5FCkJKE4WI_z0{font-size:18px;font-weight:500}.NZfzGJgNR80EEAqit5tS{font-size:16px}.NiWo93tad1Bcxa27Tfe5{font-size:16px;font-weight:500}.kVqCUoh3wmE57vcggV9c,.Q67DYCO90Apyqw8Nd6Zg{font-size:14px}.S1LI9OTY8LBv5cvmjiwS{font-size:14px;font-weight:500}.SXCOLe1t91ciY_kkzQhw{font-size:14px;font-weight:400}.oxAr3MyjJidJbTG7ijyl{font-size:12px}.I5PjzoxcqwK4lvEC1Zt7{font-size:12px;font-weight:500}.alptPkdNncio5J3nEIkG{height:32px;padding:0 8px;display:flex;flex-direction:row;align-items:center;cursor:pointer}.alptPkdNncio5J3nEIkG:hover{background-color:#f5f8f9}.vSLSQWaqfORpYgN36ade{width:8px}.unezRMh00PxYlh7N0Ws6{flex:1}.Q67DYCO90Apyqw8Nd6Zg{color:#a3b3cc}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/elements/velavu-autocomplete-suggestion.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,4CACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,WAAA,CACA,aAAA,CAEA,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,cAAA,CAEA,4BACC,wBDOM,CCHR,sBACC,SAAA,CAGD,sBACC,MAAA,CAGD,sBAEC,aDfY","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `kRPX0vQvy4o9zyDwYUf7`;
export var h2 = `gKZvyeUu6fby97pjf5AC`;
export var h3 = `rve6zmAOynegQA0EmdWg`;
export var p0 = `keDbis5WX8HNOiuC9qMd`;
export var p0m = `u5ciaKj5FCkJKE4WI_z0`;
export var p1 = `NZfzGJgNR80EEAqit5tS`;
export var p1m = `NiWo93tad1Bcxa27Tfe5`;
export var p2 = `kVqCUoh3wmE57vcggV9c`;
export var hint = `Q67DYCO90Apyqw8Nd6Zg`;
export var p2m = `S1LI9OTY8LBv5cvmjiwS`;
export var p2n = `SXCOLe1t91ciY_kkzQhw`;
export var p3 = `oxAr3MyjJidJbTG7ijyl`;
export var p3m = `I5PjzoxcqwK4lvEC1Zt7`;
export var container = `alptPkdNncio5J3nEIkG`;
export var iconSpacer = `vSLSQWaqfORpYgN36ade`;
export var hintSpacer = `unezRMh00PxYlh7N0Ws6`;
export default ___CSS_LOADER_EXPORT___;
