// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.zLXiSJkjPfWW_kqrLP1C{font-weight:500;font-size:24px}.I5tnfz9sAoOpteVHsWGM{font-weight:500;font-size:20px}.IJZZiKjoh3bxejHuR34M{font-weight:500;font-size:12px}.UP8VzqRC32VLkpve2Umk{font-size:18px}.C4vAP0n4LJ26gwHCHRdA{font-size:18px;font-weight:500}.d0Zgix_zoOub4eQrXtpK{font-size:16px}.TmSOBLMUOA0HYfi4Yhb7,.TENLXSSca4ymw3C6TnLM{font-size:16px;font-weight:500}.I4qFcBhWh6iqAhebsmJ9,.kVcD0xuYUdGNLuxIXOTH{font-size:14px}.YGOXEZt5ZSqIrAv6LRtw{font-size:14px;font-weight:500}.YOkvdVBpTkmBoKX0cXjr{font-size:14px;font-weight:400}.Fj9kVzxFJAJklY3_IaCc{font-size:12px}.Tt3y_O93Y8cH83nWcAwk{font-size:12px;font-weight:500}.I_hTC6RVVU9P3AmRFqdQ{padding:16px;box-sizing:border-box;display:flex;flex-direction:column}.TENLXSSca4ymw3C6TnLM{margin-bottom:12px}.kVcD0xuYUdGNLuxIXOTH{color:#5f718c}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/components/dashboard/dropdown/geofence-list/geofence-list-item.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,4CACC,cAAA,CACA,eAAA,CAGD,4CACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,YAAA,CACA,qBAAA,CAEA,YAAA,CACA,qBAAA,CAGD,sBAEC,kBAAA,CAGD,sBAEC,aDJgB","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `zLXiSJkjPfWW_kqrLP1C`;
export var h2 = `I5tnfz9sAoOpteVHsWGM`;
export var h3 = `IJZZiKjoh3bxejHuR34M`;
export var p0 = `UP8VzqRC32VLkpve2Umk`;
export var p0m = `C4vAP0n4LJ26gwHCHRdA`;
export var p1 = `d0Zgix_zoOub4eQrXtpK`;
export var p1m = `TmSOBLMUOA0HYfi4Yhb7`;
export var title = `TENLXSSca4ymw3C6TnLM`;
export var p2 = `I4qFcBhWh6iqAhebsmJ9`;
export var subtitle = `kVcD0xuYUdGNLuxIXOTH`;
export var p2m = `YGOXEZt5ZSqIrAv6LRtw`;
export var p2n = `YOkvdVBpTkmBoKX0cXjr`;
export var p3 = `Fj9kVzxFJAJklY3_IaCc`;
export var p3m = `Tt3y_O93Y8cH83nWcAwk`;
export var container = `I_hTC6RVVU9P3AmRFqdQ`;
export default ___CSS_LOADER_EXPORT___;
