// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.Us9X2D_muPWZLijEOkOQ{font-weight:500;font-size:24px}.fkLVLD3dkQSgbyDI0_jC{font-weight:500;font-size:20px}._sr9L3uqCHVYzTkHkgBo{font-weight:500;font-size:12px}.mMf6Onb8P26OHjeqcILP{font-size:18px}._0ptWl5j63H91TNrRrUub{font-size:18px;font-weight:500}.ICKAj_uXW1ZDEh2MDmKI{font-size:16px}.G3V45ptAR5tGs7zXFEF8{font-size:16px;font-weight:500}.ZNno4CfJWYGj8gVSJ0z_,.jvLfjmj2pLvCVjmJhSmb{font-size:14px}.MjGQJDX6mYG5IakeiIGV{font-size:14px;font-weight:500}.s34Y3gxD1s1EnKy4JZXw{font-size:14px;font-weight:400}.r0I_R4UFxkn4W10YcDNT{font-size:12px}.NmC8ulSVZEtaH0I5P5it{font-size:12px;font-weight:500}.stAgo00EMF6FYwMoiiUQ{padding:20px;display:flex;flex-direction:row;align-items:center;gap:20px}.jvLfjmj2pLvCVjmJhSmb{width:150px;flex-shrink:0;display:flex;flex-direction:row;gap:12px}.CGuXPVjixtdfdrJCBcxJ{flex:1 1 0}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/components/analytics/analytics-time-slider.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,uBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,4CACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,YAAA,CAEA,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,QAAA,CAGD,sBAEC,WAAA,CACA,aAAA,CAEA,YAAA,CACA,kBAAA,CACA,QAAA,CAGD,sBACC,UAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `Us9X2D_muPWZLijEOkOQ`;
export var h2 = `fkLVLD3dkQSgbyDI0_jC`;
export var h3 = `_sr9L3uqCHVYzTkHkgBo`;
export var p0 = `mMf6Onb8P26OHjeqcILP`;
export var p0m = `_0ptWl5j63H91TNrRrUub`;
export var p1 = `ICKAj_uXW1ZDEh2MDmKI`;
export var p1m = `G3V45ptAR5tGs7zXFEF8`;
export var p2 = `ZNno4CfJWYGj8gVSJ0z_`;
export var label = `jvLfjmj2pLvCVjmJhSmb`;
export var p2m = `MjGQJDX6mYG5IakeiIGV`;
export var p2n = `s34Y3gxD1s1EnKy4JZXw`;
export var p3 = `r0I_R4UFxkn4W10YcDNT`;
export var p3m = `NmC8ulSVZEtaH0I5P5it`;
export var container = `stAgo00EMF6FYwMoiiUQ`;
export var slider = `CGuXPVjixtdfdrJCBcxJ`;
export default ___CSS_LOADER_EXPORT___;
