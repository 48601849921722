// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.QH2Tj8692mhVruVIaMkC{font-weight:500;font-size:24px}.uvB2Hu3zzabGhAXY56Vu{font-weight:500;font-size:20px}.JMpGPsTQfLndzNbaHyxF{font-weight:500;font-size:12px}.EFWeL8qw9RXpXnHIX7dY{font-size:18px}.YFDxJAhiV0_AltTViiKu{font-size:18px;font-weight:500}._7jkiNGAejpXLtTt9IzA{font-size:16px}.JzuPplifT4UetvA0KNmv{font-size:16px;font-weight:500}.QL1pWxtiL5BJ_DQAcmp9{font-size:14px}.i0NGCY28WbAZ5iMke8fS{font-size:14px;font-weight:500}.qEUaqAlzEOEf7bG3HsAD{font-size:14px;font-weight:400}.nim1AEczNuM66oKyVqKQ{font-size:12px}.NNAnX1wIejGR0Yls99Oa{font-size:12px;font-weight:500}.a2N20uwjqZAhaU9rjh0H{width:40px;height:40px;border-radius:50px;border:1px solid #ccd6e5;background-color:#fff;display:flex;align-items:center;justify-content:center}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/elements/inset-icon.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,UAAA,CACA,WAAA,CACA,kBAAA,CACA,wBAAA,CACA,qBDgBO,CCfP,YAAA,CACA,kBAAA,CACA,sBAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `QH2Tj8692mhVruVIaMkC`;
export var h2 = `uvB2Hu3zzabGhAXY56Vu`;
export var h3 = `JMpGPsTQfLndzNbaHyxF`;
export var p0 = `EFWeL8qw9RXpXnHIX7dY`;
export var p0m = `YFDxJAhiV0_AltTViiKu`;
export var p1 = `_7jkiNGAejpXLtTt9IzA`;
export var p1m = `JzuPplifT4UetvA0KNmv`;
export var p2 = `QL1pWxtiL5BJ_DQAcmp9`;
export var p2m = `i0NGCY28WbAZ5iMke8fS`;
export var p2n = `qEUaqAlzEOEf7bG3HsAD`;
export var p3 = `nim1AEczNuM66oKyVqKQ`;
export var p3m = `NNAnX1wIejGR0Yls99Oa`;
export var icon = `a2N20uwjqZAhaU9rjh0H`;
export default ___CSS_LOADER_EXPORT___;
