// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.y3GI52STeWXsHwDbVV7z{font-weight:500;font-size:24px}.MHBouzbVeDoKE5UjKkBt{font-weight:500;font-size:20px}.nA6qh2HLxlCxm6DkAoKZ{font-weight:500;font-size:12px}.exQMIPYs3OuYkarvZ2JK{font-size:18px}.EyvmXkbJ0C44Xxt0wY6O{font-size:18px;font-weight:500}.k9BCJXLz1xnhFQUv1kap{font-size:16px}.u_jchmkjEgwZW6EvbxOm{font-size:16px;font-weight:500}.x7Kii9ev53ToUZMb597E{font-size:14px}.QSX72g3YtvkU1wKJrvTf{font-size:14px;font-weight:500}.yD88d8t14bIpD5PdCa9N{font-size:14px;font-weight:400}.bmDLMpe_rYqNIeEl0lDc,.xnWx5uJskkamWR0kXFHb{font-size:12px}._UZApFUtqLUTvRcdO9_z{font-size:12px;font-weight:500}div.PfP0ydHZcgvpO7sjjWaw{flex:0 1 auto;overflow-y:auto;min-height:210px;background:#f5f8f9}.xnWx5uJskkamWR0kXFHb{display:flex;align-items:center;justify-content:center;height:48px;color:#5f718c}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/elements/event-list.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,4CACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,yBACC,aAAA,CACA,eAAA,CACA,gBAAA,CACA,kBAAA,CAGD,sBACC,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,WAAA,CAGA,aDHgB","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `y3GI52STeWXsHwDbVV7z`;
export var h2 = `MHBouzbVeDoKE5UjKkBt`;
export var h3 = `nA6qh2HLxlCxm6DkAoKZ`;
export var p0 = `exQMIPYs3OuYkarvZ2JK`;
export var p0m = `EyvmXkbJ0C44Xxt0wY6O`;
export var p1 = `k9BCJXLz1xnhFQUv1kap`;
export var p1m = `u_jchmkjEgwZW6EvbxOm`;
export var p2 = `x7Kii9ev53ToUZMb597E`;
export var p2m = `QSX72g3YtvkU1wKJrvTf`;
export var p2n = `yD88d8t14bIpD5PdCa9N`;
export var p3 = `bmDLMpe_rYqNIeEl0lDc`;
export var empty = `xnWx5uJskkamWR0kXFHb`;
export var p3m = `_UZApFUtqLUTvRcdO9_z`;
export var listScroll = `PfP0ydHZcgvpO7sjjWaw`;
export default ___CSS_LOADER_EXPORT___;
