import React from "react";
import AnalyticsStatType from "../../data/analytics-stat-type";
import VelavuSegmentedControls from "../../elements/velavu-segmented-controls";
import * as styles from "./analytics-status-bar.module.scss";

export default function AnalyticsStatusBar(props: {
	type: AnalyticsStatType;
	onChangeType: (type: AnalyticsStatType) => void;
	selectionCount: number;
}) {
	return (
		<div className={styles.content}>
			<VelavuSegmentedControls
				selectedIndex={props.type}
				onSelectIndex={props.onChangeType}
				labels={["Temperature", "Humidity", "Battery", "Location"]}
			/>
			<span className={styles.selectionLabel}>
				{props.selectionCount > 0
					? `Displaying results of ${props.selectionCount} selected`.toLowerCase()
					: "Select items to display".toLowerCase()}
			</span>
		</div>
	);
}
