// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.aYGw5x3MS9SxjPBVqNWu{font-weight:500;font-size:24px}.PjXpc_YL1lyzte6qhHnI{font-weight:500;font-size:20px}.ajXaEOO2gKJbPanb6OqN{font-weight:500;font-size:12px}.ahecqHzs2F5TBoqyaAhU{font-size:18px}.PxSFArItIZ3ofrAwRWDK{font-size:18px;font-weight:500}.pjz3qs_3E7WX8Z_O4y4j{font-size:16px}.LhVchUMigqWxNjIff1PO{font-size:16px;font-weight:500}.GGQwiSXr4315ydB72t58{font-size:14px}.hLrLFvv832Zd5vmHiVhG{font-size:14px;font-weight:500}.t5T_fhpxgnH8Q0LSecgI{font-size:14px;font-weight:400}.o3nAHx6kW4O3b6sy2JQB{font-size:12px}.pXOxwHTm1JcuWnnW_1x_{font-size:12px;font-weight:500}.hTJzNooMe4Za1Be1w1hy,.LtrXWu3fZICFw1swRgcg{width:48px;height:48px;border-radius:24px}.LtrXWu3fZICFw1swRgcg{position:relative}.orNAixre32mJhfdho5pJ{position:absolute;bottom:-5px;right:-5px}.F2VWAIF5fZHa7yUkgk8Z{background-color:#00255d}.CmEu6NSjig3iJNg9z2si{background-color:#ccd6e5}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/elements/velavu-item-icon.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,4CACC,UAAA,CACA,WAAA,CAEA,kBAAA,CAGD,sBAGC,iBAAA,CAGD,sBACC,iBAAA,CACA,WAAA,CACA,UAAA,CAGD,sBACC,wBAAA,CAGD,sBACC,wBAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `aYGw5x3MS9SxjPBVqNWu`;
export var h2 = `PjXpc_YL1lyzte6qhHnI`;
export var h3 = `ajXaEOO2gKJbPanb6OqN`;
export var p0 = `ahecqHzs2F5TBoqyaAhU`;
export var p0m = `PxSFArItIZ3ofrAwRWDK`;
export var p1 = `pjz3qs_3E7WX8Z_O4y4j`;
export var p1m = `LhVchUMigqWxNjIff1PO`;
export var p2 = `GGQwiSXr4315ydB72t58`;
export var p2m = `hLrLFvv832Zd5vmHiVhG`;
export var p2n = `t5T_fhpxgnH8Q0LSecgI`;
export var p3 = `o3nAHx6kW4O3b6sy2JQB`;
export var p3m = `pXOxwHTm1JcuWnnW_1x_`;
export var size = `hTJzNooMe4Za1Be1w1hy`;
export var main = `LtrXWu3fZICFw1swRgcg`;
export var indicator = `orNAixre32mJhfdho5pJ`;
export var iconAsset = `F2VWAIF5fZHa7yUkgk8Z`;
export var iconDevice = `CmEu6NSjig3iJNg9z2si`;
export default ___CSS_LOADER_EXPORT___;
