// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}._ZyZ_3SN2ZGjBrgiU8Qr{font-weight:500;font-size:24px}.SG3Jju7MElcjjkiiZ1_L{font-weight:500;font-size:20px}.x6fjJLSt45C01b0xkyqA{font-weight:500;font-size:12px}.ZNNJdLF22DMwDIYlEOqw{font-size:18px}.xEa7kVWtVTPGV5VkEkcZ{font-size:18px;font-weight:500}.NQVGeTnAipf3JK4kHMMc{font-size:16px}.ZXKAPYSBmnMMdvSWSwfM{font-size:16px;font-weight:500}.a1Sh4qHkSQxQjhy6QFem{font-size:14px}.CYeTK3JDLfG9PUXb7lQ3{font-size:14px;font-weight:500}.WVMPW_VkvhbSoKFx9imj{font-size:14px;font-weight:400}.wu8jRJIxBV4FeTlzMElx{font-size:12px}.EpAJIYRMyeAG8ikyj91u{font-size:12px;font-weight:500}.QyP8RhqZ5dAz2qMLoifB{width:32px;height:32px;border-radius:100px;background-color:#3a58e2;transition:background-color 250ms ease;display:flex;flex-direction:row;align-items:center;justify-content:center}.QyP8RhqZ5dAz2qMLoifB:hover{background-color:#6681ff}.dGDzUPNTr9tQZvSwuyER{opacity:.5}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/elements/velavu-arrow-button.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,UAAA,CACA,WAAA,CAEA,mBAAA,CAEA,wBDDS,CCET,sCAAA,CAEA,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,sBAAA,CAEA,4BACC,wBDTc,CCahB,sBACC,UAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `_ZyZ_3SN2ZGjBrgiU8Qr`;
export var h2 = `SG3Jju7MElcjjkiiZ1_L`;
export var h3 = `x6fjJLSt45C01b0xkyqA`;
export var p0 = `ZNNJdLF22DMwDIYlEOqw`;
export var p0m = `xEa7kVWtVTPGV5VkEkcZ`;
export var p1 = `NQVGeTnAipf3JK4kHMMc`;
export var p1m = `ZXKAPYSBmnMMdvSWSwfM`;
export var p2 = `a1Sh4qHkSQxQjhy6QFem`;
export var p2m = `CYeTK3JDLfG9PUXb7lQ3`;
export var p2n = `WVMPW_VkvhbSoKFx9imj`;
export var p3 = `wu8jRJIxBV4FeTlzMElx`;
export var p3m = `EpAJIYRMyeAG8ikyj91u`;
export var main = `QyP8RhqZ5dAz2qMLoifB`;
export var disabled = `dGDzUPNTr9tQZvSwuyER`;
export default ___CSS_LOADER_EXPORT___;
