import React from "react";
import * as globals from "../../../src/styles/global.icss.scss";
import { DFUImage, parseDFUName, VelavuDevice } from "velavu-js-api";
import IconArrowRight from "../../dynamicicons/icon-arrow-right";
import Divider from "../../elements/divider";
import VelavuButton from "../../elements/velavu-button";
import * as styles from "./tab-tag-dfu-install-modal.module.scss";

export default function TabTagDFUInstallModal(props: {
	tag: VelavuDevice;
	image: DFUImage;

	onConfirm?: VoidFunction;
	onCancel?: VoidFunction;
}) {
	const currentVersionName = props.tag.state?.app_version;
	const currentVersion =
		currentVersionName !== undefined
			? parseDFUName(currentVersionName).version
			: undefined;

	return (
		<div className={styles.container}>
			<span className={styles.title}>
				Do you want to install this firmware update?
			</span>
			<Divider />
			<div className={styles.innerContainer}>
				<span className={styles.textPrimary}>{props.tag.id}</span>

				<div className={styles.versionRow}>
					{currentVersion !== undefined ? (
						<>
							<span className={styles.textPrimary}>
								{currentVersion}
							</span>
							<IconArrowRight color={globals.textPrimary} />
							<span className={styles.textPrimary}>
								{props.image.version}
							</span>
						</>
					) : (
						<span className={styles.textPrimary}>
							{props.image.version}
						</span>
					)}
				</div>

				<span className={styles.textSecondary}>
					{props.image.comment}
				</span>
			</div>

			<div className={styles.buttonRow}>
				<VelavuButton
					className={styles.buttonRowButton}
					label="Cancel"
					outlined
					fullWidth
					onClick={props.onCancel}
				/>
				<div className={styles.buttonRowSpacer} />
				<VelavuButton
					className={styles.buttonRowButton}
					label="Install"
					fullWidth
					onClick={props.onConfirm}
				/>
			</div>
		</div>
	);
}
