// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.NVRCOmOlGTy1PhPZzbeK{font-weight:500;font-size:24px}.AGqYTbcoJOBfARI9Eypr{font-weight:500;font-size:20px}.VW4Bli3YH5nSnWrIfj1k{font-weight:500;font-size:12px}.YcQHHp_uvUSZdKF9qAqC{font-size:18px}.V8BTz6xfyxQLFgyhTyOL{font-size:18px;font-weight:500}.Uhb_22EFYMrXdvemcYCL{font-size:16px}.Sd4bFYbqK_316kbojM1m{font-size:16px;font-weight:500}.gPY2SeOaS9CA67xgHP8q{font-size:14px}.gk5POQyFIyd5k5s5pgHX{font-size:14px;font-weight:500}.leM8f67nyKdU7EDN8owP{font-size:14px;font-weight:400}.hFrgN0Tnxs7jDeLhrQfB{font-size:12px}.vImo0JOiqv2bXpt6K3Y3{font-size:12px;font-weight:500}.Jq6qkUPfy7fYPL0rPgSk{position:relative}.Jq6qkUPfy7fYPL0rPgSk .CVI67plc9KMN_kKvThPk{position:absolute;top:100%;right:0;overflow:hidden;z-index:10;background-color:#fff;border-radius:8px;box-shadow:2px 5px 10px 0 rgba(0,37,93,.15);display:none}.Jq6qkUPfy7fYPL0rPgSk:hover .CVI67plc9KMN_kKvThPk{display:block}.Jq6qkUPfy7fYPL0rPgSk:hover .E8T8W3QS6LCtqjAEYGRB{background-color:#f5f8f9}.tF8jcUcyroAkWZoH28Ns{flex:1 1 0;min-height:0;margin:0 12px 12px 12px}.bpG8RNUEvLJLgEj5Acgt{height:100%;width:100%;display:flex;justify-content:center;align-items:center}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/components/analytics/analytics-graph.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,iBAAA,CAEA,4CACC,iBAAA,CACA,QAAA,CACA,OAAA,CACA,eAAA,CACA,UAAA,CAEA,qBDWM,CCVN,iBAAA,CACA,2CAAA,CAEA,YAAA,CAIA,kDACC,aAAA,CAGD,kDACC,wBDNK,CCWR,sBACC,UAAA,CACA,YAAA,CACA,uBAAA,CAGD,sBACC,WAAA,CACA,UAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `NVRCOmOlGTy1PhPZzbeK`;
export var h2 = `AGqYTbcoJOBfARI9Eypr`;
export var h3 = `VW4Bli3YH5nSnWrIfj1k`;
export var p0 = `YcQHHp_uvUSZdKF9qAqC`;
export var p0m = `V8BTz6xfyxQLFgyhTyOL`;
export var p1 = `Uhb_22EFYMrXdvemcYCL`;
export var p1m = `Sd4bFYbqK_316kbojM1m`;
export var p2 = `gPY2SeOaS9CA67xgHP8q`;
export var p2m = `gk5POQyFIyd5k5s5pgHX`;
export var p2n = `leM8f67nyKdU7EDN8owP`;
export var p3 = `hFrgN0Tnxs7jDeLhrQfB`;
export var p3m = `vImo0JOiqv2bXpt6K3Y3`;
export var rangePickerWrapper = `Jq6qkUPfy7fYPL0rPgSk`;
export var rangePickerPopover = `CVI67plc9KMN_kKvThPk`;
export var rangePickerButton = `E8T8W3QS6LCtqjAEYGRB`;
export var graph = `tF8jcUcyroAkWZoH28Ns`;
export var empty = `bpG8RNUEvLJLgEj5Acgt`;
export default ___CSS_LOADER_EXPORT___;
