import React, { CSSProperties } from "react";
import IconInfo from "../dynamicicons/icon-info";
import { useToggleable } from "../helper/hook-helper";
import BottomTooltip from "./bottom-tooltip";
import * as styles from "./info-tooltip.module.scss";
import { classArr } from "../helper/style-helper";

interface InfoTooltipProps {
	style?: CSSProperties;
	className?: string;

	color?: string;
	children?: string | React.ReactNode;
}

export default function InfoTooltip(props: InfoTooltipProps) {
	const [isHover, enableHover, disableHover] = useToggleable();

	return (
		<div
			style={props.style}
			className={classArr(styles.container, props.className)}
			onMouseEnter={enableHover}
			onMouseLeave={disableHover}
		>
			<IconInfo size={16} color={props.color ?? "#3A58E2"} />
			{isHover && (
				<BottomTooltip>
					{typeof props.children === "string" ? (
						<span className={styles.text}>{props.children}</span>
					) : (
						props.children
					)}
				</BottomTooltip>
			)}
		</div>
	);
}
