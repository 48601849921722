// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.aZCcOOBiIN6_1nVVw9gG{font-weight:500;font-size:24px}.AjEIjlBSmBHnDhA5SD9h{font-weight:500;font-size:20px}.bNptOznvWnBSHuodmMUt{font-weight:500;font-size:12px}.zeNoA323Z3jKTMT4vTnC{font-size:18px}.e7BK7OYAJk2uTfqtahik{font-size:18px;font-weight:500}.AzTPy3FFCuvHx_0A4zn3{font-size:16px}.UqUwpXd10Dfm8JxbK7hC{font-size:16px;font-weight:500}.sG9WxVz_anA2CehTApCj{font-size:14px}.xAHxKpbs2GftO3v_V91z{font-size:14px;font-weight:500}.HPpNNnRLqDXraGc5VvZb{font-size:14px;font-weight:400}.yf1tWt4yyVNPyx748NsG{font-size:12px}.qc9zDF2aCAXlgkt9rZNw{font-size:12px;font-weight:500}.dQ7_45JOznHn569oIII9{width:48px;height:48px;position:relative}.mVmx0aVSMOfY5Wo4IsBu{width:100%;height:100%;display:flex;align-items:center;justify-content:center;border-radius:8px;transition:background-color 250ms ease;cursor:pointer}.mVmx0aVSMOfY5Wo4IsBu:hover{background-color:#f5f8f9}.qSbW3eDwuoIRN35CocJQ{position:absolute;top:8px;right:8px;width:8px;height:8px;border-radius:4px;background-color:#da3e52;pointer-events:none}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/components/sidebar/sidebar-icon.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,UAAA,CACA,WAAA,CACA,iBAAA,CAGD,sBACC,UAAA,CACA,WAAA,CAEA,YAAA,CACA,kBAAA,CACA,sBAAA,CAEA,iBAAA,CDsGA,sCAAA,CAEA,cAAA,CAEA,4BACC,wBAxGM,CCCR,sBACC,iBAAA,CACA,OAAA,CACA,SAAA,CAEA,SAAA,CACA,UAAA,CACA,iBAAA,CACA,wBAAA,CACA,mBAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `aZCcOOBiIN6_1nVVw9gG`;
export var h2 = `AjEIjlBSmBHnDhA5SD9h`;
export var h3 = `bNptOznvWnBSHuodmMUt`;
export var p0 = `zeNoA323Z3jKTMT4vTnC`;
export var p0m = `e7BK7OYAJk2uTfqtahik`;
export var p1 = `AzTPy3FFCuvHx_0A4zn3`;
export var p1m = `UqUwpXd10Dfm8JxbK7hC`;
export var p2 = `sG9WxVz_anA2CehTApCj`;
export var p2m = `xAHxKpbs2GftO3v_V91z`;
export var p2n = `HPpNNnRLqDXraGc5VvZb`;
export var p3 = `yf1tWt4yyVNPyx748NsG`;
export var p3m = `qc9zDF2aCAXlgkt9rZNw`;
export var container = `dQ7_45JOznHn569oIII9`;
export var button = `mVmx0aVSMOfY5Wo4IsBu`;
export var badge = `qSbW3eDwuoIRN35CocJQ`;
export default ___CSS_LOADER_EXPORT___;
