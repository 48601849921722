import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react";
import {
	DeviceCategory,
	NormalizedDeviceHardware,
	normalizeDeviceHardware,
	VelavuAPI,
	VelavuAsset,
	VelavuDevice,
	VelavuDeviceVesta,
	VelavuFloor,
	VelavuSite,
} from "velavu-js-api";
import VelavuAssetDevicePair from "../../../../data/asset-device-pair";
import IconDelete from "../../../../dynamicicons/icon-delete";
import IconEdit from "../../../../dynamicicons/icon-edit";
import Divider from "../../../../elements/divider";
import DropdownPanelToolbar from "../../../../elements/dropdown-panel-toolbar";
import VelavuMenu, {
	VelavuMenuItem,
	VelavuOverflowButton,
} from "../../../../elements/velavu-menu";
import {
	useVelavuModal,
	VelavuModalPrompt,
} from "../../../../elements/velavu-modal";
import VelavuTabSwitcher from "../../../../elements/velavu-tab-switcher";
import { useGeocoder } from "../../../../helper/api-helper";
import useAssetFiltering from "../../../../helper/assets-filter-hook";
import { MQTTContext, MQTTListener } from "../../../main/mqtt-provider";
import AssetListFilter from "../asset-list/asset-list-filter";
import AssetListItem from "../asset-list/asset-listitem";
import PinnedList from "../pinned-list";
import DevicesToolbar from "./devices-toolbar/devices-toolbar";
import SiteDetailAnchors from "./site-detail-anchors";
import SiteDetailFloors from "./site-detail-floors";
import SiteDetailGateways from "./site-detail-gateways";
import SiteDetailStatus from "./site-detail-status";
import * as styles from "./site-detail.module.scss";
import { SiteDetailState } from "./site-detail-state";
import SiteDetailEvents from "./site-detail-events";

export enum SiteDetailsTab {
	Status,
	Floors,
	Gateways,
	Anchors,
	Events,
}

interface SiteDetailProps {
	site: VelavuSite;
	siteDetailState: SiteDetailState;

	onBack?: VoidFunction;
	onClose?: VoidFunction;
	onEdit?: VoidFunction;
	onDelete?: VoidFunction;

	onDeletePlan?: (floor: VelavuFloor) => void;
	onEditFloor?: (floor: VelavuFloor) => void;
	onCreateFloor?: () => void;

	selectedFloor: string | undefined;
	onSelectFloor?: (id: string | undefined) => void;

	onHoverAnchor?: (anchor: VelavuDevice | undefined) => void;
	onSelectAnchor?: (anchor: VelavuDevice | undefined) => void;
	onSelectGateway?: (gateway: VelavuDevice | undefined) => void;

	onSelectAsset?: (assetID: string) => void;
	onHoverAsset?: (asset: VelavuAsset | undefined) => void;
}

export default function SiteDetail(props: SiteDetailProps) {
	const {
		devices,
		selectedTab,
		setDevices,
		setSelectedTab,
		showAssets,
		setShowAssets,
	} = props.siteDetailState;

	const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
	const openMenu = useCallback(
		(event: React.MouseEvent<HTMLElement>) =>
			setMenuAnchor(event.currentTarget),
		[setMenuAnchor],
	);
	const closeMenu = useCallback(() => setMenuAnchor(null), [setMenuAnchor]);
	const address = useGeocoder(props.site.coordinates);

	const pushModal = useVelavuModal();
	const propsSite = props.site;
	const propsOnEdit = props.onEdit;
	const editSite = useCallback(() => {
		closeMenu();
		if (propsOnEdit !== undefined) propsOnEdit();
	}, [closeMenu, propsOnEdit]);
	const propsOnDelete = props.onDelete;
	const deleteSite = useCallback(() => {
		closeMenu();
		pushModal((resolve) => (
			<VelavuModalPrompt
				title={"Delete the following site?"}
				object={propsSite.name}
				labelConfirm="Delete"
				confirmDanger
				onSelect={resolve}
			>
				The site and all associated data will be permanently deleted.
			</VelavuModalPrompt>
		)).then((result) => {
			if (result) {
				VelavuAPI.sites
					.deleteExistingSite(propsSite.id)
					.catch(() =>
						alert(`Failed to delete site ${propsSite.name}`),
					);
				if (propsOnDelete !== undefined) propsOnDelete();
			}
		});
	}, [closeMenu, pushModal, propsSite, propsOnDelete]);

	const anchors = useMemo(
		() =>
			devices?.filter(
				(device) => device.category === DeviceCategory.Anchor,
			),
		[devices],
	);

	const onlineAnchors = useMemo(
		() => anchors?.filter((anchor) => anchor.online),
		[anchors],
	);

	const assets = useMemo(() => {
		return devices
			?.map((device): VelavuAsset | undefined => device.asset)
			.filter((it): it is VelavuAsset => it !== undefined);
	}, [devices]);

	const assetDevicePairs = useMemo(() => {
		return devices
			?.map((device) => {
				return { asset: device.asset, device };
			})
			.filter((it) => it.asset !== undefined) as VelavuAssetDevicePair[];
	}, [devices]);

	const onlineAssets = useMemo(
		() => assets?.filter((asset) => asset.online),
		[assets],
	);

	const gateways = useMemo(
		() =>
			devices?.filter(
				(device) =>
					device.category === DeviceCategory.Tag &&
					normalizeDeviceHardware(device.hardware) ===
						NormalizedDeviceHardware.Argo,
			) as VelavuDeviceVesta[] | undefined,
		[devices],
	);

	const onlineGateways = useMemo(
		() => gateways?.filter((gateway) => gateway.online),
		[gateways],
	);

	//MQTT updates
	const mqttContext = useContext(MQTTContext);
	useEffect(() => {
		const listener: MQTTListener = {
			onDeviceStateUpdate: (deviceID, state) => {
				//Update the device
				if (!devices) return;

				const index = devices?.findIndex(
					(device) => device.id === deviceID,
				);
				if (index !== -1) {
					const copy = [...devices];
					const newDevice = { ...copy[index] };
					newDevice.state = state;

					copy[index] = newDevice;
					setDevices(copy);
				}
			},
		};

		mqttContext.addListener(listener);
		return () => mqttContext.removeListener(listener);
	}, [devices, setDevices, mqttContext]);

	const {
		assets: filteredAssets,
		filterCategory,
		filterStatus,
		isFiltering,
		floorFilter,
		searchText,
		setFilterCategory,
		setFilterStatus,
		setSearchText,
		showFilterMenu,
		toggleFilterMenu,
		setFloorFilter,
	} = useAssetFiltering(assetDevicePairs, props.selectedFloor);

	const {
		onSelectAsset: propsOnSelectAsset,
		onHoverAsset: propsOnHoverAsset,
	} = props;

	return (
		<>
			<VelavuMenu
				open={!!menuAnchor}
				onClose={closeMenu}
				refPosition={menuAnchor}
			>
				<VelavuMenuItem
					icon={(size, color) => (
						<IconEdit size={size} color={color} />
					)}
					label="Edit site"
					onClick={editSite}
				/>
				<VelavuMenuItem
					icon={(size, color) => (
						<IconDelete size={size} color={color} />
					)}
					label="Delete site"
					onClick={deleteSite}
					danger
				/>
			</VelavuMenu>

			<PinnedList>
				<DropdownPanelToolbar
					onBack={
						showAssets ? () => setShowAssets(false) : props.onBack
					}
					onClose={props.onClose}
				/>

				{showAssets ? (
					<>
						<div className={styles.assetCountCard}>
							{assets === undefined ? (
								<span>Loading devices...</span>
							) : (
								<React.Fragment>
									<span
										className={styles.assetCountCardNumber}
									>
										{assets.length}
									</span>

									<span
										className={styles.assetCountCardLabel}
									>
										Assets in site
									</span>
								</React.Fragment>
							)}
						</div>

						<DevicesToolbar
							searchText={searchText}
							setSearchText={setSearchText}
							floorSelection={floorFilter}
							onChangeFloor={setFloorFilter}
							highlightFilter={isFiltering}
							showFilterMenu={showFilterMenu}
							onClickShowFilterMenu={toggleFilterMenu}
						/>

						<Divider />

						{showFilterMenu && (
							<>
								<AssetListFilter
									filterCategory={filterCategory}
									selectCategory={setFilterCategory}
									filterStatus={filterStatus}
									selectStatus={setFilterStatus}
								/>
								<Divider />
							</>
						)}

						<div className={styles.list}>
							{(filteredAssets ?? []).map((asset) => (
								<AssetListItem
									key={asset.id}
									asset={asset}
									onClick={() =>
										propsOnSelectAsset?.(asset.id)
									}
									onMouseEnter={() =>
										propsOnHoverAsset?.(asset)
									}
									onMouseLeave={() =>
										propsOnHoverAsset?.(undefined)
									}
								/>
							))}
						</div>
					</>
				) : (
					<>
						<div className={styles.titleHeader}>
							<div className={styles.titleText}>
								<span className={styles.titleHeaderTitle}>
									{props.site.name}
								</span>
								<span className={styles.titleHeaderSubtitle}>
									{address}
								</span>
							</div>

							<VelavuOverflowButton
								onClick={openMenu}
								open={!!menuAnchor}
							/>
						</div>

						<VelavuTabSwitcher
							selectedKey={selectedTab}
							onSelectKey={setSelectedTab}
							labels={{
								[SiteDetailsTab.Status]: "Status",
								[SiteDetailsTab.Floors]: "Floors",
								[SiteDetailsTab.Gateways]: "Gateways",
								[SiteDetailsTab.Anchors]: "Anchors",
								[SiteDetailsTab.Events]: "Events",
							}}
							compact
						>
							{selectedTab === SiteDetailsTab.Status && (
								<SiteDetailStatus
									anchorCountOnline={onlineAnchors?.length}
									anchorCountTotal={anchors?.length}
									assetCountOnline={onlineAssets?.length}
									assetCountTotal={assets?.length}
									gatewayCountOnline={onlineGateways?.length}
									gatewayCountTotal={gateways?.length}
									gateways={gateways}
									online={props.site.online}
									onSelectGateways={() => setSelectedTab(2)}
									onSelectAnchors={() => {
										setSelectedTab(3);
									}}
									onSelectAssets={() => setShowAssets(true)}
								/>
							)}

							{selectedTab === SiteDetailsTab.Floors && (
								<SiteDetailFloors
									site={props.site}
									onDeletePlan={props.onDeletePlan}
									selectedFloor={props.selectedFloor}
									onSelectFloor={props.onSelectFloor}
									onEditFloor={props.onEditFloor}
									onCreateFloor={props.onCreateFloor}
								/>
							)}

							{selectedTab === SiteDetailsTab.Gateways && (
								<SiteDetailGateways
									gateways={gateways}
									onSelectGateway={props.onSelectGateway}
									onHoverGateway={props.onHoverAnchor}
									activeFloorID={props.selectedFloor}
								/>
							)}

							{selectedTab === SiteDetailsTab.Anchors && (
								<SiteDetailAnchors
									anchors={anchors}
									onSelectAnchor={props.onSelectAnchor}
									onHoverAnchor={props.onHoverAnchor}
									activeFloorID={props.selectedFloor}
								/>
							)}

							{selectedTab === SiteDetailsTab.Events && (
								<SiteDetailEvents siteID={props.site.id} />
							)}
						</VelavuTabSwitcher>
					</>
				)}
			</PinnedList>
		</>
	);
}
