import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as styles from "./settings-billing-history.module.scss";
import {
	HistoricalVelavuInvoice,
	VelavuInvoice,
	VelavuInvoiceStatus,
} from "velavu-js-api";
import SettingsBillingStatusText from "./settings-billing-status-text";
import InvoiceSelect from "./invoice-select";
import SettingsBillingUsageTable from "./settings-billing-usage-table";
import {
	formatBillingPrice,
	mapInvoiceStatusColors,
	mapInvoiceStatusText,
} from "./settings-billing-format";
import VelavuButton from "../../../elements/velavu-button";
import IconDownload from "../../../dynamicicons/icon-download";
import { makeStyleable } from "../../../helper/icon-helper";

export interface SettingsBillingHistoryProps {
	invoices: HistoricalVelavuInvoice[] | "error" | null;
}

export default function SettingsBillingHistory(
	props: SettingsBillingHistoryProps,
) {
	return (
		<div className={styles.container}>
			<span className={styles.title}>Billing History</span>

			<SettingsBillingHistoryBody {...props} />
		</div>
	);
}

function SettingsBillingHistoryBody(props: SettingsBillingHistoryProps) {
	//Loading state
	if (!props.invoices) {
		return (
			<SettingsBillingStatusText>
				Checking your billing history...
			</SettingsBillingStatusText>
		);
	}

	//Error state
	if (props.invoices === "error") {
		return (
			<SettingsBillingStatusText type="error">
				There was a problem checking your billing history. Please try
				again later.
			</SettingsBillingStatusText>
		);
	}

	//Empty state
	if (props.invoices.length === 0) {
		return (
			<SettingsBillingStatusText>
				No billing history available
			</SettingsBillingStatusText>
		);
	}

	return <SettingsBillingHistoryAvailable invoices={props.invoices} />;
}

function SettingsBillingHistoryAvailable(props: {
	invoices: HistoricalVelavuInvoice[];
}) {
	const [selectedInvoiceID, setSelectedInvoiceID] = useState<string | null>(
		null,
	);
	const selectedInvoice = useMemo<HistoricalVelavuInvoice | null>(() => {
		if (selectedInvoiceID === null) {
			return null;
		}

		return (
			props.invoices.find(
				(invoice) => invoice.id === selectedInvoiceID,
			) ?? null
		);
	}, [selectedInvoiceID, props.invoices]);

	useEffect(() => {
		if (props.invoices.length > 0) {
			//Select the first invoice
			setSelectedInvoiceID(props.invoices[0].id);
		} else {
			//Clear the selected invoice
			setSelectedInvoiceID(null);
		}
	}, [props.invoices, setSelectedInvoiceID]);

	if (selectedInvoiceID !== null) {
		return (
			<React.Fragment>
				<InvoiceSelect
					invoices={props.invoices}
					value={selectedInvoiceID}
					onChange={setSelectedInvoiceID}
				/>

				<SettingsBillingHistoryDetail invoice={selectedInvoice!} />
			</React.Fragment>
		);
	} else {
		return null;
	}
}

function SettingsBillingHistoryDetail(props: { invoice: VelavuInvoice }) {
	const [statusForeground, statusBackground] = mapInvoiceStatusColors(
		props.invoice.status,
	);

	const navigatePay = useCallback(() => {
		const paymentURL = props.invoice.payment_url;
		if (paymentURL !== undefined) {
			window.open(paymentURL, "_blank");
		}
	}, [props.invoice]);

	const navigateInvoice = useCallback(() => {
		const invoiceURL = props.invoice.invoice_pdf;
		if (invoiceURL !== undefined) {
			window.open(invoiceURL, "_blank");
		}
	}, [props.invoice]);

	return (
		<React.Fragment>
			<div className={styles.summary}>
				{/* Invoice amount */}
				<div className={styles.summaryCard}>
					<span>Invoice amount</span>

					<span className={styles.summaryCardBigText}>
						{formatBillingPrice(props.invoice.total)}
					</span>
				</div>

				{/*	Status */}
				<div className={styles.summaryCard}>
					<span>Status</span>

					<span
						style={{
							borderColor: statusForeground,
							backgroundColor: statusBackground,
						}}
						className={styles.summaryCardStatusIndicator}
					>
						<div
							style={{ backgroundColor: statusForeground }}
							className={styles.indicatorDot}
						/>

						<span>
							{mapInvoiceStatusText(props.invoice.status)}
						</span>
					</span>
				</div>

				{/* Actions */}
				<div className={styles.summaryCard}>
					<span>Actions</span>

					<span className={styles.summaryCardButtonRow}>
						<VelavuButton
							label="Pay"
							disabled={
								props.invoice.status !==
								VelavuInvoiceStatus.Open
							}
							onClick={navigatePay}
						/>
						<VelavuButton
							icon={makeStyleable(IconDownload)}
							onClick={navigateInvoice}
						/>
					</span>
				</div>
			</div>

			<span className={styles.subtitle}>Usage breakdown</span>

			<SettingsBillingUsageTable usage={props.invoice.usage} />
		</React.Fragment>
	);
}
