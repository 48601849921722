import React from "react";
import * as styles from "./floor-create-intro.module.scss";
import PinnedListHeader from "../pinned-list-header";
import wallsAnimation from "../../../../img/animations/animation-walls.gif";
import VelavuButton from "../../../../elements/velavu-button";
import PinnedList from "../pinned-list";

export interface FloorCreateIntroProps {
	onClose?: () => void;
	onNext?: () => void;
}

export default function FloorCreateIntro(props: FloorCreateIntroProps) {
	return (
		<PinnedList className={styles.pinnedList}>
			<div className={styles.header}>
				<PinnedListHeader onClose={props.onClose} />

				<img className={styles.image} src={wallsAnimation} alt="" />
			</div>

			<div className={styles.content}>
				<h1 className={styles.title}>Floor plan to 3D</h1>
				<p className={styles.body}>
					The floor plan builder is an easy way to import and trace
					the layout of your sites.
					<br />
					<br />
					If you do not have floor plans available you will be able
					draw your site directly on the map.
				</p>

				<VelavuButton
					onClick={props.onNext}
					fullWidth
					label="Get started"
				/>
			</div>
		</PinnedList>
	);
}
