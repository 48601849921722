// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.k8X6W9WHkLV15ykl5Mvm{font-weight:500;font-size:24px}.z2M1UXmAtVDF7OKgrpdO{font-weight:500;font-size:20px}.GjOgzbq589nluNFgJUrr{font-weight:500;font-size:12px}.OQDcwDOeXB3w_hN2GwNU{font-size:18px}.DYyb8A30w3plYzVYfB9T{font-size:18px;font-weight:500}.kW2vnVHkfg6k_sIhVYc5{font-size:16px}.a1gOb6Eq4aOmUGigwEIn{font-size:16px;font-weight:500}.zI258PYf0DfFbRm02fu9{font-size:14px}.L4daIlCa9D8ifWOGintJ,.enVbYtR4WNXMwXY2SjFI{font-size:14px;font-weight:500}.TtQiMVv6hjxnAfaKY2Lu{font-size:14px;font-weight:400}.zAxQfuZchLmbXrJQPvcx{font-size:12px}.FXNWcwtCMUH3ByGH3kxs{font-size:12px;font-weight:500}.r9Inf1DchjkWzI7x2dab{width:100%;display:flex;flex-direction:row}.l0O0uXZhg0VUUmkmjBp6{flex:1;flex-basis:100%}.enVbYtR4WNXMwXY2SjFI{padding:16px}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/components/dashboard/dropdown/asset-list/asset-list-filter.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,4CACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,UAAA,CAEA,YAAA,CACA,kBAAA,CAGD,sBACC,MAAA,CACA,eAAA,CAGD,sBAEC,YAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `k8X6W9WHkLV15ykl5Mvm`;
export var h2 = `z2M1UXmAtVDF7OKgrpdO`;
export var h3 = `GjOgzbq589nluNFgJUrr`;
export var p0 = `OQDcwDOeXB3w_hN2GwNU`;
export var p0m = `DYyb8A30w3plYzVYfB9T`;
export var p1 = `kW2vnVHkfg6k_sIhVYc5`;
export var p1m = `a1gOb6Eq4aOmUGigwEIn`;
export var p2 = `zI258PYf0DfFbRm02fu9`;
export var p2m = `L4daIlCa9D8ifWOGintJ`;
export var title = `enVbYtR4WNXMwXY2SjFI`;
export var p2n = `TtQiMVv6hjxnAfaKY2Lu`;
export var p3 = `zAxQfuZchLmbXrJQPvcx`;
export var p3m = `FXNWcwtCMUH3ByGH3kxs`;
export var container = `r9Inf1DchjkWzI7x2dab`;
export var column = `l0O0uXZhg0VUUmkmjBp6`;
export default ___CSS_LOADER_EXPORT___;
