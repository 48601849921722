import React, { useCallback, useMemo, useState } from "react";
import Divider from "../../elements/divider";
import velavuLogo from "../../img/logo.svg";
import * as styles from "./onboarding-dialog.module.scss";
import OnboardingStepAssets from "./onboarding-step-assets";
import OnboardingStepHardware from "./onboarding-step-hardware";
import OnboardingStepInvite from "./onboarding-step-invite";
import OnboardingStepOrganization from "./onboarding-step-organization";
import OnboardingStepWelcome from "./onboarding-step-welcome";

export default function OnboardingDialog(props: {
	resolve: VoidFunction;
	isFirstUser: boolean;
}) {
	const stepCount = props.isFirstUser ? 5 : 4;

	const [step, setStep] = useState(0);
	const navigateForwards = useCallback(() => {
		setStep((step) => step + 1);
	}, [setStep]);
	const navigateBackwards = useCallback(() => {
		setStep((step) => step - 1);
	}, [setStep]);

	const [organizationName, setOrganizationName] = useState<
		string | undefined
	>(undefined);
	const cleanOrganizationName = useMemo(
		() => organizationName?.trim(),
		[organizationName],
	);

	return (
		<div className={styles.container}>
			<img className={styles.logo} src={velavuLogo} alt="velavu" />
			<Divider className={styles.divider} />
			<div className={styles.content}>
				<div
					style={{ transform: `translateX(-${step * 100}%)` }}
					className={styles.contentContainer}
				>
					<OnboardingStepWelcome
						navigateForwards={navigateForwards}
					/>
					{props.isFirstUser && (
						<OnboardingStepOrganization
							navigateForwards={navigateForwards}
							navigateBackwards={navigateBackwards}
							organization={organizationName}
							setOrganization={setOrganizationName}
						/>
					)}
					<OnboardingStepInvite
						navigateForwards={navigateForwards}
						navigateBackwards={navigateBackwards}
						organizationName={cleanOrganizationName!}
					/>
					<OnboardingStepHardware
						navigateForwards={navigateForwards}
						navigateBackwards={navigateBackwards}
					/>
					<OnboardingStepAssets
						navigateForwards={props.resolve}
						navigateBackwards={navigateBackwards}
					/>
				</div>
			</div>
			<div className={styles.ellipses}>
				{[...Array(stepCount)].map((element, index) => (
					<div
						key={index}
						className={`${styles.ellipse} ${
							index === step ? styles.ellipseSelected : ""
						}`}
					/>
				))}
			</div>
		</div>
	);
}
