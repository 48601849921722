import React, { CSSProperties } from "react";

export default function IconGeofenceEntered(props: {
	className?: string;
	style?: CSSProperties;
	color?: string;
	size?: number;
}) {
	return (
		<svg
			className={props.className}
			style={props.style}
			width={props.size ?? 24}
			height={props.size ?? 24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M4 6a2 2 0 0 0 1 1.7v8.6A2 2 0 1 0 7.7 19h8.6a2 2 0 1 0 2.7-2.7V11a1 1 0 1 0-2 0v5.3a2 2 0 0 0-.7.7H7.7a2 2 0 0 0-.7-.7V7.7c.3-.1.6-.4.7-.7H13a1 1 0 1 0 0-2H7.7A2 2 0 0 0 4 6Z"
				fill={props.color ?? "black"}
			/>
			<path
				d="M19 4.8c.4.4.4 1 0 1.4l-5.4 5.4 2.2 2.1-5.2.6a1 1 0 0 1-1-1.1l.5-5.1 2.1 2.1 5.4-5.4a1 1 0 0 1 1.4 0Z"
				fill={props.color ?? "black"}
			/>
		</svg>
	);
}
