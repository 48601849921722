import React, { useEffect, useState } from "react";
import * as styles from "./pagination-toast.module.scss";
import * as globals from "../../../styles/global.icss.scss";
import FloatingPopupPanel from "../../../elements/floating-popup-panel";
import VelavuButton from "../../../elements/velavu-button";
import InfoTooltip from "../../../elements/info-tooltip";
import Divider from "../../../elements/divider";
import { classArr } from "../../../helper/style-helper";

export type PaginationToastType = "loadArea" | "loadMore";

export interface PaginationToastProps {
	show: boolean;
	type?: PaginationToastType;
	onClickLoad?: () => void;
	isLoading?: boolean;
}

/**
 * A toast component used for prompting the user to paginate more devices
 */
export default function PaginationToast(props: PaginationToastProps) {
	//Load default props
	const isLoading: boolean = props.isLoading ?? false;

	//Cache last type as to not switch unnecessarily when hiding animation is playing
	const [type, setType] = useState<PaginationToastType>(
		props.type ?? "loadArea",
	);
	useEffect(() => {
		if (!props.show) {
			return;
		}

		if (props.type !== undefined) {
			setType(props.type);
		}
	}, [props.type, setType, props.show]);

	return (
		<FloatingPopupPanel show={props.show}>
			{type === "loadArea" ? (
				<PaginationToastLoadArea
					onClickLoad={props.onClickLoad}
					isLoading={isLoading}
				/>
			) : (
				<PaginationToastLoadMore
					onClickLoad={props.onClickLoad}
					isLoading={isLoading}
				/>
			)}
		</FloatingPopupPanel>
	);
}

interface PaginationToastLoadAreaProps {
	onClickLoad?: () => void;
	isLoading?: boolean;
}

export function PaginationToastLoadArea(props: PaginationToastLoadAreaProps) {
	return (
		<VelavuButton
			size="small"
			label="Search this area"
			disabled={props.isLoading}
			loadingIndicator={props.isLoading}
			onClick={props.onClickLoad}
		/>
	);
}

interface PaginationToastLoadMoreProps {
	onClickLoad?: () => void;
	isLoading?: boolean;
}

export function PaginationToastLoadMore(props: PaginationToastLoadMoreProps) {
	return (
		<div className={styles.loadMoreLayout}>
			<span className={styles.loadMoreLabel}>Partial results</span>

			<InfoTooltip
				className={styles.loadMoreInfo}
				color={globals.textSecondary}
			>
				<p className={styles.tooltipParagraph}>
					Large amount of assets
				</p>
				<Divider color={globals.textSecondary} crossPadding />
				<p
					className={classArr(
						styles.tooltipParagraph,
						styles.tooltipParagraphSecondary,
					)}
				>
					To view all assets, click{" "}
					<span className={styles.tooltipEmphasize}>Load more</span>{" "}
					until this window disappears.
				</p>
			</InfoTooltip>

			<VelavuButton
				size="small"
				label="Load more"
				disabled={props.isLoading}
				loadingIndicator={props.isLoading}
				onClick={props.onClickLoad}
			/>
		</div>
	);
}
