// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.vRZKjSF3jIM6ZxxIeGVg{font-weight:500;font-size:24px}.YvdabppjTUHVQh8PvJOf{font-weight:500;font-size:20px}.sJAdmlCBhpmG7NeP6rNe{font-weight:500;font-size:12px}.zNpidBDw8BSeuDpC131X{font-size:18px}.ONGOqBBlR8z0Hi81YxHb{font-size:18px;font-weight:500}.x5ptSCDF5dEg6UvFwu2B{font-size:16px}.qgPf8uMhqGN_LVuUqMsz{font-size:16px;font-weight:500}.DfEDsselYWyFQWpXGb8I{font-size:14px}.Jf1GSInPmHHEQpl96KdA{font-size:14px;font-weight:500}.qgdlvEM5OtOaKmTxcPyQ{font-size:14px;font-weight:400}.XQTju_j_9buMKqcNbPY6{font-size:12px}.bXcRSc4Ox8pvw3AeRO5j{font-size:12px;font-weight:500}textarea{overflow:hidden;margin-bottom:20px;padding:11px;max-height:190px;border-color:#e2e4e5;border-radius:8px;color:#00255d}textarea:focus{filter:drop-shadow(0px 0px 2px rgba(40, 41, 61, 0.04)) drop-shadow(0px 4px 8px rgba(96, 97, 112, 0.16))}span.xMnvSM6Wlpc7crmlyMxv{color:#5f718c;margin-bottom:5px}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/components/assets/create/asset-new.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,SACC,eAAA,CACA,kBAAA,CACA,YAAA,CACA,gBAAA,CACA,oBAAA,CACA,iBAAA,CACA,aDGc,CCDd,eACC,uGAAA,CAKF,0BACC,aDLgB,CCMhB,iBAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `vRZKjSF3jIM6ZxxIeGVg`;
export var h2 = `YvdabppjTUHVQh8PvJOf`;
export var h3 = `sJAdmlCBhpmG7NeP6rNe`;
export var p0 = `zNpidBDw8BSeuDpC131X`;
export var p0m = `ONGOqBBlR8z0Hi81YxHb`;
export var p1 = `x5ptSCDF5dEg6UvFwu2B`;
export var p1m = `qgPf8uMhqGN_LVuUqMsz`;
export var p2 = `DfEDsselYWyFQWpXGb8I`;
export var p2m = `Jf1GSInPmHHEQpl96KdA`;
export var p2n = `qgdlvEM5OtOaKmTxcPyQ`;
export var p3 = `XQTju_j_9buMKqcNbPY6`;
export var p3m = `bXcRSc4Ox8pvw3AeRO5j`;
export var label = `xMnvSM6Wlpc7crmlyMxv`;
export default ___CSS_LOADER_EXPORT___;
