import React, { CSSProperties } from "react";

export default function IconAddImage(props: {
	className?: string;
	style?: CSSProperties;
	color?: string;
	size?: number;
}) {
	return (
		<svg
			className={props.className}
			style={props.style}
			xmlns="http://www.w3.org/2000/svg"
			width={props.size ?? 48}
			height={props.size ?? 48}
			viewBox="0 0 48 48"
		>
			<circle
				cx="24"
				cy="24"
				r="23.5"
				fill="white"
				stroke={props.color ?? "black"}
			/>
			<path
				d="M32 25.3307H25.3333V31.9973C25.3333 32.351 25.1928 32.6901 24.9428 32.9401C24.6927 33.1902 24.3536 33.3307 24 33.3307C23.6463 33.3307 23.3072 33.1902 23.0572 32.9401C22.8071 32.6901 22.6666 32.351 22.6666 31.9973V25.3307H16C15.6463 25.3307 15.3072 25.1902 15.0572 24.9401C14.8071 24.6901 14.6666 24.351 14.6666 23.9973C14.6666 23.6437 14.8071 23.3046 15.0572 23.0545C15.3072 22.8045 15.6463 22.664 16 22.664H22.6666V15.9973C22.6666 15.6437 22.8071 15.3046 23.0572 15.0545C23.3072 14.8045 23.6463 14.664 24 14.664C24.3536 14.664 24.6927 14.8045 24.9428 15.0545C25.1928 15.3046 25.3333 15.6437 25.3333 15.9973V22.664H32C32.3536 22.664 32.6927 22.8045 32.9428 23.0545C33.1928 23.3046 33.3333 23.6437 33.3333 23.9973C33.3333 24.351 33.1928 24.6901 32.9428 24.9401C32.6927 25.1902 32.3536 25.3307 32 25.3307Z"
				fill={props.color ?? "black"}
			/>
		</svg>
	);
}
