import React, { useRef, useState } from "react";
import AssetCategorization, {
	mapCategoryName,
} from "../data/asset-categorization";
import { AssetCategory } from "velavu-js-api";
import IconAdd from "../dynamicicons/icon-add";
import IconArrowForward from "../dynamicicons/icon-arrow-forward";
import IconClose from "../dynamicicons/icon-close";
import { CategoryTag, GroupTag } from "./categorization-tag";
import InfoTooltip from "./info-tooltip";
import VelavuAutocomplete, {
	AutocompleteDivider,
	AutocompleteEntry,
} from "./velavu-autocomplete";
import * as styles from "./velavu-categorization-autocomplete.module.scss";

interface AutocompleteInputProps {
	value?: AssetCategorization;
	onChange: (value?: AssetCategorization) => void;
	categorization: AssetCategorization[];

	containerProps?: React.DetailedHTMLProps<
		React.HTMLAttributes<HTMLDivElement>,
		HTMLDivElement
	>;
	inputProps?: React.DetailedHTMLProps<
		React.InputHTMLAttributes<HTMLInputElement>,
		HTMLInputElement
	>;
}

const autocompleteLimit = 3;
export default function CategorizationAutocomplete(
	props: AutocompleteInputProps,
) {
	const [text, setText] = useState("");
	const searchableText = text.trim().toLowerCase();
	const inputRef = useRef<HTMLInputElement>(null);

	//Filtering the suggestions
	let suggestions: AssetCategorization[];
	const availableAdditions: AssetCategory[] = [];
	if (searchableText.length === 0) {
		suggestions = props.categorization.slice(0, autocompleteLimit);
	} else {
		suggestions = props.categorization
			.filter((suggestion) =>
				suggestion.group
					.trim()
					.toLowerCase()
					.startsWith(searchableText),
			)
			.slice(0, 3);
		for (const assetCategory of Object.values(AssetCategory)) {
			if (
				!props.categorization.find(
					(suggestion) =>
						suggestion.category === assetCategory &&
						suggestion.group.trim().toLowerCase() ===
							searchableText,
				)
			) {
				availableAdditions.push(assetCategory);
			}
		}
	}

	function clearInput() {
		setText("");
		props.onChange(undefined);
		setTimeout(() => inputRef.current!.focus(), 0);
	}

	return (
		<div className={styles.container}>
			<div className={styles.label}>
				<span className={styles.labelText}>Category & Group</span>
				<InfoTooltip>
					Category & Group categorizes your assets to keep them
					organized and easily found
				</InfoTooltip>
			</div>
			<VelavuAutocomplete
				ref={inputRef}
				containerProps={props.containerProps}
				inputProps={{
					type: "text",
					placeholder: "What is the Asset? Ex. Truck",
					value: text,
					onChange: (event) => setText(event.target.value),
					...props.inputProps,
				}}
				contentOverride={
					!props.value ? undefined : (
						<div
							style={{
								cursor: "pointer",
								flex: 1,
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
							}}
							onClick={clearInput}
						>
							<CategoryTag category={props.value.category} />
							<div style={{ width: 8 }} />
							<GroupTag
								categorization={{
									category: props.value.category,
									group: props.value.group,
								}}
							/>
							<div style={{ flex: 1 }} />
							<IconClose color="#A3B3CC" size={16} />
						</div>
					)
				}
				disableSuggestions={!!props.value}
			>
				{suggestions.length > 0 && (
					<>
						<AutocompleteDivider />
						{suggestions.map((suggestion) => (
							<AutocompleteEntry
								key={`${suggestion.category} ${suggestion.group}`}
								icon={
									<IconArrowForward
										color="#A3B3CC"
										size={16}
									/>
								}
								onClick={() => props.onChange(suggestion)}
							>
								<CategoryTag category={suggestion.category} />
								<div style={{ width: 8 }} />
								<GroupTag categorization={suggestion} />
							</AutocompleteEntry>
						))}
					</>
				)}
				{availableAdditions.length > 0 && (
					<>
						<AutocompleteDivider />
						{availableAdditions.map((addition) => {
							const categorization: AssetCategorization = {
								category: addition,
								group: text,
							};

							return (
								<AutocompleteEntry
									key={`addition ${addition}`}
									icon={<IconAdd color="#A3B3CC" size={16} />}
									onClick={() =>
										props.onChange(categorization)
									}
									hint={`Add "${text}" to ${mapCategoryName(
										addition,
									)}`}
								>
									<CategoryTag category={addition} />
									<div style={{ width: 8 }} />
									<GroupTag categorization={categorization} />
								</AutocompleteEntry>
							);
						})}
					</>
				)}
			</VelavuAutocomplete>
		</div>
	);
}
