import React, { useCallback, useEffect, useState } from "react";
import {
	TryResolveData,
	useVelavuModal,
	VelavuModalPrompt,
} from "../../elements/velavu-modal";
import * as styles from "./settings-modal.module.scss";
import VelavuTabSwitcher from "../../elements/velavu-tab-switcher";
import SimpleObservable from "../../helper/simple-observable";
import ChangePasswordModal from "./change-password-modal";
import SettingsAlerts from "./settings-alerts";
import SettingsDevices from "./settings-devices";
import SettingsEvents from "./settings-events";
import SettingsOrganization from "./settings-organization";
import SettingsTokens from "./settings-tokens";
import SettingsUser from "./settings-user";
import SettingsAdvanced from "./settings-advanced";
import SettingsBilling from "./settings-billing/settings-billing";

export enum SettingsTab {
	User,
	Devices,
	Alerts,
	Events,
	Organization,
	Billing,
	Tokens,
	Advanced,
}

export default function SettingsModal(props: {
	onTryResolve: SimpleObservable<TryResolveData<any>>;
	initialTabKey?: SettingsTab;
}) {
	const [tabKey, setTabKey] = useState<SettingsTab>(
		props.initialTabKey ?? SettingsTab.User,
	);
	const [isDirty, setDirty] = useState(false);

	const pushModal = useVelavuModal();
	const changePassword = useCallback(
		() => pushModal((close) => <ChangePasswordModal onClose={close} />),
		[pushModal],
	);

	const updateTabKey = useCallback(
		(key: SettingsTab) => {
			//If we have unsaved data, prompt the user before switching tabs
			if (isDirty) {
				pushModal((close) => (
					<VelavuModalPrompt
						title="Discard changes?"
						labelConfirm="Discard"
						labelCancel="Cancel"
						onSelect={close}
					>
						Any unsaved changes will be lost
					</VelavuModalPrompt>
				)).then((result) => result && setTabKey(key));
			} else {
				setTabKey(key);
			}
		},
		[isDirty, setTabKey, pushModal],
	);

	const propsOnTryResolve = props.onTryResolve;
	useEffect(() => {
		const listener: (data: TryResolveData<any>) => void = (data) => {
			if (isDirty) {
				//If we have unsaved data, prevent the user from going back and display a confirmation dialog
				data.cancel();
				pushModal((close) => (
					<VelavuModalPrompt
						title="Discard changes?"
						labelConfirm="Discard"
						labelCancel="Cancel"
						onSelect={close}
					>
						Any unsaved changes will be lost
					</VelavuModalPrompt>
				)).then((result) => result && data.initiateResolve());
			}
		};
		propsOnTryResolve.subscribe(listener);
		return () => propsOnTryResolve.unsubscribe(listener);
	}, [isDirty, pushModal, propsOnTryResolve]);

	return (
		<>
			<div
				style={{
					width: 640,
					boxSizing: "border-box",
					maxHeight: "75vh",
					display: "flex",
					flexDirection: "column",
				}}
			>
				<VelavuTabSwitcher
					selectedKey={tabKey}
					onSelectKey={updateTabKey}
					labels={{
						[SettingsTab.User]: "User",
						[SettingsTab.Devices]: "Devices",
						[SettingsTab.Alerts]: "Alerts",
						[SettingsTab.Events]: "Events",
						[SettingsTab.Organization]: "Organization",
						[SettingsTab.Billing]: "Billing",
						[SettingsTab.Tokens]: "API Tokens",
						[SettingsTab.Advanced]: "Advanced",
					}}
				>
					<div className={styles.container}>
						{tabKey === SettingsTab.User && (
							<SettingsUser
								isDirty={isDirty}
								setDirty={setDirty}
								changePassword={changePassword}
							/>
						)}
						{tabKey === SettingsTab.Devices && (
							<SettingsDevices
								isDirty={isDirty}
								setDirty={setDirty}
							/>
						)}

						{tabKey === SettingsTab.Alerts && (
							<SettingsAlerts
								isDirty={isDirty}
								setDirty={setDirty}
							/>
						)}
						{tabKey === SettingsTab.Events && (
							<SettingsEvents
								isDirty={isDirty}
								setDirty={setDirty}
							/>
						)}
						{tabKey === SettingsTab.Organization && (
							<SettingsOrganization />
						)}
						{tabKey === SettingsTab.Billing && <SettingsBilling />}
						{tabKey === SettingsTab.Tokens && <SettingsTokens />}
						{tabKey === SettingsTab.Advanced && (
							<SettingsAdvanced
								isDirty={isDirty}
								setDirty={setDirty}
							/>
						)}
					</div>
				</VelavuTabSwitcher>
			</div>
		</>
	);
}
