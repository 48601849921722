// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.thb8Twhm_KDgca4o_NyJ{font-weight:500;font-size:24px}.JBy_hS8iHSUfpDjSw44G{font-weight:500;font-size:20px}.llzKGpvoNGkAH90IwPHA{font-weight:500;font-size:12px}.QMnGZIcF7eAjc0I2Nvtb{font-size:18px}.ZpHm8Iq_wUeeJaqkWdaQ{font-size:18px;font-weight:500}.xTgQxvfn55SgmpbrdMsH{font-size:16px}.WAUwshQb7gVVeE8kU8AL{font-size:16px;font-weight:500}.E9yWI0ENMxtbZR_XGF90,.V40fmaiOcacFvvozPhRo{font-size:14px}.NFJwoCtQslBO6ifpo09L,.MwOmOzdegGafrFAjbmzk{font-size:14px;font-weight:500}.GUtIY6DJAQX6Bo4DfEtA{font-size:14px;font-weight:400}.xbqE2eD6jpBSAIFVSsN7{font-size:12px}.B_LLNnsNpiByQ3b4p1_q{font-size:12px;font-weight:500}.UHNlaxywbp6Lmu6o5jQu{display:flex;flex-direction:column;justify-content:center;align-items:center;cursor:pointer;width:320px;height:200px;border:1px solid #e2e4e5;border-radius:8px;transition:250ms ease}.UHNlaxywbp6Lmu6o5jQu:hover{background-color:#e5eaf0;border-color:#3a58e2}.MwOmOzdegGafrFAjbmzk{color:#00255d;margin-top:10px}.V40fmaiOcacFvvozPhRo{color:#5f718c;margin-top:6px}.hUXPGaNiCQlfxH42EYh4{display:none}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/elements/site-modal-upload-button.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,4CACC,cAAA,CAGD,4CACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,kBAAA,CACA,cAAA,CAEA,WAAA,CACA,YAAA,CAEA,wBAAA,CACA,iBAAA,CACA,qBDoGoB,CClGpB,4BACC,wBDDW,CCEX,oBDXQ,CCeV,sBAEC,aDdW,CCgBX,eAAA,CAGD,sBAEC,aDlBgB,CCoBhB,cAAA,CAGD,sBACC,YAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `thb8Twhm_KDgca4o_NyJ`;
export var h2 = `JBy_hS8iHSUfpDjSw44G`;
export var h3 = `llzKGpvoNGkAH90IwPHA`;
export var p0 = `QMnGZIcF7eAjc0I2Nvtb`;
export var p0m = `ZpHm8Iq_wUeeJaqkWdaQ`;
export var p1 = `xTgQxvfn55SgmpbrdMsH`;
export var p1m = `WAUwshQb7gVVeE8kU8AL`;
export var p2 = `E9yWI0ENMxtbZR_XGF90`;
export var subtitle = `V40fmaiOcacFvvozPhRo`;
export var p2m = `NFJwoCtQslBO6ifpo09L`;
export var title = `MwOmOzdegGafrFAjbmzk`;
export var p2n = `GUtIY6DJAQX6Bo4DfEtA`;
export var p3 = `xbqE2eD6jpBSAIFVSsN7`;
export var p3m = `B_LLNnsNpiByQ3b4p1_q`;
export var container = `UHNlaxywbp6Lmu6o5jQu`;
export var input = `hUXPGaNiCQlfxH42EYh4`;
export default ___CSS_LOADER_EXPORT___;
