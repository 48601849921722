/*------------------------------*
 | @author  Cameron Slupeiks    |
 | @date    2020.09.23          |
 | Copyright Brash Inc. (2020)  |
 *------------------------------*/

import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import logo from "../../img/logo.svg";
import velavuCity from "../../img/velavu-city.png";
import * as styles from "./authentication.module.scss";
import Challenge from "./challenge/challenge";
import LogIn from "./log-in/log-in";
import Recover from "./recover/recover";
import RecoverReset from "./recover/recover-reset";

export default function Authentication() {
	useEffect(() => {
		document.title = `Velavu`;
	}, []);

	return (
		<div className={styles.verticalSplit}>
			<div className={styles.interactSplit}>
				<div className={styles.interactColumn}>
					<img className={styles.logo} src={logo} alt="Velavu" />
					<Routes>
						<Route
							path="recover/reset"
							element={<RecoverReset />}
						/>
						<Route path="recover" element={<Recover />} />
						<Route path="challenge" element={<Challenge />} />
						<Route path="/" element={<LogIn />} />
					</Routes>
				</div>
			</div>
			<div className={styles.imageSplit}>
				<img
					className={styles.velavuCity}
					src={velavuCity}
					alt="Velavu City"
				/>
			</div>
		</div>
	);
}
