// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.Br8Y703SZ1T0OamDdpJ8{font-weight:500;font-size:24px}.C__neUFPZRe2Zi5dvwYw{font-weight:500;font-size:20px}.TKgokLlebs8pLzQI7jbn{font-weight:500;font-size:12px}.NQR1IgWA_9oO4yl8r89S{font-size:18px}.gTGW7OPkt3fCTCiW82wF{font-size:18px;font-weight:500}.HFc5Ok1kXIFdY2ovkcRq{font-size:16px}.NQZVijNa9uyod8pVGaUw{font-size:16px;font-weight:500}.bqBPrkpNaHZLv9L1Cl4V{font-size:14px}.gfzbR9eLDqr6On3cMRV0{font-size:14px;font-weight:500}.EjyJArgKPrB_9TOcWeI8{font-size:14px;font-weight:400}.sdfrxBSgfBqdfzlzMJ5Y{font-size:12px}.JEDVM85d0Eiiojcd4G1r{font-size:12px;font-weight:500}.vWJdEveKJWcte9r9m_Hr{width:100%;border-radius:8px}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/components/onboarding/onboarding-step-hardware.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,UAAA,CACA,iBAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `Br8Y703SZ1T0OamDdpJ8`;
export var h2 = `C__neUFPZRe2Zi5dvwYw`;
export var h3 = `TKgokLlebs8pLzQI7jbn`;
export var p0 = `NQR1IgWA_9oO4yl8r89S`;
export var p0m = `gTGW7OPkt3fCTCiW82wF`;
export var p1 = `HFc5Ok1kXIFdY2ovkcRq`;
export var p1m = `NQZVijNa9uyod8pVGaUw`;
export var p2 = `bqBPrkpNaHZLv9L1Cl4V`;
export var p2m = `gfzbR9eLDqr6On3cMRV0`;
export var p2n = `EjyJArgKPrB_9TOcWeI8`;
export var p3 = `sdfrxBSgfBqdfzlzMJ5Y`;
export var p3m = `JEDVM85d0Eiiojcd4G1r`;
export var image = `vWJdEveKJWcte9r9m_Hr`;
export default ___CSS_LOADER_EXPORT___;
