import React, { useCallback, useContext, useEffect, useState } from "react";
import VelavuSlider from "../../elements/velavu-slider";
import VelavuSwitchBlock from "../../elements/velavu-switch-block";
import { patchArray } from "../../helper/array-helper";
import { dirtyCheckArray, usePreference } from "../../helper/hook-helper";
import { getReadableSpeedingThreshold } from "../../helper/pref-helper";
import SignInContext from "../../sign-in-context";
import ComponentSaveBar from "./component-save-bar";
import * as styles from "./settings-events.module.scss";
import { EventDataDrivingEventDirection } from "velavu-js-api";

const drivingEvents: [EventDataDrivingEventDirection, string][] = [
	["ACCELERATION", "Harsh acceleration"],
	["BRAKING", "Harsh braking"],
	["TURNING", "Harsh turning"],
];

export default function SettingsEvents(props: {
	isDirty: boolean;
	setDirty: (dirty: boolean) => void;
}) {
	const userContext = useContext(SignInContext);
	const updateVelavuUser = userContext.updateVelavuUser;

	const [eventsSelection, setEventsSelection, eventsSelectionDirty] =
		usePreference(
			userContext.preferences.eventsEventFilter,
			dirtyCheckArray,
		);
	const [speedingVisibility, setSpeedingVisibility, speedingVisibilityDirty] =
		usePreference(userContext.preferences.eventsSpeedingVisibility);
	const [speedingThreshold, setSpeedingThreshold, speedingThresholdDirty] =
		usePreference(userContext.preferences.eventsSpeedingThreshold);

	const [isLoading, setLoading] = useState(false);
	const [lastSaveResult, setLastSaveResult] = useState<boolean | null>(null);

	const saveChanges = useCallback(() => {
		updateVelavuUser({
			settings: {
				eventsEventFilter: eventsSelection,
				eventsSpeedingVisibility: speedingVisibility,
				eventsSpeedingThreshold: speedingThreshold,
			},
		})
			.then(() => {
				setLastSaveResult(true);
			})
			.catch((error) => {
				console.log(error);
				setLastSaveResult(false);
			})
			.finally(() => setLoading(false));
	}, [
		eventsSelection,
		speedingVisibility,
		speedingThreshold,
		updateVelavuUser,
	]);

	const propsSetDirty = props.setDirty;
	useEffect(() => {
		propsSetDirty(
			eventsSelectionDirty ||
				speedingVisibilityDirty ||
				(speedingVisibility && speedingThresholdDirty),
		);
	}, [
		eventsSelectionDirty,
		speedingVisibilityDirty,
		speedingVisibility,
		speedingThresholdDirty,
		propsSetDirty,
	]);

	return (
		<div className={styles.container}>
			<h1 className={styles.title}>Driving events</h1>
			<span className={styles.info}>
				Select the items you would like to have displayed in the trip
				history view.
			</span>

			<div className={styles.inputSection}>
				<div className={styles.inputColumn}>
					{drivingEvents.map(([id, label]) => {
						const isSelected = eventsSelection.includes(id);
						return (
							<VelavuSwitchBlock
								key={id}
								className={styles.switchBlock}
								toggled={isSelected}
								onChange={() =>
									patchArray(
										setEventsSelection as (
											updater: (
												stringArray: string[],
											) => string[],
										) => void,
										id,
										!isSelected,
									)
								}
								label={label}
							/>
						);
					})}
				</div>
				<div className={styles.inputSpacer} />
				<div className={styles.inputColumn}>
					<VelavuSwitchBlock
						toggled={speedingVisibility}
						onChange={() =>
							setSpeedingVisibility(!speedingVisibility)
						}
						label="Speeding"
					>
						<div
							className={`${styles.thresholdContainer} ${
								speedingVisibility
									? styles.thresholdContainerExpanded
									: ""
							}`}
						>
							<div className={styles.thresholdContent}>
								<div className={styles.thresholdText}>
									<span className={styles.thresholdTitle}>
										Threshold over limit
									</span>
									<span className={styles.thresholdValue}>
										{getReadableSpeedingThreshold(
											speedingThreshold,
											userContext.preferences
												.measurementSystem,
										)}
									</span>
								</div>

								<VelavuSlider
									min={0}
									max={3}
									value={speedingThreshold}
									onChange={setSpeedingThreshold}
									variant="marked"
								/>
							</div>
						</div>
					</VelavuSwitchBlock>
				</div>
			</div>

			<ComponentSaveBar
				disabled={!props.isDirty || isLoading}
				onClick={saveChanges}
				labelOK="Driving events settings saved"
				labelError="Failed to save driving events settings"
				result={lastSaveResult}
			/>
		</div>
	);
}
