import React, { CSSProperties } from "react";

export default function IconMissing(props: {
	className?: string;
	style?: CSSProperties;
	color?: string;
	size?: number;
}) {
	return (
		<svg
			className={props.className}
			style={props.style}
			width={props.size ?? 24}
			height={props.size ?? 24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M9.5 9.5C9.5 8.94772 9.05228 8.5 8.5 8.5H8C5.79086 8.5 4 10.2909 4 12.5V16.5C4 18.7091 5.79086 20.5 8 20.5H16C18.2091 20.5 20 18.7091 20 16.5V12.5C20 10.2909 18.2091 8.5 16 8.5H15.5C14.9477 8.5 14.5 8.94772 14.5 9.5C14.5 10.0523 14.9477 10.5 15.5 10.5H16C17.1046 10.5 18 11.3954 18 12.5V16.5C18 17.6046 17.1046 18.5 16 18.5L8 18.5C6.89543 18.5 6 17.6046 6 16.5L6 12.5C6 11.3954 6.89543 10.5 8 10.5H8.5C9.05228 10.5 9.5 10.0523 9.5 9.5Z"
				fill={props.color ?? "black"}
			/>
			<path
				d="M13 4.5C13 3.94771 12.5523 3.5 12 3.5C11.4477 3.5 11 3.94772 11 4.5L11 12.1008H8L11.2191 16.1247C11.6195 16.6251 12.3805 16.6251 12.7809 16.1247L16 12.1008H13L13 4.5Z"
				fill={props.color ?? "black"}
			/>
		</svg>
	);
}
