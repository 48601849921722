import React, { CSSProperties, useCallback } from "react";
import { useToggleable } from "../../helper/hook-helper";
import * as styles from "./sidebar-icon.module.scss";
import SidebarTooltip from "./sidebar-tooltip";

interface SidebarIconProps {
	children?: React.ReactNode;
	tooltip?: string | React.ReactNode;
	onClick?: VoidFunction;

	clickCausesLayout?: boolean;
	badge?: boolean;
	className?: string;
	style?: CSSProperties;
}

export default function SidebarIcon(props: SidebarIconProps) {
	const [isHover, enableHover, disableHover] = useToggleable();

	const { onClick: propsOnClick, clickCausesLayout } = props;
	const onClick = useCallback(() => {
		if (clickCausesLayout) disableHover();
		if (propsOnClick) propsOnClick();
	}, [propsOnClick, clickCausesLayout, disableHover]);

	return (
		<div
			className={`${styles.container} ${
				props.className ? props.className : ""
			}`}
			style={props.style}
			onMouseEnter={enableHover}
			onMouseLeave={disableHover}
		>
			<button className={styles.button} onClick={onClick}>
				{props.children}
			</button>
			{isHover && props.tooltip && (
				<SidebarTooltip>{props.tooltip}</SidebarTooltip>
			)}
			{props.badge && <div className={styles.badge} />}
		</div>
	);
}
