// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.wdE2sIVwn2atKbwTtXP3{font-weight:500;font-size:24px}.wU25gg_cz2t7HIhpwqzw{font-weight:500;font-size:20px}.UfjOcaTpF4iFp8BfhKMW{font-weight:500;font-size:12px}.CXZ2F4onTzbOeANHoKBy{font-size:18px}.JzL1FLceyY_CLTP4vigw{font-size:18px;font-weight:500}.KLLwTKrK_qXUEH3PFI1z{font-size:16px}.GsU0Y17_VOD0dGQEUv_i{font-size:16px;font-weight:500}.Icuf3mSuMQUfckUOEKxp{font-size:14px}.ju7ZxMRtOS4ang8FnHwb{font-size:14px;font-weight:500}.bLxIuKw8DsdcQ7o_w5QE{font-size:14px;font-weight:400}.qVIr3YoTm6w6P2XmRJa6{font-size:12px}.wt2VJ697kILaUjikt0zw{font-size:12px;font-weight:500}.A5lpfeRzKmOi4V2AFMuB{background-color:#fff;padding:4px;border-radius:20px;display:flex;flex-direction:row;align-items:stretch;box-shadow:2px 5px 10px 0 rgba(0,37,93,.15)}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/widgets/map-controls.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,qBAAA,CAEA,WAAA,CAEA,kBAAA,CAEA,YAAA,CACA,kBAAA,CACA,mBAAA,CAEA,2CAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `wdE2sIVwn2atKbwTtXP3`;
export var h2 = `wU25gg_cz2t7HIhpwqzw`;
export var h3 = `UfjOcaTpF4iFp8BfhKMW`;
export var p0 = `CXZ2F4onTzbOeANHoKBy`;
export var p0m = `JzL1FLceyY_CLTP4vigw`;
export var p1 = `KLLwTKrK_qXUEH3PFI1z`;
export var p1m = `GsU0Y17_VOD0dGQEUv_i`;
export var p2 = `Icuf3mSuMQUfckUOEKxp`;
export var p2m = `ju7ZxMRtOS4ang8FnHwb`;
export var p2n = `bLxIuKw8DsdcQ7o_w5QE`;
export var p3 = `qVIr3YoTm6w6P2XmRJa6`;
export var p3m = `wt2VJ697kILaUjikt0zw`;
export var controls = `A5lpfeRzKmOi4V2AFMuB`;
export default ___CSS_LOADER_EXPORT___;
