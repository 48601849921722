import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
	NormalizedDeviceHardware,
	normalizeDeviceHardware,
	VelavuAPI,
} from "velavu-js-api";
import VelavuButton from "../../../elements/velavu-button";
import VelavuIDInput from "../../../elements/velavu-id-input";
import VelavuNotice from "../../../elements/velavu-notice";
import * as styles from "./modal-register-tag-id.module.scss";

const tagIDRegex = /^[0-9a-zA-Z]{5}$/;

interface ModalRegisterTagIDProps {
	id: string;
	setID: React.Dispatch<React.SetStateAction<string>>;
	onSubmit: (id: string) => void;
	loading?: boolean;
	error: string;
}

export default function ModalRegisterTagID(props: ModalRegisterTagIDProps) {
	const [isVesta, setIsVesta] = useState<boolean | undefined>(undefined);

	const {
		id: propsID,
		onSubmit: propsOnSubmit,
		loading: propsLoading,
	} = props;

	const inputValid = useMemo(() => {
		return tagIDRegex.test(propsID);
	}, [propsID]);

	const isAvailable = useCallback(async () => {
		try {
			const response = await VelavuAPI.devices.queryDeviceByIDOrSerial({
				id: propsID,
			});

			if (
				normalizeDeviceHardware(response.device.hardware) ===
				NormalizedDeviceHardware.Argo
			) {
				setIsVesta(true);
			} else {
				setIsVesta(false);
			}
		} catch (error) {
			console.warn(error);
		}
	}, [propsID]);

	const handleSubmit = useCallback(
		(event: React.FormEvent) => {
			event.preventDefault();

			//Ignoring if we don't have valid input or we're loading
			if (!inputValid || propsLoading) return;

			if (propsOnSubmit) propsOnSubmit(propsID);
		},
		[inputValid, propsID, propsLoading, propsOnSubmit],
	);

	useEffect(() => {
		if (inputValid) isAvailable();
	}, [inputValid, isAvailable]);

	return (
		<form className={styles.container} onSubmit={handleSubmit}>
			<div className={styles.inputContainer}>
				<VelavuIDInput
					value={props.id}
					onChange={props.setID}
					disabled={props.loading}
					error={props.error !== ""}
					autofocus
				/>
			</div>

			{props.error ? (
				<VelavuNotice type="error" body={props.error} />
			) : (
				<>
					<VelavuNotice
						type="info"
						body="Please enter the case-sensitive 5-digit code located on the rear of the device."
					/>
					<VelavuNotice
						style={{ marginTop: 16 }}
						type={isVesta === false ? "error" : "info"}
						body="Only Vesta devices can be registered through the web app. Please use the mobile app to register all other devices."
					/>
				</>
			)}
			<VelavuButton
				className={styles.button}
				label="Register"
				disabled={
					(inputValid && !isVesta) ||
					!inputValid ||
					!isVesta ||
					props.loading
				}
				fullWidth
				submit
			/>
		</form>
	);
}
