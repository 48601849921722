// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.slLpeH9TmZvTbANB8VXT{font-weight:500;font-size:24px}.C3mirPjMk_CAAz3n0YJk{font-weight:500;font-size:20px}.EQo1dWsPxJZKjAmL5JnC{font-weight:500;font-size:12px}.oGFHOlIuUtaQZPDpeYL_{font-size:18px}.gJ1yX3nkocf7eMixzeQi{font-size:18px;font-weight:500}.uGXnm5ZiYkR0jWvLLUwp{font-size:16px}.IEz6pmqqs7xDv3yDLJL4{font-size:16px;font-weight:500}.Op4RffMABUWhL4R9BT4f,.nXBiI1HptOG_Qj5bsA4V{font-size:14px}.lAbcU0nkSDAGFxSaiXtB{font-size:14px;font-weight:500}.WkRO1BlXcbVYL0lmCxmn{font-size:14px;font-weight:400}.Pm3shhZzEzVAUhXcK83h{font-size:12px}.CeF8KJG1Er2ftOAqm_6B{font-size:12px;font-weight:500}.nXBiI1HptOG_Qj5bsA4V{color:#5f718c;text-decoration:none;display:block;width:100%;text-align:center}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/components/authentication/recover/recover.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,4CACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBAEC,aDSgB,CCRhB,oBAAA,CACA,aAAA,CACA,UAAA,CACA,iBAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `slLpeH9TmZvTbANB8VXT`;
export var h2 = `C3mirPjMk_CAAz3n0YJk`;
export var h3 = `EQo1dWsPxJZKjAmL5JnC`;
export var p0 = `oGFHOlIuUtaQZPDpeYL_`;
export var p0m = `gJ1yX3nkocf7eMixzeQi`;
export var p1 = `uGXnm5ZiYkR0jWvLLUwp`;
export var p1m = `IEz6pmqqs7xDv3yDLJL4`;
export var p2 = `Op4RffMABUWhL4R9BT4f`;
export var link = `nXBiI1HptOG_Qj5bsA4V`;
export var p2m = `lAbcU0nkSDAGFxSaiXtB`;
export var p2n = `WkRO1BlXcbVYL0lmCxmn`;
export var p3 = `Pm3shhZzEzVAUhXcK83h`;
export var p3m = `CeF8KJG1Er2ftOAqm_6B`;
export default ___CSS_LOADER_EXPORT___;
