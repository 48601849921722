// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.PkGu0GAm7FP4xzgnZEZs{font-weight:500;font-size:24px}.WY1_NCoaFcHOKGZvvH_b{font-weight:500;font-size:20px}.BcyzTcVjVjY6rZWdJ7sp{font-weight:500;font-size:12px}.kUygYp_n10Ho9aD6WfkG{font-size:18px}.I7ihi0dviTV4qe7_5dnG{font-size:18px;font-weight:500}.eMivIws_2O_l8U43tGAZ,.QyZczBgFpupLlrwJcp3e span{font-size:16px}.No8ea6_5mSkiIvlEdTnx,.y4LFyXfbqxut2mLFmBGB{font-size:16px;font-weight:500}.wEy7AoiF_PY40Iw6WihL{font-size:14px}._and3CqQmXPSSvEPmPiQ{font-size:14px;font-weight:500}.A7HiT8lQUU9UsJSjYcOx{font-size:14px;font-weight:400}.GW6i8ASYjc6TzwZbgJgD{font-size:12px}.GrXVJzrnyvPTjrivBzlT{font-size:12px;font-weight:500}.YPGdwRnn5Sd7dawgpH_k{padding:20px;display:flex;flex-direction:column;align-items:center}.y4LFyXfbqxut2mLFmBGB{padding-bottom:20px}.QyZczBgFpupLlrwJcp3e{padding:40px 0;display:flex;flex-direction:column;align-items:center}.QyZczBgFpupLlrwJcp3e svg{margin-top:8px;margin-bottom:8px}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/components/asset-tag-detail/unpair-modal.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,iDACC,cAAA,CAGD,4CACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,YAAA,CAEA,YAAA,CACA,qBAAA,CACA,kBAAA,CAGD,sBAGC,mBAAA,CAGD,sBACC,cAAA,CAEA,YAAA,CACA,qBAAA,CACA,kBAAA,CAMA,0BACC,cAAA,CACA,iBAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `PkGu0GAm7FP4xzgnZEZs`;
export var h2 = `WY1_NCoaFcHOKGZvvH_b`;
export var h3 = `BcyzTcVjVjY6rZWdJ7sp`;
export var p0 = `kUygYp_n10Ho9aD6WfkG`;
export var p0m = `I7ihi0dviTV4qe7_5dnG`;
export var p1 = `eMivIws_2O_l8U43tGAZ`;
export var innerContainer = `QyZczBgFpupLlrwJcp3e`;
export var p1m = `No8ea6_5mSkiIvlEdTnx`;
export var title = `y4LFyXfbqxut2mLFmBGB`;
export var p2 = `wEy7AoiF_PY40Iw6WihL`;
export var p2m = `_and3CqQmXPSSvEPmPiQ`;
export var p2n = `A7HiT8lQUU9UsJSjYcOx`;
export var p3 = `GW6i8ASYjc6TzwZbgJgD`;
export var p3m = `GrXVJzrnyvPTjrivBzlT`;
export var container = `YPGdwRnn5Sd7dawgpH_k`;
export default ___CSS_LOADER_EXPORT___;
