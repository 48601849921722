import React, { CSSProperties } from "react";
import * as styles from "./alert-counter.module.scss";

interface AlertCounterProps {
	count: number;

	style?: CSSProperties;
	className?: string;
}

export default function AlertCounter(props: AlertCounterProps) {
	return (
		<div
			style={props.style}
			className={`${styles.counter} ${
				props.className ? props.className : ""
			}`}
		>
			{props.count}
		</div>
	);
}
