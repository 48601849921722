// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.R5Dsa0OR5ubwgrX1ZRGE{font-weight:500;font-size:24px}.QDLHXVodBq3wKlIhoIcx{font-weight:500;font-size:20px}.omcDFfGIOC1hGFdW6uDd,.AIf9vJJ6G669wTD6So4Q thead th{font-weight:500;font-size:12px}.sy_Yh7nge56uSQZ4wo69{font-size:18px}.ckwkDSz1WXmlZxbZmOka{font-size:18px;font-weight:500}.KWvK86Mue4kx9FkQXLZY{font-size:16px}.Ju423qnAdNrhiUUeWPXO{font-size:16px;font-weight:500}.HIst_1Y_9iUVag7hCJ2u{font-size:14px}.uD8lsZdrFW7E65ipvm1w,.S06x4wMsAiUpsZY9qluw{font-size:14px;font-weight:500}.RuLT7xU0HE6ZJSPRfwOn{font-size:14px;font-weight:400}._e8hOj2jlNR3XJL0qnXU{font-size:12px}.kylZeTz23WBwi14AVISo{font-size:12px;font-weight:500}.S06x4wMsAiUpsZY9qluw{color:#3a58e2;margin-bottom:8px}.AIf9vJJ6G669wTD6So4Q{width:100%;border-spacing:0 8px}.AIf9vJJ6G669wTD6So4Q thead th{height:32px;text-transform:uppercase;color:#5f718c}.AIf9vJJ6G669wTD6So4Q .nLzErsdKSjI_zPOdLnmX{text-align:center}.AIf9vJJ6G669wTD6So4Q .Pa4K3H5yKMLifTKC3C5P{text-align:start;padding-left:20px}.amlzx_J1tsBFwAXgf0_h{margin-top:16px}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/components/settings/settings-advanced/channel-topic-table.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,qDACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,4CACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBAEC,aDGS,CCFT,iBAAA,CAGD,sBACC,UAAA,CAEA,oBAAA,CAEA,+BACC,WAAA,CAEA,wBAAA,CACA,aDJe,CCQhB,4CACC,iBAAA,CAGD,4CACC,gBAAA,CACA,iBAAA,CAIF,sBACC,eAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `R5Dsa0OR5ubwgrX1ZRGE`;
export var h2 = `QDLHXVodBq3wKlIhoIcx`;
export var h3 = `omcDFfGIOC1hGFdW6uDd`;
export var table = `AIf9vJJ6G669wTD6So4Q`;
export var p0 = `sy_Yh7nge56uSQZ4wo69`;
export var p0m = `ckwkDSz1WXmlZxbZmOka`;
export var p1 = `KWvK86Mue4kx9FkQXLZY`;
export var p1m = `Ju423qnAdNrhiUUeWPXO`;
export var p2 = `HIst_1Y_9iUVag7hCJ2u`;
export var p2m = `uD8lsZdrFW7E65ipvm1w`;
export var title = `S06x4wMsAiUpsZY9qluw`;
export var p2n = `RuLT7xU0HE6ZJSPRfwOn`;
export var p3 = `_e8hOj2jlNR3XJL0qnXU`;
export var p3m = `kylZeTz23WBwi14AVISo`;
export var wirepasEndpointHeader = `nLzErsdKSjI_zPOdLnmX`;
export var mqttTopicHeader = `Pa4K3H5yKMLifTKC3C5P`;
export var addButton = `amlzx_J1tsBFwAXgf0_h`;
export default ___CSS_LOADER_EXPORT___;
