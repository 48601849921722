// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.fXI7ys7gA6BC3eWcG4cS{font-weight:500;font-size:24px}.e_mgNbEbXDjTPlUeERgr{font-weight:500;font-size:20px}.g2OoxwsZX1rBQJkIC4Uc{font-weight:500;font-size:12px}.BQjVfY4x1yaFpNDgnsMC{font-size:18px}.P443HQqLMX2VEghswQF9{font-size:18px;font-weight:500}.J4wTUu6VOCtE8lVRq1vz{font-size:16px}.H2TSh9b2zT5j1BcmwwfQ{font-size:16px;font-weight:500}.VEeWK7MDkxjsokvq_qw4{font-size:14px}.Nn7ttqLmy9QXUw4dGHqW{font-size:14px;font-weight:500}.eBUXsMrP34Nj9CC2TQHA{font-size:14px;font-weight:400}.QqXQR2IJuG1stK8rNt10{font-size:12px}.CJFN_2_IIpbPM14vW5AF{font-size:12px;font-weight:500}.ed37JhQcxpoyzfxkgBQv{background-color:#fff;border:1px solid #e2e4e5;box-sizing:border-box;border-radius:8px;transition:border-color 250ms ease}.ed37JhQcxpoyzfxkgBQv:not(.Vm6Azz1unOxSjqDrjEP4){cursor:pointer}.ed37JhQcxpoyzfxkgBQv:not(.Vm6Azz1unOxSjqDrjEP4):hover{border-color:#5f718c}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/elements/selectable-card.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,qBAAA,CACA,wBAAA,CACA,qBAAA,CACA,iBAAA,CAEA,kCAAA,CAEA,iDACC,cAAA,CAEA,uDACC,oBDDc","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `fXI7ys7gA6BC3eWcG4cS`;
export var h2 = `e_mgNbEbXDjTPlUeERgr`;
export var h3 = `g2OoxwsZX1rBQJkIC4Uc`;
export var p0 = `BQjVfY4x1yaFpNDgnsMC`;
export var p0m = `P443HQqLMX2VEghswQF9`;
export var p1 = `J4wTUu6VOCtE8lVRq1vz`;
export var p1m = `H2TSh9b2zT5j1BcmwwfQ`;
export var p2 = `VEeWK7MDkxjsokvq_qw4`;
export var p2m = `Nn7ttqLmy9QXUw4dGHqW`;
export var p2n = `eBUXsMrP34Nj9CC2TQHA`;
export var p3 = `QqXQR2IJuG1stK8rNt10`;
export var p3m = `CJFN_2_IIpbPM14vW5AF`;
export var container = `ed37JhQcxpoyzfxkgBQv`;
export var disableHover = `Vm6Azz1unOxSjqDrjEP4`;
export default ___CSS_LOADER_EXPORT___;
