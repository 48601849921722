import React from "react";
import * as styles from "./analytics-card-header.module.scss";

export default function AnalyticsCardHeader(props: {
	title?: string;
	children?: React.ReactNode;
}) {
	return (
		<div className={styles.container}>
			<span className={styles.title}>{props.title}</span>

			<div className={styles.children}>{props.children}</div>
		</div>
	);
}
