// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.macu_A5tBUBkfKGEuk74{font-weight:500;font-size:24px}._IeFMJEuGvJfx05QCO7J{font-weight:500;font-size:20px}.jsc1ekJOyYxst_oXlBEE{font-weight:500;font-size:12px}.ugPyWbFDXx0Yhyo36p2K{font-size:18px}.rV_HtbB2txtKaOEqOi1z{font-size:18px;font-weight:500}.wxMRiMBcFt1NuTnDKRg5{font-size:16px}.vzNAIQ_f_RAM57O7c6_Y{font-size:16px;font-weight:500}.rVqE0Saqr8UT9hRju5nq{font-size:14px}._55_sRmW4j6N374WwzzO,.zFsW6AfW0SeE2XmTpZK5{font-size:14px;font-weight:500}.QN9Vvt1953gbnVP8g8Wq{font-size:14px;font-weight:400}.YVbmhijdFQfeOU2nXLmm{font-size:12px}.AFmT8m6jinjzVBnSzVID{font-size:12px;font-weight:500}.ZhBGgj8_T7Rd8fJwGpeF{display:inline-block;vertical-align:middle;height:4px;width:4px;margin:0px 12px 0px 12px;border-radius:50%;background-color:#a3b3cc}.PsOK_OJiXQiKsmATOXXQ{display:flex;flex-direction:row;align-items:center}.xsIZ8g1LZYOzLrhUq12Q{width:48px;height:48px;margin-right:16px;border-radius:50%}.q1PnXUvkAdW8d1zyHRes{width:100%;height:100%;border-radius:inherit}.zFsW6AfW0SeE2XmTpZK5{color:#3a58e2;transition:250ms ease}.zFsW6AfW0SeE2XmTpZK5:hover{color:#6681ff}.mTYEwAzfYAS0RMtXqh86{display:none}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/components/asset-tag-detail/tab-asset-manage-image.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,4CACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,oBAAA,CACA,qBAAA,CACA,UAAA,CACA,SAAA,CACA,wBAAA,CACA,iBAAA,CACA,wBDEY,CCCb,sBACC,YAAA,CACA,kBAAA,CACA,kBAAA,CAGD,sBACC,UAAA,CACA,WAAA,CACA,iBAAA,CACA,iBAAA,CAGD,sBACC,UAAA,CACA,WAAA,CACA,qBAAA,CAGD,sBAGC,aD3BS,CC4BT,qBD+EoB,CC7EpB,4BACC,aD9Bc,CCkChB,sBACC,YAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `macu_A5tBUBkfKGEuk74`;
export var h2 = `_IeFMJEuGvJfx05QCO7J`;
export var h3 = `jsc1ekJOyYxst_oXlBEE`;
export var p0 = `ugPyWbFDXx0Yhyo36p2K`;
export var p0m = `rV_HtbB2txtKaOEqOi1z`;
export var p1 = `wxMRiMBcFt1NuTnDKRg5`;
export var p1m = `vzNAIQ_f_RAM57O7c6_Y`;
export var p2 = `rVqE0Saqr8UT9hRju5nq`;
export var p2m = `_55_sRmW4j6N374WwzzO`;
export var imageButton = `zFsW6AfW0SeE2XmTpZK5`;
export var p2n = `QN9Vvt1953gbnVP8g8Wq`;
export var p3 = `YVbmhijdFQfeOU2nXLmm`;
export var p3m = `AFmT8m6jinjzVBnSzVID`;
export var bulletPoint = `ZhBGgj8_T7Rd8fJwGpeF`;
export var container = `PsOK_OJiXQiKsmATOXXQ`;
export var content = `xsIZ8g1LZYOzLrhUq12Q`;
export var image = `q1PnXUvkAdW8d1zyHRes`;
export var imageInput = `mTYEwAzfYAS0RMtXqh86`;
export default ___CSS_LOADER_EXPORT___;
