import React from "react";
import IconCheckmark from "../dynamicicons/icon-checkmark";
import IconDeselect from "../dynamicicons/icon-deselect";
import { classArr } from "../helper/style-helper";
import * as styles from "./checkbox.module.scss";

export interface CheckboxProps {
	checked: boolean;
	master?: boolean;
	onClick: (state?: boolean) => void;
}

function CheckboxIcon(props: { checked: boolean; master?: boolean }) {
	if (props.checked && props.master) {
		return <IconDeselect />;
	} else if (props.checked) {
		return <IconCheckmark size={48} />;
	}

	return null;
}

export default function Checkbox(props: CheckboxProps) {
	const { checked, onClick, master } = props;

	return (
		<div className={styles.container}>
			<input type="checkbox" checked={checked} readOnly />
			<div
				className={classArr(
					styles.checkbox,
					checked && styles.checkboxChecked,
				)}
				onClick={() => onClick(!checked)}
			>
				<CheckboxIcon checked={checked} master={master} />
			</div>
		</div>
	);
}
