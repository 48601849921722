// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.DXQNNz_ZLCIV1oaO6rX_{font-weight:500;font-size:24px}.eVhk8410FxgmZTmhzQT0{font-weight:500;font-size:20px}.fnQgZ7ISoXIxaP8fPI6s{font-weight:500;font-size:12px}.ReQcmu9IZFGYFelvWnP9{font-size:18px}._96Tdu9TaTDwGWgeacCA{font-size:18px;font-weight:500}.IAbds4FaVjUOKDpLd0GK{font-size:16px}.Ps0ln6wdGIY6SUoge8uF{font-size:16px;font-weight:500}.c9EsZ5E81c_U3UJohGM6{font-size:14px}.hSvlheojyeCHntGnaFBD{font-size:14px;font-weight:500}.Nj1wMqUhe4pnSTyW5zqS{font-size:14px;font-weight:400}.VzQxeOMp81JA0HDOp0cx{font-size:12px}.yqKtvEOZzHiQoKaVd6gP{font-size:12px;font-weight:500}.QlKuMWGHJVJoeeEe1SS3{padding:20px;box-sizing:border-box;display:flex;flex-direction:column;gap:20px}.UYbdRtFxMZoveUcQcmf5{width:100%;display:flex;flex-direction:row;align-items:center;justify-content:space-around}.UYbdRtFxMZoveUcQcmf5>*{flex-shrink:0}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/components/dashboard/dropdown/floor-create/floor-create-draw-controls.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,YAAA,CACA,qBAAA,CAEA,YAAA,CACA,qBAAA,CACA,QAAA,CAGD,sBACC,UAAA,CAEA,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,4BAAA,CAEA,wBACC,aAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `DXQNNz_ZLCIV1oaO6rX_`;
export var h2 = `eVhk8410FxgmZTmhzQT0`;
export var h3 = `fnQgZ7ISoXIxaP8fPI6s`;
export var p0 = `ReQcmu9IZFGYFelvWnP9`;
export var p0m = `_96Tdu9TaTDwGWgeacCA`;
export var p1 = `IAbds4FaVjUOKDpLd0GK`;
export var p1m = `Ps0ln6wdGIY6SUoge8uF`;
export var p2 = `c9EsZ5E81c_U3UJohGM6`;
export var p2m = `hSvlheojyeCHntGnaFBD`;
export var p2n = `Nj1wMqUhe4pnSTyW5zqS`;
export var p3 = `VzQxeOMp81JA0HDOp0cx`;
export var p3m = `yqKtvEOZzHiQoKaVd6gP`;
export var content = `QlKuMWGHJVJoeeEe1SS3`;
export var toolbar = `UYbdRtFxMZoveUcQcmf5`;
export default ___CSS_LOADER_EXPORT___;
