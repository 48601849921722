import React from "react";
import hardwareAnimation from "../../img/animations/animation-hardware.gif";
import OnboardingFrame from "./onboarding-frame";
import { OnboardingFrameProps } from "./onboarding-shared-data";
import * as styles from "./onboarding-step-hardware.module.scss";
import { hardwareSetupSupportURL } from "../../constants";

export default function OnboardingStepHardware(props: OnboardingFrameProps) {
	return (
		<OnboardingFrame
			title="Hardware setup"
			description={
				<>
					View the setup guide to get up and running with Velavu
					hardware:
					<p>
						<a
							href={hardwareSetupSupportURL}
							target="_blank"
							rel="noreferrer"
						>
							support.velavu.com
						</a>
					</p>
				</>
			}
			buttonSecondary={{
				label: "Back",
				onClick: props.navigateBackwards,
			}}
			buttonPrimary={{ label: "Next", onClick: props.navigateForwards }}
			centerContent
		>
			<img className={styles.image} src={hardwareAnimation} alt="" />
		</OnboardingFrame>
	);
}
