// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tl6gK7pKYb3DoUbOzIaA{height:42px;display:flex;flex-direction:row-reverse;align-items:center;justify-content:space-between;margin-top:20px}.Rdt0CvCg3cpr2PZynbRR{width:292px;box-sizing:border-box}`, "",{"version":3,"sources":["webpack://./src/components/settings/component-save-bar.module.scss"],"names":[],"mappings":"AAAA,sBACC,WAAA,CACA,YAAA,CACA,0BAAA,CACA,kBAAA,CACA,6BAAA,CAEA,eAAA,CAGD,sBACC,WAAA,CACA,qBAAA","sourceRoot":""}]);
// Exports
export var footer = `tl6gK7pKYb3DoUbOzIaA`;
export var notice = `Rdt0CvCg3cpr2PZynbRR`;
export default ___CSS_LOADER_EXPORT___;
