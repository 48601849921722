// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.kWzy4Ed9DzDSGbnSm5Wo{font-weight:500;font-size:24px}.zq2jNF5NiDvT4TESYYLq{font-weight:500;font-size:20px}.QJDgdt4LQiHsFZvDeRg6{font-weight:500;font-size:12px}.p0dsWQvTgOYxKcAR6X6w{font-size:18px}.eAKW5Wx7BjibBUVvdvoU{font-size:18px;font-weight:500}.rv2nr8HSvHVmKXHNq1rY{font-size:16px}.bDqcorKFoDhVxI9sxqRp,.Z2u8t4gysrScIhIOAG50{font-size:16px;font-weight:500}.Ml_zbQOWXqTPqSLdetTG{font-size:14px}.oNaHKu5Phq8wtR_L3tTM{font-size:14px;font-weight:500}.f97NzTYe9GAVa_JYUyMt{font-size:14px;font-weight:400}.pxFh5xUVt_1GtHFdeY0A{font-size:12px}.jLYEDLRapDCX5FSqtWXs{font-size:12px;font-weight:500}.V00eckNG77lFH5ymirtY{display:flex;flex-direction:row;align-items:center;color:#5f718c;transition:color 250ms ease}.V00eckNG77lFH5ymirtY:hover{color:#00255d}.Zk9mo6CQOX83tHElWJfN{width:32px;height:32px;display:flex;align-items:center;justify-content:center}.Z2u8t4gysrScIhIOAG50{margin-left:8px}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/components/dashboard/dropdown/map-list/map-list-selector-option.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,4CACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,YAAA,CACA,kBAAA,CACA,kBAAA,CAEA,aDMgB,CCJhB,2BAAA,CAEA,4BACC,aAAA,CAIF,sBACC,UAAA,CACA,WAAA,CAEA,YAAA,CACA,kBAAA,CACA,sBAAA,CAGD,sBAGC,eAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `kWzy4Ed9DzDSGbnSm5Wo`;
export var h2 = `zq2jNF5NiDvT4TESYYLq`;
export var h3 = `QJDgdt4LQiHsFZvDeRg6`;
export var p0 = `p0dsWQvTgOYxKcAR6X6w`;
export var p0m = `eAKW5Wx7BjibBUVvdvoU`;
export var p1 = `rv2nr8HSvHVmKXHNq1rY`;
export var p1m = `bDqcorKFoDhVxI9sxqRp`;
export var label = `Z2u8t4gysrScIhIOAG50`;
export var p2 = `Ml_zbQOWXqTPqSLdetTG`;
export var p2m = `oNaHKu5Phq8wtR_L3tTM`;
export var p2n = `f97NzTYe9GAVa_JYUyMt`;
export var p3 = `pxFh5xUVt_1GtHFdeY0A`;
export var p3m = `jLYEDLRapDCX5FSqtWXs`;
export var button = `V00eckNG77lFH5ymirtY`;
export var iconWrapper = `Zk9mo6CQOX83tHElWJfN`;
export default ___CSS_LOADER_EXPORT___;
