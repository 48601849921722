// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.zhc2vtu10Vg5hV9Rf3a1{font-weight:500;font-size:24px}.ZhLBJkaFOtyTf9o25HKT{font-weight:500;font-size:20px}.iEJvs3xrTrchd72qjyf9{font-weight:500;font-size:12px}.WqUeXnNtc2zrx_aBl0j4{font-size:18px}.MkSmxfCAWDtQLGYWYvnA{font-size:18px;font-weight:500}.Y7iLLaFBLkleda8ZOfAU{font-size:16px}.RGPqWUr9W9Pq7UVXMhBY,.NfYWcqdqUDyMo3w7zqy4,.Z7qWYmy3ifeehsQ8eYtx{font-size:16px;font-weight:500}.W3gZVh0hHFPWrySQTH8L,.K2ii5_b8pp8DSUli1K8_ .TBRsRx20HzFWm5LHf0Nq,.FLyu9RnB9v_q75bvj_Iw{font-size:14px}.hyUU4YurFrVKYAcT8eyP{font-size:14px;font-weight:500}.OG_JAy0HjeUbnMmvH3cX{font-size:14px;font-weight:400}.RsNHYiiFDBdQWmkILYf0{font-size:12px}.ODUtsyPezjlbzAYKqtSf{font-size:12px;font-weight:500}.Y9AlShLE4wDxPhe_OIGQ{padding:20px;flex-grow:1}.FLyu9RnB9v_q75bvj_Iw{color:#5f718c}.zoWMPuOGf5oWTOgsjO5X{margin-top:12px}.K2ii5_b8pp8DSUli1K8_{display:grid;width:100%;grid-template-columns:max-content 50% 50%;column-gap:12px;padding-bottom:12px}.K2ii5_b8pp8DSUli1K8_ .TBRsRx20HzFWm5LHf0Nq{color:#5f718c;font-weight:400;margin:0px 0px 12px 0px}.K2ii5_b8pp8DSUli1K8_ .U7qZGdFlmGvDgz6T7Pw_{width:294px}.VsIgOZW30sLOJnHQlgXk{display:flex;align-items:center}.NfYWcqdqUDyMo3w7zqy4{margin-left:4px}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/components/settings/settings-devices.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,kEACC,cAAA,CACA,eAAA,CAGD,wFACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,YAAA,CACA,WAAA,CAOD,sBAEC,aAAA,CAGD,sBACC,eAAA,CAGD,sBACC,YAAA,CACA,UAAA,CACA,yCAAA,CACA,eAAA,CACA,mBAAA,CAEA,4CAEC,aDhBe,CCiBf,eAAA,CACA,uBAAA,CAGD,4CACC,WAAA,CAIF,sBACC,YAAA,CACA,kBAAA,CAGD,sBAEC,eAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `zhc2vtu10Vg5hV9Rf3a1`;
export var h2 = `ZhLBJkaFOtyTf9o25HKT`;
export var h3 = `iEJvs3xrTrchd72qjyf9`;
export var p0 = `WqUeXnNtc2zrx_aBl0j4`;
export var p0m = `MkSmxfCAWDtQLGYWYvnA`;
export var p1 = `Y7iLLaFBLkleda8ZOfAU`;
export var p1m = `RGPqWUr9W9Pq7UVXMhBY`;
export var sectionHeader = `NfYWcqdqUDyMo3w7zqy4`;
export var title = `Z7qWYmy3ifeehsQ8eYtx`;
export var p2 = `W3gZVh0hHFPWrySQTH8L`;
export var grid = `K2ii5_b8pp8DSUli1K8_`;
export var columnHeader = `TBRsRx20HzFWm5LHf0Nq`;
export var info = `FLyu9RnB9v_q75bvj_Iw`;
export var p2m = `hyUU4YurFrVKYAcT8eyP`;
export var p2n = `OG_JAy0HjeUbnMmvH3cX`;
export var p3 = `RsNHYiiFDBdQWmkILYf0`;
export var p3m = `ODUtsyPezjlbzAYKqtSf`;
export var container = `Y9AlShLE4wDxPhe_OIGQ`;
export var notice = `zoWMPuOGf5oWTOgsjO5X`;
export var select = `U7qZGdFlmGvDgz6T7Pw_`;
export var section = `VsIgOZW30sLOJnHQlgXk`;
export default ___CSS_LOADER_EXPORT___;
