import React from "react";
import * as styles from "./settings-billing-status-text.module.scss";
import { classArr } from "../../../helper/style-helper";

export type SettingsBillingStatusTextType = "loading" | "error";

export interface SettingsBillingStatusTextProps {
	className?: string;
	children?: React.ReactNode;
	type?: SettingsBillingStatusTextType;
}

export default function SettingsBillingStatusText(
	props: SettingsBillingStatusTextProps,
) {
	return (
		<span
			className={classArr(
				styles.text,
				props.type === "error" && styles.error,
				props.className,
			)}
		>
			{props.children}
		</span>
	);
}
