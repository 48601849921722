// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.OtbtEfD10vib4ATDHgDM{font-weight:500;font-size:24px}.fZC6Cn7JUEwOGWD5CEaL{font-weight:500;font-size:20px}.Qu16yqRmhH_VpgToXnqY{font-weight:500;font-size:12px}.i_L7NevXSJT0rIayX57p{font-size:18px}.PqAz6K5IJasDQMpHfgTl,.A21Kc27xpD3blMGuAyKL{font-size:18px;font-weight:500}.RqzEkzunBAxs6FEMQqAk{font-size:16px}.XuL8TMJ8lPJhsTJ4ekaz{font-size:16px;font-weight:500}.n2t_0kWyKUSGK7Dm8w1_{font-size:14px}.eStFZbUy5PR81doLmPVP{font-size:14px;font-weight:500}.FY8zLCwKODa12s1ieEIq{font-size:14px;font-weight:400}.KTtyV8pWQs7d1tC_lMby{font-size:12px}.OtdyRFWMsRnbfJGF_95q{font-size:12px;font-weight:500}.BKoXReiz4IMGuROmDLgT{padding:20px;flex-grow:1}.A21Kc27xpD3blMGuAyKL{margin-top:0}.TRAJs8wyNmbMTzjy6_lb{display:grid;grid-template-columns:repeat(2, 1fr);grid-gap:20px;margin-bottom:20px}.ZNnK6j_isaCbTb_9joIZ{display:flex;flex-direction:row;gap:20px;min-width:0}.CEM0CKCyBzPfxMDO48rG{margin-bottom:20px}.x8NoqRALIiI5EKvkGRce{display:flex;align-items:center;justify-content:end}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/components/settings/settings-advanced.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,4CACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,YAAA,CACA,WAAA,CAGD,sBAEC,YAAA,CAGD,sBACC,YAAA,CACA,oCAAA,CACA,aAAA,CAEA,kBAAA,CAGD,sBACC,YAAA,CACA,kBAAA,CACA,QAAA,CAEA,WAAA,CAGD,sBACC,kBAAA,CAGD,sBACC,YAAA,CACA,kBAAA,CACA,mBAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `OtbtEfD10vib4ATDHgDM`;
export var h2 = `fZC6Cn7JUEwOGWD5CEaL`;
export var h3 = `Qu16yqRmhH_VpgToXnqY`;
export var p0 = `i_L7NevXSJT0rIayX57p`;
export var p0m = `PqAz6K5IJasDQMpHfgTl`;
export var title = `A21Kc27xpD3blMGuAyKL`;
export var p1 = `RqzEkzunBAxs6FEMQqAk`;
export var p1m = `XuL8TMJ8lPJhsTJ4ekaz`;
export var p2 = `n2t_0kWyKUSGK7Dm8w1_`;
export var p2m = `eStFZbUy5PR81doLmPVP`;
export var p2n = `FY8zLCwKODa12s1ieEIq`;
export var p3 = `KTtyV8pWQs7d1tC_lMby`;
export var p3m = `OtdyRFWMsRnbfJGF_95q`;
export var container = `BKoXReiz4IMGuROmDLgT`;
export var optionGrid = `TRAJs8wyNmbMTzjy6_lb`;
export var inputRow = `ZNnK6j_isaCbTb_9joIZ`;
export var formBlock = `CEM0CKCyBzPfxMDO48rG`;
export var footer = `x8NoqRALIiI5EKvkGRce`;
export default ___CSS_LOADER_EXPORT___;
