import React, { useEffect, useState } from "react";
import * as styles from "./popover.module.scss";
import { classArr } from "../helper/style-helper";

export interface PopoverProps {
	className?: string;
	children?: React.ReactNode;
	isVisible?: boolean;
	onDismiss?: () => void;
}

export default function Popover(props: PopoverProps) {
	const [element, setElement] = useState<HTMLElement | null>(null);

	const propsOnDismiss = props.onDismiss;
	useEffect(() => {
		//Ignore if the popover is hidden
		if (!props.isVisible) {
			return;
		}

		//Dismiss the popover if the user clicks outside the element
		function handleClickOutside(event: MouseEvent) {
			if (element !== null && !element.contains(event.target as Node)) {
				event.preventDefault();
				propsOnDismiss?.();
			}
		}

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [element, props.isVisible, propsOnDismiss]);

	//Don't render anything if the popover is hidden
	if (!props.isVisible) {
		return null;
	}

	return (
		<div
			ref={setElement}
			className={classArr(styles.container, props.className)}
		>
			{props.children}
		</div>
	);
}
