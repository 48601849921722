// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icxJYhpdrdKerQneg3Y2{margin-top:24px;display:flex;flex-direction:row;height:32px;align-items:center}.f_TvqtKGYoY9xEOotpuR{flex-grow:1;flex-basis:0}.SjdNeTc4Kc31qirs7xjh{width:16px;flex-shrink:0}`, "",{"version":3,"sources":["webpack://./src/components/authentication/authentication-button-bar.module.scss"],"names":[],"mappings":"AAAA,sBACC,eAAA,CAEA,YAAA,CACA,kBAAA,CAEA,WAAA,CACA,kBAAA,CAGD,sBACC,WAAA,CACA,YAAA,CAGD,sBACC,UAAA,CACA,aAAA","sourceRoot":""}]);
// Exports
export var container = `icxJYhpdrdKerQneg3Y2`;
export var button = `f_TvqtKGYoY9xEOotpuR`;
export var spacer = `SjdNeTc4Kc31qirs7xjh`;
export default ___CSS_LOADER_EXPORT___;
