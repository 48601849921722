// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.niumBJXG8Wsy2T8SMq_u{font-weight:500;font-size:24px}.d38R8otWwajHJLo0VJdD{font-weight:500;font-size:20px}.Bv_YONrAT9AkYui0vq74{font-weight:500;font-size:12px}.jpB4w1p5meLCbJrLC2Pk{font-size:18px}.PTtcOdLfwLEXhKdAOHZM{font-size:18px;font-weight:500}.u79yLUVoA7pkXqGJZPDQ{font-size:16px}.MQ5lbd_E_kYBKTFTyq6w{font-size:16px;font-weight:500}.y2VU3e2hXiLfUBnHtUsy{font-size:14px}.w42gZxLfJpokZgO1Pp3J{font-size:14px;font-weight:500}.e7Eof_iqBnNAzlu7N_3I{font-size:14px;font-weight:400}.YvHAhx5EVTE0IAc6O2Be{font-size:12px}.eFddDI2Pt7E6pYsogvL1{font-size:12px;font-weight:500}.vG31VFGkjIDvlrJLOf8D{flex-grow:1;color:#5f718c}.lfHLyZkmnnsZ5zAznAul{display:flex;flex-direction:row;align-items:center}.lfHLyZkmnnsZ5zAznAul:hover .ptX7UWZs86wH_0S34_Ga{opacity:100%}.oo_oFvxFLHG4WYzsogEV{margin:0 8px 0 8px}.tq4C8cSHyU3FfqkldNEf{text-decoration:underline;text-decoration-color:rgba(0,0,0,0);transition:250ms ease}.tq4C8cSHyU3FfqkldNEf:hover{text-decoration-color:#00255d}.ptX7UWZs86wH_0S34_Ga{opacity:0;transition:250ms ease}.smM3H0Nye4YyKYcu7DNd{display:flex;flex-direction:row;align-items:center}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/components/dashboard/dropdown/asset-detail/asset-properties.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,WAAA,CACA,aDSgB,CCNjB,sBACC,YAAA,CACA,kBAAA,CACA,kBAAA,CAGC,kDACC,YAAA,CAKH,sBACC,kBAAA,CAGD,sBACC,yBAAA,CACA,mCAAA,CACA,qBDwFoB,CCtFpB,4BACC,6BDnBU,CCuBZ,sBACC,SAAA,CACA,qBD+EoB,CC5ErB,sBACC,YAAA,CACA,kBAAA,CACA,kBAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `niumBJXG8Wsy2T8SMq_u`;
export var h2 = `d38R8otWwajHJLo0VJdD`;
export var h3 = `Bv_YONrAT9AkYui0vq74`;
export var p0 = `jpB4w1p5meLCbJrLC2Pk`;
export var p0m = `PTtcOdLfwLEXhKdAOHZM`;
export var p1 = `u79yLUVoA7pkXqGJZPDQ`;
export var p1m = `MQ5lbd_E_kYBKTFTyq6w`;
export var p2 = `y2VU3e2hXiLfUBnHtUsy`;
export var p2m = `w42gZxLfJpokZgO1Pp3J`;
export var p2n = `e7Eof_iqBnNAzlu7N_3I`;
export var p3 = `YvHAhx5EVTE0IAc6O2Be`;
export var p3m = `eFddDI2Pt7E6pYsogvL1`;
export var statusText = `vG31VFGkjIDvlrJLOf8D`;
export var parent = `lfHLyZkmnnsZ5zAznAul`;
export var removeParent = `ptX7UWZs86wH_0S34_Ga`;
export var parentInfo = `oo_oFvxFLHG4WYzsogEV`;
export var parentName = `tq4C8cSHyU3FfqkldNEf`;
export var centerRow = `smM3H0Nye4YyKYcu7DNd`;
export default ___CSS_LOADER_EXPORT___;
