import { Feature, Geometry, Point } from "geojson";
import { GeoJSONSource } from "mapbox-gl";
import { useMemo, useRef } from "react";
import { VelavuDevice } from "velavu-js-api";
import { geoJSONBlankCollection } from "./mapbox-injectable";
import styles from "./mapbox-injectable-anchors-routes.module.scss";
import {
	MapHelperInstance,
	useListenerSetupMapEffect,
	useStyleDependantMapEffect,
	useStyleSetupMapEffect,
} from "../../helper/map-helper";
import { createVelavuMapboxPopup } from "../../mapbox-component/velavu-mapbox-popup";
import { radToDeg } from "../../helper/math-helper";

const mapboxIDAnchorRouteLine = "anchor-route-lines";
const mapboxIDAnchorRouteLineHoverArea = "anchor-route-lines-hover-area";
const mapboxIDAnchorRouteArrow = "anchor-route-arrow";

interface ConnectionPath {
	//A unique integer ID for this connection path
	integerID: number;
	//Coordinates of the source end of this path
	startCoords: [number, number];
	//Coordinates of the destination end of this path
	endCoords: [number, number];
	//Coordinates of the center of this path
	center: [number, number];
	//Angle from start to end in degrees
	angle: number;
	//Details about the device connection
	connectionDetails: ConnectionDetails;
}

interface ConnectionDetails {
	startDeviceID: string;
	endDeviceID: string;
	installQuality: number;
	rssi: number;
	txPower: number;
}

// We must extend ConnectionDetails because Mapbox does not support
// nested objects in GeoJSON properties.
// Note that only string and number values are supported as well.
interface ArrowProperties extends ConnectionDetails {
	rotation: number;
}

/// Hashes a string to an integer
function hashString(string: string): number {
	let hash = 0;
	if (string.length === 0) {
		return hash;
	}

	for (let i = 0; i < string.length; i++) {
		const char = string.charCodeAt(i);
		hash = (hash << 5) - hash + char;
		hash |= hash; // Convert to 32bit integer
	}

	return hash;
}

export default function useMapboxInjectableAnchorsRoutes(
	mapInstance: MapHelperInstance,
	anchors?: VelavuDevice[],
	beforeLayer?: string,
) {
	const connectionPathArray = useMemo(() => {
		return (
			anchors
				?.map((device): ConnectionPath | undefined => {
					//Get this device's coordinates
					const startCoords = device.location?.coordinates;
					if (startCoords === undefined) {
						return undefined;
					}

					//Get the ID of the device this device is routing to
					if (!device.state || !("routing" in device.state)) {
						return undefined;
					}
					const connectedDeviceID = device.state.routing.device_id;

					//Look up that device
					const connectedDevice = anchors.find(
						(it) => it.id === connectedDeviceID,
					);
					if (connectedDevice === undefined) {
						return undefined;
					}

					//Get the connected device's coordinates
					const endCoords = connectedDevice.location?.coordinates;
					if (endCoords === undefined) {
						return undefined;
					}

					//Get the center of the path
					const center: [number, number] = [
						(startCoords[0] + endCoords[0]) / 2,
						(startCoords[1] + endCoords[1]) / 2,
					];

					//Calculate the difference angle
					const diffX = endCoords[0] - startCoords[0];
					const diffY = endCoords[1] - startCoords[1];
					const angle = radToDeg(Math.atan2(diffY, diffX));

					const details: ConnectionDetails = {
						startDeviceID: device.id,
						endDeviceID: connectedDeviceID,
						installQuality: device.state.routing.install_quality,
						rssi: device.state.routing.rssi,
						txPower: device.state.routing.tx_power,
					};

					return {
						integerID: hashString(device.id),
						startCoords: startCoords,
						endCoords: endCoords,
						center: center,
						angle: angle,
						connectionDetails: details,
					};
				})
				.filter((it): it is ConnectionPath => it !== undefined) ?? []
		);
	}, [anchors]);

	useStyleSetupMapEffect(mapInstance, (_, box) => {
		box.addSource(mapboxIDAnchorRouteLine, {
			type: "geojson",
			data: geoJSONBlankCollection,
		});

		box.addSource(mapboxIDAnchorRouteArrow, {
			type: "geojson",
			data: geoJSONBlankCollection,
		});

		box.addLayer(
			{
				id: mapboxIDAnchorRouteLine,
				type: "line",
				source: mapboxIDAnchorRouteLine,
				layout: {
					"line-cap": "round",
					"line-join": "round",
				},
				paint: {
					"line-color": [
						"case",
						["boolean", ["feature-state", "hover"], false],
						"#3957DF",
						"#A3B3CC",
					],
					"line-width": ["step", ["zoom"], 0, 18, 1, 19, 2, 20, 3],
					"line-dasharray": [
						"step",
						["zoom"],
						["literal", [0, 2]],
						18,
						["literal", [0, 2]],
						18.5,
						["literal", [0, 2.5]],
						19,
						["literal", [0, 3]],
						19.5,
						["literal", [0, 3.5]],
						20,
						["literal", [0, 4]],
						20.5,
						["literal", [0, 4.5]],
						21,
						["literal", [0, 5]],
						21.5,
						["literal", [0, 5.5]],
						22,
						["literal", [0, 6]],
					],
					"line-opacity": ["step", ["zoom"], 0, 17, 0, 18, 1],
				},
			},
			beforeLayer,
		);

		//Create a thicker transparent line to use as a hover target
		box.addLayer({
			id: mapboxIDAnchorRouteLineHoverArea,
			type: "line",
			source: mapboxIDAnchorRouteLine,
			paint: {
				"line-opacity": 0,
				"line-width": 18,
			},
		});

		box.addLayer(
			{
				id: mapboxIDAnchorRouteArrow,
				type: "symbol",
				source: mapboxIDAnchorRouteArrow,
				layout: {
					"icon-image": "icon-routing-arrow",
					"icon-size": 0.2,
					"icon-rotate": ["get", "rotation"],
					"icon-allow-overlap": true,
					"icon-ignore-placement": true,
				},
				paint: {
					"icon-color": [
						"case",
						["boolean", ["feature-state", "hover"], false],
						"#3957DF",
						"#A3B3CC",
					],
					"icon-opacity": ["step", ["zoom"], 0, 17, 0, 18, 1],
				},
			},
			beforeLayer,
		);
	});

	const routePopup = useMemo(
		() =>
			createVelavuMapboxPopup({
				className: styles.tooltipContainer,
				offset: [0, -10],
				anchor: "bottom",
				maxWidth: "400px",
			}),
		[],
	);

	//Update line source
	useStyleDependantMapEffect(
		mapInstance,
		(map) => {
			const lineSource = map.getSource(
				mapboxIDAnchorRouteLine,
			) as GeoJSONSource;

			lineSource.setData({
				type: "FeatureCollection",
				features: connectionPathArray.map((path) => ({
					type: "Feature",
					id: path.integerID,
					geometry: {
						type: "LineString",
						coordinates: [path.startCoords, path.endCoords],
					},
					properties: {},
				})),
			});
		},
		[connectionPathArray],
	);

	//Update arrow source
	useStyleDependantMapEffect(
		mapInstance,
		(map) => {
			const arrowSource = map.getSource(
				mapboxIDAnchorRouteArrow,
			) as GeoJSONSource;

			arrowSource.setData({
				type: "FeatureCollection",
				features: connectionPathArray.map<
					Feature<Geometry, ArrowProperties>
				>((path) => ({
					type: "Feature",
					id: path.integerID,
					geometry: {
						type: "Point",
						coordinates: path.center,
					},
					properties: {
						...path.connectionDetails,
						rotation: path.angle * -1,
					},
				})),
			});
		},
		[connectionPathArray],
	);

	//Track line hover state
	const hoveredConnectionID = useRef<number | null>(null);
	useListenerSetupMapEffect(
		mapInstance.map,
		(map, box) => {
			box.on("mousemove", mapboxIDAnchorRouteLineHoverArea, (event) => {
				if (event.features !== undefined && event.features.length > 0) {
					//Untrack the last hovered connection
					if (hoveredConnectionID.current !== null) {
						map.setFeatureState(
							{
								source: mapboxIDAnchorRouteLine,
								id: hoveredConnectionID.current,
							},
							{
								hover: false,
							},
						);

						map.setFeatureState(
							{
								source: mapboxIDAnchorRouteArrow,
								id: hoveredConnectionID.current,
							},
							{
								hover: false,
							},
						);
					}

					//Set the new hovered connection line
					const hoveredID = event.features[0].id as number;
					map.setFeatureState(
						{
							source: mapboxIDAnchorRouteLine,
							id: hoveredID,
						},
						{
							hover: true,
						},
					);
					map.setFeatureState(
						{
							source: mapboxIDAnchorRouteArrow,
							id: hoveredID,
						},
						{
							hover: true,
						},
					);

					hoveredConnectionID.current = hoveredID;
				}
			});

			box.on("mouseleave", mapboxIDAnchorRouteLineHoverArea, () => {
				if (hoveredConnectionID.current !== null) {
					map.setFeatureState(
						{
							source: mapboxIDAnchorRouteLine,
							id: hoveredConnectionID.current,
						},
						{
							hover: false,
						},
					);
					map.setFeatureState(
						{
							source: mapboxIDAnchorRouteArrow,
							id: hoveredConnectionID.current,
						},
						{
							hover: false,
						},
					);
					hoveredConnectionID.current = null;
				}
			});
		},
		[],
	);

	//Show popup on arrow hover
	useListenerSetupMapEffect(
		mapInstance.map,
		(map, box) => {
			box.on("mouseenter", mapboxIDAnchorRouteArrow, (event) => {
				const feature = event.features![0];
				const properties = feature.properties as ArrowProperties;
				const connection: ConnectionDetails = properties;

				const anchorSvg = `
				<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path fill-rule="evenodd" clip-rule="evenodd" d="M10.6665 4.66732H5.33317C3.49222 4.66732 1.99984 6.1597 1.99984 8.00065C1.99984 9.8416 3.49222 11.334 5.33317 11.334H10.6665C12.5075 11.334 13.9998 9.8416 13.9998 8.00065C13.9998 6.1597 12.5075 4.66732 10.6665 4.66732ZM5.33317 3.33398C2.75584 3.33398 0.666504 5.42332 0.666504 8.00065C0.666504 10.578 2.75584 12.6673 5.33317 12.6673H10.6665C13.2438 12.6673 15.3332 10.578 15.3332 8.00065C15.3332 5.42332 13.2438 3.33398 10.6665 3.33398H5.33317Z" fill="#3A58E2"/>
					<path fill-rule="evenodd" clip-rule="evenodd" d="M10.6665 7.33398H5.33317C4.96498 7.33398 4.6665 7.63246 4.6665 8.00065C4.6665 8.36884 4.96498 8.66732 5.33317 8.66732H10.6665C11.0347 8.66732 11.3332 8.36884 11.3332 8.00065C11.3332 7.63246 11.0347 7.33398 10.6665 7.33398ZM5.33317 6.00065C4.2286 6.00065 3.33317 6.89608 3.33317 8.00065C3.33317 9.10522 4.2286 10.0007 5.33317 10.0007H10.6665C11.7711 10.0007 12.6665 9.10522 12.6665 8.00065C12.6665 6.89608 11.7711 6.00065 10.6665 6.00065H5.33317Z" fill="#3A58E2"/>
				</svg>
			`;

				const arrowSvg = `
				<svg width="26" height="16" viewBox="0 0 26 16" fill="none" xmlns="http://www.w3.org/2000/svg">
					<rect x="3" y="7" width="15" height="2" rx="1" fill="#A3B3CC"/>
					<path d="M23 7.13397C23.6667 7.51887 23.6667 8.48113 23 8.86603L17 12.3301C16.3333 12.715 15.5 12.2339 15.5 11.4641L15.5 4.5359C15.5 3.7661 16.3333 3.28497 17 3.66987L23 7.13397Z" fill="#A3B3CC"/>
				</svg>
			`;

				const descriptionHTML = `
				<div>
					${anchorSvg}
					${connection.startDeviceID}
					${arrowSvg}
					${anchorSvg}
					${connection.endDeviceID}
				</div>
				${
					connection.installQuality !== undefined
						? `
						<div>
							<div>${connection.installQuality}</div>
							Install quality
						</div>
					`
						: ""
				}
				<div>
					<div>${connection.rssi}</div>
					Signal strength
					<span>(dBm)</span>
				</div>
				<div>
					<div>${connection.txPower}</div>
					TX power
					<span>(dBm)</span>
				</div>
			`;
				const coordinates = (
					feature.geometry as Point
				).coordinates.slice() as [number, number];
				routePopup
					.setLngLat(coordinates)
					.setHTML(descriptionHTML)
					.addTo(map);
			});

			box.on("mouseleave", mapboxIDAnchorRouteArrow, () => {
				routePopup.remove();
			});
		},
		[routePopup],
	);

	return mapboxIDAnchorRouteLine;
}
