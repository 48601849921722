import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { useNavigate } from "react-router-dom";
import {
	AssetCategory,
	GetAllAssetsParams,
	VelavuAPI,
	VelavuAsset,
} from "velavu-js-api";
import AssetCategorization, {
	convertCategoryGroupsToAssetCategorizations,
	splitReduceAssetCategorizations,
} from "../../data/asset-categorization";
import IconAdd from "../../dynamicicons/icon-add";
import IconVelavuAssets from "../../dynamicicons/icon-velavu-assets";
import AssetFilterMenu from "../../elements/asset-filter-menu";
import { CategoryTag, GroupTag } from "../../elements/categorization-tag";
import Checkbox from "../../elements/checkbox";
import Divider from "../../elements/divider";
import ListHeader from "../../elements/list-header";
import ListPagination from "../../elements/list-pagination";
import LoadingDots from "../../elements/loading-dots";
import StatusIndicator from "../../elements/status-indicator";
import ToggleableFade from "../../elements/toggleable-fade";
import VelavuButton from "../../elements/velavu-button";
import {
	VelavuTableBody,
	VelavuTableColumn,
	VelavuTableHeader,
	VelavuTableHeaderLabel,
	VelavuTableRow,
} from "../../elements/velavu-header-table";
import {
	VelavuModalPrompt,
	VelavuTitledModal,
	useVelavuModal,
} from "../../elements/velavu-modal";
import { useToggleable } from "../../helper/hook-helper";
import { makeStyleable } from "../../helper/icon-helper";
import { deepCompare } from "../../helper/object-helper";
import { getAssetStatus } from "../../helper/status-helper";
import { NoAssetsIcon } from "../../img/icons/icons";
import ScreenContainer from "../../screens/screen-container";
import { useListAssetsState } from "../../state/list-assets-state";
import DetailModal from "../asset-tag-detail/detail-modal";
import * as styles from "./assets.module.scss";
import VelavuAssetDevicePair from "../../data/asset-device-pair";
import ConnectionStatus from "../../data/connection-status";
import SignInContext from "../../sign-in-context";

function Loading() {
	return (
		<div className={styles.loading}>
			<LoadingDots size={40} color={"#a3b3cc"} />
		</div>
	);
}

function NoAssets() {
	return (
		<div className={styles.emptyContent}>
			<NoAssetsIcon />
			<span className={styles.heading}>No Assets registered</span>
			<span className={styles.info}>
				Once you add an asset, it will show up here.
			</span>
		</div>
	);
}

interface AssetActionInProgessModalProps {
	title: string;
	action: string;
	progressCurrent: number;
	progressTotal: number;
}

function AssetActionInProgessModal(props: AssetActionInProgessModalProps) {
	const { title, action, progressCurrent, progressTotal } = props;

	const actionText = useMemo(() => {
		switch (action) {
			case "delete":
				return "deleted";
			default:
				return "unpaired";
		}
	}, [action]);

	return (
		<ToggleableFade
			visible={progressCurrent !== progressTotal}
			shift
			className={styles.progressScrim}
		>
			<div className={styles.progressContainer}>
				<div className={styles.progressHeader}>{title}</div>
				<Divider />
				<div className={styles.progressBody}>
					{`${progressCurrent}/${progressTotal} ${actionText}`}
				</div>
			</div>
		</ToggleableFade>
	);
}

export default function Assets() {
	useEffect(() => {
		document.title = `Velavu | Assets`;
	});

	return (
		<ScreenContainer>
			<AssetsList />
		</ScreenContainer>
	);
}

function AssetName(props: { asset: VelavuAsset; onClick?: VoidFunction }) {
	return (
		<div className={styles.assetName}>
			<StatusIndicator status={getAssetStatus(props.asset)} size={12} />
			<div className={styles.assetLabel} onClick={props.onClick}>
				{props.asset.name}
			</div>
		</div>
	);
}

function AssetDevice(props: { asset: VelavuAsset; onPair: VoidFunction }) {
	if (props.asset.device_id !== undefined) {
		return (
			<div className={styles.assetDevice}>{props.asset.device_id}</div>
		);
	}

	return (
		<VelavuButton
			label="Pair tag"
			icon={(size, color, className) => (
				<IconAdd size={size} color={color} className={className} />
			)}
			size="small"
			onClick={(event) => {
				event.stopPropagation();
				props.onPair();
			}}
		/>
	);
}

function AssetsList() {
	const [searchText, setSearchText] = useState("");

	const [scrollToTop, setScrollToTop] = useState(false);

	const [actionProgress, setActionProgress] = useState(0);
	const [actionInProgress, setActionInProgress] = useState<
		"delete" | "unpair" | undefined
	>(undefined);

	const [totalPages, setTotalPages] = useState<number>(1);
	const [currentPage, setCurrentPage] = useState<number>(1);

	const [assetsState, assetsActions] = useListAssetsState();
	const [selectedAssets, setSelectedAssets] = useState<string[]>([]);

	const [filterStatus, setFilterStatus] = useState<ConnectionStatus | null>(
		null,
	);
	const [filterUnpaired, setFilterUnpaired] = useState<boolean>(false);
	const [filterCategory, setFilterCategory] = useState<AssetCategory | null>(
		null,
	);
	const [filterCategorization, setFilterCategorization] =
		useState<AssetCategorization | null>(null);
	const [appliedAssetFilters, setAppliedAssetFilters] =
		useState<GetAllAssetsParams>({});

	const assetFilters = useMemo<GetAllAssetsParams>(() => {
		const params: GetAllAssetsParams = {};

		if (filterUnpaired) {
			params.paired = false;
		}

		if (filterCategorization !== null) {
			params.category = filterCategorization.category;
			params.group = filterCategorization.group;
		} else if (filterCategory !== null) {
			params.category = filterCategory;
		}

		switch (filterStatus) {
			case "active":
				params.online = true;
				break;
			case "inactive":
				params.online = false;
				break;
			case "registered":
				params.only_registered = true;
				break;
		}

		return params;
	}, [filterCategorization, filterCategory, filterUnpaired, filterStatus]);

	const isDirty = useMemo(() => {
		return !deepCompare(appliedAssetFilters, assetFilters);
	}, [appliedAssetFilters, assetFilters]);

	const assetsActionsLoadAssets = assetsActions.loadAssets;
	const refetchAssets = useCallback(
		(params: GetAllAssetsParams) => {
			setTotalPages(1);
			setCurrentPage(1);
			assetsActionsLoadAssets(params);
		},
		[setTotalPages, setCurrentPage, assetsActionsLoadAssets],
	);

	const refetchEffectFirstMount = useRef(true);
	const refetchEffectLastAppliedAssetFilters = useRef(appliedAssetFilters);
	useEffect(() => {
		//Don't run on first mount to avoid double loading
		if (refetchEffectFirstMount.current) {
			refetchEffectFirstMount.current = false;
			return;
		}

		//Load assets with the combined filters and search query
		let cleanSearchText: string | undefined = searchText.trim();
		if (cleanSearchText.length === 0) {
			cleanSearchText = undefined;
		}
		const loadParams: GetAllAssetsParams = {
			...appliedAssetFilters,
			name: cleanSearchText,
		};

		//If the asset filters have changed, apply the update immediately
		if (
			!deepCompare(
				refetchEffectLastAppliedAssetFilters.current,
				appliedAssetFilters,
			)
		) {
			refetchEffectLastAppliedAssetFilters.current = appliedAssetFilters;
			refetchAssets(loadParams);
		} else {
			//Debounce the refetch to avoid spamming the server
			//as the user is typing
			const timeoutID = window.setTimeout(() => {
				refetchAssets(loadParams);
			}, 300);
			return () => {
				window.clearTimeout(timeoutID);
			};
		}
	}, [refetchAssets, appliedAssetFilters, searchText]);

	const applyFilters = useCallback(() => {
		setAppliedAssetFilters(assetFilters);
	}, [setAppliedAssetFilters, assetFilters]);

	const displayAssets = useMemo(() => {
		// Render assets based on page number (limit of 250 per page)
		const startIndex = (currentPage - 1) * 250;
		const endIndex = currentPage * 250;

		return assetsState.assets.slice(startIndex, endIndex);
	}, [assetsState.assets, currentPage]);

	const loadMoreAssets = useCallback(() => {
		if (assetsState.hasMore) {
			assetsActions.loadMore();
			setTotalPages((totalPages) => totalPages + 1);
		}

		setCurrentPage((currentPage) => currentPage + 1);
	}, [assetsState.hasMore, assetsActions]);

	const userContext = useContext(SignInContext);
	const categoryGroups =
		userContext.organization?.meta?.assets?.category_groups;
	const assetCategorizations = useMemo<AssetCategorization[]>(() => {
		if (categoryGroups === undefined) {
			return [];
		} else {
			return convertCategoryGroupsToAssetCategorizations(categoryGroups);
		}
	}, [categoryGroups]);

	const pushModal = useVelavuModal();
	const inspectAsset = useCallback(
		(asset: VelavuAsset, defaultOpenPair?: undefined | "asset" | "tag") => {
			let detailPromise: Promise<VelavuAssetDevicePair>;
			if (asset.device_id !== undefined) {
				detailPromise = VelavuAPI.devices
					.getSpecificDevice(asset.device_id)
					.then((device): VelavuAssetDevicePair => {
						if (device.asset === undefined) {
							throw new Error(
								"Client out of sync; device does not have an asset",
							);
						}

						return {
							asset: device.asset,
							device: device,
						};
					});
			} else {
				detailPromise = VelavuAPI.assets
					.getSpecificAsset(asset.id)
					.then(
						(asset): VelavuAssetDevicePair => ({
							asset: asset,
						}),
					);
			}

			detailPromise
				.then(({ asset, device }) => {
					pushModal(
						(close) => (
							<VelavuTitledModal
								title="Asset details"
								onClose={close}
							>
								<DetailModal
									asset={asset}
									device={device}
									defaultTab="asset"
									defaultOpenPair={defaultOpenPair}
									onUnpair={(type) => {
										assetsActions.updateAsset(
											type.asset.id,
											() => {
												return {
													...type.asset,
													device: undefined,
												};
											},
										);
									}}
									onPair={(type) => {
										assetsActions.updateAsset(
											type.asset.id,
											() => {
												return {
													...type.asset,
													device_id: type.tag.id,
													online: type.tag.online,
												};
											},
										);
									}}
									onUpdateAsset={(
										assetID,
										name,
										category,
										group,
									) => {
										assetsActions.updateAsset(
											assetID,
											(asset) => {
												return {
													...asset,
													name,
													category,
													group,
												};
											},
										);
									}}
								/>
							</VelavuTitledModal>
						),
						{ pinToTop: true },
					);
				})
				.catch(console.log);
		},
		[pushModal, assetsActions],
	);
	const deleteAsset = useCallback(
		(asset: VelavuAsset) => {
			pushModal((resolve) => (
				<VelavuModalPrompt
					title={"Delete the following asset?"}
					object={asset.name}
					labelConfirm="Delete"
					confirmDanger
					onSelect={resolve}
				>
					The asset and all associated data will be permanently
					deleted.
				</VelavuModalPrompt>
			)).then((result) => {
				if (result) {
					assetsActions.deleteAsset(asset.id);
				}
			});
		},
		[assetsActions, pushModal],
	);

	const deleteSelectedAssets = useCallback(() => {
		pushModal((resolve) => (
			<VelavuModalPrompt
				title={`Permanently delete ${selectedAssets.length} assets?`}
				confirmText="permanently delete"
				confirmDanger
				onSelect={resolve}
			>
				<span>
					To confirm deletion, please type <i>permanently delete</i>{" "}
					in the field below
				</span>
			</VelavuModalPrompt>
		)).then(async (result) => {
			if (result) {
				setActionInProgress("delete");

				for (let i = 0; i < selectedAssets.length; i++) {
					try {
						assetsActions.deleteAsset(selectedAssets[i]);
						setActionProgress(i + 1);
					} catch (error) {
						console.error(error);
					}
				}

				setSelectedAssets([]);
				setActionInProgress(undefined);
				setActionProgress(0);
			}
		});

		return () => {
			setActionInProgress(undefined);
		};
	}, [pushModal, selectedAssets, assetsActions]);

	const unpairSelectedAssets = useCallback(() => {
		pushModal((resolve) => (
			<VelavuModalPrompt
				title={`Unpair ${selectedAssets.length} assets from their current devices?`}
				confirmText="unpair"
				labelConfirm="Unpair"
				onSelect={resolve}
			>
				<span>
					To confirm unpairing, please type <i>unpair</i> in the field
					below
				</span>
			</VelavuModalPrompt>
		)).then(async (result) => {
			if (result) {
				setActionInProgress("unpair");

				for (let i = 0; i < selectedAssets.length; i++) {
					try {
						assetsActions.updateAsset(
							selectedAssets[i],
							(asset) => {
								return {
									...asset,
									device: undefined,
								};
							},
						);
						setActionProgress(i + 1);
					} catch (error) {
						console.error(error);
					}
				}

				setSelectedAssets([]);
				setActionInProgress(undefined);
				setActionProgress(0);
			}
		});
	}, [assetsActions, pushModal, selectedAssets]);

	const selectAsset = useCallback(
		(selected: boolean, assetID: string) => {
			setSelectedAssets((selectedAssets) => {
				if (selected) {
					return [...selectedAssets, assetID];
				} else {
					return selectedAssets.filter((id) => id !== assetID);
				}
			});
		},
		[setSelectedAssets],
	);

	const selectAllAssets = useCallback(() => {
		setSelectedAssets((selectedAssets) => {
			if (selectedAssets.length > 0) {
				return [];
			} else {
				return displayAssets.map((asset) => asset.id);
			}
		});
	}, [displayAssets, setSelectedAssets]);

	const result = useMemo(() => {
		if (displayAssets.length && !assetsState.isLoading) {
			return displayAssets.map((tag) => (
				<AssetTableRow
					key={tag.id}
					asset={tag}
					selected={selectedAssets.includes(tag.id)}
					onSelect={(selected) => selectAsset(selected!, tag.id)}
					onDetails={() => inspectAsset(tag)}
					onDelete={() => deleteAsset(tag)}
					onPair={() => inspectAsset(tag, "tag")}
				/>
			));
		} else if (displayAssets.length === 0 && !assetsState.isLoading) {
			return <NoAssets />;
		} else {
			return <Loading />;
		}
	}, [
		displayAssets,
		assetsState.isLoading,
		selectedAssets,
		selectAsset,
		inspectAsset,
		deleteAsset,
	]);

	//The category must be set by the categorization.
	//If the category changes, the categorization is invalid.
	useEffect(() => {
		setFilterCategorization((categorization) => {
			if (
				categorization !== null &&
				categorization.category !== filterCategory
			) {
				return null;
			} else {
				return categorization;
			}
		});
	}, [filterCategory, setFilterCategorization]);

	useEffect(() => {
		if (filterCategorization !== null) {
			setFilterCategory(filterCategorization.category);
		}
	}, [filterCategorization, setFilterCategory]);

	return (
		<>
			{actionInProgress && (
				<AssetActionInProgessModal
					title={
						actionInProgress === "delete"
							? "Deleting assets"
							: "Unpairing assets"
					}
					action={actionInProgress}
					progressCurrent={actionProgress}
					progressTotal={selectedAssets.length}
				/>
			)}
			<ListHeader
				icon={makeStyleable(IconVelavuAssets)}
				title="Assets"
				search={{
					searchPlaceholder: "Search",
					searchText: searchText,
					setSearchText: setSearchText,
				}}
				add={{ label: "Add Asset", route: "/assets/create" }}
			/>
			<AssetFilterMenu
				filterCategory={filterCategory}
				setFilterCategory={setFilterCategory}
				categorizations={assetCategorizations}
				filterCategorization={filterCategorization}
				setFilterCategorization={setFilterCategorization}
				filterStatus={filterStatus}
				setFilterStatus={setFilterStatus}
				filterUnpaired={filterUnpaired}
				setFilterUnpaired={setFilterUnpaired}
				selectedAssets={selectedAssets}
				onApply={applyFilters}
				onUnpair={unpairSelectedAssets}
				onDelete={deleteSelectedAssets}
				isDirty={isDirty}
			/>
			<VelavuTableHeader className={styles.tableHeader}>
				<VelavuTableHeaderLabel type="fixed" width={48}>
					<Checkbox
						checked={selectedAssets.length > 0}
						onClick={selectAllAssets}
						master
					/>
				</VelavuTableHeaderLabel>
				<VelavuTableHeaderLabel>Asset name</VelavuTableHeaderLabel>
				<VelavuTableHeaderLabel>Category</VelavuTableHeaderLabel>
				<VelavuTableHeaderLabel>Group</VelavuTableHeaderLabel>
				<VelavuTableHeaderLabel>Tag ID</VelavuTableHeaderLabel>
				<VelavuTableHeaderLabel type="edge" width={60} />
			</VelavuTableHeader>
			<VelavuTableBody
				isEmpty={!displayAssets.length}
				className={styles.tableBody}
				scrollToTop={scrollToTop}
				setScrollToTop={setScrollToTop}
			>
				{result}
			</VelavuTableBody>
			<ListPagination
				totalPages={totalPages}
				currentPage={currentPage}
				setCurrentPage={setCurrentPage}
				setScrollToTop={setScrollToTop}
				hasMore={assetsState.hasMore}
				loadMore={loadMoreAssets}
				isLoading={assetsState.isLoading}
			/>
		</>
	);
}

function AssetTableRow(props: {
	asset: VelavuAsset;
	onDetails: VoidFunction;
	onDelete: VoidFunction;
	onPair: VoidFunction;
	onSelect: (selected?: boolean) => void;
	selected: boolean;
}) {
	const [hoverState, enterHoverState, exitHoverState] = useToggleable();

	return (
		<>
			<VelavuTableRow
				className={styles.tableRow}
				onMouseEnter={enterHoverState}
				onMouseLeave={exitHoverState}
			>
				<VelavuTableColumn type="fixed" width={48}>
					<Checkbox
						checked={props.selected}
						onClick={props.onSelect}
					/>
				</VelavuTableColumn>
				<VelavuTableColumn onClick={props.onDetails}>
					<AssetName asset={props.asset} />
				</VelavuTableColumn>
				<VelavuTableColumn onClick={props.onDetails}>
					<CategoryTag category={props.asset.category} />
				</VelavuTableColumn>
				<VelavuTableColumn onClick={props.onDetails}>
					<GroupTag
						categorization={{
							category: props.asset.category,
							group: props.asset.group,
						}}
					/>
				</VelavuTableColumn>
				<VelavuTableColumn onClick={props.onDetails}>
					<AssetDevice asset={props.asset} onPair={props.onPair} />
				</VelavuTableColumn>
				<VelavuTableColumn
					type="edge"
					width={60}
					onClick={props.onDetails}
				/>
			</VelavuTableRow>
		</>
	);
}
