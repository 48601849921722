// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.xQqAjmNbT6goxxo0270R{font-weight:500;font-size:24px}.qlkv2IJIRLpvvZSTcxrT{font-weight:500;font-size:20px}.AqVCB6ueioltyFoK7KRR{font-weight:500;font-size:12px}.ESNu36lPAnN8H9oO5LmA{font-size:18px}.M2Y05V0DBJJRD1qhCXkP{font-size:18px;font-weight:500}.odH1uME0e2_kYkMV0Xwi{font-size:16px}.KaLIF746erIJM5skWAB7{font-size:16px;font-weight:500}.XiZ6UPK5melvLe9rjhPv{font-size:14px}.PQt_Fn2q8_jWXWQDJpsn{font-size:14px;font-weight:500}.ENljXE7P0vvQcVUfXST_{font-size:14px;font-weight:400}.NS345LwbOsW3xX6R46Wz{font-size:12px}.v3GVyQ1wpIpmV4EpKLqm{font-size:12px;font-weight:500}.U1JyMvMcC25JroB3zytp{flex-grow:1;overflow:hidden;overflow-y:auto}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/components/settings/settings-modal.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,WAAA,CACA,eAAA,CACA,eAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `xQqAjmNbT6goxxo0270R`;
export var h2 = `qlkv2IJIRLpvvZSTcxrT`;
export var h3 = `AqVCB6ueioltyFoK7KRR`;
export var p0 = `ESNu36lPAnN8H9oO5LmA`;
export var p0m = `M2Y05V0DBJJRD1qhCXkP`;
export var p1 = `odH1uME0e2_kYkMV0Xwi`;
export var p1m = `KaLIF746erIJM5skWAB7`;
export var p2 = `XiZ6UPK5melvLe9rjhPv`;
export var p2m = `PQt_Fn2q8_jWXWQDJpsn`;
export var p2n = `ENljXE7P0vvQcVUfXST_`;
export var p3 = `NS345LwbOsW3xX6R46Wz`;
export var p3m = `v3GVyQ1wpIpmV4EpKLqm`;
export var container = `U1JyMvMcC25JroB3zytp`;
export default ___CSS_LOADER_EXPORT___;
