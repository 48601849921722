import React, { useCallback, useState } from "react";
import { VelavuAPI, VelavuAsset, VelavuGeofence } from "velavu-js-api";
import IconDelete from "../../../../dynamicicons/icon-delete";
import IconEdit from "../../../../dynamicicons/icon-edit";
import DropdownPanelToolbar from "../../../../elements/dropdown-panel-toolbar";
import VelavuMenu, {
	VelavuMenuItem,
	VelavuOverflowButton,
} from "../../../../elements/velavu-menu";
import {
	useVelavuModal,
	VelavuModalPrompt,
} from "../../../../elements/velavu-modal";
import { useGeocoder } from "../../../../helper/api-helper";
import PinnedList from "../pinned-list";
import * as styles from "./geofence-detail.module.scss";
import VelavuTabSwitcher from "../../../../elements/velavu-tab-switcher";
import GeofenceDetailAssets from "./geofence-detail-assets";
import GeofenceDetailEvents from "./geofence-detail-events";

export interface GeofenceDetailProps {
	geofence: VelavuGeofence;
	onBack?: () => void;
	onClose?: () => void;

	onEdit?: () => void;
	onDelete?: () => void;

	onSelectAsset?: (assetID: string) => void;
	onHoverAsset?: (asset: VelavuAsset | undefined) => void;
}

enum GeofenceDetailsTab {
	Assets,
	Events,
}

export default function GeofenceDetail(props: GeofenceDetailProps) {
	const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
	const openMenu = useCallback(
		(event: React.MouseEvent<HTMLElement>) =>
			setMenuAnchor(event.currentTarget),
		[setMenuAnchor],
	);
	const closeMenu = useCallback(() => setMenuAnchor(null), [setMenuAnchor]);

	const pushModal = useVelavuModal();
	const propsGeofence = props.geofence;
	const propsOnEdit = props.onEdit;
	const handleEditGeofence = useCallback(() => {
		closeMenu();
		if (propsOnEdit !== undefined) propsOnEdit();
	}, [closeMenu, propsOnEdit]);
	const propsOnDelete = props.onDelete;
	const handleDeleteGeofence = useCallback(() => {
		closeMenu();
		pushModal((resolve) => (
			<VelavuModalPrompt
				title={"Delete the following geofence?"}
				object={propsGeofence.name}
				labelConfirm="Delete"
				confirmDanger
				onSelect={resolve}
			>
				The site and all associated data will be permanently deleted.
			</VelavuModalPrompt>
		)).then((result) => {
			if (result) {
				VelavuAPI.geofences
					.deleteExistingGeofence(propsGeofence.id)
					.catch(() =>
						alert(`Failed to delete geofence ${propsGeofence.id}`),
					);
				if (propsOnDelete !== undefined) propsOnDelete();
			}
		});
	}, [closeMenu, pushModal, propsGeofence, propsOnDelete]);

	const address = useGeocoder(propsGeofence.center);

	const [selectedTab, setSelectedTab] = useState(GeofenceDetailsTab.Assets);

	return (
		<React.Fragment>
			<VelavuMenu
				open={!!menuAnchor}
				onClose={closeMenu}
				refPosition={menuAnchor}
			>
				<VelavuMenuItem
					icon={(size, color) => (
						<IconEdit size={size} color={color} />
					)}
					label="Edit geofence"
					onClick={handleEditGeofence}
				/>
				<VelavuMenuItem
					icon={(size, color) => (
						<IconDelete size={size} color={color} />
					)}
					label="Delete geofence"
					onClick={handleDeleteGeofence}
					danger
				/>
			</VelavuMenu>

			<PinnedList>
				<DropdownPanelToolbar
					onBack={props.onBack}
					onClose={props.onClose}
				/>

				<div className={styles.titleHeader}>
					<div className={styles.titleText}>
						<span className={styles.titleHeaderTitle}>
							{propsGeofence.name}
						</span>
						<span className={styles.titleHeaderSubtitle}>
							{address}
						</span>
					</div>

					<VelavuOverflowButton
						onClick={openMenu}
						open={!!menuAnchor}
					/>
				</div>

				<VelavuTabSwitcher
					selectedKey={selectedTab}
					onSelectKey={setSelectedTab}
					labels={{
						[GeofenceDetailsTab.Assets]: "Assets",
						[GeofenceDetailsTab.Events]: "Events",
					}}
				>
					{selectedTab === GeofenceDetailsTab.Assets && (
						<GeofenceDetailAssets
							geofenceID={props.geofence.id}
							onSelectAsset={props.onSelectAsset}
							onHoverAsset={props.onHoverAsset}
						/>
					)}

					{selectedTab === GeofenceDetailsTab.Events && (
						<GeofenceDetailEvents geofenceID={props.geofence.id} />
					)}
				</VelavuTabSwitcher>
			</PinnedList>
		</React.Fragment>
	);
}
