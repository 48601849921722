// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.bkpLY079lmF1ueAypVeD{font-weight:500;font-size:24px}.KJQCyo1XWZo5frgpZpJD{font-weight:500;font-size:20px}.yu9amhInQ_ZiDuDAJJLr{font-weight:500;font-size:12px}.t8CpjFWy0Jx06BHc4TvL{font-size:18px}.WJLJdYUcH44ok9j8XW6y{font-size:18px;font-weight:500}.hpxvxKpGPnf1F1n8lVZQ{font-size:16px}.X379rN_s9l9MrqCSU2hk,.T3OA_4yvxK9NAQEXxJLV{font-size:16px;font-weight:500}.wKnzjOXP37OG4sLjTQN8,.UgtWeO9OhrGlr9MGf4MW{font-size:14px}.wv0JexJQkpajB7wGHGu0{font-size:14px;font-weight:500}.humpmStR1KunnpeJuZs6{font-size:14px;font-weight:400}.F4qtlmLriXgMcaurjlmw{font-size:12px}.fyE9X9Juye_pEioj1J_V{font-size:12px;font-weight:500}.Qa8zhFtZKsKNo6C_zWil{display:flex;flex-direction:row;align-items:self-start;padding:16px}.Qa8zhFtZKsKNo6C_zWil>*{flex-shrink:0}.AcAoCCvNgF2uFM11Fugt{margin-right:16px}.ZJfz9CYhylai675pEd6q{display:flex;flex-direction:column;flex-grow:1;flex-basis:0;margin-right:16px}.T3OA_4yvxK9NAQEXxJLV{margin-bottom:12px;max-width:12em;overflow:hidden;white-space:nowrap;text-overflow:ellipsis}.T3OA_4yvxK9NAQEXxJLV svg{overflow:visible}.UgtWeO9OhrGlr9MGf4MW{color:#5f718c}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/components/shared/site-switcher-list-item.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,4CACC,cAAA,CACA,eAAA,CAGD,4CACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,YAAA,CACA,kBAAA,CAEA,sBAAA,CACA,YAAA,CAEA,wBACC,aAAA,CAIF,sBACC,iBAAA,CAGD,sBACC,YAAA,CACA,qBAAA,CACA,WAAA,CACA,YAAA,CAEA,iBAAA,CAGD,sBAEC,kBAAA,CACA,cAAA,CDqGA,eAAA,CACA,kBAAA,CACA,sBAAA,CAEA,0BACC,gBAAA,CCtGF,sBAEC,aDvBgB","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `bkpLY079lmF1ueAypVeD`;
export var h2 = `KJQCyo1XWZo5frgpZpJD`;
export var h3 = `yu9amhInQ_ZiDuDAJJLr`;
export var p0 = `t8CpjFWy0Jx06BHc4TvL`;
export var p0m = `WJLJdYUcH44ok9j8XW6y`;
export var p1 = `hpxvxKpGPnf1F1n8lVZQ`;
export var p1m = `X379rN_s9l9MrqCSU2hk`;
export var title = `T3OA_4yvxK9NAQEXxJLV`;
export var p2 = `wKnzjOXP37OG4sLjTQN8`;
export var subtitle = `UgtWeO9OhrGlr9MGf4MW`;
export var p2m = `wv0JexJQkpajB7wGHGu0`;
export var p2n = `humpmStR1KunnpeJuZs6`;
export var p3 = `F4qtlmLriXgMcaurjlmw`;
export var p3m = `fyE9X9Juye_pEioj1J_V`;
export var container = `Qa8zhFtZKsKNo6C_zWil`;
export var status = `AcAoCCvNgF2uFM11Fugt`;
export var text = `ZJfz9CYhylai675pEd6q`;
export default ___CSS_LOADER_EXPORT___;
