import React, { CSSProperties } from "react";

export default function IconConnection(props: {
	className?: string;
	style?: CSSProperties;
	color?: string;
	size?: number;
}) {
	return (
		<svg
			className={props.className}
			style={props.style}
			xmlns="http://www.w3.org/2000/svg"
			width={props.size ?? 24}
			height={props.size ?? 24}
			viewBox="0 0 24 24"
		>
			<path
				d="M3 15C3 14.1716 3.67157 13.5 4.5 13.5C5.32843 13.5 6 14.1716 6 15V18C6 18.8284 5.32843 19.5 4.5 19.5C3.67157 19.5 3 18.8284 3 18V15Z"
				fill={props.color ?? "black"}
			/>
			<path
				d="M13 9C13 8.17157 13.6716 7.5 14.5 7.5C15.3284 7.5 16 8.17157 16 9V18C16 18.8284 15.3284 19.5 14.5 19.5C13.6716 19.5 13 18.8284 13 18V9Z"
				fill={props.color ?? "black"}
			/>
			<path
				d="M8 12C8 11.1716 8.67157 10.5 9.5 10.5C10.3284 10.5 11 11.1716 11 12V18C11 18.8284 10.3284 19.5 9.5 19.5C8.67157 19.5 8 18.8284 8 18V12Z"
				fill={props.color ?? "black"}
			/>
			<path
				d="M18 6C18 5.17157 18.6716 4.5 19.5 4.5C20.3284 4.5 21 5.17157 21 6V18C21 18.8284 20.3284 19.5 19.5 19.5C18.6716 19.5 18 18.8284 18 18V6Z"
				fill={props.color ?? "black"}
			/>
		</svg>
	);
}
