import React, { useState } from "react";
import {
	normalizeDeviceHardware,
	VelavuDevice,
	VelavuSite,
} from "velavu-js-api";
import DropdownPanelToolbar from "../../../../elements/dropdown-panel-toolbar";
import VelavuItemIcon from "../../../../elements/velavu-item-icon";
import VelavuTabSwitcher from "../../../../elements/velavu-tab-switcher";
import * as styles from "./anchor-detail.module.scss";
import AnchorEnvironment from "./anchor-environment";
import AnchorProperties from "./anchor-properties";
import EventsDetailList from "../../../../elements/events-detail-list";
import { EventGroup } from "../../../../data/event-group";

interface AnchorDetailProps {
	site: VelavuSite;
	anchor: VelavuDevice;
	onBack: () => void;
	onClose: () => void;
}

enum AnchorDetailTab {
	Info,
	Events,
	Environment,
}

const EVENT_GROUPS: readonly EventGroup[] = [
	EventGroup.All,
	EventGroup.LowBattery,
	EventGroup.Connectivity,
	EventGroup.Custom,
];

export default function AnchorDetail(props: AnchorDetailProps) {
	const [selectedTab, setSelectedTab] = useState(AnchorDetailTab.Info);

	return (
		<div className={styles.container}>
			<DropdownPanelToolbar
				onBack={props.onBack}
				onClose={props.onClose}
			/>
			<div className={styles.header}>
				<VelavuItemIcon
					data={normalizeDeviceHardware(props.anchor.hardware)}
				/>
				<div className={styles.headerText}>
					<span className={styles.headerID}>{props.anchor.id}</span>
					<span className={styles.headerLocation}>
						{props.site.name}
					</span>
				</div>
			</div>
			<VelavuTabSwitcher
				selectedKey={selectedTab}
				onSelectKey={setSelectedTab}
				labels={{
					[AnchorDetailTab.Info]: "Info",
					[AnchorDetailTab.Events]: "Events",
					[AnchorDetailTab.Environment]: "Environment",
				}}
			>
				{selectedTab === AnchorDetailTab.Info && (
					<div className={styles.tables}>
						<AnchorProperties
							anchor={props.anchor}
							site={props.site}
						/>
					</div>
				)}

				{selectedTab === AnchorDetailTab.Events && (
					<EventsDetailList
						deviceID={props.anchor.id}
						groups={EVENT_GROUPS}
					/>
				)}

				{selectedTab === AnchorDetailTab.Environment && (
					<div className={styles.tables}>
						<AnchorEnvironment anchor={props.anchor} />
					</div>
				)}
			</VelavuTabSwitcher>
		</div>
	);
}
