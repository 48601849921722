// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.zr7bp_Nu62bYLctzWvfu{font-weight:500;font-size:24px}.YKqvp8CztlsZYzQWbTSQ{font-weight:500;font-size:20px}.kzA43GqJiBdBP8kcoeXN{font-weight:500;font-size:12px}.IzHpaNRgSoDP2wy08N6i{font-size:18px}.Y4Bd1NOFUPm7j6VKFrED{font-size:18px;font-weight:500}.IfjhD3vNgDMnvje0lsDt{font-size:16px}.lLls6Kn3Z5rUuQTFxo9s{font-size:16px;font-weight:500}.sMlrylFC0QXR4UxToNCE{font-size:14px}.s_SMSSv7bT788sJk83fZ{font-size:14px;font-weight:500}.tRfDPZWvx6eouczuSU6h{font-size:14px;font-weight:400}.LwwRlSd_lAl6zFR5B6d7{font-size:12px}.uWEh34PqYJxpcfSAHcFX{font-size:12px;font-weight:500}.cKmmBp2WRpgJt61aNjUr{width:320px;padding:20px;box-sizing:border-box}.IOPxah3EalFasKoGugNI{display:inline-block;font-weight:500;color:#00255d;font-size:20px;margin-bottom:20px}.FEV_v1CkRldi_wNJ5aQm{margin-top:16px;display:flex;flex-direction:row;align-items:center;justify-content:stretch}.OUQdCx1KwEsTQAWTW8cs{width:8px;flex-shrink:0}.KCPmJKOMAiLUGLl6IgaJ{margin-bottom:16px}.Q_qarb9lQ__R_P3OCGG6{margin-top:16px}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/components/settings/change-password-modal.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,WAAA,CAEA,YAAA,CACA,qBAAA,CAGD,sBACC,oBAAA,CACA,eAAA,CACA,aAAA,CACA,cAAA,CACA,kBAAA,CAGD,sBACC,eAAA,CAEA,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,uBAAA,CAGD,sBACC,SAAA,CACA,aAAA,CAGD,sBACC,kBAAA,CAGD,sBACC,eAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `zr7bp_Nu62bYLctzWvfu`;
export var h2 = `YKqvp8CztlsZYzQWbTSQ`;
export var h3 = `kzA43GqJiBdBP8kcoeXN`;
export var p0 = `IzHpaNRgSoDP2wy08N6i`;
export var p0m = `Y4Bd1NOFUPm7j6VKFrED`;
export var p1 = `IfjhD3vNgDMnvje0lsDt`;
export var p1m = `lLls6Kn3Z5rUuQTFxo9s`;
export var p2 = `sMlrylFC0QXR4UxToNCE`;
export var p2m = `s_SMSSv7bT788sJk83fZ`;
export var p2n = `tRfDPZWvx6eouczuSU6h`;
export var p3 = `LwwRlSd_lAl6zFR5B6d7`;
export var p3m = `uWEh34PqYJxpcfSAHcFX`;
export var container = `cKmmBp2WRpgJt61aNjUr`;
export var title = `IOPxah3EalFasKoGugNI`;
export var buttonBar = `FEV_v1CkRldi_wNJ5aQm`;
export var buttonBarSpacer = `OUQdCx1KwEsTQAWTW8cs`;
export var inputMargin = `KCPmJKOMAiLUGLl6IgaJ`;
export var statusNotice = `Q_qarb9lQ__R_P3OCGG6`;
export default ___CSS_LOADER_EXPORT___;
