import React, {
	useCallback,
	useContext,
	useMemo,
	useRef,
	useState,
} from "react";
import IconClose from "../dynamicicons/icon-close";
import SimpleObservable from "../helper/simple-observable";
import Divider from "./divider";
import ToggleableFade from "./toggleable-fade";
import VelavuButton from "./velavu-button";
import VelavuIconButton from "./velavu-icon-button";
import VelavuInput from "./velavu-input";
import * as styles from "./velavu-modal.module.scss";

export interface TryResolveData<T> {
	resolve: T | undefined; //The resolution data
	cancel: VoidFunction; //Cancels this resolve attempt
	initiateResolve: (data?: T) => void; //Closes this dialog
}

interface VelavuModalBuilder<T> {
	(
		resolve: (value?: T) => void,
		onTryResolve: SimpleObservable<TryResolveData<T>>,
	): React.ReactNode;
}

interface VelavuModalRegistered<T> {
	builder: VelavuModalBuilder<T>;
	id: number;
	promiseResolve: (value?: T | undefined) => void;
	removeState: boolean;
	pinToTop: boolean;
	onTryResolve: SimpleObservable<TryResolveData<T>>;
}

interface ModalContextProps {
	push: <T>(
		builder: VelavuModalBuilder<T>,
		config?: ModalConfig,
	) => Promise<T | undefined>;
}

interface ModalConfig {
	//Whether to pin this modal to the top
	pinToTop?: boolean;
	isCloseable?: boolean;
}

const VelavuModalContext = React.createContext<ModalContextProps>({
	push() {
		throw new Error("No modal context available");
	},
});

export function useVelavuModal() {
	return useContext(VelavuModalContext).push;
}

//Place somewhere in the tree to encapsulate modals below it
export function VelavuModalProvider(props: {
	children: React.ReactNode;
	zIndex?: number;
}) {
	const { children, zIndex } = props;

	const modalCounter = useRef(0);
	const [modals, setModals] = useState<VelavuModalRegistered<any>[]>([]);

	const addModal = useCallback(
		<T,>(builder: VelavuModalBuilder<T>, config?: ModalConfig) => {
			return new Promise<T | undefined>((resolve) => {
				const newModal: VelavuModalRegistered<T> = {
					builder: builder,
					id: modalCounter.current++,
					promiseResolve: resolve,
					removeState: false,
					pinToTop: config?.pinToTop ?? false,
					onTryResolve: new SimpleObservable<TryResolveData<T>>(),
				};

				setModals((modals) => modals.concat(newModal));
			});
		},
		[setModals],
	);

	const removeModal = useCallback(
		<T,>(modal: VelavuModalRegistered<T>) => {
			//Finding the modal index
			const index = modals.indexOf(modal);
			if (index === -1) return;

			setModals(modals.filter((allModals) => allModals !== modal));
		},
		[setModals, modals],
	);

	const animateRemoveModal = useCallback(
		<T,>(modal: VelavuModalRegistered<T>) => {
			//Finding the modal index
			const i = modals.indexOf(modal);
			if (i === -1) return;
			const newModals = [...modals];
			newModals[i] = {
				...newModals[i],
				removeState: true,
			};

			setModals(newModals);
		},
		[setModals, modals],
	);

	const resolveModal = useCallback(
		<T,>(modal: VelavuModalRegistered<T>, value?: T) => {
			modal.promiseResolve(value);
			animateRemoveModal(modal);
		},
		[animateRemoveModal],
	);

	return (
		<VelavuModalContext.Provider value={{ push: addModal }}>
			{children}

			{modals.map((modal) => {
				const tryResolve = (resolve?: unknown | undefined) => {
					//Emitting the update
					let resolveCancelled = false;
					const cancelResolve = () => (resolveCancelled = true);
					const initiateResolve = (resolve: unknown | undefined) =>
						resolveModal(modal, resolve);
					modal.onTryResolve.emit({
						resolve: resolve,
						cancel: cancelResolve,
						initiateResolve: initiateResolve,
					});

					//Resolving the modal
					if (!resolveCancelled) {
						resolveModal(modal, resolve);
					}
				};

				return (
					<ToggleableFade
						key={modal.id}
						visible={!modal.removeState}
						shift
						onFadeOut={() => removeModal(modal)}
						style={zIndex ? { zIndex: zIndex } : undefined}
						className={`${styles.scrim} ${
							modal.pinToTop
								? styles.scrimPinned
								: styles.scrimCenter
						}`}
						onClick={() => tryResolve()}
					>
						<div
							className={styles.dialog}
							onClick={(event) => event.stopPropagation()}
						>
							{modal.builder(tryResolve, modal.onTryResolve)}
						</div>
					</ToggleableFade>
				);
			})}
		</VelavuModalContext.Provider>
	);
}

//Use as the root element of a modal to add a title and close button
export function VelavuTitledModal(props: {
	title: string;
	onClose: VoidFunction;
	children?: React.ReactNode;
}) {
	const { title, onClose, children } = props;

	return (
		<React.Fragment>
			<div className={styles.dialogHeader}>
				<span className={styles.dialogTitle}>{title}</span>
				<VelavuIconButton onClick={onClose}>
					<IconClose color="#A3B3CC" />
				</VelavuIconButton>
			</div>
			<div className={styles.dialogContent}>{children}</div>
		</React.Fragment>
	);
}

interface VelavuModalPromptProps {
	title: string;
	object?: string | number;
	children?: string | React.ReactNode;

	labelConfirm?: string;
	labelCancel?: string;
	confirmText?: string;
	confirmDanger?: boolean;
	multipleSelections?: boolean;
	onSelect: (confirm: boolean) => void;
}

//Use as the root element of a modal to create a cancel / confirm dialog
export function VelavuModalPrompt(props: VelavuModalPromptProps) {
	const {
		title,
		object,
		children,
		labelConfirm,
		labelCancel,
		confirmText,
		confirmDanger,
		onSelect,
	} = props;

	const [confirmTextState, setConfirmText] = useState<string>("");
	const onChange = useCallback(
		(value: string) => {
			setConfirmText(value.toLowerCase());
		},
		[setConfirmText],
	);

	const disabled = useMemo(() => {
		if (confirmText) {
			return confirmTextState !== confirmText.toLowerCase();
		}
		return false;
	}, [confirmText, confirmTextState]);

	return (
		<div className={styles.dialogPromptContainer}>
			<div className={styles.dialogPromptHeader}>
				{title}
				{object && <span className={styles.object}>{object}</span>}
			</div>

			<Divider />

			{children && (
				<div className={styles.dialogPromptBody}>{children}</div>
			)}

			{confirmText && (
				<VelavuInput
					className={styles.dialogPromptInput}
					onChange={onChange}
					placeholder={confirmText}
					italicPlaceholder
					fullWidth
				/>
			)}

			<div className={styles.dialogPromptButtons}>
				<VelavuButton
					label={labelCancel ?? "Cancel"}
					outlined
					fullWidth
					onClick={() => onSelect(false)}
				/>
				<div className={styles.dialogPromptSpacer} />
				<VelavuButton
					label={labelConfirm ?? "Delete"}
					danger={confirmDanger}
					disabled={disabled}
					fullWidth
					onClick={() => onSelect(true)}
				/>
			</div>
		</div>
	);
}
