// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.P6fEU0aFD3Plm6uhFEhO{font-weight:500;font-size:24px}.vXpb2W3fMbOaNIO2axMc{font-weight:500;font-size:20px}.dop2NrK1bLV9Csumy2q4{font-weight:500;font-size:12px}.WnAFBtpJhnsU8XXGFECQ{font-size:18px}.Scvfng8438_QmMmCg3V4{font-size:18px;font-weight:500}.umDX6xJCktvLuUH5tKIS{font-size:16px}.iQZG6QaX3mTVQVYydA9Q{font-size:16px;font-weight:500}.Os_paRGSP8qbSuYiSp6r,.SVLEz5TLrt7mt14UXG4p{font-size:14px}.Wqb5kcSzYBiaSRAcKI2j,.MpWW1Ns0E2gF5e5RHZ5Q{font-size:14px;font-weight:500}.TJnm3pFRGAj4GYMcE40_{font-size:14px;font-weight:400}.E58wCis1XHVi4yHqF3d_{font-size:12px}.bzByXh61ncZr0LrLa30E{font-size:12px;font-weight:500}.niCuG_SCa8i14WpwqAdh{margin:12px;padding:10px 20px;display:flex;flex-direction:row;align-items:center}.m8LDo5lhBXIEflOzC2ci{margin-left:20px;flex:1 1 0;display:flex;flex-direction:column}.MpWW1Ns0E2gF5e5RHZ5Q{color:#00255d}.SVLEz5TLrt7mt14UXG4p{color:#5f718c;display:flex;flex-direction:row;gap:12px}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/elements/event-card.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,4CACC,cAAA,CAGD,4CACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,WAAA,CACA,iBAAA,CAEA,YAAA,CACA,kBAAA,CACA,kBAAA,CAGD,sBACC,gBAAA,CACA,UAAA,CAEA,YAAA,CACA,qBAAA,CAGD,sBAEC,aDTc,CCYf,sBAEC,aDbgB,CCehB,YAAA,CACA,kBAAA,CACA,QAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `P6fEU0aFD3Plm6uhFEhO`;
export var h2 = `vXpb2W3fMbOaNIO2axMc`;
export var h3 = `dop2NrK1bLV9Csumy2q4`;
export var p0 = `WnAFBtpJhnsU8XXGFECQ`;
export var p0m = `Scvfng8438_QmMmCg3V4`;
export var p1 = `umDX6xJCktvLuUH5tKIS`;
export var p1m = `iQZG6QaX3mTVQVYydA9Q`;
export var p2 = `Os_paRGSP8qbSuYiSp6r`;
export var eventCardSubtitle = `SVLEz5TLrt7mt14UXG4p`;
export var p2m = `Wqb5kcSzYBiaSRAcKI2j`;
export var eventCardTitle = `MpWW1Ns0E2gF5e5RHZ5Q`;
export var p2n = `TJnm3pFRGAj4GYMcE40_`;
export var p3 = `E58wCis1XHVi4yHqF3d_`;
export var p3m = `bzByXh61ncZr0LrLa30E`;
export var eventCard = `niCuG_SCa8i14WpwqAdh`;
export var eventCardText = `m8LDo5lhBXIEflOzC2ci`;
export default ___CSS_LOADER_EXPORT___;
