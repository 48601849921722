import React from "react";

export default function IconInventoryStatus(props: {
	fill: string;
	stroke: string;
}) {
	return (
		<svg
			width="12"
			height="12"
			viewBox="0 0 12 12"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle
				cx="6"
				cy="6"
				r="4.5"
				fill={props.fill}
				stroke={props.stroke}
				strokeWidth="3"
			/>
		</svg>
	);
}
