import React from "react";
import IconCar from "../../../dynamicicons/icon-car";
import IconSpeed from "../../../dynamicicons/icon-speed";
import VelavuIconButton from "../../../elements/velavu-icon-button";
import * as styles from "./layers.module.scss";

interface LayersProps {
	showEvents?: boolean;
	setShowEvents: (showSpeeding: boolean) => void;
	showSpeeding?: boolean;
	setShowSpeeding: (showSpeeding: boolean) => void;
}

const colorActive = "#DA3E52";
const colorInactive = "#CCD6E5";

export default function Layers(props: LayersProps) {
	return (
		<div className={styles.layers}>
			<span className={styles.label}>Layers</span>
			<VelavuIconButton
				onClick={() => props.setShowEvents(!props.showEvents)}
				tooltip="Show Driving Events"
			>
				<IconCar
					color={props.showEvents ? colorActive : colorInactive}
					size={24}
				/>
			</VelavuIconButton>
			<VelavuIconButton
				onClick={() => props.setShowSpeeding(!props.showSpeeding)}
				tooltip="Show Speeding Zones"
			>
				<IconSpeed
					color={props.showSpeeding ? colorActive : colorInactive}
					size={24}
				/>
			</VelavuIconButton>
		</div>
	);
}
