import React, { useEffect, useMemo, useRef, useState } from "react";
import * as styles from "./mapbox-injectable-draw-walls.module.scss";
import PlanToolType from "../../data/plan-tool-type";
import PlaceableFloorImage, {
	usePlaceableFloorImageURL,
} from "../../data/placeable-floor-image";
import {
	MapHelperInstance,
	useListenerSetupMapEffect,
	useStyleDependantMapEffect,
	useStyleSetupMapEffect,
} from "../../helper/map-helper";
import { geoJSONBlankCollection, imageSourceBlank } from "./mapbox-injectable";
import * as globals from "../../styles/global.icss.scss";
import mapboxgl, {
	EventData,
	GeoJSONSource,
	ImageSource,
	MapEventType,
} from "mapbox-gl";
import { Feature, Geometry } from "geojson";
import iconCheckCircle from "../../img/check-circle.svg";

const mapboxIDWallsEditorPoints = "draw-walls-editor-points";
const mapboxIDWallsEditorLines = "draw-walls-editor-lines";
const mapboxIDWallsEditorPreviewLine = "draw-walls-editor-preview-line";

export interface MapboxInjectableDrawWallsParams {
	mapInstance: MapHelperInstance;
	disabled?: boolean;

	toolType: PlanToolType;

	walls: [number, number][][];
	onChangeWalls: React.Dispatch<React.SetStateAction<[number, number][][]>>;
}

interface EditorPointProperties {
	wallIndex: number;
	pointIndex: number;
}

function resolveFeatureID(wallIndex: number, pointIndex: number) {
	return wallIndex * 1_000_000 + pointIndex;
}

export default function useMapboxInjectableDrawWalls(
	params: MapboxInjectableDrawWallsParams,
) {
	//Create and register the checkmark marker
	const markerCompleteLine = useMemo(() => {
		const element = document.createElement("div");
		element.className = styles.completeLineContainer;
		element.innerHTML = `
		<button class="${styles.completeLineContainer}">
			<div class="${styles.completeLineButton}">
				<img src="${iconCheckCircle}" alt="Done" />
				<svg class="${styles.hoverIndicator}"
					 xmlns="http://www.w3.org/2000/svg"
					 width="24"
					 height="24"
					 viewBox="0 0 24 24">
					<circle cx="12" cy="12" r="8" fill="#9CE2BF" />
				</svg>
			</div>
		</button>
		`;

		element.style.display = "none";
		return new mapboxgl.Marker(element);
	}, []);
	useEffect(() => {
		const map = params.mapInstance.map;
		if (map !== undefined) {
			markerCompleteLine.setLngLat([0, 0]).addTo(map);

			return () => {
				markerCompleteLine.remove();
			};
		}
	}, [params.mapInstance.map, markerCompleteLine]);

	const [cursorCoords, setCursorCoords] = useState<
		[number, number] | undefined
	>(undefined);

	//The index of the wall that will be added to while drawing.
	//null if a new wall should be added
	const [drawWallTargetIndex, setDrawWallTargetIndex] = useState<
		number | null
	>(null);
	useEffect(() => {
		if (
			drawWallTargetIndex !== null &&
			drawWallTargetIndex >= params.walls.length
		) {
			setDrawWallTargetIndex(null);
		}
	}, [drawWallTargetIndex, params.walls]);

	//Reset the target wall index when changing tools
	useEffect(() => {
		if (params.toolType !== "draw") {
			setDrawWallTargetIndex(null);
		}
	}, [params.toolType, setDrawWallTargetIndex]);

	useStyleSetupMapEffect(params.mapInstance, (_, box) => {
		box.addSource(mapboxIDWallsEditorPoints, {
			type: "geojson",
			data: geoJSONBlankCollection,
		});
		box.addLayer({
			id: mapboxIDWallsEditorPoints,
			type: "circle",
			source: mapboxIDWallsEditorPoints,
			paint: {
				"circle-color": "#FFFFFF",
				"circle-stroke-color": globals.accent,
				"circle-stroke-width": 2,
				"circle-radius": 4,
			},
		});

		box.addSource(mapboxIDWallsEditorLines, {
			type: "geojson",
			data: geoJSONBlankCollection,
		});
		box.addLayer(
			{
				id: mapboxIDWallsEditorLines,
				type: "line",
				source: mapboxIDWallsEditorLines,
				layout: {
					"line-join": "round",
					"line-cap": "round",
				},
				paint: {
					"line-color": "#3957DF",
					"line-width": 3,
				},
			},
			mapboxIDWallsEditorPoints,
		);

		box.addSource(mapboxIDWallsEditorPreviewLine, {
			type: "geojson",
			data: geoJSONBlankCollection,
		});
		box.addLayer(
			{
				id: mapboxIDWallsEditorPreviewLine,
				type: "line",
				source: mapboxIDWallsEditorPreviewLine,
				layout: {
					"line-join": "round",
					"line-cap": "round",
				},
				paint: {
					"line-color": "#3957DF",
					"line-width": 3,
					"line-opacity": 0.5,
				},
			},
			mapboxIDWallsEditorPoints,
		);
	});

	useStyleDependantMapEffect(
		params.mapInstance,
		(map, box) => {
			const editorPointsSource = map.getSource(
				mapboxIDWallsEditorPoints,
			) as GeoJSONSource;
			const editorLinesSource = map.getSource(
				mapboxIDWallsEditorLines,
			) as GeoJSONSource;
			const editorPreviewLineSource = map.getSource(
				mapboxIDWallsEditorPreviewLine,
			) as GeoJSONSource;

			//Set blank data if we are disabled
			if (params.disabled) {
				editorPointsSource.setData(geoJSONBlankCollection);
				editorLinesSource.setData(geoJSONBlankCollection);
				editorPreviewLineSource.setData(geoJSONBlankCollection);

				return;
			}

			const walls = params.walls;

			//Update the editor points
			editorPointsSource.setData({
				type: "FeatureCollection",
				features: walls
					.map((wall, wallIndex) => {
						return wall.map(
							(
								point,
								pointIndex,
							): Feature<Geometry, EditorPointProperties> => {
								return {
									type: "Feature",
									id: resolveFeatureID(wallIndex, pointIndex),
									geometry: {
										type: "Point",
										coordinates: point,
									},
									properties: {
										wallIndex: wallIndex,
										pointIndex: pointIndex,
									},
								};
							},
						);
					})
					.flat(1),
			});

			//Update the editor lines
			editorLinesSource.setData({
				type: "FeatureCollection",
				features: walls
					.map((wall, wallIndex) => {
						if (wall.length < 2) {
							return [];
						} else {
							return wall
								.slice(0, -1)
								.map(
									(
										point,
										pointIndex,
									): Feature<
										Geometry,
										EditorPointProperties
									> => {
										//Exclude the last point
										const nextPoint = wall[pointIndex + 1]; //Get the next point
										return {
											type: "Feature",
											id: resolveFeatureID(
												wallIndex,
												pointIndex,
											),
											geometry: {
												type: "LineString",
												coordinates: [point, nextPoint], //Create a line between this point and the next one
											},
											properties: {
												wallIndex: wallIndex,
												pointIndex: pointIndex,
											},
										};
									},
								);
						}
					})
					.flat(1),
			});

			//Add a ghost line to the cursor
			if (
				params.toolType === "draw" &&
				drawWallTargetIndex !== null &&
				cursorCoords !== undefined
			) {
				const targetWall = walls[drawWallTargetIndex];
				const targetPoint = targetWall[targetWall.length - 1];

				editorPreviewLineSource.setData({
					type: "Feature",
					geometry: {
						type: "LineString",
						coordinates: [targetPoint, cursorCoords],
					},
					properties: {},
				});
			} else {
				editorPreviewLineSource.setData(geoJSONBlankCollection);
			}
		},
		[
			params.disabled,
			params.walls,
			cursorCoords,
			params.toolType,
			drawWallTargetIndex,
		],
	);

	//Set the cursor to a crosshair while we are enabled
	useStyleDependantMapEffect(
		params.mapInstance,
		(map, box) => {
			if (!params.disabled) {
				map.getCanvas().style.cursor = "crosshair";
			} else {
				map.getCanvas().style.cursor = "";
			}
		},
		[params.disabled],
	);

	//Track the cursor coordinates state
	useListenerSetupMapEffect(
		params.mapInstance.map,
		(_, box) => {
			if (!params.disabled) {
				box.on("mousemove", (event) => {
					setCursorCoords([event.lngLat.lng, event.lngLat.lat]);
				});
			}
		},
		[setCursorCoords, params.disabled],
	);

	//Add a new point on click
	useListenerSetupMapEffect(
		params.mapInstance.map,
		(_, box) => {
			if (!params.disabled && params.toolType === "draw") {
				box.on("click", (event) => {
					//Extend the walls array with the click point
					const clickPoint: [number, number] = [
						event.lngLat.lng,
						event.lngLat.lat,
					];

					const newWallArray = [...params.walls];

					if (drawWallTargetIndex === null) {
						const newWallIndex = newWallArray.length;
						newWallArray.push([clickPoint]);
						params.onChangeWalls(newWallArray);
						setDrawWallTargetIndex(newWallIndex);
					} else {
						const targetWall = newWallArray[drawWallTargetIndex];
						newWallArray[drawWallTargetIndex] = [
							...targetWall,
							clickPoint,
						];
						params.onChangeWalls(newWallArray);
					}
				});
			}
		},
		[
			params.disabled,
			params.toolType,
			params.onChangeWalls,
			params.walls,
			drawWallTargetIndex,
			setDrawWallTargetIndex,
		],
	);

	//Show the checkmark to complete a line
	useEffect(() => {
		if (drawWallTargetIndex !== null && params.toolType === "draw") {
			const targetWall = params.walls[drawWallTargetIndex];
			if (targetWall.length >= 2) {
				const targetPoint = targetWall[targetWall.length - 1];
				markerCompleteLine.setLngLat(targetPoint);
				markerCompleteLine.getElement().style.display = "block";
				return;
			}
		}

		markerCompleteLine.getElement().style.display = "none";
	}, [
		markerCompleteLine,
		params.walls,
		drawWallTargetIndex,
		params.toolType,
	]);

	//Reset the target wall index when clicking the checkmark
	useEffect(() => {
		const button = markerCompleteLine
			.getElement()
			.getElementsByClassName(styles.completeLineContainer)[0];

		const listener = (event: Event) => {
			event.stopPropagation();
			setDrawWallTargetIndex(null);
		};
		button.addEventListener("click", listener);

		return () => {
			button.removeEventListener("click", listener);
		};
	}, [markerCompleteLine, setDrawWallTargetIndex]);

	//Click and drag to move points
	useListenerSetupMapEffect(
		params.mapInstance.map,
		(map, box) => {
			if (!params.disabled && params.toolType === "edit") {
				const canvas = map.getCanvas();

				let moveTarget: EditorPointProperties | null = null;

				const updatePoint = (
					properties: EditorPointProperties,
					coords: [number, number],
				) => {
					params.onChangeWalls((walls) => {
						const newWalls = [...walls];
						newWalls[properties.wallIndex][properties.pointIndex] =
							coords;
						return newWalls;
					});
				};

				const onMove = (
					event: MapEventType["mousemove"] & EventData,
				) => {
					const clickCoords: [number, number] = [
						event.lngLat.lng,
						event.lngLat.lat,
					];

					//Update the location
					updatePoint(moveTarget!, clickCoords);
				};
				const onUp = (event: MapEventType["mouseup"] & EventData) => {
					const clickCoords: [number, number] = [
						event.lngLat.lng,
						event.lngLat.lat,
					];

					//Update the location
					updatePoint(moveTarget!, clickCoords);

					//Clean up
					canvas.style.cursor = "crosshair";
					map.off("mousemove", onMove);
					moveTarget = null;
				};
				const onDown = (
					event: MapEventType["mousedown"] & EventData,
				) => {
					//Prevent the default map drag behavior
					event.preventDefault();

					//Register the move target
					moveTarget = event.features![0]
						.properties as EditorPointProperties;

					//Set the cursor to grab
					canvas.style.cursor = "grabbing";

					//Register listeners for the lifecycle of this grab
					map.on("mousemove", onMove);
					map.once("mouseup", onUp);
				};
				map.on("mousedown", mapboxIDWallsEditorPoints, onDown);

				return () => {
					map.off("mousemove", onMove);
					map.off("mousedown", mapboxIDWallsEditorPoints, onDown);
				};
			}
		},
		[params.disabled, params.toolType, params.onChangeWalls],
	);

	//Delete lines on click
	useListenerSetupMapEffect(
		params.mapInstance.map,
		(_, box) => {
			if (!params.disabled && params.toolType === "erase") {
				box.on("click", mapboxIDWallsEditorLines, (event) => {
					//Get the click target
					const properties = event.features![0]
						.properties as EditorPointProperties;

					//Split the wall at the target point
					const targetWall = params.walls[properties.wallIndex];
					const targetWallBefore = targetWall.slice(
						0,
						properties.pointIndex + 1,
					);
					const targetWallAfter = targetWall.slice(
						properties.pointIndex + 1,
					);

					const wallArrayBefore = params.walls.slice(
						0,
						properties.wallIndex,
					);
					const wallArrayAfter = params.walls.slice(
						properties.wallIndex + 1,
					);

					const newWallArray: [number, number][][] = [];

					newWallArray.push(...wallArrayBefore);
					if (targetWallBefore.length >= 2) {
						newWallArray.push(targetWallBefore);
					}
					if (targetWallAfter.length >= 2) {
						newWallArray.push(targetWallAfter);
					}
					newWallArray.push(...wallArrayAfter);

					params.onChangeWalls(newWallArray);
				});
			}
		},
		[
			params.disabled,
			params.toolType,
			params.onChangeWalls,
			params.walls,
			drawWallTargetIndex,
		],
	);

	useStyleDependantMapEffect(
		params.mapInstance,
		(map, box) => {
			if (params.toolType === "erase") {
				map.setPaintProperty(mapboxIDWallsEditorLines, "line-color", [
					"case",
					["boolean", ["feature-state", "hover"], false],
					globals.error,
					globals.accent,
				]);
			} else {
				map.setPaintProperty(
					mapboxIDWallsEditorLines,
					"line-color",
					globals.accent,
				);
			}
		},
		[params.toolType],
	);

	const hoverEditorLineID = useRef<string | number | undefined>(undefined);
	useListenerSetupMapEffect(
		params.mapInstance.map,
		(map, box) => {
			box.on("mousemove", mapboxIDWallsEditorLines, (event) => {
				if (hoverEditorLineID.current !== undefined) {
					map.setFeatureState(
						{
							source: mapboxIDWallsEditorLines,
							id: hoverEditorLineID.current,
						},
						{
							hover: false,
						},
					);
				}

				const hoverID = event.features![0].id;
				hoverEditorLineID.current = hoverID;
				map.setFeatureState(
					{
						source: mapboxIDWallsEditorLines,
						id: hoverID,
					},
					{
						hover: true,
					},
				);
			});

			box.on("mouseleave", mapboxIDWallsEditorLines, (event) => {
				if (hoverEditorLineID.current !== undefined) {
					map.setFeatureState(
						{
							source: mapboxIDWallsEditorLines,
							id: hoverEditorLineID.current,
						},
						{
							hover: false,
						},
					);

					hoverEditorLineID.current = undefined;
				}
			});
		},
		[],
	);
}
