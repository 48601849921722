// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.Gvo7T2wjGGHjx5R3kUAd{font-weight:500;font-size:24px}.uxiZ_zVU284BjFl1V22X{font-weight:500;font-size:20px}.bB5sx0tJiXE8tE57M2pU{font-weight:500;font-size:12px}.sKGT4o3iWZLVay1PDmx0{font-size:18px}.k_gaSsdit2g2fNIO_x4Y{font-size:18px;font-weight:500}.fToLzT04HVsNTWOdh5Ly{font-size:16px}.ErsMxuipYMzLLAiq8_ZH,.ojZHeTnkgJ0yQqdqATn2{font-size:16px;font-weight:500}.SIpEidghYe5QtQPqqUzY{font-size:14px}.NMwh0U0mJqHaMPVYw2Ar{font-size:14px;font-weight:500}.cwVS8hLck1ZYy9sJzMLu{font-size:14px;font-weight:400}.SCLV5jaK_qDje7yulxCl{font-size:12px}.g6YZBASMPeWJ19Q6o80j{font-size:12px;font-weight:500}.uMjovBheBg1ThShPABpk{border-radius:8px;border:1px solid #e2e4e5;height:175px;box-sizing:border-box;display:flex;flex-direction:row;align-items:center;justify-content:space-evenly;transition:background-color 250ms ease;cursor:pointer;color:#00255d;text-decoration:none}.uMjovBheBg1ThShPABpk:hover{background-color:#f5f8f9}.FIdgHE947YEUcdHJwxFn{display:flex;flex-direction:row;align-items:center}.ojZHeTnkgJ0yQqdqATn2{margin-left:8px}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/components/onboarding/onboarding-step-assets.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,4CACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,iBAAA,CACA,wBAAA,CAEA,YAAA,CACA,qBAAA,CAEA,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,4BAAA,CD0GA,sCAAA,CAEA,cAAA,CCxGA,aDJc,CCKd,oBAAA,CDyGA,4BACC,wBAxGM,CCCR,sBACC,YAAA,CACA,kBAAA,CACA,kBAAA,CAGD,sBAGC,eAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `Gvo7T2wjGGHjx5R3kUAd`;
export var h2 = `uxiZ_zVU284BjFl1V22X`;
export var h3 = `bB5sx0tJiXE8tE57M2pU`;
export var p0 = `sKGT4o3iWZLVay1PDmx0`;
export var p0m = `k_gaSsdit2g2fNIO_x4Y`;
export var p1 = `fToLzT04HVsNTWOdh5Ly`;
export var p1m = `ErsMxuipYMzLLAiq8_ZH`;
export var buttonLabel = `ojZHeTnkgJ0yQqdqATn2`;
export var p2 = `SIpEidghYe5QtQPqqUzY`;
export var p2m = `NMwh0U0mJqHaMPVYw2Ar`;
export var p2n = `cwVS8hLck1ZYy9sJzMLu`;
export var p3 = `SCLV5jaK_qDje7yulxCl`;
export var p3m = `g6YZBASMPeWJ19Q6o80j`;
export var content = `uMjovBheBg1ThShPABpk`;
export var button = `FIdgHE947YEUcdHJwxFn`;
export default ___CSS_LOADER_EXPORT___;
