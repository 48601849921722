import React, { CSSProperties } from "react";

export default function IconDeviceDFU(props: {
	className?: string;
	style?: CSSProperties;
	color?: string;
	size?: number;
}) {
	return (
		<svg
			className={props.className}
			style={props.style}
			xmlns="http://www.w3.org/2000/svg"
			width={props.size ?? 24}
			height={props.size ?? 24}
			viewBox="0 0 24 24"
		>
			<path
				d="M23 7a5.972 5.972 0 0 1-1 3.318 6.031 6.031 0 0 1-2 1.88A5.958 5.958 0 0 1 17 13c-.337 0-.667-.028-.988-.081L16 12.917a6.005 6.005 0 0 1-4.919-6.905L11.083 6c.12-.718.368-1.393.72-2a6.03 6.03 0 0 1 1.842-1.975L13.682 2A6 6 0 0 1 23 7Zm-3.354 2.475-1.62-1.621A.5.5 0 0 1 18.378 7h.871A2.25 2.25 0 0 0 17 4.75a.75.75 0 0 1 0-1.5A3.75 3.75 0 0 1 20.75 7h.871a.5.5 0 0 1 .354.854l-1.621 1.62a.5.5 0 0 1-.708 0Zm-5.292-4.95 1.62 1.621a.5.5 0 0 1-.353.854h-.871A2.25 2.25 0 0 0 17 9.25a.75.75 0 0 1 0 1.5A3.75 3.75 0 0 1 13.25 7h-.871a.5.5 0 0 1-.354-.854l1.621-1.62a.5.5 0 0 1 .708 0Z"
				fill={props.color ?? "black"}
			/>

			<path
				d="M11.41 2H8a6 6 0 0 0-6 6v8a6 6 0 0 0 6 6h8a6 6 0 0 0 6-6v-3.41a7.507 7.507 0 0 1-2 1.286V16a4 4 0 0 1-4 4H8a4 4 0 0 1-4-4V8a4 4 0 0 1 4-4h2.124a7.506 7.506 0 0 1 1.286-2Z"
				fill={props.color ?? "black"}
			/>

			<path
				d="M18 14.434V16a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h1.566a7.568 7.568 0 0 0 0 2H8v8h8v-1.566a7.566 7.566 0 0 0 2 0Z"
				fill={props.color ?? "black"}
			/>
		</svg>
	);
}
