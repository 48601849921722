// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.SWZeQvJVH9ubR8xtfiRT{font-weight:500;font-size:24px}.LLqEoIG5lArBhAPuPsAE{font-weight:500;font-size:20px}.gKihwd0_cey2Mh5khaEg{font-weight:500;font-size:12px}.KbUxvZBbx8gwNw38j9FJ{font-size:18px}.lpW_i37MDiN5EbldkpwR{font-size:18px;font-weight:500}.jQTdMgC3mAPW6nIfWyhi{font-size:16px}.BGr5podKZnvP8mxXTLlQ{font-size:16px;font-weight:500}.LQyX0NHFnBRdt9PRi45m{font-size:14px}.SEKSq2ocvqt3dDd8RWBA{font-size:14px;font-weight:500}.l_1apzpf_8z4t2c1vfeY{font-size:14px;font-weight:400}.d78uJ3L_HTPsMEsgvjg6{font-size:12px}.G76TKAW3_O8VGmEIHa5s{font-size:12px;font-weight:500}.NKLJBDB6h8Fek9HTvE2Q{width:32px;height:32px;border-radius:50%}.W839_JkbSumL5pdbav8r{width:100%;height:100%;border-radius:inherit}.RbPxi1xdedYcDdOSTzYC{display:flex;align-items:center;justify-content:center;background-color:#00255d;color:#3a58e2;font-weight:bold;font-size:16px;text-transform:uppercase}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/elements/velavu-avatar.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,UAAA,CACA,WAAA,CAEA,iBAAA,CAGD,sBACC,UAAA,CACA,WAAA,CACA,qBAAA,CAGD,sBACC,YAAA,CACA,kBAAA,CACA,sBAAA,CAEA,wBDVW,CCYX,aDfS,CCgBT,gBAAA,CACA,cAAA,CACA,wBAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `SWZeQvJVH9ubR8xtfiRT`;
export var h2 = `LLqEoIG5lArBhAPuPsAE`;
export var h3 = `gKihwd0_cey2Mh5khaEg`;
export var p0 = `KbUxvZBbx8gwNw38j9FJ`;
export var p0m = `lpW_i37MDiN5EbldkpwR`;
export var p1 = `jQTdMgC3mAPW6nIfWyhi`;
export var p1m = `BGr5podKZnvP8mxXTLlQ`;
export var p2 = `LQyX0NHFnBRdt9PRi45m`;
export var p2m = `SEKSq2ocvqt3dDd8RWBA`;
export var p2n = `l_1apzpf_8z4t2c1vfeY`;
export var p3 = `d78uJ3L_HTPsMEsgvjg6`;
export var p3m = `G76TKAW3_O8VGmEIHa5s`;
export var content = `NKLJBDB6h8Fek9HTvE2Q`;
export var image = `W839_JkbSumL5pdbav8r`;
export var initials = `RbPxi1xdedYcDdOSTzYC`;
export default ___CSS_LOADER_EXPORT___;
