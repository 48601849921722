import React, { CSSProperties } from "react";

export default function IconEvents(props: {
	className?: string;
	style?: CSSProperties;
	color?: string;
	size?: number;
}) {
	return (
		<svg
			className={props.className}
			style={props.style}
			xmlns="http://www.w3.org/2000/svg"
			width={props.size ?? 24}
			height={props.size ?? 24}
			viewBox="0 0 24 24"
		>
			<path
				d="M7.5 18C8.32843 18 9 17.3284 9 16.5C9 15.6716 8.32843 15 7.5 15C6.67157 15 6 15.6716 6 16.5C6 17.3284 6.67157 18 7.5 18ZM11 16.5C11 18.433 9.433 20 7.5 20C5.567 20 4 18.433 4 16.5C4 14.567 5.567 13 7.5 13C9.433 13 11 14.567 11 16.5ZM16.5 18C17.3284 18 18 17.3284 18 16.5C18 15.6716 17.3284 15 16.5 15C15.6716 15 15 15.6716 15 16.5C15 17.3284 15.6716 18 16.5 18ZM7.5 9C8.32843 9 9 8.32843 9 7.5C9 6.67157 8.32843 6 7.5 6C6.67157 6 6 6.67157 6 7.5C6 8.32843 6.67157 9 7.5 9ZM16.5 9C17.3284 9 18 8.32843 18 7.5C18 6.67157 17.3284 6 16.5 6C15.6716 6 15 6.67157 15 7.5C15 8.32843 15.6716 9 16.5 9ZM11 7.5C11 9.433 9.433 11 7.5 11C5.567 11 4 9.433 4 7.5C4 5.567 5.567 4 7.5 4C9.433 4 11 5.567 11 7.5ZM16.5 20C18.433 20 20 18.433 20 16.5C20 14.567 18.433 13 16.5 13C14.567 13 13 14.567 13 16.5C13 18.433 14.567 20 16.5 20ZM16.5 11C18.433 11 20 9.433 20 7.5C20 5.567 18.433 4 16.5 4C14.567 4 13 5.567 13 7.5C13 9.433 14.567 11 16.5 11Z"
				fill={props.color ?? "black"}
			/>
		</svg>
	);
}
