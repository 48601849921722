// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.AYJHVpIVPErlcI3qUPIV,.rv9LgSqUayzQzGwQeYlL{font-weight:500;font-size:24px}.asI0z4kkAQDOcCl8shfq{font-weight:500;font-size:20px}.Buu3bkKUDqyKK42Zng8V{font-weight:500;font-size:12px}.psGbnDHzesMMKe6IcOgq{font-size:18px}.X9Rqgsk_knIVD9seH1cg{font-size:18px;font-weight:500}.DNQgoLMURpGXAl2JAnvI{font-size:16px}.PGrQXCZbByNG7deMYMAW{font-size:16px;font-weight:500}.PcOJ9nx132iVsKV64jU1,.BQqjDDYFVSWtPJO8cuKx{font-size:14px}.LTeZPhxFvlrvxuQAiD0D{font-size:14px;font-weight:500}.zOaQ35zGwRP_g9xgBjCB{font-size:14px;font-weight:400}.VdXAHORy_aL1LaWrZO4m{font-size:12px}.p9Zkcz9ej37dhaXjnVxk{font-size:12px;font-weight:500}.TZJsKqG9QRO3Ta0up4Ya{overflow:hidden}.KRrzUnM814wX64rRr32x{background-color:#f5f8f9}.kP1MFIM5WxTce0WviFal{width:100%}.WbB_V4sdtdQ9IM8MDURs{padding:40px 20px 20px;box-sizing:border-box}.rv9LgSqUayzQzGwQeYlL{margin:0 0 8px}.BQqjDDYFVSWtPJO8cuKx{margin-bottom:40px}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/components/dashboard/dropdown/floor-create/floor-create-intro.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,4CACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,4CACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,eAAA,CAGD,sBACC,wBDYO,CCTR,sBACC,UAAA,CAGD,sBACC,sBAAA,CACA,qBAAA,CAGD,sBAEC,cAAA,CAGD,sBAEC,kBAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `AYJHVpIVPErlcI3qUPIV`;
export var title = `rv9LgSqUayzQzGwQeYlL`;
export var h2 = `asI0z4kkAQDOcCl8shfq`;
export var h3 = `Buu3bkKUDqyKK42Zng8V`;
export var p0 = `psGbnDHzesMMKe6IcOgq`;
export var p0m = `X9Rqgsk_knIVD9seH1cg`;
export var p1 = `DNQgoLMURpGXAl2JAnvI`;
export var p1m = `PGrQXCZbByNG7deMYMAW`;
export var p2 = `PcOJ9nx132iVsKV64jU1`;
export var body = `BQqjDDYFVSWtPJO8cuKx`;
export var p2m = `LTeZPhxFvlrvxuQAiD0D`;
export var p2n = `zOaQ35zGwRP_g9xgBjCB`;
export var p3 = `VdXAHORy_aL1LaWrZO4m`;
export var p3m = `p9Zkcz9ej37dhaXjnVxk`;
export var pinnedList = `TZJsKqG9QRO3Ta0up4Ya`;
export var header = `KRrzUnM814wX64rRr32x`;
export var image = `kP1MFIM5WxTce0WviFal`;
export var content = `WbB_V4sdtdQ9IM8MDURs`;
export default ___CSS_LOADER_EXPORT___;
