import React, { CSSProperties } from "react";

export default function IconSiteExited(props: {
	className?: string;
	style?: CSSProperties;
	color?: string;
	size?: number;
}) {
	return (
		<svg
			className={props.className}
			style={props.style}
			width={props.size ?? 24}
			height={props.size ?? 24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M13.3557 6.03424C12.5899 5.32192 11.4101 5.32192 10.6442 6.03424L6.64425 9.75441C6.23432 10.1357 6 10.6731 6 11.2375V16.4827C6 17.6025 6.90108 18.5 8 18.5H16C16.5523 18.5 17 18.9477 17 19.5C17 20.0523 16.5523 20.5 16 20.5H8C5.78603 20.5 4 18.6965 4 16.4827V11.2375C4 10.1194 4.464 9.05085 5.28219 8.2899L9.28219 4.56973C10.8111 3.14781 13.1697 3.14344 14.7036 4.55661L16.6542 6.24366C17.0719 6.60495 17.1176 7.23646 16.7563 7.65417C16.3951 8.07189 15.7635 8.11763 15.3458 7.75634L13.3826 6.05832C13.3735 6.05046 13.3646 6.04243 13.3557 6.03424Z"
				fill={props.color ?? "black"}
			/>
			<path
				d="M10 12C9.44771 12 9 12.4477 9 13C9 13.5523 9.44772 14 10 14H17.6008V17L21.6247 13.7809C22.1251 13.3805 22.1251 12.6195 21.6247 12.2191L17.6008 9V12H10Z"
				fill={props.color ?? "black"}
			/>
		</svg>
	);
}
