import { DateTime, Interval } from "luxon";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
	AssetCategory,
	LocationResource,
	LocationType,
	NormalizedDeviceHardware,
	VelavuAPI,
	VelavuAsset,
	VelavuDevice,
	VelavuInventory,
	VelavuRoute,
	normalizeDeviceHardware,
} from "velavu-js-api";
import { useDateRange } from "../../../../data/date-range";
import UnpairAssetTag from "../../../../data/unpair-asset-tag";
import IconPlace from "../../../../dynamicicons/icon-place";
import Divider from "../../../../elements/divider";
import DropdownPanelToolbar from "../../../../elements/dropdown-panel-toolbar";
import VelavuButton from "../../../../elements/velavu-button";
import VelavuItemIcon from "../../../../elements/velavu-item-icon";
import {
	VelavuModalPrompt,
	VelavuTitledModal,
	useVelavuModal,
} from "../../../../elements/velavu-modal";
import VelavuTabSwitcher from "../../../../elements/velavu-tab-switcher";
import { useDisplayLocation } from "../../../../helper/api-helper";
import { useCancellableEffect } from "../../../../helper/hook-helper";
import { mapLocationType } from "../../../../helper/language-helper";
import { classArr } from "../../../../helper/style-helper";
import DetailModal from "../../../asset-tag-detail/detail-modal";
import * as styles from "./asset-detail.module.scss";
import AssetEnvironment from "./asset-environment";
import AssetInventory from "./asset-inventory";
import AssetProperties from "./asset-properties";
import AssetRoutes from "./asset-routes";
import DatePicker from "../../../../elements/date-picker";
import EventsDetailList from "../../../../elements/events-detail-list";

export enum AssetDetailTab {
	Info,
	TripHistory,
	Events,
	Environment,
	Inventory,
}

interface AssetDetailProps {
	asset: VelavuAsset;
	device?: VelavuDevice;
	onBack: () => void;
	onClose: () => void;

	onPair: (data: UnpairAssetTag) => void;
	onUnpair: (data: UnpairAssetTag) => void;
	onUpdateAsset: (
		assetID: string,
		name: string,
		category: AssetCategory,
		group: string,
		notes: string,
	) => void;
	onRepositionAsset: VoidFunction;
	onRemoveParentAsset: VoidFunction;
	onSelectAsset: (assetID: string) => void;
	onHoverAsset: (asset: VelavuAsset | undefined) => void;

	onDeleteRoute: VoidFunction;
	hoverRoute?: VelavuRoute;
	setHoverRoute: (route?: VelavuRoute) => void;
	selectedRoute?: VelavuRoute;
	setSelectedRoute: (route: VelavuRoute | undefined) => void;
	onSelectRoute: VoidFunction;

	tab: AssetDetailTab | undefined;
	setTab: (tab: AssetDetailTab) => void;
}

export default function AssetDetail(props: AssetDetailProps) {
	const {
		asset,
		device,
		onBack,
		onClose,
		onPair,
		onUnpair,
		onUpdateAsset,
		onRepositionAsset,
		onRemoveParentAsset,
		onSelectAsset,
		onHoverAsset,
		onDeleteRoute,
		hoverRoute,
		setHoverRoute,
		selectedRoute,
		setSelectedRoute,
		onSelectRoute,
		tab,
		setTab,
	} = props;

	const assetLocation = useDisplayLocation(device);

	const [routes, setRoutes] = useState<VelavuRoute[] | undefined>(undefined);

	const [inventory, setInventory] = useState<VelavuInventory | undefined>(
		undefined,
	);

	const [sortNew, setSortNew] = useState(true);

	//Last week -> Today
	const [dateRange, setDateRange] = useDateRange(7);

	const pushModal = useVelavuModal();

	const removeParentAsset = useCallback(
		(parentID: string) => {
			VelavuAPI.assetInventory
				.removeInventoryItem(parentID, asset.id)
				.then(onRemoveParentAsset)
				.catch((error) =>
					console.log("Failed to remove asset from parent:", error),
				);
		},
		[asset, onRemoveParentAsset],
	);

	const deleteRoute = useCallback(
		(deletedRoute: VelavuRoute) => {
			pushModal((resolve) => (
				<VelavuModalPrompt
					title={"Delete the following route?"}
					labelConfirm="Delete"
					confirmDanger
					onSelect={resolve}
				>
					The route and all associated data will be permanently
					deleted.
				</VelavuModalPrompt>
			)).then((result) => {
				if (result) {
					VelavuAPI.assetRoutes
						.deleteSpecificRoute(asset.id, deletedRoute.id)
						.then(() =>
							setRoutes(
								routes?.filter(
									(route) => route.id != deletedRoute.id,
								),
							),
						)
						.finally(onDeleteRoute)
						.catch(() => alert(`Failed to delete route`));
				}
			});
		},
		[asset.id, onDeleteRoute, pushModal, routes],
	);

	const selectRoute = useCallback(
		(route: VelavuRoute | undefined) => {
			setSelectedRoute(route);
			onSelectRoute();
		},
		[onSelectRoute, setSelectedRoute],
	);

	useCancellableEffect(
		(addPromise) => {
			if (tab !== AssetDetailTab.TripHistory) return;

			setRoutes(undefined);

			addPromise(
				VelavuAPI.assetRoutes.getAllRoutes(asset.id, {
					since: dateRange.start,
					until: dateRange.end,
				}),
			)
				.then((routes) => setRoutes(routes))
				.catch(console.log);
		},
		[asset, dateRange, setRoutes, tab],
	);
	const sortedRoutes = useMemo(() => {
		return routes?.sort(sortNew ? compareRouteDesc : compareRouteAsc);
	}, [routes, sortNew]);

	const selectedTab = useMemo(() => {
		return tab ?? AssetDetailTab.Info;
	}, [tab]);

	useCancellableEffect(
		(addPromise) => {
			setInventory(undefined);

			if (tab !== AssetDetailTab.Inventory) return;

			addPromise(VelavuAPI.assetInventory.getAssetInventory(asset.id))
				.then((response: VelavuInventory) => {
					if (Object.entries(response).length !== 0) {
						setInventory(response);
					}
				})
				.catch((error) => console.log(error));
		},
		[asset, setInventory, tab],
	);

	useEffect(() => {
		if (
			selectedTab !== AssetDetailTab.TripHistory &&
			selectedRoute !== undefined
		) {
			selectRoute(undefined);
		}
	}, [selectedRoute, selectRoute, selectedTab]);

	const inspectAsset = useCallback(() => {
		pushModal(
			(close) => (
				<VelavuTitledModal title="Asset details" onClose={close}>
					<DetailModal
						asset={asset}
						device={device}
						onUnpair={onUnpair}
						onPair={onPair}
						onUpdateAsset={onUpdateAsset}
					/>
				</VelavuTitledModal>
			),
			{ pinToTop: true },
		);
	}, [asset, device, onPair, onUnpair, onUpdateAsset, pushModal]);

	const navigate = useNavigate();

	const navigateAnalytics = useCallback(() => {
		navigate("/analytics", { state: { assetID: asset.id } });
	}, [navigate, asset]);

	return (
		<div className={styles.container}>
			<DropdownPanelToolbar onBack={onBack} onClose={onClose} />

			<div className={styles.header} onClick={onRepositionAsset}>
				<VelavuItemIcon
					data={
						asset.profile_img_url
							? { url: asset.profile_img_url }
							: asset.category
					}
				/>
				<div className={styles.headerText}>
					<span className={styles.headerName}>{asset.name}</span>
					<span className={styles.headerLocation}>
						{assetLocation}
					</span>
					{device?.location !== undefined &&
						device.location.location_type !==
							LocationType.Fixed && (
							<DeviceLastSeen
								location={device.location}
								dynamicPosition={
									device.config?.positioning?.dynamic_mode
								}
							/>
						)}
				</div>
				<div className={styles.headerSpacer} />
				<IconPlace className={styles.headerIcon} color="#5F718C" />
			</div>

			<VelavuTabSwitcher
				selectedKey={selectedTab}
				onSelectKey={setTab}
				labels={{
					[AssetDetailTab.Info]: "Info",
					[AssetDetailTab.TripHistory]:
						normalizeDeviceHardware(device?.hardware) ===
							NormalizedDeviceHardware.Argo && "Trip history",
					[AssetDetailTab.Events]: "Events",
					[AssetDetailTab.Environment]:
						(normalizeDeviceHardware(device?.hardware) ===
							NormalizedDeviceHardware.Juno ||
							normalizeDeviceHardware(device?.hardware) ===
								NormalizedDeviceHardware.Meridian) &&
						"Environment",
					[AssetDetailTab.Inventory]:
						normalizeDeviceHardware(device?.hardware) ===
							NormalizedDeviceHardware.Argo && "Inventory",
				}}
			>
				{selectedTab === AssetDetailTab.Info && (
					<div className={styles.tables}>
						<AssetProperties
							asset={asset}
							device={device}
							onSelectAsset={onSelectAsset}
							removeParentAsset={() =>
								asset.parent_id &&
								removeParentAsset(asset.parent_id)
							}
						/>
						<div className={styles.detailsRow}>
							<VelavuButton
								className={styles.detailsButton}
								label="View details"
								fullWidth
								onClick={inspectAsset}
							/>
							<VelavuButton
								className={styles.detailsButton}
								label="Analytics"
								fullWidth
								onClick={navigateAnalytics}
								outlined
							/>
						</div>
					</div>
				)}

				{selectedTab === AssetDetailTab.TripHistory && (
					<>
						<DatePicker
							range={dateRange}
							onUpdate={setDateRange}
							sortNew={sortNew}
							setSortNew={setSortNew}
						/>
						<Divider />
						<div className={styles.listScroll}>
							<AssetRoutes
								routes={sortedRoutes}
								onHoverRoute={setHoverRoute}
								hoveredRouteID={hoverRoute?.id}
								onSelectRoute={setSelectedRoute}
								selectedRoute={selectedRoute}
								onDeleteRoute={deleteRoute}
							/>
						</div>
					</>
				)}

				{selectedTab === AssetDetailTab.Events && (
					<EventsDetailList assetID={asset.id} />
				)}

				{selectedTab === AssetDetailTab.Environment && (
					<AssetEnvironment device={device} />
				)}

				{selectedTab === AssetDetailTab.Inventory && (
					<AssetInventory
						asset={asset}
						inventory={inventory}
						setInventory={setInventory}
						onSelectAsset={onSelectAsset}
						onHoverAsset={onHoverAsset}
					/>
				)}
			</VelavuTabSwitcher>
		</div>
	);
}

function compareRouteAsc(route1: VelavuRoute, route2: VelavuRoute) {
	return (
		new Date(route1.data.time_start).getTime() -
		new Date(route2.data.time_start).getTime()
	);
}

function compareRouteDesc(route1: VelavuRoute, route2: VelavuRoute) {
	return -compareRouteAsc(route1, route2);
}

interface FormattedLastSeen {
	short: string;
	long: string;
}

function formatLastSeen(date: DateTime): FormattedLastSeen {
	const now = DateTime.now();
	const interval = Interval.fromDateTimes(date, now);
	const duration = interval.toDuration(["minutes", "hours", "days"]);
	const long = date
		.setLocale("en-US")
		.toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS);

	if (duration.minutes < 1 && duration.hours < 1 && duration.days < 1) {
		return {
			short: "Just now",
			long: long,
		};
	}
	if (duration.hours < 1 && duration.days < 1) {
		const minutes = Math.floor(duration.minutes);
		return {
			short: minutes === 1 ? "1 minute ago " : `${minutes} minutes ago`,
			long: long,
		};
	} else if (duration.hours < 24 && duration.days < 1) {
		const hours = Math.floor(duration.hours);
		return {
			short: hours === 1 ? "1 hour ago" : `${hours} hours ago`,
			long: long,
		};
	} else if (duration.days < 7) {
		const days = Math.floor(duration.days);
		return {
			short: days === 1 ? "1 day ago" : `${days} days ago`,
			long: long,
		};
	} else {
		return {
			short: date.setLocale("en-US").toFormat("h:mm a, LLL dd"),
			long: long,
		};
	}
}

function DeviceLastSeen(props: {
	location: LocationResource;
	dynamicPosition?: boolean;
}) {
	const { location, dynamicPosition } = props;
	const { short, long } = useMemo(
		() => formatLastSeen(DateTime.fromISO(location.timestamp)),
		[location.timestamp],
	);
	const locationType = useMemo(
		() => mapLocationType(location.location_type),
		[location.location_type],
	);

	return (
		<span className={classArr(styles.headerLastSeen)} title={long}>
			{`${
				dynamicPosition ? "Last moved: " : ""
			}${short} • ${locationType}`}
		</span>
	);
}
