import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import useDeviceOnlineCallback from "../hook/use-device-online-callback";
import { MQTTContext, MQTTListener } from "../components/main/mqtt-provider";
import {
	DeviceCategory,
	PairedLocatedVelavuDevice,
	VelavuAPI,
	BoundingBox,
	boundingBoxContainsPoint,
	VelavuDevice,
	GET_ALL_DEVICES_BBOX_ALL,
} from "velavu-js-api";

interface InternalMapDevicesState {
	activeRegion: BoundingBox | null;
	devices: PairedLocatedVelavuDevice[];
	isLoading: boolean;
	error: unknown | null;
	continuationToken: string | null;
	isComplete: boolean;
}

export interface MapDevicesState {
	activeRegion: BoundingBox | null;
	devices: PairedLocatedVelavuDevice[];
	isLoading: boolean;
	error: unknown | null;
	hasMore: boolean;
	isComplete: boolean;
}

function internalMapDevicesStateToPublicState(
	state: InternalMapDevicesState,
): MapDevicesState {
	return {
		activeRegion: state.activeRegion,
		devices: state.devices,
		isLoading: state.isLoading,
		error: state.error,
		hasMore: state.continuationToken !== null,
		isComplete: state.isComplete,
	};
}

export interface MapDevicesActions {
	loadRegion: (region: BoundingBox) => void;
	loadMore: () => void;
	removeDevice: (deviceID: string) => void;
	addDevice: (device: PairedLocatedVelavuDevice) => void;
	updateDevice: (
		deviceID: string,
		updater: (
			device: PairedLocatedVelavuDevice,
		) => PairedLocatedVelavuDevice,
	) => void;
}

/**
 * Merges arrays of devices together, removing duplicates
 */
export function mergeDevices<T extends VelavuDevice>(...devices: T[]): T[] {
	const mergedDevicesMap = new Map<string, T>();
	for (const device of devices) {
		mergedDevicesMap.set(device.id, device);
	}
	return Array.from(mergedDevicesMap.values());
}

export function useMapDevicesState(): [MapDevicesState, MapDevicesActions] {
	const [devicesState, setDevicesState] = useState<InternalMapDevicesState>({
		activeRegion: null,
		devices: [],
		isLoading: false,
		error: null,
		continuationToken: null,
		isComplete: false,
	});

	const loadRegion = useCallback(
		async (region: BoundingBox | null) => {
			//Check to make sure we're not loading
			if (devicesState.isLoading) {
				console.warn("Attempted to load devices while already loading");
				return;
			}

			//Reset the devices state
			const newDevicesState: Readonly<InternalMapDevicesState> = {
				...devicesState,
				isLoading: true,
			};
			setDevicesState(newDevicesState);

			//Load devices within the region
			try {
				const allDevices = await VelavuAPI.devices.getAllDevices({
					category: DeviceCategory.Tag,
					paired: true,
					bbox: region ?? GET_ALL_DEVICES_BBOX_ALL,
				});

				//Retain devices that are still visible in the requested region
				let retainedDevices: PairedLocatedVelavuDevice[];
				if (region === null) {
					retainedDevices = [];
				} else {
					retainedDevices = devicesState.devices.filter((device) =>
						boundingBoxContainsPoint(
							region,
							device.location.coordinates,
						),
					);
				}
				const mergedDevices = mergeDevices<PairedLocatedVelavuDevice>(
					...retainedDevices,
					...allDevices.data,
				);

				setDevicesState({
					activeRegion: region,
					isLoading: false,
					devices: mergedDevices,
					continuationToken: allDevices.continuationToken ?? null,
					error: null,
					isComplete:
						region === null &&
						allDevices.continuationToken === undefined,
				});
			} catch (error) {
				setDevicesState({
					...newDevicesState,
					isLoading: false,
					error: error,
				});
			}
		},
		[devicesState, setDevicesState],
	);

	const loadMore = useCallback(async () => {
		//Check state
		if (devicesState.isLoading) {
			console.warn(
				"Attempted to load more devices while already loading",
			);
			return;
		}
		if (devicesState.continuationToken === null) {
			console.warn(
				"Attempted to load more devices without a continuation token",
			);
			return;
		}

		//Reset the devices state
		const newDevicesState: Readonly<InternalMapDevicesState> = {
			...devicesState,
			isLoading: true,
			error: null,
		};
		setDevicesState(newDevicesState);

		try {
			const moreDevices = await VelavuAPI.devices.getAllDevices(
				{
					category: DeviceCategory.Tag,
					paired: true,
					bbox: devicesState.activeRegion ?? GET_ALL_DEVICES_BBOX_ALL,
				},
				{
					continuationToken: devicesState.continuationToken,
				},
			);

			setDevicesState({
				...newDevicesState,
				isLoading: false,
				devices: mergeDevices(
					...newDevicesState.devices,
					...moreDevices.data,
				),
				continuationToken: moreDevices.continuationToken ?? null,
			});
		} catch (error) {
			setDevicesState({
				...newDevicesState,
				isLoading: false,
				error: error,
			});
		}
	}, [devicesState, setDevicesState]);

	useEffect(() => {
		//Load the initial region
		loadRegion(null);
	}, []);

	const removeDevice = useCallback(
		(deviceID: string) => {
			setDevicesState((devicesState) => {
				//Copy the current state
				const updatedDevicesState = { ...devicesState };
				const updatedDevices = [...updatedDevicesState.devices];

				//Remove the device with the matching ID
				const deviceIndex = updatedDevices.findIndex(
					(device) => device.id === deviceID,
				);
				if (deviceIndex !== -1) {
					updatedDevices.splice(deviceIndex, 1);
				}

				//Return the updated state
				updatedDevicesState.devices = updatedDevices;
				return updatedDevicesState;
			});
		},
		[setDevicesState],
	);

	const addDevice = useCallback(
		(device: PairedLocatedVelavuDevice) => {
			setDevicesState((devicesState) => {
				//Make sure the device falls within the boundaries
				if (
					devicesState.activeRegion === null ||
					!boundingBoxContainsPoint(
						devicesState.activeRegion,
						device.location.coordinates,
					)
				) {
					return devicesState;
				}

				//Copy the current state
				const updatedDevicesState = { ...devicesState };
				const updatedDevices = [...updatedDevicesState.devices];

				//Add the device
				updatedDevices.push(device);

				//Return the updated state
				updatedDevicesState.devices = updatedDevices;
				return updatedDevicesState;
			});
		},
		[setDevicesState],
	);

	const updateDevice = useCallback(
		(
			deviceID: string,
			updater: (
				device: PairedLocatedVelavuDevice,
			) => PairedLocatedVelavuDevice,
		) => {
			setDevicesState((devicesState) => {
				//Copy the current state
				const updatedDevicesState = { ...devicesState };
				const updatedDevices = [...updatedDevicesState.devices];

				//Update the device with the matching ID
				const deviceIndex = updatedDevices.findIndex(
					(device) => device.id === deviceID,
				);
				if (deviceIndex !== -1) {
					updatedDevices[deviceIndex] = updater(
						updatedDevices[deviceIndex],
					);
				}

				//Return the updated state
				updatedDevicesState.devices = updatedDevices;
				return updatedDevicesState;
			});
		},
		[setDevicesState],
	);

	//Subscribe to device online callbacks
	useDeviceOnlineCallback(
		useCallback(
			(deviceID, online) => {
				setDevicesState((devicesState) => {
					//Copy the current state
					const updatedDevicesState = { ...devicesState };
					const updatedDevices = [...updatedDevicesState.devices];

					//Replace the device with the matching ID's online state
					const deviceIndex = updatedDevices.findIndex(
						(device) => device.id === deviceID,
					);
					if (deviceIndex !== -1) {
						updatedDevices[deviceIndex] = {
							...updatedDevices[deviceIndex],
							online: online,
						};
					}

					//Return the updated state
					updatedDevicesState.devices = updatedDevices;
					return updatedDevicesState;
				});
			},
			[setDevicesState],
		),
	);

	//INET only: Subscribe to device state updates
	const mqttContext = useContext(MQTTContext);
	useEffect(() => {
		const listener: MQTTListener = {
			onDeviceStateUpdate: (deviceID, state) => {
				//Update the device
				setDevicesState((devicesState) => {
					//Copy the current state
					const updatedDevicesState = { ...devicesState };
					const updatedDevices = [...updatedDevicesState.devices];

					//Update the device state
					const deviceIndex = updatedDevices.findIndex(
						(device) => device.id === deviceID,
					);
					if (deviceIndex !== -1) {
						const updatedDevice: PairedLocatedVelavuDevice = {
							...updatedDevices[deviceIndex],
						};
						updatedDevice.state = state;
						updatedDevices[deviceIndex] = updatedDevice;
					}

					//Return the updated state
					updatedDevicesState.devices = updatedDevices;
					return updatedDevicesState;
				});
			},
		};

		mqttContext.addListener(listener);
		return () => mqttContext.removeListener(listener);
	}, [mqttContext, setDevicesState]);

	//Subscribe to device move callbacks
	useEffect(() => {
		const listener: MQTTListener = {
			onAssetMove: (event) => {
				setDevicesState((devicesState) => {
					//Copy the current state
					const updatedDevicesState = { ...devicesState };
					const updatedDevices = [...updatedDevicesState.devices];

					//Update the device with the matching ID
					const deviceIndex = updatedDevices.findIndex(
						(device) => device.id === event.deviceID,
					);
					if (deviceIndex !== -1) {
						updatedDevices[deviceIndex] = {
							...updatedDevices[deviceIndex],
							location: {
								...updatedDevices[deviceIndex].location,
								coordinates: event.coordinates,
							},
						};
					}

					//Return the updated state
					updatedDevicesState.devices = updatedDevices;
					return updatedDevicesState;
				});
			},
		};

		mqttContext.addListener(listener);
		return () => mqttContext.removeListener(listener);
	}, [mqttContext, setDevicesState]);

	//Convert the internal state to public state
	const publicDevicesState = useMemo(() => {
		return internalMapDevicesStateToPublicState(devicesState);
	}, [devicesState]);

	//Memoize the actions
	const actions: MapDevicesActions = useMemo(() => {
		return {
			loadRegion,
			loadMore,
			removeDevice,
			addDevice,
			updateDevice,
		};
	}, [loadRegion, loadMore, removeDevice, addDevice, updateDevice]);

	return [publicDevicesState, actions];
}
