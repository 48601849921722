// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}._6BxnS4rzxP0KQKlUaQR{font-weight:500;font-size:24px}.hjJTxsPi_iTjksrXU5kw{font-weight:500;font-size:20px}.Rl8z7TMLj1701Nz9Pi9H{font-weight:500;font-size:12px}._hSc4dugcL_B3uvmS1lu{font-size:18px}.C733Lg7ZtJIdxq8zq0QM{font-size:18px;font-weight:500}.twEcGRUawNq_7nzVosax{font-size:16px}.dreSicdfeFs_oXfyhBYQ{font-size:16px;font-weight:500}.WpqPzXKnQArKxm8isPXJ{font-size:14px}.gv9kUXmc1pgIuo9OrC__{font-size:14px;font-weight:500}.Zp99yG2KYTarAdYSrF27{font-size:14px;font-weight:400}.GplbrtC3eV_UhWRmWfRO{font-size:12px}.ASHp0VT4AZtfw2WllssN{font-size:12px;font-weight:500}.LMyWCLOBcvV_kEBmeu9e{position:relative;background-color:#e5eaf0;height:100%;width:100%;display:flex;flex-direction:column}.dM0sKenB1gEbXpf8S61_{width:calc(100% - 40px);height:calc(100% - 40px);border-radius:20px;margin:20px;display:flex;flex-direction:column}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/screens/screen-container.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,iBAAA,CACA,wBDYY,CCXZ,WAAA,CACA,UAAA,CAEA,YAAA,CACA,qBAAA,CAGD,sBACC,uBAAA,CACA,wBAAA,CACA,kBAAA,CACA,WAAA,CAEA,YAAA,CACA,qBAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `_6BxnS4rzxP0KQKlUaQR`;
export var h2 = `hjJTxsPi_iTjksrXU5kw`;
export var h3 = `Rl8z7TMLj1701Nz9Pi9H`;
export var p0 = `_hSc4dugcL_B3uvmS1lu`;
export var p0m = `C733Lg7ZtJIdxq8zq0QM`;
export var p1 = `twEcGRUawNq_7nzVosax`;
export var p1m = `dreSicdfeFs_oXfyhBYQ`;
export var p2 = `WpqPzXKnQArKxm8isPXJ`;
export var p2m = `gv9kUXmc1pgIuo9OrC__`;
export var p2n = `Zp99yG2KYTarAdYSrF27`;
export var p3 = `GplbrtC3eV_UhWRmWfRO`;
export var p3m = `ASHp0VT4AZtfw2WllssN`;
export var container = `LMyWCLOBcvV_kEBmeu9e`;
export var content = `dM0sKenB1gEbXpf8S61_`;
export default ___CSS_LOADER_EXPORT___;
