import React from "react";
import { VelavuDevice } from "velavu-js-api";
import * as styles from "./anchor-environment.module.scss";

interface AnchorEnvironmentProps {
	anchor: VelavuDevice;
}

export default function AnchorEnvironment(props: AnchorEnvironmentProps) {
	const environment = props.anchor.environment;

	return (
		<div className={styles.container}>
			<div className={styles.box} style={{ marginRight: 20 }}>
				<div className={styles.headers}>
					<span className={styles.ratingHeader}>
						{environment !== undefined
							? `${environment.temperature_c}°`
							: "--"}
					</span>
					<span className={styles.textHeader}>Temperature</span>
				</div>
			</div>
			<div className={styles.box}>
				<div className={styles.headers}>
					<span className={styles.ratingHeader}>
						{environment !== undefined
							? `${Math.round(environment.humidity)}%`
							: "--"}
					</span>
					<span className={styles.textHeader}>Humidity</span>
				</div>
			</div>
		</div>
	);
}
