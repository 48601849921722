import React, { useCallback, useMemo } from "react";
import { VelavuAPI, VelavuDevice, VelavuSite } from "velavu-js-api";
import DynamicBattery from "../../../../elements/dynamic-battery";
import StatusIndicator from "../../../../elements/status-indicator";
import VelavuButton from "../../../../elements/velavu-button";
import {
	useVelavuModal,
	VelavuTitledModal,
} from "../../../../elements/velavu-modal";
import VelavuSimpleTable, {
	CenteredTD,
} from "../../../../elements/velavu-simple-table";
import { getLocationFloorID } from "../../../../helper/floor-helper";
import {
	mapConnectionStatus,
	mapFloorNumber,
} from "../../../../helper/language-helper";
import { getDeviceStatus } from "../../../../helper/status-helper";
import DetailModal from "../../../asset-tag-detail/detail-modal";
import * as styles from "./anchor-properties.module.scss";

interface AnchorPropertiesProps {
	anchor: VelavuDevice;
	site: VelavuSite;
}

function AnchorStatus(props: { anchor: VelavuDevice }) {
	const status = useMemo(() => {
		return getDeviceStatus(props.anchor);
	}, [props.anchor]);

	return (
		<>
			<StatusIndicator status={status} />
			<span>{mapConnectionStatus(status)}</span>
		</>
	);
}

export default function AnchorProperties(props: AnchorPropertiesProps) {
	const { anchor: propsAnchor, site: propsSite } = props;

	const pushModal = useVelavuModal();

	const anchorLevel = useMemo(() => {
		let level = undefined;

		propsSite.floors?.forEach((floor) => {
			if (floor.id === getLocationFloorID(propsAnchor.location)) {
				level = mapFloorNumber(floor.level);
			}
		});

		if (!level) return "N/A";

		return level;
	}, [propsAnchor, propsSite]);

	const playSound = useCallback(() => {
		VelavuAPI.devices
			.modifySpecificDevice(propsAnchor.id, {
				state: {
					buzzer_on: true,
				},
			})
			.then((res) => console.log(res))
			.catch((err) => console.log(err));
	}, [propsAnchor.id]);

	const inspectAnchor = useCallback(() => {
		pushModal(
			(close) => (
				<VelavuTitledModal title="Device details" onClose={close}>
					<DetailModal
						device={propsAnchor}
						deviceOnly={true}
						defaultTab={"device"}
					/>
				</VelavuTitledModal>
			),
			{ pinToTop: true },
		);
	}, [pushModal, propsAnchor]);

	return (
		<>
			<VelavuSimpleTable>
				<tbody>
					<tr>
						<td>Status</td>
						<CenteredTD>
							<AnchorStatus anchor={propsAnchor} />
						</CenteredTD>
					</tr>
					<tr>
						<td>Battery</td>
						<CenteredTD>
							<DynamicBattery
								resource={props.anchor.state?.power}
							/>
							<span style={{ marginLeft: 6 }}>
								{props.anchor.state?.power?.battery_level
									? `${props.anchor.state.power.battery_level}%`
									: "N/A"}
							</span>
						</CenteredTD>
					</tr>
					<tr>
						<td>Level</td>
						<td>{anchorLevel}</td>
					</tr>
					<tr>
						<td>Notes</td>
						<td>
							{props.anchor.notes ? props.anchor.notes : "N/A"}
						</td>
					</tr>
				</tbody>
			</VelavuSimpleTable>
			<div className={styles.buttons}>
				<VelavuButton
					className={styles.button}
					label="View details"
					fullWidth
					onClick={inspectAnchor}
				/>
				<VelavuButton
					className={styles.button}
					label="Play sound"
					fullWidth
					onClick={playSound}
					outlined
				/>
			</div>
		</>
	);
}
