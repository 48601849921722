import React, { CSSProperties, useMemo } from "react";
import { EventGroup } from "../data/event-group";
import {
	makeStyleable,
	StyleableIcon,
	StyleableIconComponent,
} from "../helper/icon-helper";
import IconEvents from "../dynamicicons/icon-events";
import IconBattery from "../dynamicicons/icon-battery";
import IconConnection from "../dynamicicons/icon-connection";
import IconVelavuGeofence from "../dynamicicons/icon-velavu-geofence";
import IconShock from "../dynamicicons/icon-shock";
import IconRule from "../dynamicicons/icon-rule";
import VelavuSelect from "./velavu-select";
import { mapEventGroup } from "../helper/language-helper";

export interface EventSelectProps {
	style?: CSSProperties;
	className?: string;
	options?: readonly EventGroup[];
	value: EventGroup;
	onChange: (selection: EventGroup) => void;
	fullWidth?: boolean;
	bottomPadding?: boolean;
}

export default function EventSelect(props: EventSelectProps) {
	//Show the icon of the selected group
	const selectIcon = useMemo<StyleableIcon>(() => {
		let component: StyleableIconComponent;
		switch (props.value) {
			case EventGroup.All:
				component = IconEvents;
				break;
			case EventGroup.LowBattery:
				component = IconBattery;
				break;
			case EventGroup.Connectivity:
				component = IconConnection;
				break;
			case EventGroup.SitesAndGeofences:
				component = IconVelavuGeofence;
				break;
			case EventGroup.Motion:
				component = IconShock;
				break;
			case EventGroup.Custom:
				component = IconRule;
				break;
		}

		return makeStyleable(component);
	}, [props.value]);

	const options = props.options ?? Object.values(EventGroup);

	return (
		<VelavuSelect
			style={props.style}
			className={props.className}
			value={props.value}
			onChange={props.onChange}
			icon={selectIcon}
			fullWidth
		>
			{options.map((option) => (
				<option key={option} value={option}>
					{mapEventGroup(option)}
				</option>
			))}
		</VelavuSelect>
	);
}
