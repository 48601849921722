import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Link, useLocation, Location } from "react-router-dom";
import AssetCategorization from "../../../data/asset-categorization";
import AssetAddWizard from "../../../elements/asset-add-wizard";
import * as wizardStyles from "../../../elements/asset-add-wizard.module.scss";
import VelavuArrowButton from "../../../elements/velavu-arrow-button";
import VelavuButton from "../../../elements/velavu-button";
import CategorizationAutocomplete from "../../../elements/velavu-categorization-autocomplete";
import VelavuInput from "../../../elements/velavu-input";
import { useCategorizationSuggestions } from "../../../helper/api-helper";
import { AssetAddData } from "./asset-add-data";
import AssetImagePreview from "./asset-image-preview";
import * as styles from "./asset-new.module.scss";

function Notes(props: {
	value: string;
	onChange: Dispatch<SetStateAction<string>>;
}) {
	const [height, setHeight] = useState(30);

	return (
		<>
			<span className={`${styles.p2m} ${styles.label}`}>Notes</span>
			<textarea
				value={props.value}
				onChange={(e) => {
					const t = e.target as HTMLTextAreaElement;
					props.onChange(t.value);
					if (t.scrollHeight > t.clientHeight)
						setHeight(t.scrollHeight);
				}}
				style={{ height: height }}
			></textarea>
		</>
	);
}

export default function AssetNew() {
	const location = useLocation() as Location<{
		wizardData: AssetAddData;
	} | null>;
	const wizardData = location?.state?.wizardData;

	const [assetName, setAssetName] = useState<string>(
		wizardData?.asset.assetName ?? "",
	);
	const [assetImage, setAssetImage] = useState<string | undefined>(
		wizardData?.asset.assetImage ?? undefined,
	);
	const [assetCategorization, setAssetCategorization] = useState<
		AssetCategorization | undefined
	>(wizardData?.asset.assetCategorization);
	const [assetNotes, setAssetNotes] = useState<string>(
		wizardData?.asset.assetNotes ?? "",
	);

	const categorizationSuggestions = useCategorizationSuggestions();

	const canNavigateNext = assetName.trim().length > 0 && assetCategorization;

	useEffect(() => {
		document.title = "Velavu | New Asset";
	});

	return (
		<AssetAddWizard
			previous={
				<Link className={wizardStyles.navButton} to="/assets">
					<VelavuButton label="Exit" outlined />
				</Link>
			}
			next={
				<Link
					className={wizardStyles.navButton}
					to="/assets/create/tag"
					state={{
						wizardData: {
							asset: {
								assetName: assetName,
								assetCategorization: assetCategorization!,
								assetNotes: assetNotes,
								assetImage: assetImage,
							},
							tag: wizardData?.tag,
						} satisfies AssetAddData,
					}}
					onClick={(event) =>
						!canNavigateNext && event.preventDefault()
					}
				>
					<VelavuArrowButton
						disabled={!canNavigateNext}
						direction="right"
					/>
				</Link>
			}
			stageCount={3}
			currentStage={0}
		>
			<div style={{ padding: 20 }}>
				<h1>Asset information</h1>
				<AssetImagePreview
					style={{ marginTop: 20 }}
					category={assetCategorization?.category}
					image={assetImage}
					setImage={setAssetImage}
				/>
				<form>
					<VelavuInput
						style={{ marginBottom: 20 }}
						type="text"
						titleText="Name"
						placeholder="Ex. Ford Ranger"
						value={assetName}
						onChange={setAssetName}
						fullWidth
					/>
					<CategorizationAutocomplete
						containerProps={{ style: { marginBottom: 20 } }}
						value={assetCategorization}
						onChange={setAssetCategorization}
						categorization={categorizationSuggestions}
					/>
					<Notes value={assetNotes} onChange={setAssetNotes} />
				</form>
			</div>
		</AssetAddWizard>
	);
}
