import React, { CSSProperties } from "react";

export default function IconReboot(props: {
	className?: string;
	style?: CSSProperties;
	color?: string;
	size?: number;
}) {
	return (
		<svg
			className={props.className}
			style={props.style}
			xmlns="http://www.w3.org/2000/svg"
			width={props.size ?? 16}
			height={props.size ?? 16}
			viewBox="0 0 16 16"
		>
			<path
				d="M10.8269 10.8278C12.389 9.26568 12.389 6.73302 10.8269 5.17092C9.26478 3.60882 6.73212 3.60882 5.17002 5.17092C5.15825 5.1827 5.14614 5.19394 5.13373 5.20465L5.97916 6.05007C6.19129 6.2622 6.04044 6.62047 5.74346 6.61576L3.11302 6.61576C2.92446 6.61576 2.77832 6.46962 2.77832 6.28106L2.77832 3.65062C2.77896 3.58538 2.79874 3.52175 2.8352 3.46764C2.87166 3.41353 2.9232 3.37131 2.98343 3.34622C3.04366 3.32112 3.10993 3.31425 3.17403 3.32647C3.23812 3.33868 3.29723 3.36943 3.34401 3.41492L4.19349 4.2644C4.20419 4.25199 4.21544 4.23989 4.22721 4.22811C6.31001 2.14532 9.68689 2.14532 11.7697 4.22811C13.8525 6.31091 13.8525 9.68779 11.7697 11.7706C9.68689 13.8534 6.31001 13.8534 4.22721 11.7706C3.51698 11.0604 3.04805 10.1979 2.8223 9.28753C2.73368 8.93017 2.95154 8.56862 3.3089 8.48C3.66627 8.39138 4.02781 8.60924 4.11644 8.9666C4.28545 9.64814 4.63602 10.2938 5.17002 10.8278C6.73212 12.3899 9.26478 12.3899 10.8269 10.8278Z"
				fill={props.color ?? "black"}
			/>
		</svg>
	);
	{
		/*<svg
			className={props.className}
			style={props.style}
			xmlns="http://www.w3.org/2000/svg"
			width={props.size ?? 24}
			height={props.size ?? 24}
			viewBox="0 0 24 24"
		>
			<path
				d="M10.8269 10.8278C12.389 9.26568 12.389 6.73302 10.8269 5.17092C9.26478 3.60882 6.73212 3.60882 5.17002 5.17092C5.15825 5.1827 5.14614 5.19394 5.13373 5.20465L5.97916 6.05007C6.19129 6.2622 6.04044 6.62047 5.74346 6.61576L3.11302 6.61576C2.92446 6.61576 2.77832 6.46962 2.77832 6.28106L2.77832 3.65062C2.77896 3.58538 2.79874 3.52175 2.8352 3.46764C2.87166 3.41353 2.9232 3.37131 2.98343 3.34622C3.04366 3.32112 3.10993 3.31425 3.17403 3.32647C3.23812 3.33868 3.29723 3.36943 3.34401 3.41492L4.19349 4.2644C4.20419 4.25199 4.21544 4.23989 4.22721 4.22811C6.31001 2.14532 9.68689 2.14532 11.7697 4.22811C13.8525 6.31091 13.8525 9.68779 11.7697 11.7706C9.68689 13.8534 6.31001 13.8534 4.22721 11.7706C3.51698 11.0604 3.04805 10.1979 2.8223 9.28753C2.73368 8.93017 2.95154 8.56862 3.3089 8.48C3.66627 8.39138 4.02781 8.60924 4.11644 8.9666C4.28545 9.64814 4.63602 10.2938 5.17002 10.8278C6.73212 12.3899 9.26478 12.3899 10.8269 10.8278Z"
				fill={props.color ?? "black"}
			/>
	</svg>*/
	}
}
