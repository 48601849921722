// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.QCkLXH9b5pNrlY4tD3Lr{font-weight:500;font-size:24px}.w7Jv1QvUn8ui72b5iFwI{font-weight:500;font-size:20px}.CWMJA4oPFiW9Z9W1RNgo{font-weight:500;font-size:12px}.xDBNfg0wKBhc4_K07EYf{font-size:18px}.HOb_ZyWQnMt96RWBwkab{font-size:18px;font-weight:500}.J_FVK09L2a6SSiV6zAT0{font-size:16px}.mEDp8pX566utDgBvgFu1{font-size:16px;font-weight:500}.vlISJRL5XMvJXmcw6fnX{font-size:14px}.YkPa3hJuOhkWFVUnU9zb,.uBMkemHHIOa5w__xy_rH,.YJTLeUEc1mnkgtWvjgH4{font-size:14px;font-weight:500}.cfsOTwmOwI2K3fC2HZSs{font-size:14px;font-weight:400}.wr2cVjoeBhP6W82ls416{font-size:12px}.lb1BBDxzZDvnBBOEBtrP{font-size:12px;font-weight:500}.RklDCRa_zDwzEh03gTtG{display:flex;flex-direction:row;align-items:center}.YJTLeUEc1mnkgtWvjgH4{margin-left:8px;margin-right:4px}.Hgx6cM_5EqNjiRACibJA{margin-right:12px}.W0nO0mXpnquGvoxVw63q{margin:4px 0;width:180px;overflow-wrap:break-word;white-space:normal}.DhdCyuS1zlmvA67_DAO3{font-weight:normal;color:#e6ebf2}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/components/dashboard/toast/pagination-toast.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,kEACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,YAAA,CACA,kBAAA,CACA,kBAAA,CAGD,sBAEC,eAAA,CACA,gBAAA,CAGD,sBACC,iBAAA,CAGD,sBACC,YAAA,CACA,WAAA,CACA,wBAAA,CACA,kBAAA,CAGD,sBACC,kBAAA,CACA,aAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `QCkLXH9b5pNrlY4tD3Lr`;
export var h2 = `w7Jv1QvUn8ui72b5iFwI`;
export var h3 = `CWMJA4oPFiW9Z9W1RNgo`;
export var p0 = `xDBNfg0wKBhc4_K07EYf`;
export var p0m = `HOb_ZyWQnMt96RWBwkab`;
export var p1 = `J_FVK09L2a6SSiV6zAT0`;
export var p1m = `mEDp8pX566utDgBvgFu1`;
export var p2 = `vlISJRL5XMvJXmcw6fnX`;
export var p2m = `YkPa3hJuOhkWFVUnU9zb`;
export var tooltipEmphasize = `uBMkemHHIOa5w__xy_rH`;
export var loadMoreLabel = `YJTLeUEc1mnkgtWvjgH4`;
export var p2n = `cfsOTwmOwI2K3fC2HZSs`;
export var p3 = `wr2cVjoeBhP6W82ls416`;
export var p3m = `lb1BBDxzZDvnBBOEBtrP`;
export var loadMoreLayout = `RklDCRa_zDwzEh03gTtG`;
export var loadMoreInfo = `Hgx6cM_5EqNjiRACibJA`;
export var tooltipParagraph = `W0nO0mXpnquGvoxVw63q`;
export var tooltipParagraphSecondary = `DhdCyuS1zlmvA67_DAO3`;
export default ___CSS_LOADER_EXPORT___;
