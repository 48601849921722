import PlaceableFloorImage, {
	usePlaceableFloorImageURL,
} from "../../data/placeable-floor-image";
import {
	MapHelperInstance,
	useStyleDependantMapEffect,
	useStyleSetupMapEffect,
} from "../../helper/map-helper";
import { ImageSource } from "mapbox-gl";
import { geoJSONBlankCollection, imageSourceBlank } from "./mapbox-injectable";
import * as globals from "../../styles/global.icss.scss";

export interface MapboxInjectableFloorplanImageParams {
	mapInstance: MapHelperInstance;
	referenceImage?: {
		coords: [number, number][];
		image: PlaceableFloorImage;
	};
}

const mapboxIDFloorplanImage = "floorplan-image";

export default function useMapboxInjectableFloorplanImage(
	params: MapboxInjectableFloorplanImageParams,
) {
	const floorImageURL = usePlaceableFloorImageURL(
		params.referenceImage?.image.renderable,
	);

	const floorImageCoords = params.referenceImage?.coords;

	useStyleSetupMapEffect(params.mapInstance, (_, box) => {
		box.addSource(mapboxIDFloorplanImage, {
			type: "image",
			...imageSourceBlank,
		});
		box.addLayer({
			id: mapboxIDFloorplanImage,
			source: mapboxIDFloorplanImage,
			type: "raster",
			paint: {
				"raster-opacity": 0.4,
			},
		});
	});

	useStyleDependantMapEffect(
		params.mapInstance,
		(map) => {
			//Update the floor plan image
			const floorplanImageSource = map.getSource(
				mapboxIDFloorplanImage,
			) as ImageSource;

			if (floorImageURL !== undefined && floorImageCoords !== undefined) {
				floorplanImageSource.updateImage({
					url: floorImageURL,
					coordinates: floorImageCoords,
				});
			} else {
				floorplanImageSource.updateImage(imageSourceBlank);
			}
		},
		[floorImageURL, floorImageCoords],
	);
}
