import { SiteDetailsTab } from "./site-detail";
import {
	DeviceCategory,
	NormalizedDeviceHardware,
	normalizeDeviceHardware,
	VelavuAPI,
	VelavuDevice,
} from "velavu-js-api";
import { useCancellableEffect } from "../../../../helper/hook-helper";
import { useMemo, useState } from "react";

export interface SiteDetailState {
	selectedTab: SiteDetailsTab;
	setSelectedTab: (tab: SiteDetailsTab) => void;

	devices: VelavuDevice[];
	mapDisplayDevices: VelavuDevice[];
	setDevices: (devices: VelavuDevice[]) => void;

	showAssets: boolean;
	setShowAssets: (showAssets: boolean) => void;
}

export function useSiteDetailState(
	siteID: string | undefined,
): SiteDetailState {
	const [selectedTab, setSelectedTab] = useState<SiteDetailsTab>(
		SiteDetailsTab.Status,
	);
	const [devices, setDevices] = useState<VelavuDevice[]>([]);
	const [showAssets, setShowAssets] = useState(false);

	//Derive map display devices
	const mapDisplayDevices = useMemo(() => {
		if (devices === undefined) {
			return [];
		}

		switch (selectedTab) {
			case SiteDetailsTab.Anchors:
				//Show anchors and gateways
				return devices.filter((device) => {
					return (
						device.category === DeviceCategory.Anchor ||
						normalizeDeviceHardware(device.hardware) ===
							NormalizedDeviceHardware.Argo
					);
				});
			case SiteDetailsTab.Gateways:
				//Show gateways
				return devices.filter((device) => {
					return (
						normalizeDeviceHardware(device.hardware) ===
						NormalizedDeviceHardware.Argo
					);
				});
			default:
				//Show assets
				return devices.filter((device) => {
					return device.asset !== undefined;
				});
		}
	}, [devices, selectedTab]);

	//Load devices
	useCancellableEffect(
		(addPromise) => {
			if (siteID === undefined) {
				setDevices([]);
			} else {
				addPromise(
					VelavuAPI.devices.getAllDevices({
						siteID: siteID,
					}),
				)
					.then((result) => setDevices(result.data))
					.catch(console.log);
			}
		},
		[siteID, setDevices],
	);

	return useMemo(
		() => ({
			selectedTab,
			setSelectedTab,
			devices,
			mapDisplayDevices,
			setDevices,
			showAssets,
			setShowAssets,
		}),
		[
			selectedTab,
			setSelectedTab,
			devices,
			mapDisplayDevices,
			setDevices,
			showAssets,
			setShowAssets,
		],
	);
}
