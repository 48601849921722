// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.wKEimiy75P1Sq0Zoi2Gt{font-weight:500;font-size:24px}.IM0cMZXK6CBmMKqJ8MaZ{font-weight:500;font-size:20px}.Orl6XGWMQezE94b3KW4_{font-weight:500;font-size:12px}.pPUMX7PuOBZhOOl9Cy_2{font-size:18px}.Bz9iKqMBz03ykaGiE5h1{font-size:18px;font-weight:500}.fHOYyeVqefTLVc6iNuno{font-size:16px}.pRnnMLWTaAP7aUyh7uDM{font-size:16px;font-weight:500}.FLvtwFM3pGObdgiTZAUP{font-size:14px}.CC4Q_vIEZ__dxJppsLUN{font-size:14px;font-weight:500}.XOPXx_IncLTZiQnuxc2d{font-size:14px;font-weight:400}.zfnPSfkcsHallCQqrxL9{font-size:12px}.oLRgEMOEXzqfMktGkN5X{font-size:12px;font-weight:500}.usYpgxRCBASHYPoXgfK3{padding-top:8px;padding-left:12px;padding-right:12px}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/elements/events-detail-list.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,eAAA,CACA,iBAAA,CACA,kBAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `wKEimiy75P1Sq0Zoi2Gt`;
export var h2 = `IM0cMZXK6CBmMKqJ8MaZ`;
export var h3 = `Orl6XGWMQezE94b3KW4_`;
export var p0 = `pPUMX7PuOBZhOOl9Cy_2`;
export var p0m = `Bz9iKqMBz03ykaGiE5h1`;
export var p1 = `fHOYyeVqefTLVc6iNuno`;
export var p1m = `pRnnMLWTaAP7aUyh7uDM`;
export var p2 = `FLvtwFM3pGObdgiTZAUP`;
export var p2m = `CC4Q_vIEZ__dxJppsLUN`;
export var p2n = `XOPXx_IncLTZiQnuxc2d`;
export var p3 = `zfnPSfkcsHallCQqrxL9`;
export var p3m = `oLRgEMOEXzqfMktGkN5X`;
export var dropdown = `usYpgxRCBASHYPoXgfK3`;
export default ___CSS_LOADER_EXPORT___;
