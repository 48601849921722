// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.uOapNO10PDkuxiVKrFAR{font-weight:500;font-size:24px}.D4jCqJg0tR_6uXM1uruV{font-weight:500;font-size:20px}.RsggCbzYzLplvFMFw1GT{font-weight:500;font-size:12px}.FAVFSQA81dK1MQsPSrQT{font-size:18px}.Pxe3dBjlI3TVmuK_TJcg{font-size:18px;font-weight:500}.r6pTFMgh9WFx102cQEFh{font-size:16px}.tHTZTSGUPp4mEIEXA7Ft{font-size:16px;font-weight:500}.yVegGHf2yTBx2ZpE1Ohj,.k1vqyuPhlOzzXit1_Jgi,.okUHZAbBdWet34j8NjJ1{font-size:14px}.rBjguf4AdeteMaxgCmR8{font-size:14px;font-weight:500}.si9pRyKNBsoRfvRBnOMa{font-size:14px;font-weight:400}.Ap8d1nUcKdOo0BvSUG6c{font-size:12px}.IVOkrkEDiFG0aRhAmyYg{font-size:12px;font-weight:500}.gVlo51F4CmLr4yOBEoLH{display:flex;flex-direction:column}.j7dkYJWf8sAlhdmiLQSW{display:flex;flex-direction:row;align-items:center;justify-content:center;width:100%;gap:10px;padding-bottom:24px}.okUHZAbBdWet34j8NjJ1{color:#5f718c;text-decoration:none;transition:color 250ms ease;border:none;background:none;cursor:pointer}.okUHZAbBdWet34j8NjJ1:hover{color:#00255d}.k1vqyuPhlOzzXit1_Jgi{color:#a3b3cc}.X4EiNEGsKykQ6_v98pQK{flex-grow:1}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/elements/date-range-selector.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,kEACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,YAAA,CACA,qBAAA,CAGD,sBACC,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,sBAAA,CACA,UAAA,CACA,QAAA,CACA,mBAAA,CAGD,sBAEC,aDNgB,CCOhB,oBAAA,CAEA,2BAAA,CAEA,WAAA,CACA,eAAA,CACA,cAAA,CAEA,4BACC,aDjBa,CCqBf,sBAEC,aAAA,CAGD,sBACC,WAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `uOapNO10PDkuxiVKrFAR`;
export var h2 = `D4jCqJg0tR_6uXM1uruV`;
export var h3 = `RsggCbzYzLplvFMFw1GT`;
export var p0 = `FAVFSQA81dK1MQsPSrQT`;
export var p0m = `Pxe3dBjlI3TVmuK_TJcg`;
export var p1 = `r6pTFMgh9WFx102cQEFh`;
export var p1m = `tHTZTSGUPp4mEIEXA7Ft`;
export var p2 = `yVegGHf2yTBx2ZpE1Ohj`;
export var footerLabel = `k1vqyuPhlOzzXit1_Jgi`;
export var footerLink = `okUHZAbBdWet34j8NjJ1`;
export var p2m = `rBjguf4AdeteMaxgCmR8`;
export var p2n = `si9pRyKNBsoRfvRBnOMa`;
export var p3 = `Ap8d1nUcKdOo0BvSUG6c`;
export var p3m = `IVOkrkEDiFG0aRhAmyYg`;
export var calendar = `gVlo51F4CmLr4yOBEoLH`;
export var footer = `j7dkYJWf8sAlhdmiLQSW`;
export var spacer = `X4EiNEGsKykQ6_v98pQK`;
export default ___CSS_LOADER_EXPORT___;
