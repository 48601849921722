// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.jcgkoyNJFpWtQSJwtVVH{font-weight:500;font-size:24px}.OuzXmgcqFJVjKV31m9Sg,.auGQCcYEwB2U592QyRfH{font-weight:500;font-size:20px}.G6iKN1WT4yZTOsSeYkSc{font-weight:500;font-size:12px}.Dv6FiYc_EIER4judkesO{font-size:18px}.l4fiYWdqWnZFdyvEKTxj{font-size:18px;font-weight:500}.UujSOFsgiEWpHmKldnj5{font-size:16px}.J36tGFOjjmLPcuK_muTS{font-size:16px;font-weight:500}.EZCGnJgbRcigpEOhYFLK{font-size:14px}.pXBRR4l3_t8e_ivsvsxi{font-size:14px;font-weight:500}.f9cYB6uD51Opcm3Gc2Dn{font-size:14px;font-weight:400}.eKBHlrpGZlJkaX8lx881{font-size:12px}.anNMBsb_nfnysDWZtqul{font-size:12px;font-weight:500}.e6TDyqY4TqeHF5v586JP{display:flex;flex-direction:row;align-items:center}.auGQCcYEwB2U592QyRfH{margin-left:12px}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/elements/section-title.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,4CACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,YAAA,CACA,kBAAA,CACA,kBAAA,CAGD,sBAGC,gBAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `jcgkoyNJFpWtQSJwtVVH`;
export var h2 = `OuzXmgcqFJVjKV31m9Sg`;
export var title = `auGQCcYEwB2U592QyRfH`;
export var h3 = `G6iKN1WT4yZTOsSeYkSc`;
export var p0 = `Dv6FiYc_EIER4judkesO`;
export var p0m = `l4fiYWdqWnZFdyvEKTxj`;
export var p1 = `UujSOFsgiEWpHmKldnj5`;
export var p1m = `J36tGFOjjmLPcuK_muTS`;
export var p2 = `EZCGnJgbRcigpEOhYFLK`;
export var p2m = `pXBRR4l3_t8e_ivsvsxi`;
export var p2n = `f9cYB6uD51Opcm3Gc2Dn`;
export var p3 = `eKBHlrpGZlJkaX8lx881`;
export var p3m = `anNMBsb_nfnysDWZtqul`;
export var content = `e6TDyqY4TqeHF5v586JP`;
export default ___CSS_LOADER_EXPORT___;
