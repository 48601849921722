// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.o20IgWUJoEsSGQ_dOR1S{font-weight:500;font-size:24px}.QCauEv8OlQiKXy8CyltY{font-weight:500;font-size:20px}.rrzjCym1MWTmbX9b8Qxq{font-weight:500;font-size:12px}.lboUradxnSYsUkXHmGII{font-size:18px}.vstIzpwuVmaFC0kOdllM{font-size:18px;font-weight:500}.aSpoTJhLI_LJLEtkSmuV{font-size:16px}.fg8m55Rx7J6Y5782AjyX{font-size:16px;font-weight:500}.KGnQ4OP7Wi2J85iXBMw9{font-size:14px}.FySrBTFoxHxfCwfd4EU7{font-size:14px;font-weight:500}.Zh54X9OgrhvHXPt60p4O{font-size:14px;font-weight:400}.rIKi_asu5nLQ3WKRkoLv{font-size:12px}.o02swJmMQmIxlTZQdddZ{font-size:12px;font-weight:500}.AkqMsYwUxPRIdLME1h2e{z-index:10;position:absolute;top:100%;left:50%;transform:translateX(-50%);background-color:#fff;border-radius:12px;border:1px solid #e2e4e5;box-sizing:border-box;box-shadow:0 8px 16px 0 rgba(96,97,112,.16),0 2px 4px 0 rgba(40,41,61,.04);padding:20px}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/elements/popover.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CCzGD,sBACC,UAHS,CAKT,iBAAA,CACA,QAAA,CACA,QAAA,CACA,0BAAA,CAEA,qBDWO,CCVP,kBAAA,CACA,wBAAA,CACA,qBAAA,CACA,0EACC,CAED,YAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `o20IgWUJoEsSGQ_dOR1S`;
export var h2 = `QCauEv8OlQiKXy8CyltY`;
export var h3 = `rrzjCym1MWTmbX9b8Qxq`;
export var p0 = `lboUradxnSYsUkXHmGII`;
export var p0m = `vstIzpwuVmaFC0kOdllM`;
export var p1 = `aSpoTJhLI_LJLEtkSmuV`;
export var p1m = `fg8m55Rx7J6Y5782AjyX`;
export var p2 = `KGnQ4OP7Wi2J85iXBMw9`;
export var p2m = `FySrBTFoxHxfCwfd4EU7`;
export var p2n = `Zh54X9OgrhvHXPt60p4O`;
export var p3 = `rIKi_asu5nLQ3WKRkoLv`;
export var p3m = `o02swJmMQmIxlTZQdddZ`;
export var container = `AkqMsYwUxPRIdLME1h2e`;
export default ___CSS_LOADER_EXPORT___;
