// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.Ms3v71J3fruMaz4W0Lv_{font-weight:500;font-size:24px}.yWQ7ArV3JN341Q_9ftOB{font-weight:500;font-size:20px}.z4sgMCX9RsVeuyGahv1g{font-weight:500;font-size:12px}.Oliblcq6leBAM7zOEDzT{font-size:18px}.cC9ZLu_TNy63uSrX8IRt{font-size:18px;font-weight:500}.AfU1zRgvwxecLOKadynm{font-size:16px}.WIhYDNPiGkL2ZTVac0gj,.pCqoe4I7uBVHepsHOznK{font-size:16px;font-weight:500}.sJKnRsNM_Dokgh1xEUur{font-size:14px}.l6rUk85kzm9KxE0tzaNX{font-size:14px;font-weight:500}.Xz5PqhFyLmjawKi91bvL{font-size:14px;font-weight:400}.CfEuq6IKGWA_yp_l9eau{font-size:12px}.Noidps1JebwflXHECshH{font-size:12px;font-weight:500}.MdocGeZVFWox2XJ1mj1L{display:flex;flex-direction:row;align-items:center;padding:12px 24px}.OZ1umzaSruwkBBZ82bwZ{flex-grow:1;flex-shrink:1 !important;overflow-y:auto;border-bottom-left-radius:20px;border-bottom-right-radius:20px}.QMAC6NVXSyyqbFY_LFGp{margin:0 12px 12px 12px}.QMAC6NVXSyyqbFY_LFGp:first-child{margin-top:12px}.MeeL7gm9a5paVxIK1o0z{height:50px;padding:0 16px;display:flex;flex-direction:row;align-items:center}.x6W2nXJ1QzOkd4jRwM4O{flex:1;margin-right:12px}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/components/dashboard/dropdown/site-list/site-list.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,4CACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,iBAAA,CAGD,sBACC,WAAA,CACA,wBAAA,CACA,eAAA,CACA,8BAAA,CACA,+BAAA,CAGD,sBACC,uBAAA,CAEA,kCACC,eAAA,CAIF,sBACC,WAAA,CACA,cAAA,CAEA,YAAA,CACA,kBAAA,CACA,kBAAA,CAOD,sBACC,MAAA,CACA,iBAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `Ms3v71J3fruMaz4W0Lv_`;
export var h2 = `yWQ7ArV3JN341Q_9ftOB`;
export var h3 = `z4sgMCX9RsVeuyGahv1g`;
export var p0 = `Oliblcq6leBAM7zOEDzT`;
export var p0m = `cC9ZLu_TNy63uSrX8IRt`;
export var p1 = `AfU1zRgvwxecLOKadynm`;
export var p1m = `WIhYDNPiGkL2ZTVac0gj`;
export var globalLabel = `pCqoe4I7uBVHepsHOznK`;
export var p2 = `sJKnRsNM_Dokgh1xEUur`;
export var p2m = `l6rUk85kzm9KxE0tzaNX`;
export var p2n = `Xz5PqhFyLmjawKi91bvL`;
export var p3 = `CfEuq6IKGWA_yp_l9eau`;
export var p3m = `Noidps1JebwflXHECshH`;
export var header = `MdocGeZVFWox2XJ1mj1L`;
export var list = `OZ1umzaSruwkBBZ82bwZ`;
export var item = `QMAC6NVXSyyqbFY_LFGp`;
export var globalCard = `MeeL7gm9a5paVxIK1o0z`;
export var search = `x6W2nXJ1QzOkd4jRwM4O`;
export default ___CSS_LOADER_EXPORT___;
