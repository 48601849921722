import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { useLocation } from "react-router-dom";
import {
	DeviceCategory,
	GetAllDevicesParams,
	NormalizedDeviceHardware,
	VelavuAPI,
	VelavuAsset,
	VelavuDevice,
	normalizeDeviceHardware,
} from "velavu-js-api";
import ConnectionStatus from "../../data/connection-status";
import IconAdd from "../../dynamicicons/icon-add";
import IconCircleCheck from "../../dynamicicons/icon-circle-check";
import IconRefresh from "../../dynamicicons/icon-refresh";
import IconVelavuTags from "../../dynamicicons/icon-velavu-devices";
import Checkbox from "../../elements/checkbox";
import DeviceFilterMenu from "../../elements/device-filter-menu";
import DeviceIcon from "../../elements/device-icon";
import Divider from "../../elements/divider";
import DynamicBattery from "../../elements/dynamic-battery";
import ListHeader from "../../elements/list-header";
import ListPagination from "../../elements/list-pagination";
import LoadingDots from "../../elements/loading-dots";
import StatusIndicator from "../../elements/status-indicator";
import ToggleableFade from "../../elements/toggleable-fade";
import VelavuButton from "../../elements/velavu-button";
import {
	VelavuTableBody,
	VelavuTableColumn,
	VelavuTableDivider,
	VelavuTableHeader,
	VelavuTableHeaderLabel,
	VelavuTableRow,
} from "../../elements/velavu-header-table";
import {
	VelavuModalPrompt,
	VelavuTitledModal,
	useVelavuModal,
} from "../../elements/velavu-modal";
import { useToggleable } from "../../helper/hook-helper";
import { makeStyleable } from "../../helper/icon-helper";
import {
	mapDeviceCategory,
	mapDeviceHardware,
} from "../../helper/language-helper";
import { deepCompare } from "../../helper/object-helper";
import { getDeviceStatus } from "../../helper/status-helper";
import { classArr } from "../../helper/style-helper";
import { NoDevicesIcon } from "../../img/icons/icons";
import ScreenContainer from "../../screens/screen-container";
import { useListDevicesState } from "../../state/list-devices-state";
import DetailModal from "../asset-tag-detail/detail-modal";
import styles from "./devices.module.scss";
import ModalRegisterTag from "./register/modal-register-tag";

interface TagsHistoryState {
	deviceID?: string;
}

export default function Devices() {
	useEffect(() => {
		document.title = `Velavu | Devices`;
	});

	return (
		<ScreenContainer>
			<TagsList />
		</ScreenContainer>
	);
}

function NoDevices() {
	return (
		<div className={styles.emptyContent}>
			<NoDevicesIcon />
			<span className={styles.heading}>No Devices registered</span>
			<span className={styles.info}>
				Once you register devices, they will show up here.
			</span>
		</div>
	);
}

interface DeviceActionInProgessModalProps {
	title: string;
	action: string;
	progressCurrent: number;
	progressTotal: number;
}

function DeviceActionInProgessModal(props: DeviceActionInProgessModalProps) {
	const { title, action, progressCurrent, progressTotal } = props;

	const actionText = useMemo(() => {
		switch (action) {
			case "delete":
				return "deleted";
			default:
				return "rebooted";
		}
	}, [action]);

	return (
		<ToggleableFade
			visible={progressCurrent !== progressTotal}
			shift
			className={styles.progressScrim}
		>
			<div className={styles.progressContainer}>
				<div className={styles.progressHeader}>{title}</div>
				<Divider />
				<div className={styles.progressBody}>
					{`${progressCurrent}/${progressTotal} ${actionText}`}
				</div>
			</div>
		</ToggleableFade>
	);
}

function DeviceId(props: { device: VelavuDevice }) {
	return (
		<div className={styles.deviceLabel}>
			<StatusIndicator status={getDeviceStatus(props.device)} size={12} />
			<div className={styles.deviceId}>{props.device.id}</div>
		</div>
	);
}

function DevicesTableRow(props: {
	device: VelavuDevice;
	onDetails: VoidFunction;
	onPair: VoidFunction;
	onReboot: VoidFunction;
	onSelect: (selected?: boolean) => void;
	selected: boolean;
}) {
	const [hoverState, enterHoverState, exitHoverState] = useToggleable();
	return (
		<>
			<VelavuTableRow
				className={styles.tableRow}
				onMouseEnter={enterHoverState}
				onMouseLeave={exitHoverState}
			>
				<VelavuTableColumn type="fixed" width={48}>
					<Checkbox
						checked={props.selected}
						onClick={props.onSelect}
					/>
				</VelavuTableColumn>
				<VelavuTableColumn onClick={props.onDetails}>
					<DeviceId device={props.device} />
				</VelavuTableColumn>
				<VelavuTableColumn onClick={props.onDetails}>
					<DeviceIcon
						hardware={normalizeDeviceHardware(
							props.device.hardware,
						)}
					/>
					<span
						className={styles.tableCell}
						style={{ marginLeft: 8 }}
					>
						{mapDeviceHardware(
							normalizeDeviceHardware(props.device.hardware),
						)}
					</span>
				</VelavuTableColumn>
				<VelavuTableColumn onClick={props.onDetails}>
					<span className={styles.tableCell}>
						{mapDeviceCategory(props.device.category)}
					</span>
				</VelavuTableColumn>
				<VelavuTableColumn onClick={props.onDetails}>
					{props.device.asset !== undefined ? (
						<AssetName asset={props.device.asset} />
					) : props.device.category === DeviceCategory.Tag ? (
						<VelavuButton
							label="Pair asset"
							icon={(size, color, className) => (
								<IconAdd
									size={size}
									color={color}
									className={className}
								/>
							)}
							size="small"
							onClick={(event) => {
								event.stopPropagation();
								props.onPair();
							}}
						/>
					) : (
						<span>&#8212;</span>
					)}
				</VelavuTableColumn>
				<VelavuTableColumn onClick={props.onDetails}>
					<DynamicBattery
						level={
							props.device.state?.power?.battery_level !==
							undefined
								? props.device.state?.power.battery_level / 100
								: -1
						}
						charging={
							props.device.state !== undefined &&
							props.device.state.power !== undefined &&
							"usb_power" in props.device.state.power &&
							props.device.state.power.usb_power
						}
					/>
					<span
						className={styles.tableCell}
						style={{ marginLeft: 4 }}
					>
						{props.device.state?.power?.battery_level !== undefined
							? `${props.device.state.power.battery_level}%`
							: "N/A"}
					</span>
				</VelavuTableColumn>
				<VelavuTableColumn onClick={props.onDetails}>
					<div className={styles.configuredIconContainer}>
						{props.device.config_confirmed ? (
							<IconCircleCheck size={16} color="#00B283" />
						) : (
							<IconRefresh size={16} />
						)}
					</div>
				</VelavuTableColumn>
				<VelavuTableColumn
					type="edge"
					width={60}
					onClick={props.onDetails}
				/>
			</VelavuTableRow>
		</>
	);
}

function AssetName(props: { asset: VelavuAsset }) {
	return (
		<div className={classArr(styles.assetName, styles.tableCell)}>
			{props.asset.name}
		</div>
	);
}

function Loading() {
	return (
		<div className={styles.loading}>
			<LoadingDots size={40} color={"#a3b3cc"} />
		</div>
	);
}

function TagsList() {
	const location = useLocation<TagsHistoryState>();

	const [searchText, setSearchText] = useState("");

	const [scrollToTop, setScrollToTop] = useState(false);

	const [actionProgress, setActionProgress] = useState(0);
	const [actionInProgress, setActionInProgress] = useState<
		"unregister" | "reboot" | undefined
	>(undefined);

	const [totalPages, setTotalPages] = useState<number>(1);
	const [currentPage, setCurrentPage] = useState<number>(1);

	const [devicesState, devicesActions] = useListDevicesState();
	const [selectedDevices, setSelectedDevices] = useState<string[]>([]);

	const [filterStatus, setFilterStatus] = useState<ConnectionStatus | null>(
		null,
	);
	const [filterUnpaired, setFilterUnpaired] = useState(false);
	const [filterUnconfigured, setFilterUnconfigured] = useState(false);
	const [filterCategory, setFilterCategory] = useState<DeviceCategory | null>(
		null,
	);
	const [filterHardware, setFilterHardware] =
		useState<NormalizedDeviceHardware | null>(null);
	const [appliedDeviceFilters, setAppliedDeviceFilters] =
		useState<GetAllDevicesParams>({
			online: undefined,
			hardware: undefined,
			category: undefined,
			paired: undefined,
			onlyRegistered: undefined,
			configConfirmed: undefined,
		});
	const deviceFilters = useMemo((): GetAllDevicesParams => {
		return {
			online:
				filterStatus === "active"
					? true
					: filterStatus === "inactive"
					? false
					: undefined,
			hardware: filterHardware ?? undefined,
			category: filterCategory ?? undefined,
			paired: filterUnpaired ? false : undefined,
			onlyRegistered: filterStatus === "registered" ? true : undefined,
			configConfirmed: filterUnconfigured ? false : undefined,
		};
	}, [
		filterCategory,
		filterHardware,
		filterStatus,
		filterUnconfigured,
		filterUnpaired,
	]);

	const isDirty = useMemo(() => {
		return !deepCompare(appliedDeviceFilters, deviceFilters);
	}, [appliedDeviceFilters, deviceFilters]);

	const devicesActionsLoadDevices = devicesActions.loadDevices;
	const refetchDevices = useCallback(
		(params: GetAllDevicesParams) => {
			setTotalPages(1);
			setCurrentPage(1);
			devicesActionsLoadDevices(params);
		},
		[setTotalPages, setCurrentPage, devicesActionsLoadDevices],
	);

	const refetchEffectFirstMount = useRef(true);
	const refetchEffectLastAppliedDeviceFilters = useRef(appliedDeviceFilters);
	useEffect(() => {
		//Don't run on first mount to avoid double loading
		if (refetchEffectFirstMount.current) {
			refetchEffectFirstMount.current = false;
			return;
		}

		//Load assets with the combined filters and search query
		let cleanSearchText: string | undefined = searchText.trim();
		if (cleanSearchText.length === 0) {
			cleanSearchText = undefined;
		}
		const loadParams: GetAllDevicesParams = {
			...appliedDeviceFilters,
			id: cleanSearchText,
		};

		//If the device filters have changed, apply the update immediately
		if (
			!deepCompare(
				refetchEffectLastAppliedDeviceFilters.current,
				appliedDeviceFilters,
			)
		) {
			refetchEffectLastAppliedDeviceFilters.current =
				appliedDeviceFilters;
			refetchDevices(loadParams);
		} else {
			//Debounce the refetch to avoid spamming the server
			//as the user is typing
			const timeoutID = window.setTimeout(() => {
				refetchDevices(loadParams);
			}, 300);
			return () => {
				window.clearTimeout(timeoutID);
			};
		}
	}, [refetchDevices, appliedDeviceFilters, searchText]);

	const applyDeviceFilters = useCallback(() => {
		setAppliedDeviceFilters(deviceFilters);
	}, [setAppliedDeviceFilters, deviceFilters]);

	const displayDevices = useMemo(() => {
		// Render assets based on page number (limit of 250 per page)
		const startIndex = (currentPage - 1) * 250;
		const endIndex = currentPage * 250;

		return devicesState.devices.slice(startIndex, endIndex);
	}, [devicesState.devices, currentPage]);

	const loadMoreDevices = useCallback(() => {
		if (devicesState.hasMore) {
			devicesActions.loadMore();
			setTotalPages((totalPages) => totalPages + 1);
		}

		setCurrentPage((currentPage) => currentPage + 1);
	}, [devicesState.hasMore, devicesActions]);

	const pushModal = useVelavuModal();
	const inspectDevice = useCallback(
		(
			device: VelavuDevice,
			defaultOpenPair?: undefined | "asset" | "tag",
		) => {
			VelavuAPI.devices
				.getSpecificDevice(device.id)
				.then((device: VelavuDevice) => {
					pushModal(
						(close) => (
							<VelavuTitledModal
								title="Device details"
								onClose={close}
							>
								<DetailModal
									asset={device.asset}
									device={device}
									deviceOnly={
										device.category ===
										DeviceCategory.Anchor
									}
									defaultOpenPair={defaultOpenPair}
									defaultTab="device"
									onUnpair={(type) => {
										devicesActions.updateDevice(
											type.tag.id,
											() => {
												return {
													...type.tag,
													asset: undefined,
												};
											},
										);
									}}
									onPair={(type) => {
										devicesActions.updateDevice(
											type.tag.id,
											() => {
												return {
													...type.tag,
													asset: type.asset,
												};
											},
										);
									}}
									onUpdateAsset={(
										assetID,
										name,
										category,
										group,
										//notes
									) => {
										devicesActions.updateDevice(
											assetID,
											(device) => {
												return {
													...device,
													asset: {
														...device.asset!,
														name: name,
														category: category,
														group: group,
													},
												};
											},
										);
									}}
								/>
							</VelavuTitledModal>
						),
						{ pinToTop: true },
					);
				})
				.catch(console.log);
		},
		[pushModal, devicesActions],
	);

	const registerTag = useCallback(() => {
		pushModal((close) => (
			<ModalRegisterTag
				onClose={close}
				onRegister={(newTag) => devicesActions.registerDevice(newTag)}
			/>
		));
	}, [devicesActions, pushModal]);

	const unregisterSelectedTags = useCallback(() => {
		pushModal((resolve) => (
			<VelavuModalPrompt
				title={`Permanently unregister ${selectedDevices.length} device(s)?`}
				confirmText="permanently unregister"
				confirmDanger
				onSelect={resolve}
			>
				<span>
					To unregister the selected device(s), please type{" "}
					<i>permanently unregister</i> in the field below
				</span>
			</VelavuModalPrompt>
		)).then(async (result) => {
			if (result) {
				setActionInProgress("unregister");

				for (let i = 0; i < selectedDevices.length; i++) {
					try {
						devicesActions.unregisterDevice(selectedDevices[i]);
						setActionProgress(i + 1);
					} catch (error) {
						console.error(error);
					}
				}

				setSelectedDevices([]);
				setActionInProgress(undefined);
				setActionProgress(0);
			}
		});

		return () => {
			setActionInProgress(undefined);
		};
	}, [pushModal, selectedDevices, devicesActions]);

	const rebootTag = useCallback((tag: VelavuDevice) => {
		VelavuAPI.devices
			.rebootSpecificDevice(tag.id)
			.then(() => {})
			.catch(console.log);
	}, []);

	const rebootSelectedTags = useCallback(() => {
		pushModal((resolve) => (
			<VelavuModalPrompt
				title={`Reboot ${selectedDevices.length} devices?`}
				labelConfirm="Reboot"
				onSelect={resolve}
			>
				<span>
					<b>Note: </b>Only online devices can be remotely rebooted.
				</span>
			</VelavuModalPrompt>
		)).then(async (result) => {
			if (result) {
				setActionInProgress("reboot");

				for (let i = 0; i < selectedDevices.length; i++) {
					try {
						await VelavuAPI.devices.rebootSpecificDevice(
							selectedDevices[i],
						);
						setActionProgress(i + 1);
					} catch (error) {
						console.error(error);
					}
				}
				setSelectedDevices([]);
				setActionInProgress(undefined);
				setActionProgress(0);
			}
		});

		return () => {
			setActionInProgress(undefined);
		};
	}, [pushModal, selectedDevices]);

	const selectDevice = useCallback(
		(selected: boolean, deviceID: string) => {
			setSelectedDevices((selectedDevices) => {
				if (selected) {
					return [...selectedDevices, deviceID];
				} else {
					return selectedDevices.filter((id) => id !== deviceID);
				}
			});
		},
		[setSelectedDevices],
	);

	const selectAllDevices = useCallback(() => {
		setSelectedDevices((selectedDevices) => {
			if (selectedDevices.length === displayDevices.length) {
				return [];
			} else {
				return displayDevices.map((device) => device.id);
			}
		});
	}, [displayDevices]);

	//Reset the hardware filter when the category changes
	useEffect(() => {
		setFilterHardware(null);
	}, [filterCategory, setFilterHardware]);

	//Handle navigation
	useEffect(() => {
		const deviceID = location.state?.deviceID;
		if (deviceID === undefined || devicesState.devices === undefined)
			return;

		//Get the specified tag
		const tag = devicesState.devices.find((tag) => tag.id === deviceID);
		if (tag === undefined) return;

		//Inspect the tag
		inspectDevice(tag);

		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.state?.deviceID, devicesState.devices]);

	const result = useMemo(() => {
		if (displayDevices.length && !devicesState.isLoading) {
			return displayDevices.map((device) => (
				<DevicesTableRow
					key={device.id}
					device={device}
					selected={selectedDevices.includes(device.id)}
					onSelect={(selected) => selectDevice(selected!, device.id)}
					onDetails={() => inspectDevice(device)}
					onPair={() => inspectDevice(device, "asset")}
					onReboot={() => rebootTag(device)}
				/>
			));
		} else if (displayDevices.length === 0 && !devicesState.isLoading) {
			return <NoDevices />;
		} else {
			return <Loading />;
		}
	}, [
		displayDevices,
		devicesState.isLoading,
		selectedDevices,
		selectDevice,
		inspectDevice,
		rebootTag,
	]);

	return (
		<>
			{actionInProgress && (
				<DeviceActionInProgessModal
					title={
						actionInProgress === "unregister"
							? "Unregister devices"
							: "Rebooting devices"
					}
					action={actionInProgress}
					progressCurrent={actionProgress}
					progressTotal={selectedDevices.length}
				/>
			)}
			<ListHeader
				icon={makeStyleable(IconVelavuTags)}
				title="Devices"
				search={{
					searchPlaceholder: "Search",
					searchText: searchText,
					setSearchText: setSearchText,
				}}
				add={{ label: "Register tag", onClick: registerTag }}
			/>
			<DeviceFilterMenu
				filterCategory={filterCategory}
				setFilterCategory={setFilterCategory}
				filterHardware={filterHardware}
				setFilterHardware={setFilterHardware}
				filterStatus={filterStatus}
				setFilterStatus={setFilterStatus}
				filterUnpaired={filterUnpaired}
				setFilterUnpaired={setFilterUnpaired}
				filterUnconfigured={filterUnconfigured}
				setFilterUnconfigured={setFilterUnconfigured}
				selectedDevices={selectedDevices}
				onReboot={rebootSelectedTags}
				onUnregister={unregisterSelectedTags}
				onApply={applyDeviceFilters}
				isDirty={isDirty}
			/>
			<VelavuTableHeader className={styles.tableHeader}>
				<VelavuTableHeaderLabel type="fixed" width={48}>
					<Checkbox
						checked={selectedDevices.length > 0}
						onClick={selectAllDevices}
						master
					/>
				</VelavuTableHeaderLabel>
				<VelavuTableHeaderLabel>Device ID</VelavuTableHeaderLabel>
				<VelavuTableHeaderLabel>Device</VelavuTableHeaderLabel>
				<VelavuTableHeaderLabel>Type</VelavuTableHeaderLabel>
				<VelavuTableHeaderLabel>Asset</VelavuTableHeaderLabel>
				<VelavuTableHeaderLabel>Battery</VelavuTableHeaderLabel>
				<VelavuTableHeaderLabel>Configured</VelavuTableHeaderLabel>
				<VelavuTableHeaderLabel type="edge" width={60} />
			</VelavuTableHeader>
			<VelavuTableDivider />
			<VelavuTableBody
				isEmpty={!displayDevices.length}
				className={styles.tableBody}
				scrollToTop={scrollToTop}
				setScrollToTop={setScrollToTop}
			>
				{result}
			</VelavuTableBody>
			<ListPagination
				totalPages={totalPages}
				currentPage={currentPage}
				setCurrentPage={setCurrentPage}
				setScrollToTop={setScrollToTop}
				hasMore={devicesState.hasMore}
				loadMore={loadMoreDevices}
				isLoading={devicesState.isLoading}
			/>
		</>
	);
}
