import {
	LocatedVelavuDevice,
	PairedLocatedVelavuDevice,
	VelavuDevice,
	VelavuSite,
} from "velavu-js-api";
import { useContext, useEffect, useState } from "react";
import DeviceMarker, {
	DevicePositionAnimation,
	deviceToDeviceMarker,
} from "../data/device-marker";
import { MQTTContext, MQTTListener } from "../components/main/mqtt-provider";

const gpsUpdateInterval = 5 * 1000; //5 seconds

/**
 * useDeviceMarkers maintains a mapping of PairedLocatedVelavuDevice[] to DeviceMarker[],
 * while keeping up-to-date with live location updates.
 * @param devices The devices to map to VelavuDeviceMarker objects
 * @param sites An array of all loaded sites to use for lookups
 * @param mapDimensions The current dimensions of the map view
 */
export default function useDeviceMarkers(
	devices: LocatedVelavuDevice[],
	sites: VelavuSite[] | undefined,
	mapDimensions: [number, number],
): DeviceMarker[] {
	const [deviceMarkers, setDeviceMarkers] = useState<DeviceMarker[]>([]);

	//Keep the deviceMarkers array up-to-date with the devices parameter
	useEffect(() => {
		const timeNow = performance.now();

		setDeviceMarkers((deviceMarkers) => {
			return devices.map((device): DeviceMarker => {
				//Preserve animation state
				const oldDeviceMarker = deviceMarkers.find(
					(deviceMarker) => deviceMarker.deviceID === device.id,
				);
				let anim: DevicePositionAnimation;
				if (oldDeviceMarker?.anim !== undefined) {
					anim = oldDeviceMarker.anim;
				} else {
					anim = {
						startCoords: device.location.coordinates,
						startDate: timeNow,
						endCoords: device.location.coordinates,
						endDate: timeNow,
					};
				}

				//Look up the site
				const site = sites?.find((site) => site.id === device.site_id);

				return {
					...deviceToDeviceMarker(device, mapDimensions, site),
					anim: anim,
				};
			});
		});
	}, [devices, setDeviceMarkers, sites, mapDimensions]);

	//Subscribe to movement updates
	const mqttContext = useContext(MQTTContext);

	useEffect(() => {
		const listener: MQTTListener = {
			onAssetMove: (event) => {
				setDeviceMarkers((deviceMarkers) => {
					//Find a matching device marker
					const index = deviceMarkers.findIndex(
						(marker) => event.deviceID === marker.deviceID,
					);
					if (index === -1) {
						return deviceMarkers;
					}

					//Update the marker with the new animation
					const updatedDeviceMarkers = [...deviceMarkers];
					const marker = updatedDeviceMarkers[index];
					updatedDeviceMarkers[index] = {
						...marker,
						anim: {
							startCoords:
								marker.anim?.endCoords ??
								marker.location.coordinates,
							startDate: performance.now(),
							endCoords: event.coordinates,
							endDate: performance.now() + gpsUpdateInterval,
						},
					};
					return updatedDeviceMarkers;
				});
			},
		};

		mqttContext.addListener(listener);
		return () => mqttContext.removeListener(listener);
	}, [mqttContext, setDeviceMarkers]);

	return deviceMarkers;
}
