// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.MJVf1jGgs9xsfFwY2dFF{font-weight:500;font-size:24px}._AtrDKAP1NwdSE1OOvZw{font-weight:500;font-size:20px}.bcgDS6vB6aAYiFD49oIr{font-weight:500;font-size:12px}.c6_Ilp_w6nku4giJEjmC{font-size:18px}.UFVPhIuVxedv9Sr1sRtw{font-size:18px;font-weight:500}.HyUGXVwrFpcihdrhENQc{font-size:16px}.j4JpFZdSld5dV22j4ZSn,.LjyzgnD7JpKUemRe_XlC{font-size:16px;font-weight:500}.EcMfON7xHz2IU2Az8COc{font-size:14px}.DH7b0OG90tqL60Rp97sX{font-size:14px;font-weight:500}.WbHEpa02UBJ5uCQRyYi9{font-size:14px;font-weight:400}.UTuzkGkurXqxxMRmsy4X{font-size:12px}.cc3SRt9FhIN0OidkKp45{font-size:12px;font-weight:500}.MzI7yqK_SsuqsxdsWG8O{width:100%;display:flex;flex-direction:row;align-items:center;cursor:pointer}.vxIpnBPsP5F5RkEvz90n{margin-bottom:8px}.LjyzgnD7JpKUemRe_XlC{margin:0 8px}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/elements/velavu-expand-menu.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,4CACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CCxGD,sBACC,UAAA,CAEA,YAAA,CACA,kBAAA,CACA,kBAAA,CAEA,cAAA,CAGD,sBACC,iBAAA,CAGD,sBACC,YAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `MJVf1jGgs9xsfFwY2dFF`;
export var h2 = `_AtrDKAP1NwdSE1OOvZw`;
export var h3 = `bcgDS6vB6aAYiFD49oIr`;
export var p0 = `c6_Ilp_w6nku4giJEjmC`;
export var p0m = `UFVPhIuVxedv9Sr1sRtw`;
export var p1 = `HyUGXVwrFpcihdrhENQc`;
export var p1m = `j4JpFZdSld5dV22j4ZSn`;
export var label = `LjyzgnD7JpKUemRe_XlC`;
export var p2 = `EcMfON7xHz2IU2Az8COc`;
export var p2m = `DH7b0OG90tqL60Rp97sX`;
export var p2n = `WbHEpa02UBJ5uCQRyYi9`;
export var p3 = `UTuzkGkurXqxxMRmsy4X`;
export var p3m = `cc3SRt9FhIN0OidkKp45`;
export var titleRow = `MzI7yqK_SsuqsxdsWG8O`;
export var openMargin = `vxIpnBPsP5F5RkEvz90n`;
export default ___CSS_LOADER_EXPORT___;
