import React from "react";
import { StyleableIcon } from "../helper/icon-helper";
import InsetIcon from "./inset-icon";
import * as styles from "./section-title.module.scss";

/**
 * A header that shows a section's icon along with its name
 */
export default function SectionTitle(props: {
	icon: StyleableIcon;
	label: string;
}) {
	return (
		<div className={styles.content}>
			<InsetIcon icon={props.icon} />
			<span className={styles.title}>{props.label}</span>
		</div>
	);
}
