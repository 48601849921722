import React from "react";
import * as styles from "./button-assets.module.scss";

export default function ButtonAssets(props: { onClick: VoidFunction }) {
	return (
		<button className={styles.button} onClick={props.onClick}>
			Assets
		</button>
	);
}
