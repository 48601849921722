// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.czMlcpOUcdIyOMeja2Ms{font-weight:500;font-size:24px}.wlGi5NIFTp8kAAtoSmC9{font-weight:500;font-size:20px}.keOi4KuDNuM5qtCqxHAd{font-weight:500;font-size:12px}.AeiDTANTTHU95X9pmN2I{font-size:18px}.UMfL5Bv5ykC68Q3D8Gk_{font-size:18px;font-weight:500}.OVOXmqJpwiRbtdBFWZ1h{font-size:16px}.ktMhXopaPxHnDezFreZG{font-size:16px;font-weight:500}.Z4M4qI7a_Zqb_1nZlkWC{font-size:14px}.F6mk_ZGdvkvmJdExeNr9{font-size:14px;font-weight:500}.rD_1sBAymHhxFsqXjhFK{font-size:14px;font-weight:400}.xQEHnrpO4UpuaMdlHB7x{font-size:12px}.Dw9n62aoYugCuVYsDkps{font-size:12px;font-weight:500}.GsIcFu1NefShdyRFiXzt{padding:20px;box-sizing:border-box}.mTeTlY2vMDl6exA2GXuq{margin-top:20px}.BAVS8MJSoC_aTmDKaI33{margin-top:20px}.t2Y7MruW10bIvrugehbv{width:50%}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/components/dashboard/dropdown/floor-create/floor-create-import-upload.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,YAAA,CACA,qBAAA,CAGD,sBACC,eAAA,CAGD,sBACC,eAAA,CAGD,sBACC,SAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `czMlcpOUcdIyOMeja2Ms`;
export var h2 = `wlGi5NIFTp8kAAtoSmC9`;
export var h3 = `keOi4KuDNuM5qtCqxHAd`;
export var p0 = `AeiDTANTTHU95X9pmN2I`;
export var p0m = `UMfL5Bv5ykC68Q3D8Gk_`;
export var p1 = `OVOXmqJpwiRbtdBFWZ1h`;
export var p1m = `ktMhXopaPxHnDezFreZG`;
export var p2 = `Z4M4qI7a_Zqb_1nZlkWC`;
export var p2m = `F6mk_ZGdvkvmJdExeNr9`;
export var p2n = `rD_1sBAymHhxFsqXjhFK`;
export var p3 = `xQEHnrpO4UpuaMdlHB7x`;
export var p3m = `Dw9n62aoYugCuVYsDkps`;
export var container = `GsIcFu1NefShdyRFiXzt`;
export var uploadButton = `mTeTlY2vMDl6exA2GXuq`;
export var buttonBar = `BAVS8MJSoC_aTmDKaI33`;
export var buttonBack = `t2Y7MruW10bIvrugehbv`;
export default ___CSS_LOADER_EXPORT___;
