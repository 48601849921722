import React, { CSSProperties } from "react";

export default function IconGeofenceExited(props: {
	className?: string;
	style?: CSSProperties;
	color?: string;
	size?: number;
}) {
	return (
		<svg
			className={props.className}
			style={props.style}
			width={props.size ?? 24}
			height={props.size ?? 24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M5 7.7A2 2 0 1 1 7.7 5H11a1 1 0 1 1 0 2H7.7a2 2 0 0 1-.7.7v8.6c.3.1.6.4.7.7h8.6c.1-.3.4-.6.7-.7V13a1 1 0 1 1 2 0v3.3a2 2 0 1 1-2.7 2.7H7.7A2 2 0 1 1 5 16.3V7.7Z"
				fill={props.color ?? "black"}
			/>
			<path
				d="M9.8 12.6a1 1 0 1 0 1.4 1.4l5.4-5.4 2.1 2.2.6-5.2a1 1 0 0 0-1.1-1l-5.1.5 2.1 2.1-5.4 5.4Z"
				fill={props.color ?? "black"}
			/>
		</svg>
	);
}
