import React from "react";
import { EventCategory } from "velavu-js-api";
import IconWifiAlt from "../dynamicicons/icon-wifi-alt";
import IconWifiOffAlt from "../dynamicicons/icon-wifi-off-alt";
import IconViewGridPlus from "../dynamicicons/icon-view-grid-plus";
import IconBatteryWarn from "../dynamicicons/icon-battery-warn";
import IconCar from "../dynamicicons/icon-car";
import IconShock from "../dynamicicons/icon-shock";
import IconVelavuAlerts from "../dynamicicons/icon-velavu-alerts";
import IconFound from "../dynamicicons/icon-found";
import IconMissing from "../dynamicicons/icon-missing";
import IconSiteEntered from "../dynamicicons/icon-site-entered";
import IconSiteExited from "../dynamicicons/icon-site-exited";
import IconRequestedAssistance from "../dynamicicons/icon-requested-assistance";
import IconGeofenceEntered from "../dynamicicons/icon-geofence-entered";
import IconGeofenceExited from "../dynamicicons/icon-geofence-exited";
import IconBattery from "../dynamicicons/icon-battery";
import IconDeviceDFU from "../dynamicicons/icon-device-dfu";
import IconDeviceBoot from "../dynamicicons/icon-device-boot";
import IconDeviceError from "../dynamicicons/icon-device-error";
import IconEnvironment from "../dynamicicons/icon-environment";
import IconLocation from "../dynamicicons/icon-location";
import IconRule from "../dynamicicons/icon-rule";

/**
 * An icon that represents an event category
 */
export default function AlertIcon(props: {
	type: EventCategory;
	size?: number;
	color?: string;
}) {
	switch (props.type) {
		case EventCategory.BatteryLevel:
		case EventCategory.LowBattery:
			return <IconBatteryWarn size={props.size} color={props.color} />;
		case EventCategory.DFURequest:
		case EventCategory.OTAPRequest:
			return <IconDeviceDFU size={props.size} color={props.color} />;
		case EventCategory.DeviceBoot:
			return <IconDeviceBoot size={props.size} color={props.color} />;
		case EventCategory.DeviceConnect:
			return <IconWifiAlt size={props.size} color={props.color} />;
		case EventCategory.DeviceDisconnect:
			return <IconWifiOffAlt size={props.size} color={props.color} />;
		case EventCategory.DeviceError:
			return <IconDeviceError size={props.size} color={props.color} />;
		case EventCategory.DeviceProvision:
			//TODO: Replace?
			return <IconViewGridPlus size={props.size} color={props.color} />;
		case EventCategory.DrivingEvent:
		case EventCategory.DrivingRoute:
			return <IconCar size={props.size} color={props.color} />;
		case EventCategory.Environment:
		case EventCategory.EnvironmentThreshold:
			return <IconEnvironment size={props.size} color={props.color} />;
		case EventCategory.GeofenceEntered:
			return (
				<IconGeofenceEntered size={props.size} color={props.color} />
			);
		case EventCategory.GeofenceExited:
			return <IconGeofenceExited size={props.size} color={props.color} />;
		case EventCategory.Location:
			return <IconLocation size={props.size} color={props.color} />;
		case EventCategory.Motion:
		case EventCategory.Shock:
			return <IconShock size={props.size} color={props.color} />;
		case EventCategory.SiteEntered:
			return <IconSiteEntered size={props.size} color={props.color} />;
		case EventCategory.SiteExited:
			return <IconSiteExited size={props.size} color={props.color} />;
		case EventCategory.InventoryAdded:
			return <IconFound size={props.size} color={props.color} />;
		case EventCategory.InventoryRemoved:
			return <IconMissing size={props.size} color={props.color} />;
		case EventCategory.WearableAlert:
			return (
				<IconRequestedAssistance
					size={props.size}
					color={props.color}
				/>
			);
		case EventCategory.CustomEvent:
			return <IconRule size={props.size} color={props.color} />;
		default:
			return <IconVelavuAlerts size={props.size} color={props.color} />;
	}
}
