// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.WUKbBPWJMCNzkTs2sczm{font-weight:500;font-size:24px}.QysE6wvTWiJYHsn9wbTH{font-weight:500;font-size:20px}.hluw20X9hd7916qRDgwv{font-weight:500;font-size:12px}.hFpoBD1raDWIncRnyJUZ{font-size:18px}.d4vMBKnCbNHha_Sm1YZg{font-size:18px;font-weight:500}.jsVXuh9Ool49PXuXYxVJ{font-size:16px}.CGUE09VOpkAFWz6DSv3o{font-size:16px;font-weight:500}.qPHHNKMfvG0aLvDyhCoc{font-size:14px}.mXzlP_f7u86ks4aIup9j{font-size:14px;font-weight:500}._ejFbZg_9LHXyhtFiZ4T{font-size:14px;font-weight:400}.XX5rCK6VgEvVpvDScTtg{font-size:12px}.HulNtjNRCtIPsZ2nnwFh{font-size:12px;font-weight:500}.xMy0QQ3QkNkQDNceKefJ{padding:20px}.iuJI5Ml96IM_p5xHPOCS{margin-top:20px}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/components/asset-tag-detail/tab-tag.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,YAAA,CAGD,sBACC,eAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `WUKbBPWJMCNzkTs2sczm`;
export var h2 = `QysE6wvTWiJYHsn9wbTH`;
export var h3 = `hluw20X9hd7916qRDgwv`;
export var p0 = `hFpoBD1raDWIncRnyJUZ`;
export var p0m = `d4vMBKnCbNHha_Sm1YZg`;
export var p1 = `jsVXuh9Ool49PXuXYxVJ`;
export var p1m = `CGUE09VOpkAFWz6DSv3o`;
export var p2 = `qPHHNKMfvG0aLvDyhCoc`;
export var p2m = `mXzlP_f7u86ks4aIup9j`;
export var p2n = `_ejFbZg_9LHXyhtFiZ4T`;
export var p3 = `XX5rCK6VgEvVpvDScTtg`;
export var p3m = `HulNtjNRCtIPsZ2nnwFh`;
export var container = `xMy0QQ3QkNkQDNceKefJ`;
export var expandMenu = `iuJI5Ml96IM_p5xHPOCS`;
export default ___CSS_LOADER_EXPORT___;
