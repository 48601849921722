import React, { CSSProperties } from "react";
import * as styles from "./velavu-select.module.scss";
import { classArr } from "../helper/style-helper";
import { StyleableIcon } from "../helper/icon-helper";
import * as globals from "../styles/global.icss.scss";

interface VelavuSelectProps<T> {
	className?: string;
	style?: CSSProperties;
	children?: React.ReactNode;
	icon?: StyleableIcon;

	value?: T;
	onChange?: (value: T) => void;
	disabled?: boolean;

	titleText?: string;

	fullWidth?: boolean;
	bottomPadding?: boolean;
}

export default function VelavuSelect<T extends string>(
	props: VelavuSelectProps<T>,
): React.JSX.Element;
export default function VelavuSelect<T extends number>(
	props: VelavuSelectProps<T>,
): React.JSX.Element;
export default function VelavuSelect<T extends string | number>(
	props: VelavuSelectProps<T>,
) {
	return (
		<div
			style={props.style}
			className={classArr(
				styles.container,
				props.className,
				props.bottomPadding ? styles.bottomPadding : undefined,
			)}
		>
			{props.titleText && (
				<span className={styles.titleText}>{props.titleText}</span>
			)}
			<div
				className={classArr(
					styles.selectWrapper,
					props.fullWidth && styles.fullWidth,
				)}
			>
				{props.icon !== undefined && (
					<div className={styles.iconWrapper}>
						{props.icon?.(16, globals.textPrimary)}
					</div>
				)}
				<select
					className={classArr(
						styles.select,
						props.fullWidth && styles.fullWidth,
						props.icon && styles.selectWithIcon,
					)}
					value={props.value}
					onChange={(event) =>
						props.onChange?.(
							(typeof props.value === "number"
								? Number(event.target.value)
								: event.target.value) as T,
						)
					}
					disabled={props.disabled}
				>
					{props.children}
				</select>
			</div>
		</div>
	);
}
