import React, { CSSProperties, useCallback } from "react";
import IconMoreHorizontal from "../dynamicicons/icon-more-horizontal";
import VelavuIconButton from "./velavu-icon-button";
import * as styles from "./velavu-menu.module.scss";
import { classArr } from "../helper/style-helper";

interface VelavuMenuProps {
	style?: CSSProperties;
	className?: string;

	open: boolean;
	onClose: VoidFunction;

	refPosition: HTMLElement | null;

	children?: React.ReactNode;

	align?: "left" | "right";
}

export default function VelavuMenu(props: VelavuMenuProps) {
	const { align = "left" } = props;
	const propsOnClose = props.onClose;
	const onKey = useCallback(
		(event: React.KeyboardEvent) => {
			if (event.key === "Escape") {
				propsOnClose();
			}
		},
		[propsOnClose],
	);

	if (!props.open) return null;

	const positionRect = props.refPosition!.getBoundingClientRect();

	return (
		<div role="presentation" className={styles.scrim} onKeyDown={onKey}>
			<div
				aria-hidden="true"
				className={styles.hitbox}
				onClick={props.onClose}
			/>
			<div
				className={`${styles.container} ${props.className ?? ""}`}
				style={{
					...(align === "left"
						? { left: positionRect.left }
						: { right: window.innerWidth - positionRect.right }),
					top: positionRect.bottom,
					...props.style,
				}}
			>
				{props.children}
			</div>
		</div>
	);
}

interface VelavuMenuItemProps {
	icon?: (size: number, color: string) => React.ReactNode;
	label: string;
	danger?: boolean;
	disabled?: boolean;
	onClick?: VoidFunction;
}

export function VelavuMenuItem(props: VelavuMenuItemProps) {
	return (
		<div
			className={classArr(styles.item, props.disabled && styles.disabled)}
			onClick={props.disabled ? undefined : props.onClick}
		>
			{props.icon && (
				<>
					{props.icon(16, props.danger ? "#DA3E52" : "#5F718C")}
					<div className={styles.itemIconSpacer} />
				</>
			)}
			<span
				className={
					props.danger
						? styles.itemLabelDanger
						: styles.itemLabelStandard
				}
			>
				{props.label}
			</span>
		</div>
	);
}

interface VelavuOverflowButtonProps {
	className?: string;
	style?: CSSProperties;

	disabled?: boolean;
	open?: boolean;
	onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const VelavuOverflowButton = React.forwardRef<
	HTMLButtonElement,
	VelavuOverflowButtonProps
>((props, ref) => (
	<VelavuIconButton
		ref={ref}
		style={props.style}
		className={props.className}
		tooltip="More"
		focus={props.open}
		onClick={props.onClick}
		disabled={props.disabled}
	>
		<IconMoreHorizontal color="#5F718C" />
	</VelavuIconButton>
));
