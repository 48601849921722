// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.zTMU6tyACn50JCTL2xoA{font-weight:500;font-size:24px}.h1x_ZvaoMx5p9QZaUyjQ{font-weight:500;font-size:20px}.aP4x51WymsEn0ECx1VTT{font-weight:500;font-size:12px}.ARRn628xGGrYDfjehUfp{font-size:18px}.dENpF0sveyd7Axfu_n80{font-size:18px;font-weight:500}.RF9EJ6bFDTC4bmYSINyj{font-size:16px}.YnE5CD9n8UDSqb6hkyle,.iohNOeD_gQWf5lhex5nn{font-size:16px;font-weight:500}.njGsitaVAGi9Js6Fq92h{font-size:14px}.GjKDKKKjfUtNy2_KwXmk,.eOwzOcG_HzhUgX77CtXW{font-size:14px;font-weight:500}.rzvfZg53I_jaWZGtwfKZ{font-size:14px;font-weight:400}.eQenIyCUKo0jft663q3v{font-size:12px}.hBNeCciRFGmtleTl4nZF{font-size:12px;font-weight:500}.lIprFBVmPv6qTNohpPDA{display:flex;flex-direction:column;gap:20px}.iohNOeD_gQWf5lhex5nn{margin-top:0}.eOwzOcG_HzhUgX77CtXW{color:#5f718c}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/components/settings/settings-billing/settings-billing-current.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,4CACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,4CACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,YAAA,CACA,qBAAA,CACA,QAAA,CAGD,sBAEC,YAAA,CAGD,sBAEC,aDFgB","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `zTMU6tyACn50JCTL2xoA`;
export var h2 = `h1x_ZvaoMx5p9QZaUyjQ`;
export var h3 = `aP4x51WymsEn0ECx1VTT`;
export var p0 = `ARRn628xGGrYDfjehUfp`;
export var p0m = `dENpF0sveyd7Axfu_n80`;
export var p1 = `RF9EJ6bFDTC4bmYSINyj`;
export var p1m = `YnE5CD9n8UDSqb6hkyle`;
export var title = `iohNOeD_gQWf5lhex5nn`;
export var p2 = `njGsitaVAGi9Js6Fq92h`;
export var p2m = `GjKDKKKjfUtNy2_KwXmk`;
export var subtitle = `eOwzOcG_HzhUgX77CtXW`;
export var p2n = `rzvfZg53I_jaWZGtwfKZ`;
export var p3 = `eQenIyCUKo0jft663q3v`;
export var p3m = `hBNeCciRFGmtleTl4nZF`;
export var container = `lIprFBVmPv6qTNohpPDA`;
export default ___CSS_LOADER_EXPORT___;
