// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.l3lTkcEyIxe0PMDT3Q5G{display:flex;flex-direction:row;align-items:center;margin-top:20px;gap:20px}.rFIiZtrs3dPQRrfYLLrY{flex:1 1 0}`, "",{"version":3,"sources":["webpack://./src/components/dashboard/dropdown/anchor-detail/anchor-properties.module.scss"],"names":[],"mappings":"AAAA,sBACC,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,eAAA,CACA,QAAA,CAGD,sBACC,UAAA","sourceRoot":""}]);
// Exports
export var buttons = `l3lTkcEyIxe0PMDT3Q5G`;
export var button = `rFIiZtrs3dPQRrfYLLrY`;
export default ___CSS_LOADER_EXPORT___;
