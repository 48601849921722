// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.Af3jEejOPhk1tSYZcMhO{font-weight:500;font-size:24px}.CwwKxvFjUflnZR1WaUD0{font-weight:500;font-size:20px}.F8qDBG4vU9m2PHQBLwVB{font-weight:500;font-size:12px}.PXAXcolw6PjcFJJuwjU7{font-size:18px}.DIJYPTcAHriN8tbHLx4G{font-size:18px;font-weight:500}.xGw8b2pkw2P4ArR74s6N,.PMedJLe6abuL_pHjoiQO{font-size:16px}.ijyi_40QJ301ve080F4x,.HJkUuz6RwtO3bmYTPcPi{font-size:16px;font-weight:500}.Wo7EDJPiwqY1PQ6EFYtv,.GrDhczM3weKVE2cWZ89j{font-size:14px}.oTxBNyatxFBJ1_cXHZpJ{font-size:14px;font-weight:500}.ElFk5oG4P5Wskf6niwgS{font-size:14px;font-weight:400}.isfSoEUChaJnkGi2XrpR{font-size:12px}.QolUKZuWx794jh4eG9Pj{font-size:12px;font-weight:500}.fTu2OKEhESmv29TNkvJc{padding:0 20px 20px 20px;display:flex;flex-direction:row}.gYs8KUaZ3YbrwXAvScW2{flex-grow:1;flex-basis:0;display:flex;flex-direction:column}.GrDhczM3weKVE2cWZ89j{color:#5f718c;margin-top:8px}.PMedJLe6abuL_pHjoiQO{display:flex;flex-direction:row;align-items:center;justify-content:center;gap:10px}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/components/dashboard/dropdown/geofence-detail/geofence-detail.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,4CACC,cAAA,CAGD,4CACC,cAAA,CACA,eAAA,CAGD,4CACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,wBAAA,CAEA,YAAA,CACA,kBAAA,CAGD,sBACC,WAAA,CACA,YAAA,CAEA,YAAA,CACA,qBAAA,CAOD,sBAEC,aDVgB,CCWhB,cAAA,CAGD,sBAGC,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,sBAAA,CACA,QAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `Af3jEejOPhk1tSYZcMhO`;
export var h2 = `CwwKxvFjUflnZR1WaUD0`;
export var h3 = `F8qDBG4vU9m2PHQBLwVB`;
export var p0 = `PXAXcolw6PjcFJJuwjU7`;
export var p0m = `DIJYPTcAHriN8tbHLx4G`;
export var p1 = `xGw8b2pkw2P4ArR74s6N`;
export var titleNotice = `PMedJLe6abuL_pHjoiQO`;
export var p1m = `ijyi_40QJ301ve080F4x`;
export var titleHeaderTitle = `HJkUuz6RwtO3bmYTPcPi`;
export var p2 = `Wo7EDJPiwqY1PQ6EFYtv`;
export var titleHeaderSubtitle = `GrDhczM3weKVE2cWZ89j`;
export var p2m = `oTxBNyatxFBJ1_cXHZpJ`;
export var p2n = `ElFk5oG4P5Wskf6niwgS`;
export var p3 = `isfSoEUChaJnkGi2XrpR`;
export var p3m = `QolUKZuWx794jh4eG9Pj`;
export var titleHeader = `fTu2OKEhESmv29TNkvJc`;
export var titleText = `gYs8KUaZ3YbrwXAvScW2`;
export default ___CSS_LOADER_EXPORT___;
