// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.Ho0VdETVmfxNEc8tOIDw{font-weight:500;font-size:24px}.zez40C8StgxNDkCrlB6_{font-weight:500;font-size:20px}.whGFgabJuJutX517TfU2{font-weight:500;font-size:12px}.lRFr0zVZI_IlFsn9OG8J{font-size:18px}.KiWi7E665SbwGsKov9sV{font-size:18px;font-weight:500}.yDnChcBuNnRrzhtRcshv{font-size:16px}.z5tp2WvrjJfdwWKSdCMB{font-size:16px;font-weight:500}.RuRoj_mkvDXf37GkbVSi{font-size:14px}.n56HZ6UnFWhoWvyAtv1A{font-size:14px;font-weight:500}.Gevapsc2XEad8QtsBf_i{font-size:14px;font-weight:400}.Y4TbXSzOHCF1U6yG_Hgr{font-size:12px}.OGYQwtK_RTBOGuf9vjA5{font-size:12px;font-weight:500}.kmKIBGDJsMb6rqePeKcp{padding:20px;flex-grow:1}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/components/settings/settings-billing/settings-billing.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,YAAA,CACA,WAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `Ho0VdETVmfxNEc8tOIDw`;
export var h2 = `zez40C8StgxNDkCrlB6_`;
export var h3 = `whGFgabJuJutX517TfU2`;
export var p0 = `lRFr0zVZI_IlFsn9OG8J`;
export var p0m = `KiWi7E665SbwGsKov9sV`;
export var p1 = `yDnChcBuNnRrzhtRcshv`;
export var p1m = `z5tp2WvrjJfdwWKSdCMB`;
export var p2 = `RuRoj_mkvDXf37GkbVSi`;
export var p2m = `n56HZ6UnFWhoWvyAtv1A`;
export var p2n = `Gevapsc2XEad8QtsBf_i`;
export var p3 = `Y4TbXSzOHCF1U6yG_Hgr`;
export var p3m = `OGYQwtK_RTBOGuf9vjA5`;
export var container = `kmKIBGDJsMb6rqePeKcp`;
export default ___CSS_LOADER_EXPORT___;
