// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.wSN5FVwOf0wOg1RNnfFr{font-weight:500;font-size:24px}.b4g71dqOTTsXjuDqDCaX{font-weight:500;font-size:20px}.CVIYbESgLM9qwvgud7mK{font-weight:500;font-size:12px}.P0KzkiK_NdTXjwYN1VJI{font-size:18px}.Vzr6W6fHhezlivcLRIPA{font-size:18px;font-weight:500}.vQY6pYCE24OnMospNVoX{font-size:16px}.y1qNQgyVCboYk24eOtVO{font-size:16px;font-weight:500}.JqVShhicuYCH6ebQq5Oj{font-size:14px}.z6mvuQ0DqtiqpGyoFrME{font-size:14px;font-weight:500}.s0Lcx3KChOu34RLZiNCT{font-size:14px;font-weight:400}.l11nuNJggOHYmpLUZDHQ{font-size:12px}.GXIcPOWgcMoC79qx6G2R{font-size:12px;font-weight:500}.LdwnH5QTLxcLvh70rW_g{display:flex;flex-direction:row;align-items:center;padding:12px 24px}.VPj8eXFfXNjiMsVpJMxB{flex:1;margin-right:12px}.novngdaxlHSb8B0Nj5kn{background-color:#f5f8f9;flex-grow:1;flex-shrink:1;min-height:260px;overflow-y:auto;border-bottom-left-radius:20px;border-bottom-right-radius:20px;padding:12px;box-sizing:border-box;display:flex;flex-direction:column;gap:12px}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/components/dashboard/dropdown/geofence-list/geofence-list.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,iBAAA,CAGD,sBACC,MAAA,CACA,iBAAA,CAGD,sBACC,wBDIO,CCFP,WAAA,CACA,aAAA,CACA,gBAAA,CACA,eAAA,CAEA,8BAAA,CACA,+BAAA,CAEA,YAAA,CACA,qBAAA,CAEA,YAAA,CACA,qBAAA,CACA,QAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `wSN5FVwOf0wOg1RNnfFr`;
export var h2 = `b4g71dqOTTsXjuDqDCaX`;
export var h3 = `CVIYbESgLM9qwvgud7mK`;
export var p0 = `P0KzkiK_NdTXjwYN1VJI`;
export var p0m = `Vzr6W6fHhezlivcLRIPA`;
export var p1 = `vQY6pYCE24OnMospNVoX`;
export var p1m = `y1qNQgyVCboYk24eOtVO`;
export var p2 = `JqVShhicuYCH6ebQq5Oj`;
export var p2m = `z6mvuQ0DqtiqpGyoFrME`;
export var p2n = `s0Lcx3KChOu34RLZiNCT`;
export var p3 = `l11nuNJggOHYmpLUZDHQ`;
export var p3m = `GXIcPOWgcMoC79qx6G2R`;
export var header = `LdwnH5QTLxcLvh70rW_g`;
export var search = `VPj8eXFfXNjiMsVpJMxB`;
export var list = `novngdaxlHSb8B0Nj5kn`;
export default ___CSS_LOADER_EXPORT___;
