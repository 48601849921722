import React, { useEffect, useMemo, useState } from "react";
import * as styles from "./backend-device-table.module.scss";
import {
	BackendDevice,
	NormalizedDeviceHardware,
	normalizeDeviceHardware,
	VelavuAPI,
	VelavuDevice,
} from "velavu-js-api";
import {
	VelavuTableBody,
	VelavuTableColumn,
	VelavuTableDivider,
	VelavuTableHeader,
	VelavuTableHeaderLabel,
	VelavuTableRoundContainer,
	VelavuTableRow,
} from "../../../elements/velavu-header-table";
import { useToggleable } from "../../../helper/hook-helper";
import VelavuIconButton from "../../../elements/velavu-icon-button";
import DeviceIcon from "../../../elements/device-icon";
import IconCircleAdd from "../../../dynamicicons/icon-circle-add";
import IconRemoveFilledCircle from "../../../dynamicicons/icon-remove-filled-circle";
import * as globals from "../../../styles/global.icss.scss";
import { classArr } from "../../../helper/style-helper";

export interface BackendDeviceTableProps {
	className?: string;
	devices?: BackendDevice[];
	configureDevice?: (deviceID: string) => void;
	removeDevice?: (deviceID: string) => void;
	disabled?: boolean;
}

interface TableDevice {
	id: string;
	hardware: NormalizedDeviceHardware | undefined;
}

interface ConfiguredTableDevice extends TableDevice {
	connected: boolean;
}

export default function BackendDeviceTable(props: BackendDeviceTableProps) {
	//Fetch devices
	const [devices, setDevices] = useState<VelavuDevice[] | undefined>();
	useEffect(() => {
		VelavuAPI.devices
			.getAllDevices({
				hardware: NormalizedDeviceHardware.Argo,
			})
			.then((result) => {
				//TODO: Paginate devices
				setDevices(result.data);
			})
			.catch((error) => {
				console.warn("Failed to fetch devices", error);
			});
	}, [setDevices]);

	//Split devices by configured / available
	const [configuredDevices, availableDevices] = useMemo(() => {
		const backendDevices = props.devices ?? [];

		const configuredDevices: ConfiguredTableDevice[] = [];
		const availableDevices: TableDevice[] = [];

		if (devices !== undefined) {
			for (const device of devices) {
				//Ignore devices that don't support a backend
				const normalizedDeviceHardware = normalizeDeviceHardware(
					device.hardware,
				);
				if (normalizedDeviceHardware !== NormalizedDeviceHardware.Argo)
					continue;

				const backendDevice = backendDevices.find(
					(backendDevice) => backendDevice.id === device.id,
				);
				if (backendDevice !== undefined) {
					configuredDevices.push({
						id: device.id,
						hardware: normalizedDeviceHardware,
						connected: backendDevice.connected,
					});
				} else {
					availableDevices.push({
						id: device.id,
						hardware: normalizedDeviceHardware,
					});
				}
			}
		}

		return [configuredDevices, availableDevices];
	}, [props.devices, devices]);

	return (
		<div
			className={classArr(
				props.className,
				styles.twoColumn,
				props.disabled && styles.disabled,
			)}
		>
			<div>
				<span className={styles.title}>Configured devices</span>

				<VelavuTableRoundContainer>
					<VelavuTableHeader>
						<VelavuTableHeaderLabel flex={2}>
							Device ID
						</VelavuTableHeaderLabel>
						<VelavuTableHeaderLabel flex={3}>
							Status
						</VelavuTableHeaderLabel>
						<VelavuTableHeaderLabel type="edge" width={30} />
					</VelavuTableHeader>
					<VelavuTableDivider />
					<VelavuTableBody className={styles.tableBody}>
						{configuredDevices.map((device) => (
							<ConfiguredDeviceRow
								key={device.id}
								device={device}
								onMove={() => props.removeDevice?.(device.id)}
								disabled={props.disabled}
							/>
						))}
					</VelavuTableBody>
				</VelavuTableRoundContainer>
			</div>

			<div>
				<span className={styles.title}>Available devices</span>

				<VelavuTableRoundContainer>
					<VelavuTableHeader>
						<VelavuTableHeaderLabel flex={2}>
							Device ID
						</VelavuTableHeaderLabel>
						<VelavuTableHeaderLabel type="edge" width={30} />
					</VelavuTableHeader>
					<VelavuTableDivider />
					<VelavuTableBody className={styles.tableBody}>
						{availableDevices.map((device) => (
							<AvailableDeviceRow
								key={device.id}
								device={device}
								onMove={() =>
									props.configureDevice?.(device.id)
								}
								disabled={props.disabled}
							/>
						))}
					</VelavuTableBody>
				</VelavuTableRoundContainer>
			</div>
		</div>
	);
}

function ConfiguredDeviceRow(props: {
	device: ConfiguredTableDevice;
	onMove: () => void;
	disabled?: boolean;
}) {
	const [hoverState, enterHoverState, exitHoverState] = useToggleable();

	return (
		<VelavuTableRow
			className={styles.tableRow}
			onMouseEnter={enterHoverState}
			onMouseLeave={exitHoverState}
		>
			<VelavuTableColumn flex={2}>
				<DeviceIcon hardware={props.device.hardware} />
				<span style={{ marginLeft: 8 }}>{props.device.id}</span>
			</VelavuTableColumn>
			<VelavuTableColumn flex={3}>
				{props.device.connected ? (
					<span className={styles.active}>Connected</span>
				) : (
					<span className={styles.inactive}>Disconnected</span>
				)}
			</VelavuTableColumn>
			<VelavuTableColumn type="edge" width={30}>
				{!props.disabled && (
					<VelavuIconButton
						className={classArr(
							styles.tableButton,
							hoverState && styles.tableButtonHover,
						)}
						onClick={props.onMove}
					>
						<IconRemoveFilledCircle
							size={16}
							color={globals.textPrimary}
						/>
					</VelavuIconButton>
				)}
			</VelavuTableColumn>
		</VelavuTableRow>
	);
}

function AvailableDeviceRow(props: {
	device: TableDevice;
	onMove: () => void;
	disabled?: boolean;
}) {
	const [hoverState, enterHoverState, exitHoverState] = useToggleable();

	return (
		<VelavuTableRow
			className={styles.tableRow}
			onMouseEnter={enterHoverState}
			onMouseLeave={exitHoverState}
		>
			<VelavuTableColumn flex={2}>
				<DeviceIcon hardware={props.device.hardware} />
				<span style={{ marginLeft: 8 }}>{props.device.id}</span>
			</VelavuTableColumn>
			<VelavuTableColumn type="edge" width={30}>
				{!props.disabled && (
					<VelavuIconButton
						className={classArr(
							styles.tableButton,
							hoverState && styles.tableButtonHover,
						)}
						onClick={props.onMove}
					>
						<IconCircleAdd size={16} color={globals.textPrimary} />
					</VelavuIconButton>
				)}
			</VelavuTableColumn>
		</VelavuTableRow>
	);
}
