import React from "react";
import * as styles from "./settings-billing-subscription.module.scss";
import { VelavuSubscription, VelavuSubscriptionStatus } from "velavu-js-api";
import { classArr } from "../../../helper/style-helper";
import { DateTime, Interval } from "luxon";
import { formatBillingDate } from "./settings-billing-format";

export interface SettingsBillingSubscriptionProps {
	subscription: VelavuSubscription | null;
}

export function daysUntil(dateString: string): number {
	const currentDate = DateTime.now();
	const futureDate = DateTime.fromISO(dateString);

	const interval = Interval.fromDateTimes(currentDate, futureDate);

	return Math.floor(interval.length("days"));
}

export default function SettingsBillingSubscription(
	props: SettingsBillingSubscriptionProps,
) {
	const subscription = props.subscription;

	return (
		<div className={styles.container}>
			{subscription === null && (
				<>
					<div
						className={classArr(
							styles.indicator,
							styles.indicatorNoSubscription,
						)}
					/>
					<span className={styles.status}>No subscription</span>
				</>
			)}

			{subscription?.status === VelavuSubscriptionStatus.Trialing && (
				<>
					<div
						className={classArr(
							styles.indicator,
							styles.indicatorTrial,
						)}
					/>
					<span className={styles.status}>Trial</span>

					<span className={styles.valueLabel}>Days remaining:</span>
					<span className={styles.value}>
						{daysUntil(subscription.trial_end_date)}
					</span>
				</>
			)}

			{subscription?.status === VelavuSubscriptionStatus.Paused && (
				<>
					<div
						className={classArr(
							styles.indicator,
							styles.indicatorPaused,
						)}
					/>
					<span className={styles.status}>Paused</span>

					<span className={styles.valueLabel}>Since:</span>
					<span className={styles.value}>
						{formatBillingDate(subscription.start_date)}
					</span>
				</>
			)}

			{subscription?.status === VelavuSubscriptionStatus.Active && (
				<>
					<div
						className={classArr(
							styles.indicator,
							styles.indicatorActive,
						)}
					/>
					<span className={styles.status}>Active</span>

					<span className={styles.valueLabel}>Since:</span>
					<span className={styles.value}>
						{formatBillingDate(subscription.start_date)}
					</span>
				</>
			)}
		</div>
	);
}
