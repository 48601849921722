import * as styles from "./velavu-mapbox-popup.module.scss";
import mapboxgl from "mapbox-gl";
import { classArr } from "../helper/style-helper";

/**
 * Creates a mapbox Popup instance with modern Velavu styling
 */
export function createVelavuMapboxPopup(
	optionsOverride: mapboxgl.PopupOptions = {},
) {
	return new mapboxgl.Popup({
		...optionsOverride,
		className: classArr(
			styles.velavuMapboxPopupTooltip,
			optionsOverride.className,
		),
		closeButton: optionsOverride.closeButton ?? false,
		closeOnClick: optionsOverride.closeOnClick ?? false,
		offset: optionsOverride.offset ?? [20, 0],
		anchor: optionsOverride.anchor ?? "left",
	});
}
