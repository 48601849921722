import React from "react";
import * as styles from "./velavu-arrow-button.module.scss";

type ArrowDirection = "right" | "left" | "confirm";

interface VelavuButtonProps {
	direction: ArrowDirection; //The direction to point the arrow
	disabled?: boolean; //Whether this button should be disabled
	onClick?: () => void; //A callback when this button is clicked
}

//A circular arrow button
export default function VelavuArrowButton(props: VelavuButtonProps) {
	return (
		<button
			className={`${styles.main} ${
				props.disabled ? styles.disabled : ""
			}`}
			disabled={props.disabled}
			onClick={props.onClick}
		>
			<ArrowIcon direction={props.direction} />
		</button>
	);
}

function ArrowIcon(props: { direction: ArrowDirection }) {
	if (props.direction === "right") {
		return (
			<svg
				style={{ transform: "translateX(1px)" }}
				xmlns="http://www.w3.org/2000/svg"
				width="24"
				height="24"
				viewBox="0 0 24 24"
			>
				<path
					d="M9.31 6.71c-.39.39-.39 1.02 0 1.41L13.19 12l-3.88 3.88c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0l4.59-4.59c.39-.39.39-1.02 0-1.41L10.72 6.7c-.38-.38-1.02-.38-1.41.01z"
					fill="white"
				/>
			</svg>
		);
	} else if (props.direction === "left") {
		return (
			<svg
				style={{ transform: "translateX(-1px)" }}
				xmlns="http://www.w3.org/2000/svg"
				width="24"
				height="24"
				viewBox="0 0 24 24"
			>
				<path
					d="M14.91 6.71c-.39-.39-1.02-.39-1.41 0L8.91 11.3c-.39.39-.39 1.02 0 1.41l4.59 4.59c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L11.03 12l3.88-3.88c.38-.39.38-1.03 0-1.41z"
					fill="white"
				/>
			</svg>
		);
	} else {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="24"
				height="24"
				viewBox="0 0 24 24"
			>
				<path
					d="M9 16.2l-3.5-3.5c-.39-.39-1.01-.39-1.4 0-.39.39-.39 1.01 0 1.4l4.19 4.19c.39.39 1.02.39 1.41 0L20.3 7.7c.39-.39.39-1.01 0-1.4-.39-.39-1.01-.39-1.4 0L9 16.2z"
					fill="white"
				/>
			</svg>
		);
	}
}
