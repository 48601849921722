import { AnyVelavuEvent } from "velavu-js-api";

export function compareEventAsc(
	event1: AnyVelavuEvent,
	event2: AnyVelavuEvent,
) {
	return (
		new Date(event1.timestamp).getTime() -
		new Date(event2.timestamp).getTime()
	);
}

export function compareEventDesc(
	event1: AnyVelavuEvent,
	event2: AnyVelavuEvent,
) {
	return -compareEventAsc(event1, event2);
}
