// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.pCuIn0a96gCheM4aJgq3{font-weight:500;font-size:24px}.ysmpGe1kz5VOFr3H64Cm{font-weight:500;font-size:20px}.NMON5LIh3QhPwW1HRE_g{font-weight:500;font-size:12px}.S2O6wsbwaFwIuu77Plfm{font-size:18px}.fSb9RN7MeDt1taxtlbYp{font-size:18px;font-weight:500}.DauBxHdeWWmGKNugUomj{font-size:16px}.mr4hMfAv5xCYYzUwfNL0{font-size:16px;font-weight:500}.HgS5ZfkYufqrjuss88Rh{font-size:14px}.cN9Ap9aDPHSn5tQ8pVXf{font-size:14px;font-weight:500}.Jo7aGOSCCQlG5GQ6B8qF{font-size:14px;font-weight:400}.ZkLw6LHV4kCjhBUt9hwO{font-size:12px}.LCeJbJaa_ztfo7SMavf6{font-size:12px;font-weight:500}.fiikw5Zmx1nHkQjiWc2l{display:flex;flex-direction:column;gap:16px;padding:20px}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/components/dashboard/dropdown/geofence-create/geofence-create.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,YAAA,CACA,qBAAA,CACA,QAAA,CAEA,YAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `pCuIn0a96gCheM4aJgq3`;
export var h2 = `ysmpGe1kz5VOFr3H64Cm`;
export var h3 = `NMON5LIh3QhPwW1HRE_g`;
export var p0 = `S2O6wsbwaFwIuu77Plfm`;
export var p0m = `fSb9RN7MeDt1taxtlbYp`;
export var p1 = `DauBxHdeWWmGKNugUomj`;
export var p1m = `mr4hMfAv5xCYYzUwfNL0`;
export var p2 = `HgS5ZfkYufqrjuss88Rh`;
export var p2m = `cN9Ap9aDPHSn5tQ8pVXf`;
export var p2n = `Jo7aGOSCCQlG5GQ6B8qF`;
export var p3 = `ZkLw6LHV4kCjhBUt9hwO`;
export var p3m = `LCeJbJaa_ztfo7SMavf6`;
export var container = `fiikw5Zmx1nHkQjiWc2l`;
export default ___CSS_LOADER_EXPORT___;
