import React, { CSSProperties } from "react";

export default function IconRule(props: {
	className?: string;
	style?: CSSProperties;
	color?: string;
	size?: number;
}) {
	return (
		<svg
			className={props.className}
			style={props.style}
			xmlns="http://www.w3.org/2000/svg"
			width={props.size ?? 24}
			height={props.size ?? 24}
			viewBox="0 0 24 24"
		>
			<path
				d="M4 5C4 3.89543 4.89543 3 6 3H18C19.1046 3 20 3.89543 20 5V14.5279C20 16.043 19.144 17.428 17.7889 18.1056L12.8944 20.5528C12.3314 20.8343 11.6686 20.8343 11.1056 20.5528L6.21114 18.1056C4.85601 17.428 4 16.043 4 14.5279L4 5Z"
				fill={props.color ?? "black"}
			/>
			<rect x="7" y="7" width="10" height="2" rx="1" fill="white" />
			<rect x="7" y="11" width="10" height="2" rx="1" fill="white" />
		</svg>
	);
}
