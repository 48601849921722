import React, { CSSProperties } from "react";
import * as styles from "./editor-tool.module.scss";

type ButtonSize = "medium" | "small";

interface EditorToolProps {
	icon: (size: number, color: string) => React.ReactNode;
	label: string;
	size: ButtonSize;

	active?: boolean;
	disabled?: boolean;
	onClick?: VoidFunction;

	style?: CSSProperties;
	className?: string;
}

export default function EditorTool(props: EditorToolProps) {
	return (
		<button
			style={props.style}
			className={`${styles.container} ${
				props.size === "medium" ? styles.widthMedium : styles.widthSmall
			} ${props.active ? styles.active : ""} ${props.className ?? ""}`}
			disabled={props.disabled}
			onClick={props.onClick}
		>
			{props.icon(24, props.active ? "#3A58E2" : "#00255D")}
			<span className={styles.label}>{props.label}</span>
		</button>
	);
}
