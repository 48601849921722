import React, { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
	AssetCategory,
	MODIFY_SPECIFIC_ASSET_UNPAIR_DEVICE_ID,
	VelavuAPI,
	VelavuAsset,
	VelavuDevice,
} from "velavu-js-api";
import UnpairAssetTag from "../../data/unpair-asset-tag";
import { useVelavuModal } from "../../elements/velavu-modal";
import VelavuTabSwitcher from "../../elements/velavu-tab-switcher";
import * as styles from "./detail-modal.module.scss";
import AssetLink from "./link/asset-link";
import TabAsset from "./tab-asset";
import TabTag from "./tab-tag";
import UnpairModal from "./unpair-modal";

enum DetailModalTab {
	Asset,
	Device,
}

interface DetailModalProps {
	asset?: VelavuAsset;
	device?: VelavuDevice;
	deviceOnly?: boolean;
	onUnpair?: (type: UnpairAssetTag) => void;
	onPair?: (data: UnpairAssetTag) => void;
	onUpdateAsset?: (
		assetID: string,
		name: string,
		category: AssetCategory,
		group: string,
		notes: string,
	) => void;

	defaultTab?: "asset" | "device";
	defaultOpenPair?: undefined | "asset" | "tag";
}

export default function DetailModal(props: DetailModalProps) {
	const [asset, setAsset] = useState(props.asset);
	const [device, setTag] = useState(props.device);

	const [selectedTab, setSelectedTab] = useState(
		props.defaultTab === "device"
			? DetailModalTab.Device
			: DetailModalTab.Asset,
	);

	const [labels] = useState(
		!props.deviceOnly
			? {
					[DetailModalTab.Asset]: "Asset",
					[DetailModalTab.Device]: "Device",
			  }
			: {
					[DetailModalTab.Asset]: null,
					[DetailModalTab.Device]: "Device",
			  },
	);

	const { onPair: propsOnPair, onUnpair: propsOnUnpair } = props;

	const pushModal = useVelavuModal();
	const unpair = useCallback(
		(data: UnpairAssetTag) => {
			//Push a conformation modal before unpairing
			pushModal((resolve) => (
				<UnpairModal
					data={data}
					onConfirm={() => resolve(true)}
					onCancel={() => resolve(false)}
				/>
			)).then((result) => {
				if (!result) return;

				//Updating the remote data
				VelavuAPI.assets
					.modifySpecificAsset(data.asset.id, {
						device_id: MODIFY_SPECIFIC_ASSET_UNPAIR_DEVICE_ID,
					})
					.then(() => {
						//Updating the state
						if (data.type === "asset") {
							setAsset(undefined);
						} else {
							setTag(undefined);
						}

						//Passing the event to the parent
						propsOnUnpair?.(data);
					})
					.catch((error) => console.log(error.response));
			});
		},
		[pushModal, propsOnUnpair],
	);
	const pair = useCallback(
		(data: UnpairAssetTag) => {
			//Updating the remote data
			VelavuAPI.assets
				.modifySpecificAsset(data.asset.id, {
					device_id: data.tag.id,
				})
				.then(() => {
					//Updating the state
					if (data.type === "asset") {
						setAsset(data.asset);
					} else {
						setTag(data.tag);
					}

					//Passing the event to the parent
					propsOnPair?.(data);
				})
				.catch((error) => console.log(error.response));
		},
		[propsOnPair],
	);

	const navigate = useNavigate();
	const propsAsset = props.asset;
	const propsDevice = props.device;
	const navigateAssetOnMap = useMemo((): undefined | (() => void) => {
		if (propsAsset === undefined || propsDevice === undefined) {
			return undefined;
		}

		return () => {
			navigate("/", { state: { deviceID: propsDevice.id } });
		};
	}, [propsAsset, propsDevice, navigate]);

	return (
		<div className={styles.container}>
			<AssetLink
				asset={asset}
				device={device}
				deviceOnly={props.deviceOnly}
				onUnpair={unpair}
				onPair={pair}
				defaultOpenPair={props.defaultOpenPair}
				onClickAssetName={navigateAssetOnMap}
			/>
			<VelavuTabSwitcher
				selectedKey={selectedTab}
				onSelectKey={setSelectedTab}
				labels={labels}
			>
				<div className={styles.content}>
					{selectedTab === DetailModalTab.Asset ? (
						asset ? (
							<TabAsset
								asset={asset}
								onUpdate={(name, category, group, notes) => {
									//Updating the local asset
									setAsset({
										...asset,
										name: name,
										category: category,
										group: group,
										//notes: notes
									});

									//Forwarding the event to the parent
									props.onUpdateAsset?.(
										asset!.id,
										name,
										category,
										group,
										notes,
									);
								}}
								onUpdateImage={(url?: string) =>
									setAsset({ ...asset, profile_img_url: url })
								}
							/>
						) : (
							<TabBlank label="No asset paired" />
						)
					) : device ? (
						<TabTag tag={device} onClickMap={navigateAssetOnMap} />
					) : (
						<TabBlank label="No tag paired" />
					)}
				</div>
			</VelavuTabSwitcher>
		</div>
	);
}

function TabBlank(props: { label: string }) {
	return (
		<div className={styles.tabBlank}>
			<span>{props.label}</span>
		</div>
	);
}
