import { confirmSignIn } from "aws-amplify/auth";
import React, { FormEvent, useCallback, useState } from "react";
import {
	minPasswordLength,
	passwordRequirementsNotice,
} from "../../../constants";
import VelavuButton from "../../../elements/velavu-button";
import VelavuInput from "../../../elements/velavu-input";
import VelavuNotice from "../../../elements/velavu-notice";
import SignInContext from "../../../sign-in-context";
import AuthenticationButtonBar from "../authentication-button-bar";
import * as onboardingStyles from "../authentication.module.scss";

export default function Challenge() {
	const signInDetails = React.useContext(SignInContext);

	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");

	const [errorText, setErrorText] = useState<string | undefined>(undefined);

	const [isLoading, setLoading] = useState(false);

	const inputValid =
		password.length >= minPasswordLength && password === confirmPassword;

	const submitChallenge = useCallback(
		(event: FormEvent<HTMLFormElement>) => {
			event.preventDefault();

			//Ignoring if the user hasn't properly completed the form
			if (!inputValid) return;

			setLoading(true);

			confirmSignIn({
				challengeResponse: password,
			})
				.then((user) => {})
				.catch((error) => {
					console.log(error.response);
					setErrorText(error.message);
				});
		},
		[inputValid, setLoading, password, setErrorText],
	);

	//If we don't have any state, redirect back to the login screen
	/* if (!user) {
		return <Redirect to="/login" />;
	} */

	return (
		<form onSubmit={submitChallenge}>
			<p className={onboardingStyles.instructionsText}>
				Please update your password
			</p>

			<VelavuInput
				value={password}
				onChange={setPassword}
				placeholder="Password"
				type="password"
				autoComplete="new-password"
				disabled={isLoading}
				fullWidth
				bottomPadding
			/>
			<VelavuInput
				value={confirmPassword}
				onChange={setConfirmPassword}
				placeholder="Confirm password"
				type="password"
				autoComplete="new-password"
				disabled={isLoading}
				fullWidth
				bottomPadding
			/>

			{errorText ? (
				<VelavuNotice
					className={onboardingStyles.noticeMargin}
					type="error"
					body={errorText}
				/>
			) : (
				<VelavuNotice
					className={onboardingStyles.noticeMargin}
					type="info"
					body={passwordRequirementsNotice}
				/>
			)}

			<AuthenticationButtonBar
				primary={
					<VelavuButton
						label="Update password"
						disabled={isLoading || !inputValid}
						fullWidth
						submit
					/>
				}
			/>
		</form>
	);
}
