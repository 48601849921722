import React from "react";
import { VelavuSite } from "velavu-js-api";
import IconClose from "../../dynamicicons/icon-close";
import IconFilterList from "../../dynamicicons/icon-filter-list";
import IconSearch from "../../dynamicicons/icon-search";
import VelavuIconButton from "../../elements/velavu-icon-button";
import VelavuInput from "../../elements/velavu-input";
import * as styles from "./assets-navigation-header.module.scss";
import SelectorSite from "./selector-site";

export default function AssetsNavigationHeader(props: {
	isSearching: boolean;
	openSearch?: VoidFunction;
	finishSearch?: VoidFunction;
	searchText: string;
	setSearchText: (searchText: string) => void;

	selectedSite?: VelavuSite | undefined;
	onOpenSiteSelector?: VoidFunction;
	selectedFloor?: number | undefined;
	onSelectFloor?: (floorNum: number | undefined) => void;

	isFiltering: boolean;
	isShowFilterMenu: boolean;
	onToggleFilterMenu: VoidFunction;
}) {
	const {
		finishSearch,
		isFiltering,
		isSearching,
		onOpenSiteSelector,
		onSelectFloor,
		onToggleFilterMenu,
		openSearch,
		searchText,
		selectedFloor,
		selectedSite,
		setSearchText,
		isShowFilterMenu,
	} = props;
	const filterColor = isFiltering ? "#3A58E2" : "#5F718C";

	return (
		<div className={styles.header}>
			{isSearching ? (
				<VelavuInput
					className={styles.search}
					leadingIcon={<IconSearch size={16} color="#00255D" />}
					trailingIcon={
						finishSearch !== undefined ? (
							<VelavuIconButton
								reallySmall
								onClick={finishSearch}
							>
								<IconClose size={16} color="#A3B3CC" />
							</VelavuIconButton>
						) : undefined
					}
					placeholder="Search assets"
					value={searchText}
					onChange={setSearchText}
					fullWidth
				/>
			) : (
				<>
					<SelectorSite
						style={{ flexGrow: 1, marginRight: 16 }}
						site={selectedSite}
						onClick={onOpenSiteSelector}
						floorNum={selectedFloor}
						onClickFloor={onSelectFloor}
					/>
					{openSearch !== undefined && (
						<VelavuIconButton
							style={{ marginRight: 8 }}
							onClick={openSearch}
						>
							<IconSearch color="#5F718C" />
						</VelavuIconButton>
					)}
				</>
			)}
			<VelavuIconButton
				highlight={isFiltering}
				onClick={onToggleFilterMenu}
			>
				{isShowFilterMenu ? (
					<IconClose color={filterColor} />
				) : (
					<IconFilterList color={filterColor} />
				)}
			</VelavuIconButton>
		</div>
	);
}
