import React from "react";
import * as styles from "./event-list.module.scss";
import { AnyVelavuEvent } from "velavu-js-api";
import EventCard from "./event-card";
import { classArr } from "../helper/style-helper";

export interface EventListProps {
	className?: string;
	events: AnyVelavuEvent[] | undefined;
	includeEventOrigin?: boolean;
}

/**
 * Renders a list of event cards
 */
export default function EventList(props: EventListProps) {
	const events = props.events;

	return (
		<div className={classArr(props.className, styles.listScroll)}>
			{events === undefined && (
				<div className={styles.empty}>
					<span>Checking events…</span>
				</div>
			)}

			{events !== undefined && events.length === 0 && (
				<div className={styles.empty}>
					<span>No events</span>
				</div>
			)}

			{events?.map((event) => (
				<EventCard
					key={event.id}
					event={event}
					includeEventOrigin={props.includeEventOrigin}
				/>
			))}
		</div>
	);
}
