// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.C2_0cRDpquoessAsBwyg{font-weight:500;font-size:24px}.F2rpWte8MmgWbTk7Qw_9{font-weight:500;font-size:20px}.evBsyBA23NFcoMlxJ4HA{font-weight:500;font-size:12px}.ortrsiSJ8eD7ajNmTmbE{font-size:18px}.nGe_PEGy40QpMIIQxlSb{font-size:18px;font-weight:500}.RMjIl0XfvNxVL8cZjDeg{font-size:16px}.Fyhjp2I0vtQ0YC_7U83X{font-size:16px;font-weight:500}.CegUzCTq7TriAf4kjL8p{font-size:14px}.JfVmNj1t9_cC21qexrXX{font-size:14px;font-weight:500}.ziXrkEeimzhuJ_auPiMo{font-size:14px;font-weight:400}.t2pU1Vqmd0zv9Nh7Z_zJ{font-size:12px}.kIi1f4F4spKyu557JmGE{font-size:12px;font-weight:500}.zjFY_1CZjjlGZ0Gvslr6{width:150px;height:119px;border-radius:12px;border:1px solid #3a58e2;background-color:#e5eaf0;display:flex;justify-content:center;align-items:center}.Rx73tzmPJK8bUMEPpTh_{display:flex;flex-direction:row;align-items:center;margin:20px}.auMZ0cDcrlFyF7e4hiwS{display:flex;flex-direction:column;align-items:center}.JqqLZRRDsE82IHTQPUNG{color:#3a58e2;padding:0 0 4px 0;font-size:28px;font-weight:500}.eGl2TQOtsWzpRH_oIqeg{color:#00255d;font-size:14px;font-weight:500}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/components/dashboard/dropdown/asset-detail/asset-environment.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,WAAA,CACA,YAAA,CACA,kBAAA,CACA,wBAAA,CACA,wBDSY,CCPZ,YAAA,CACA,sBAAA,CACA,kBAAA,CAGD,sBACC,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,WAAA,CAGD,sBACC,YAAA,CACA,qBAAA,CACA,kBAAA,CAGD,sBACC,aDrBS,CCuBT,iBAAA,CACA,cAAA,CACA,eAAA,CAGD,sBACC,aD1BW,CC4BX,cAAA,CACA,eAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `C2_0cRDpquoessAsBwyg`;
export var h2 = `F2rpWte8MmgWbTk7Qw_9`;
export var h3 = `evBsyBA23NFcoMlxJ4HA`;
export var p0 = `ortrsiSJ8eD7ajNmTmbE`;
export var p0m = `nGe_PEGy40QpMIIQxlSb`;
export var p1 = `RMjIl0XfvNxVL8cZjDeg`;
export var p1m = `Fyhjp2I0vtQ0YC_7U83X`;
export var p2 = `CegUzCTq7TriAf4kjL8p`;
export var p2m = `JfVmNj1t9_cC21qexrXX`;
export var p2n = `ziXrkEeimzhuJ_auPiMo`;
export var p3 = `t2pU1Vqmd0zv9Nh7Z_zJ`;
export var p3m = `kIi1f4F4spKyu557JmGE`;
export var box = `zjFY_1CZjjlGZ0Gvslr6`;
export var container = `Rx73tzmPJK8bUMEPpTh_`;
export var headers = `auMZ0cDcrlFyF7e4hiwS`;
export var ratingHeader = `JqqLZRRDsE82IHTQPUNG`;
export var textHeader = `eGl2TQOtsWzpRH_oIqeg`;
export default ___CSS_LOADER_EXPORT___;
