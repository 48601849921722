import React, { CSSProperties } from "react";

export default function IconDeviceError(props: {
	className?: string;
	style?: CSSProperties;
	color?: string;
	size?: number;
}) {
	return (
		<svg
			className={props.className}
			style={props.style}
			xmlns="http://www.w3.org/2000/svg"
			width={props.size ?? 24}
			height={props.size ?? 24}
			viewBox="0 0 24 24"
		>
			<path
				d="M22 10.3A6 6 0 0 0 13.7 2 6 6 0 0 0 11 6a6 6 0 0 0 4.9 7 6 6 0 0 0 6-2.7ZM16 4a1 1 0 1 1 2 0v3a1 1 0 1 1-2 0V4Zm1 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z"
				fill={props.color ?? "black"}
			/>

			<path
				d="M8 2h3.4c-.5.6-1 1.3-1.3 2H8a4 4 0 0 0-4 4v8a4 4 0 0 0 4 4h8a4 4 0 0 0 4-4v-2.1c.7-.3 1.4-.8 2-1.3V16a6 6 0 0 1-6 6H8a6 6 0 0 1-6-6V8a6 6 0 0 1 6-6Z"
				fill={props.color ?? "black"}
			/>

			<path
				d="M18 16v-1.6a7.6 7.6 0 0 1-2 0V16H8V8h1.6a7.6 7.6 0 0 1 0-2H8a2 2 0 0 0-2 2v8c0 1.1.9 2 2 2h8a2 2 0 0 0 2-2Z"
				fill={props.color ?? "black"}
			/>
		</svg>
	);
}
