// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.sfRvWGB0gn8Y5srMEsU9{font-weight:500;font-size:24px}.dAj_QJfoHxix4htHHt8n{font-weight:500;font-size:20px}.HRZwoWDZQgE7Oaycdo7N{font-weight:500;font-size:12px}.lalpn0si2ywEFDSXYq3b{font-size:18px}.UqAyB4SGy6ABIMF9g27N{font-size:18px;font-weight:500}.J1OsFYaDqWKmirNnwzXI{font-size:16px}.UkUoHtgc1eW_TJhHtayO{font-size:16px;font-weight:500}.J5jrWVogU0948vmWq64Q{font-size:14px}.SC5GqvRcElZ3WzfLnw2P{font-size:14px;font-weight:500}.Z92FTi1Bc0YtP0jvbXnQ{font-size:14px;font-weight:400}.Y72nPgyrsk8qXKMsgYcw{font-size:12px}.wHo3h_cQqtWdVnMZe0_x{font-size:12px;font-weight:500}.XxZAVgAm0N4JyH446d8c{padding:20px;display:flex;flex-direction:column}.eSIA5pJMBAF9acwwptM9{align-self:flex-end}textarea{overflow:hidden;margin-bottom:20px;padding:11px;max-height:190px;border-color:#e2e4e5;border-radius:8px;color:#00255d}textarea:focus{filter:drop-shadow(0px 0px 2px rgba(40, 41, 61, 0.04)) drop-shadow(0px 4px 8px rgba(96, 97, 112, 0.16))}span.nlrGRGzEzvHRaWO0fLh8{color:#5f718c;margin-bottom:5px}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/components/asset-tag-detail/tab-asset.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,YAAA,CAEA,YAAA,CACA,qBAAA,CAGD,sBACC,mBAAA,CAGD,SACC,eAAA,CACA,kBAAA,CACA,YAAA,CACA,gBAAA,CACA,oBAAA,CACA,iBAAA,CACA,aDRc,CCUd,eACC,uGAAA,CAKF,0BACC,aDhBgB,CCiBhB,iBAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `sfRvWGB0gn8Y5srMEsU9`;
export var h2 = `dAj_QJfoHxix4htHHt8n`;
export var h3 = `HRZwoWDZQgE7Oaycdo7N`;
export var p0 = `lalpn0si2ywEFDSXYq3b`;
export var p0m = `UqAyB4SGy6ABIMF9g27N`;
export var p1 = `J1OsFYaDqWKmirNnwzXI`;
export var p1m = `UkUoHtgc1eW_TJhHtayO`;
export var p2 = `J5jrWVogU0948vmWq64Q`;
export var p2m = `SC5GqvRcElZ3WzfLnw2P`;
export var p2n = `Z92FTi1Bc0YtP0jvbXnQ`;
export var p3 = `Y72nPgyrsk8qXKMsgYcw`;
export var p3m = `wHo3h_cQqtWdVnMZe0_x`;
export var container = `XxZAVgAm0N4JyH446d8c`;
export var button = `eSIA5pJMBAF9acwwptM9`;
export var label = `nlrGRGzEzvHRaWO0fLh8`;
export default ___CSS_LOADER_EXPORT___;
