// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.wEplZZG5dhTRgOF7Ao9w{font-weight:500;font-size:24px}.MtBz5cQEKU3LyYEfTd1A{font-weight:500;font-size:20px}.NLsY33_zwLMpE7otZEgC{font-weight:500;font-size:12px}.eKINavhjeAOptdYixdMR{font-size:18px}.KORe0grEoSSKBdfH54Cr{font-size:18px;font-weight:500}.d5OPvcpydNoXUAvREZFW{font-size:16px}.RdzBZr68FZsAJFS67G40{font-size:16px;font-weight:500}.XMbHkRF8KeuoEQsafGwJ{font-size:14px}.Pb62LIzGrafQs8jgVnQd{font-size:14px;font-weight:500}.Wpf2rpds8xBPiz3SnvOQ{font-size:14px;font-weight:400}.VvRTsq_LqQEaMpwu33wV,.BlN_AFr70Zv0mquJk2eT{font-size:12px}.UlzQjODz_YpvxkIFt1mf{font-size:12px;font-weight:500}.BlN_AFr70Zv0mquJk2eT{display:flex;align-items:center;justify-content:center;height:48px;color:#5f718c}._2WjwAKrL1VFR8HmsYk9{width:200px;height:160px;margin:auto;text-align:center}._2WjwAKrL1VFR8HmsYk9 svg{display:block;margin:auto;transform:translateY(20px)}._2WjwAKrL1VFR8HmsYk9 .oQrapr6UyApCoSF2Udq7{font-size:16px;font-weight:500;display:block;width:100%}._2WjwAKrL1VFR8HmsYk9 .UxtJplxZUVuKFrfaGNsp{font-size:14px;font-weight:400;display:block;width:100%;color:#5f718c}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/components/dashboard/dropdown/asset-detail/asset-routes.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,4CACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,WAAA,CAGA,aDIgB,CCDjB,sBACC,WAAA,CACA,YAAA,CACA,WAAA,CACA,iBAAA,CAEA,0BACC,aAAA,CACA,WAAA,CACA,0BAAA,CAGD,4CACC,cAAA,CACA,eAAA,CACA,aAAA,CACA,UAAA,CAGD,4CACC,cAAA,CACA,eAAA,CACA,aAAA,CACA,UAAA,CACA,aAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `wEplZZG5dhTRgOF7Ao9w`;
export var h2 = `MtBz5cQEKU3LyYEfTd1A`;
export var h3 = `NLsY33_zwLMpE7otZEgC`;
export var p0 = `eKINavhjeAOptdYixdMR`;
export var p0m = `KORe0grEoSSKBdfH54Cr`;
export var p1 = `d5OPvcpydNoXUAvREZFW`;
export var p1m = `RdzBZr68FZsAJFS67G40`;
export var p2 = `XMbHkRF8KeuoEQsafGwJ`;
export var p2m = `Pb62LIzGrafQs8jgVnQd`;
export var p2n = `Wpf2rpds8xBPiz3SnvOQ`;
export var p3 = `VvRTsq_LqQEaMpwu33wV`;
export var empty = `BlN_AFr70Zv0mquJk2eT`;
export var p3m = `UlzQjODz_YpvxkIFt1mf`;
export var emptyContent = `_2WjwAKrL1VFR8HmsYk9`;
export var heading = `oQrapr6UyApCoSF2Udq7`;
export var info = `UxtJplxZUVuKFrfaGNsp`;
export default ___CSS_LOADER_EXPORT___;
