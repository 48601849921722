import { signIn } from "aws-amplify/auth";
import React, { FormEvent, useCallback, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LoadingDots from "../../../elements/loading-dots";
import VelavuButton from "../../../elements/velavu-button";
import VelavuInput from "../../../elements/velavu-input";
import VelavuNotice from "../../../elements/velavu-notice";
import SignInContext from "../../../sign-in-context";
import AuthenticationButtonBar from "../authentication-button-bar";
import * as onboardingStyles from "../authentication.module.scss";
import * as styles from "./log-in.module.scss";

const errorUsername = "UserNotFoundException";
const errorPassword = "NotAuthorizedException";

export default function LogIn() {
	const signInDetails = React.useContext(SignInContext);

	const navigate = useNavigate();

	const urlParams = useMemo(
		() => new URLSearchParams(window.location.search),
		[],
	);
	const [email, setEmail] = useState(urlParams.get("username") ?? "");
	const [password, setPassword] = useState(urlParams.get("password") ?? "");

	const [errorText, setErrorText] = useState<string | undefined>(undefined);
	const [errorHighlightCode, setErrorHighlightCode] = useState<
		string | undefined
	>(undefined);

	const [isLoading, setLoading] = useState(false);

	const submitLogIn = useCallback(
		(event: FormEvent<HTMLFormElement>) => {
			event.preventDefault();

			//Ignoring if the user hasn't properly entered their username and password
			if (!(email && password)) return;

			setLoading(true);

			signIn({
				username: email,
				password: password,
			})
				.then((output) => {
					if (
						output.nextStep.signInStep ===
						"CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED"
					) {
						navigate("/login/challenge");
					}
				})
				.catch((error) => {
					console.log(error.response);

					setErrorText(error.message);
					setErrorHighlightCode(error.code);
					setLoading(false);
				});
		},
		[email, password, navigate, setErrorText, setErrorHighlightCode],
	);

	return (
		<form onSubmit={submitLogIn}>
			<p className={onboardingStyles.instructionsText}>
				Welcome back, please log in to your account
			</p>

			<VelavuInput
				value={email}
				onChange={setEmail}
				placeholder="Email address"
				autoComplete="email"
				error={errorHighlightCode === errorUsername}
				disabled={isLoading}
				fullWidth
				bottomPadding
			/>
			<VelavuInput
				value={password}
				onChange={setPassword}
				placeholder="Password"
				type="password"
				autoComplete="current-password"
				error={errorHighlightCode === errorPassword}
				disabled={isLoading}
				fullWidth
				bottomPadding
			/>

			{errorText && (
				<VelavuNotice
					className={onboardingStyles.noticeMargin}
					type="error"
					body={errorText}
				/>
			)}

			<AuthenticationButtonBar
				primary={
					<VelavuButton
						label="Log in"
						disabled={isLoading || !(email && password)}
						fullWidth
						submit
						loadingIndicator={isLoading}
					/>
				}
				secondary={
					<Link className={styles.link} to="/login/recover">
						Forgot password?
					</Link>
				}
			/>
		</form>
	);
}
