import React, { CSSProperties, useCallback } from "react";
import * as styles from "./discrete-input.module.scss";
import { classArr } from "../helper/style-helper";

export interface DiscreteInputProps {
	style?: CSSProperties;
	className?: string;

	value?: string;
	onChange?: (value: string) => void;
	onFocus?: () => void;
	onBlur?: () => void;
	onMouseEnter?: () => void;
	onMouseLeave?: () => void;

	placeholder?: string;
	type?: "text" | "password" | "search" | "number";

	error?: boolean;
	reveal?: boolean;
}

/**
 * A compact text input component that can blend in as a
 * regular text label when not in use
 */
export default function DiscreteInput(props: DiscreteInputProps) {
	const propsOnChange = props.onChange;
	const onChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			propsOnChange?.(event.target.value);
		},
		[propsOnChange],
	);

	return (
		<input
			style={props.style}
			className={classArr(
				styles.input,
				props.error && styles.inputError,
				props.reveal && styles.inputReveal,
				props.className,
			)}
			type={props.type ?? "text"}
			placeholder={props.placeholder}
			value={props.value}
			onChange={onChange}
			onFocus={props.onFocus}
			onBlur={props.onBlur}
			onMouseEnter={props.onMouseEnter}
			onMouseLeave={props.onMouseLeave}
		/>
	);
}
