import React, {
	Dispatch,
	SetStateAction,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react";
import { useLocation } from "react-router-dom";
import {
	DeviceCategory,
	EventCategory,
	MeasurementSystem,
	VelavuAPI,
	VelavuDevice,
	VelavuSite,
} from "velavu-js-api";
import AnalyticsStatType from "../../data/analytics-stat-type";
import { useDateRange } from "../../data/date-range";
import { VelavuModalProvider } from "../../elements/velavu-modal";
import { useRemoteSync, useSites } from "../../helper/api-helper";
import { useCancellableEffect } from "../../helper/hook-helper";
import { classArr } from "../../helper/style-helper";
import { formatTemperature } from "../../helper/unit-helper";
import SignInContext from "../../sign-in-context";
import {
	AnchorsBats,
	AnchorsHums,
	AnchorsTemps,
	AssetsBats,
	AssetsHums,
	AssetsTemps,
	LoadingGraph,
} from "./analytics-graph";
import AnalyticsMap, { LoadingMap } from "./analytics-map";
import AnalyticsSidebar, { LoadingSidebar } from "./analytics-sidebar";
import AnalyticsStatusBar from "./analytics-status-bar";
import styles from "./analytics.module.scss";

enum Tabs {
	assets,
	anchors,
}

export interface AnalyticsLocationState {
	assetID?: string;
}

// Could be used to show 'Please wait...' elements
function Loading() {
	const [statType, setStatType] = useState(AnalyticsStatType.Temperature);

	return (
		<VelavuModalProvider>
			<div className={styles.page}>
				<div className={classArr(styles.card, styles.sidebar)}>
					<LoadingSidebar />
				</div>
				<div className={styles.content}>
					<div className={styles.card}>
						<AnalyticsStatusBar
							type={statType}
							onChangeType={setStatType}
							selectionCount={0}
						/>
					</div>

					{statType !== AnalyticsStatType.Location && (
						<div className={classArr(styles.card, styles.cardData)}>
							<LoadingGraph stat={statType} />
						</div>
					)}

					<div className={classArr(styles.card, styles.cardData)}>
						<LoadingMap stat={statType} />
					</div>
				</div>
			</div>
		</VelavuModalProvider>
	);
}

function Loaded(props: {
	sites: VelavuSite[];
	assets: VelavuDevice[];
	anchors: VelavuDevice[];
	selectedSiteID?: string;
	setSelectedSiteID: Dispatch<SetStateAction<string | undefined>>;
}) {
	const location = useLocation<AnalyticsLocationState | undefined>();

	const userContext = useContext(SignInContext);
	const userPreferences = userContext.preferences;
	const userMeasurementSystem = userPreferences.measurementSystem;

	const [statType, setStatType] = useState(AnalyticsStatType.Temperature);
	const [selectedTab, setSelectedTab] = useState<Tabs>(Tabs.assets);
	const [selectedAssetIDs, setSelectedAssetIDs] = useState<string[]>(() => {
		const state = location.state;
		if (state?.assetID !== undefined) {
			return [state.assetID];
		} else {
			return [];
		}
	});
	const [selectedAnchorIDs, setSelectedAnchorIDs] = useState<string[]>([]);
	const [range, setRange] = useDateRange(7);

	const batteryDataAnchors = useRemoteSync(
		selectedAnchorIDs,
		range,
		(deviceID, range) =>
			VelavuAPI.events
				.getAllEvents({
					deviceID: deviceID,
					category: EventCategory.BatteryLevel,
					since: range.start,
					until: range.end,
				})
				.then((result) => result.data),
	);

	const environmentDataAnchors = useRemoteSync(
		selectedAnchorIDs,
		range,
		(deviceID, range) =>
			VelavuAPI.events
				.getAllEvents({
					deviceID: deviceID,
					category: EventCategory.Environment,
					since: range.start,
					until: range.end,
				})
				.then((result) => result.data),
	);

	const batteryDataAssets = useRemoteSync(
		selectedAssetIDs,
		range,
		(assetID, range) =>
			VelavuAPI.events
				.getAllEvents({
					assetID: assetID,
					category: EventCategory.BatteryLevel,
					since: range.start,
					until: range.end,
				})
				.then((result) => result.data),
	);

	const environmentDataAssets = useRemoteSync(
		selectedAssetIDs,
		range,
		(assetID, range) =>
			VelavuAPI.events
				.getAllEvents({
					assetID: assetID,
					category: EventCategory.Environment,
					since: range.start,
					until: range.end,
				})
				.then((result) => result.data),
	);

	let graph;
	if (selectedTab === Tabs.assets) {
		switch (statType) {
			case AnalyticsStatType.Temperature:
				graph = (
					<AssetsTemps
						entries={props.assets.map((a) => a.asset!)}
						ids={selectedAssetIDs}
						dateRange={range}
						measurementSystem={userMeasurementSystem}
						setDateRange={setRange}
						env={environmentDataAssets}
					/>
				);
				break;
			case AnalyticsStatType.Humidity:
				graph = (
					<AssetsHums
						entries={props.assets.map((a) => a.asset!)}
						ids={selectedAssetIDs}
						dateRange={range}
						setDateRange={setRange}
						env={environmentDataAssets}
					/>
				);
				break;
			case AnalyticsStatType.Battery:
				graph = (
					<AssetsBats
						entries={props.assets.map((a) => a.asset!)}
						ids={selectedAssetIDs}
						dateRange={range}
						setDateRange={setRange}
						bat={batteryDataAssets}
					/>
				);
				break;
		}
	} else {
		switch (statType) {
			case AnalyticsStatType.Temperature:
				graph = (
					<AnchorsTemps
						entries={props.anchors}
						ids={selectedAssetIDs}
						dateRange={range}
						setDateRange={setRange}
						measurementSystem={userMeasurementSystem}
						env={environmentDataAnchors}
					/>
				);
				break;
			case AnalyticsStatType.Humidity:
				graph = (
					<AnchorsHums
						entries={props.anchors}
						ids={selectedAssetIDs}
						dateRange={range}
						setDateRange={setRange}
						env={environmentDataAnchors}
					/>
				);
				break;
			case AnalyticsStatType.Battery:
				graph = (
					<AnchorsBats
						entries={props.anchors}
						ids={selectedAssetIDs}
						dateRange={range}
						setDateRange={setRange}
						bat={batteryDataAnchors}
					/>
				);
				break;
		}
	}

	const analyticsMapDevices = useMemo((): {
		assets: VelavuDevice[];
		anchors: VelavuDevice[];
	} => {
		if (selectedTab === Tabs.assets) {
			return {
				assets: props.assets.filter((asset) =>
					selectedAssetIDs.includes(asset.asset!.id),
				),
				anchors: [],
			};
		} else {
			return {
				assets: [],
				anchors: props.anchors.filter((anchor) =>
					selectedAnchorIDs.includes(anchor.id),
				),
			};
		}
	}, [
		selectedTab,
		props.assets,
		props.anchors,
		selectedAssetIDs,
		selectedAnchorIDs,
	]);

	return (
		<VelavuModalProvider>
			<div className={styles.page}>
				<div className={classArr(styles.card, styles.sidebar)}>
					<AnalyticsSidebar
						sites={props.sites}
						statType={statType}
						measurementSystem={userMeasurementSystem}
						setSelectedTab={setSelectedTab}
						assets={props.assets}
						selectedAssets={selectedAssetIDs}
						setSelectedAssets={setSelectedAssetIDs}
						anchors={props.anchors}
						selectedAnchors={selectedAnchorIDs}
						setSelectedAnchors={setSelectedAnchorIDs}
						selectedSiteID={props.selectedSiteID}
						setSelectedSiteID={props.setSelectedSiteID}
					/>
				</div>
				<div className={styles.content}>
					<div className={styles.card}>
						<AnalyticsStatusBar
							type={statType}
							onChangeType={setStatType}
							selectionCount={
								selectedTab === Tabs.assets
									? selectedAssetIDs.length
									: selectedAnchorIDs.length
							}
						/>
					</div>

					{statType !== AnalyticsStatType.Location && (
						<div className={classArr(styles.card, styles.cardData)}>
							{graph}
						</div>
					)}

					<div className={classArr(styles.card, styles.cardData)}>
						<AnalyticsMap
							sites={props.sites}
							stat={statType}
							measurementSystem={userMeasurementSystem}
							assets={analyticsMapDevices.assets}
							anchors={analyticsMapDevices.anchors}
							dateRange={range}
							setDateRange={setRange}
						/>
					</div>
				</div>
			</div>
		</VelavuModalProvider>
	);
}

export default function Analytics() {
	const [sites] = useSites();
	const [selectedSiteID, setSelectedSiteID] = useState<string>();
	const [assets, setAssets] = useState<VelavuDevice[]>();
	const [anchors, setAnchors] = useState<VelavuDevice[]>();

	useCancellableEffect(
		(addPromise) => {
			addPromise(
				VelavuAPI.devices.getAllDevices({
					siteID: selectedSiteID,
					paired: true,
				}),
			)
				.then((result) => {
					setAssets(result.data);
				})
				.catch((error) => console.log(error.response));
		},

		[selectedSiteID],
	);

	useCancellableEffect(
		(addPromise) => {
			addPromise(
				VelavuAPI.devices.getAllDevices({
					siteID: selectedSiteID,
					category: DeviceCategory.Anchor,
				}),
			)
				.then((result) => {
					setAnchors(result.data);
				})
				.catch((error) => console.log(error.response));
		},

		[selectedSiteID],
	);

	useEffect(() => {
		document.title = `Velavu | Analytics`;
	});

	if (!assets || !anchors || !sites) return <Loading />;

	return (
		<Loaded
			sites={sites}
			anchors={anchors}
			assets={assets}
			selectedSiteID={selectedSiteID}
			setSelectedSiteID={setSelectedSiteID}
		/>
	);
}

export type UnitRange = { min: number; max: number };
export function mapAnalyticsUnitRange(
	stat: AnalyticsStatType,
	measurementSystem?: MeasurementSystem,
): UnitRange | undefined {
	switch (stat) {
		case AnalyticsStatType.Temperature:
			if (measurementSystem) {
				return {
					min: Math.round(formatTemperature(15, measurementSystem)),
					max: Math.round(formatTemperature(30, measurementSystem)),
				};
			} else {
				return { min: 15, max: 30 };
			}
		case AnalyticsStatType.Humidity:
			return { min: 0, max: 100 };
		case AnalyticsStatType.Battery:
			return { min: 0, max: 100 };
		default:
			return undefined;
	}
}
