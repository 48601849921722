import React, { useMemo } from "react";
import * as styles from "./settings-billing-summary-card.module.scss";
import {
	formatBillingPeriod,
	formatBillingPrice,
} from "./settings-billing-format";
import { classArr } from "../../../helper/style-helper";

export interface SettingsBillingSummaryCardProps {
	className?: string;
	start: string;
	end: string;
	total: number;
}

export default function SettingsBillingSummaryCard(
	props: SettingsBillingSummaryCardProps,
) {
	const periodText = useMemo(
		() => formatBillingPeriod(props.start, props.end),
		[props.start, props.end],
	);

	return (
		<div className={classArr(styles.card, props.className)}>
			<span className={styles.period}>{periodText}</span>

			<span className={styles.spacer} />

			<span className={styles.toDate}>to date</span>

			<span className={styles.price}>
				{formatBillingPrice(props.total)}
			</span>
		</div>
	);
}
