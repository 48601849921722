// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.BtfEqn7QTHkq9od83OwN{font-weight:500;font-size:24px}.WKFGo9d2qYwX8XQ8iwgC{font-weight:500;font-size:20px}.GC6XLss2awpOPrs2x3BA{font-weight:500;font-size:12px}.fUmYwXSS9EKX_Oj6gqxR{font-size:18px}.D67X0dYpa_HspqQRZLES{font-size:18px;font-weight:500}.kOLvg4rEq1I24CVnLkpY{font-size:16px}.puDdWyAiMqS02_PHsE8S{font-size:16px;font-weight:500}.GIdbBv4j8_O5RBiaBwzK,.Wn25pwh5nclwjnIm0cy3,.dXkuLhcIwBAIbaRXBEcV{font-size:14px}.gcPKAqQNx0tEbrk0aNPm{font-size:14px;font-weight:500}.tXPIZENETseDDbtmmpvv{font-size:14px;font-weight:400}.BCwtpoDCk90RMlI6zxof{font-size:12px}.uVR3rhYyv9hp9hrIUCyf{font-size:12px;font-weight:500}.dXkuLhcIwBAIbaRXBEcV{color:#5f718c;text-decoration:none;display:block;width:100%;text-align:center}.Wn25pwh5nclwjnIm0cy3{display:inline-block;margin-top:8px;margin-bottom:32px;color:#5f718c}.ojvbUinE5iIL2UKMUPTF{color:#00255d;text-decoration:none;display:inline;border:none;background:none;cursor:pointer}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/components/authentication/recover/recover-reset.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,kEACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBAEC,aDSgB,CCRhB,oBAAA,CACA,aAAA,CACA,UAAA,CACA,iBAAA,CAGD,sBACC,oBAAA,CAEA,cAAA,CACA,kBAAA,CAGA,aDLgB,CCQjB,sBACC,aDVc,CCWd,oBAAA,CAEA,cAAA,CACA,WAAA,CACA,eAAA,CACA,cAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `BtfEqn7QTHkq9od83OwN`;
export var h2 = `WKFGo9d2qYwX8XQ8iwgC`;
export var h3 = `GC6XLss2awpOPrs2x3BA`;
export var p0 = `fUmYwXSS9EKX_Oj6gqxR`;
export var p0m = `D67X0dYpa_HspqQRZLES`;
export var p1 = `kOLvg4rEq1I24CVnLkpY`;
export var p1m = `puDdWyAiMqS02_PHsE8S`;
export var p2 = `GIdbBv4j8_O5RBiaBwzK`;
export var resend = `Wn25pwh5nclwjnIm0cy3`;
export var link = `dXkuLhcIwBAIbaRXBEcV`;
export var p2m = `gcPKAqQNx0tEbrk0aNPm`;
export var p2n = `tXPIZENETseDDbtmmpvv`;
export var p3 = `BCwtpoDCk90RMlI6zxof`;
export var p3m = `uVR3rhYyv9hp9hrIUCyf`;
export var resendLink = `ojvbUinE5iIL2UKMUPTF`;
export default ___CSS_LOADER_EXPORT___;
