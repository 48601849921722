// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.lbBQq7jELYbdqHVOikmZ{font-weight:500;font-size:24px}.XIm3SPGfHdddI_aADb5B{font-weight:500;font-size:20px}.hYL6L80Lkvs2XmMvpVVi{font-weight:500;font-size:12px}.NSQmRzs1apFa5OGceHIE{font-size:18px}.AQ2FA46xt9riUrxrVKPz{font-size:18px;font-weight:500}.WXKwVNeqnRBbBwT3pWSU{font-size:16px}.V0JYiaT7p4byKlJM13wA{font-size:16px;font-weight:500}.U8qyyuWLIIfyiEQ0aURE{font-size:14px}.wzLsaiw_N_pKDpHzbaGH{font-size:14px;font-weight:500}.LGxSpd8X_cjglgK7ue3y{font-size:14px;font-weight:400}.IMREFeFuePjC275t679v{font-size:12px}.o4len0OGM2Yfl5eow7nl{font-size:12px;font-weight:500}.emBuSeqcO3vTwQ58_sNX{width:100%;display:flex;align-items:center}.emBuSeqcO3vTwQ58_sNX input{position:absolute;opacity:0;cursor:default;height:0;width:0}.KiCU92hrbtqWWYnkoaFr{display:flex;align-items:center;height:16px;width:16px;border-radius:6px;border:2px solid #ccc}.uIJon4mscxfqRPldp9yV{background-color:#00255d;border:0px solid #00255d;height:20px;width:20px}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/elements/checkbox.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,UAAA,CACA,YAAA,CACA,kBAAA,CAGD,4BACC,iBAAA,CACA,SAAA,CACA,cAAA,CACA,QAAA,CACA,OAAA,CAGD,sBACC,YAAA,CACA,kBAAA,CACA,WAAA,CACA,UAAA,CACA,iBAAA,CACA,qBAAA,CAGD,sBACC,wBDhBW,CCiBX,wBAAA,CACA,WAAA,CACA,UAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `lbBQq7jELYbdqHVOikmZ`;
export var h2 = `XIm3SPGfHdddI_aADb5B`;
export var h3 = `hYL6L80Lkvs2XmMvpVVi`;
export var p0 = `NSQmRzs1apFa5OGceHIE`;
export var p0m = `AQ2FA46xt9riUrxrVKPz`;
export var p1 = `WXKwVNeqnRBbBwT3pWSU`;
export var p1m = `V0JYiaT7p4byKlJM13wA`;
export var p2 = `U8qyyuWLIIfyiEQ0aURE`;
export var p2m = `wzLsaiw_N_pKDpHzbaGH`;
export var p2n = `LGxSpd8X_cjglgK7ue3y`;
export var p3 = `IMREFeFuePjC275t679v`;
export var p3m = `o4len0OGM2Yfl5eow7nl`;
export var container = `emBuSeqcO3vTwQ58_sNX`;
export var checkbox = `KiCU92hrbtqWWYnkoaFr`;
export var checkboxChecked = `uIJon4mscxfqRPldp9yV`;
export default ___CSS_LOADER_EXPORT___;
