import React, { CSSProperties } from "react";

export default function IconParentAsset(props: {
	className?: string;
	style?: CSSProperties;
	color?: string;
	size?: number;
}) {
	return (
		<svg
			className={props.className}
			style={props.style}
			width={props.size ?? 16}
			height={props.size ?? 16}
			viewBox="0 0 16 16"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4 0C1.79086 0 0 1.79086 0 4V12C0 14.2091 1.79086 16 4 16H12C14.2091 16 16 14.2091 16 12V4C16 1.79086 14.2091 0 12 0H4ZM4 3C3.44772 3 3 3.44772 3 4C3 4.55228 3.44772 5 4 5H12C12.5523 5 13 4.55228 13 4C13 3.44772 12.5523 3 12 3H4ZM3 12C3 11.4477 3.44772 11 4 11H12C12.5523 11 13 11.4477 13 12C13 12.5523 12.5523 13 12 13H4C3.44772 13 3 12.5523 3 12ZM4 7C3.44772 7 3 7.44772 3 8C3 8.55229 3.44772 9 4 9H10C10.5523 9 11 8.55229 11 8C11 7.44772 10.5523 7 10 7H4Z"
				fill={props.color ?? "black"}
			/>
		</svg>
	);
}
