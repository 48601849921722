// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.KmBZYrR4dDgLQ_aTd7oq{font-weight:500;font-size:24px}.rt60wjUOgFeAq_u7uUxw{font-weight:500;font-size:20px}.TLGliq2e9V_NO3EESizT{font-weight:500;font-size:12px}.NXqqYvrghQ3zLRuYJ__c{font-size:18px}.uYsht5gpAflfnu5NvW2q{font-size:18px;font-weight:500}.w_OHdmzrkg4KSB2SqAQl{font-size:16px}.zPinZETe9gUWHRoMfZLz{font-size:16px;font-weight:500}.QW9oglYRxaPnW_GGlFiD{font-size:14px}.K7n_b7ymTLeuzvMJtB3h{font-size:14px;font-weight:500}.U3mEc2Lxm0KtnxkJFKh4{font-size:14px;font-weight:400}.m5Sg1x2VdjnkZoMXxKXF{font-size:12px}.ZxGqr063F9y4k2dfmlhk{font-size:12px;font-weight:500}.OtJ_zeR6KByeiGPohWgG{transition:opacity 250ms ease-in-out}.OtJ_zeR6KByeiGPohWgG._mFAlh_Bxz2ZpyrmG9FV>*{transition:transform 250ms ease}.AQoJBEfLipaVsJYlg4I6{opacity:1}.KnBzyxA2Eh01_tx5qBAt{opacity:0}.KnBzyxA2Eh01_tx5qBAt._mFAlh_Bxz2ZpyrmG9FV>*{transform:translateY(-10px)}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/elements/toggleable-fade.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,oCAAA,CAEA,6CACC,+BAAA,CAIF,sBACC,SAAA,CAGD,sBACC,SAAA,CAEA,6CACC,2BAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `KmBZYrR4dDgLQ_aTd7oq`;
export var h2 = `rt60wjUOgFeAq_u7uUxw`;
export var h3 = `TLGliq2e9V_NO3EESizT`;
export var p0 = `NXqqYvrghQ3zLRuYJ__c`;
export var p0m = `uYsht5gpAflfnu5NvW2q`;
export var p1 = `w_OHdmzrkg4KSB2SqAQl`;
export var p1m = `zPinZETe9gUWHRoMfZLz`;
export var p2 = `QW9oglYRxaPnW_GGlFiD`;
export var p2m = `K7n_b7ymTLeuzvMJtB3h`;
export var p2n = `U3mEc2Lxm0KtnxkJFKh4`;
export var p3 = `m5Sg1x2VdjnkZoMXxKXF`;
export var p3m = `ZxGqr063F9y4k2dfmlhk`;
export var container = `OtJ_zeR6KByeiGPohWgG`;
export var shift = `_mFAlh_Bxz2ZpyrmG9FV`;
export var containerVisible = `AQoJBEfLipaVsJYlg4I6`;
export var containerHidden = `KnBzyxA2Eh01_tx5qBAt`;
export default ___CSS_LOADER_EXPORT___;
