import React, { useMemo } from "react";
import { normalizeDeviceHardware, VelavuDevice } from "velavu-js-api";
import DynamicBattery from "../../../elements/dynamic-battery";
import StatusIndicator from "../../../elements/status-indicator";
import VelavuButton from "../../../elements/velavu-button";
import VelavuItemIcon from "../../../elements/velavu-item-icon";
import VelavuNotice from "../../../elements/velavu-notice";
import VelavuSimpleTable, {
	CenteredTD,
	MultilineTD,
} from "../../../elements/velavu-simple-table";
import { useDisplayLocation } from "../../../helper/api-helper";
import {
	mapConnectionStatus,
	mapDeviceHardware,
} from "../../../helper/language-helper";
import { getDeviceStatus } from "../../../helper/status-helper";
import * as styles from "./modal-register-tag-details.module.scss";

interface ModalRegisterTagDetailsProps {
	tag: VelavuDevice;
	onClose?: VoidFunction;
	onRepeat?: VoidFunction;
}

function TagStatus(props: { tag: VelavuDevice }) {
	const status = useMemo(() => {
		return getDeviceStatus(props.tag);
	}, [props.tag]);

	return (
		<>
			<StatusIndicator status={status} />
			<span>{mapConnectionStatus(status)}</span>
		</>
	);
}

export default function ModalRegisterTagDetails(
	props: ModalRegisterTagDetailsProps,
) {
	const assetLocation = useDisplayLocation(
		props.tag.location !== undefined &&
			(props.tag.location.location_type === "GPS" ||
				props.tag.location.location_type === "MESH")
			? props.tag
			: undefined,
	);

	return (
		<div className={styles.container}>
			<div className={styles.header}>
				<VelavuItemIcon
					data={normalizeDeviceHardware(props.tag.hardware)}
				/>
				<div className={styles.headerText}>
					<span className={styles.headerTitle}>{props.tag.id}</span>
					<span className={styles.headerSubtitle}>
						{mapDeviceHardware(
							normalizeDeviceHardware(props.tag.hardware),
						)}
					</span>
				</div>
			</div>

			<VelavuSimpleTable>
				<tbody>
					<tr>
						<td>Tag ID</td>
						<td>{props.tag.id}</td>
					</tr>
					<tr>
						<td>Status</td>
						<CenteredTD>
							<TagStatus tag={props.tag} />
						</CenteredTD>
					</tr>
					{props.tag.state?.power && (
						<tr>
							<td>Battery</td>
							<CenteredTD>
								<span style={{ marginRight: 4 }}>
									{props.tag.state?.power?.battery_level !==
									undefined
										? `${props.tag.state.power.battery_level}%`
										: "N/A"}
								</span>
								<DynamicBattery
									resource={props.tag.state.power}
								/>
							</CenteredTD>
						</tr>
					)}
					{props.tag.state && (
						<tr>
							<td>Firmware</td>
							<td>{props.tag.state.app_version}</td>
						</tr>
					)}
					<tr>
						<td>Location</td>
						<MultilineTD>{assetLocation}</MultilineTD>
					</tr>
				</tbody>
			</VelavuSimpleTable>

			<div className={styles.spacer} />

			<VelavuNotice type="confirm" body="Tag successfully registered" />
			<div className={styles.buttonBar}>
				<VelavuButton
					label="Register another"
					onClick={props.onRepeat}
					outlined
					fullWidth
				/>
				<div className={styles.buttonBarSpacer} />
				<VelavuButton label="Done" onClick={props.onClose} fullWidth />
			</div>
		</div>
	);
}
