// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.LWCJdxS_PlPLVffWnN0V{font-weight:500;font-size:24px}.E02Dwh8YxKR1t2_yz7za{font-weight:500;font-size:20px}.TEmw6AKV0pu8AMUuVCPm{font-weight:500;font-size:12px}._Uh0GA3PMTmFtXhSUiaC{font-size:18px}.FvPMcfFfsDEC6vhAO2B5{font-size:18px;font-weight:500}.IfZFabZLu5zgDjCYH9tZ{font-size:16px}.GQ0s2afKJeLSVyDGZRDp{font-size:16px;font-weight:500}.ANuE4MPhJU7Y6Z6_kLrg{font-size:14px}.oJpXUy_4IepBnYmVkdXF,.fPI4JSZ9E0TDhiIWIy9L,.Pd5jiSWCIvbAXbUmrtDA,._yeNiPr0RZ6aFYKikQrM{font-size:14px;font-weight:500}.sk8Zrecf_JCByparWPur{font-size:14px;font-weight:400}.ixv3Ehn0wVjphmk7k0wE,.XQzhQu4gBP91QYgfHeG8{font-size:12px}.aESHeuJEjm1XoQDDpZM_{font-size:12px;font-weight:500}.gyA4EcaQUiQMwbBShlz6{margin-top:10px}.fi13igqtjwDm399TR3VM{padding:12px 16px;display:flex;flex-direction:row;align-items:center}.Bsui0wIbifWVb2EAwAda{flex-grow:1;flex-basis:0}.XQzhQu4gBP91QYgfHeG8{color:#5f718c;margin-top:10px}.Pd5jiSWCIvbAXbUmrtDA{display:block;margin-left:20px;margin-top:16px}.T7Iij6UaSfHptU1fZYEQ{transition:opacity 250ms ease;opacity:0}.xG_g8LfrwIcEt_6wenF3{opacity:1}.zGndfwJxQMDS_6gr3r8y{display:flex;align-items:center;justify-content:center}.fPI4JSZ9E0TDhiIWIy9L{color:#5f718c}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/components/asset-tag-detail/tab-tag-dfu.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,wFACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,4CACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,eAAA,CAGD,sBACC,iBAAA,CAEA,YAAA,CACA,kBAAA,CACA,kBAAA,CAGD,sBACC,WAAA,CACA,YAAA,CAOD,sBAEC,aDZgB,CCahB,eAAA,CAGD,sBACC,aAAA,CACA,gBAAA,CACA,eAAA,CAKD,sBACC,6BAAA,CACA,SAAA,CAGD,sBACC,SAAA,CAGD,sBACC,YAAA,CACA,kBAAA,CACA,sBAAA,CAGD,sBAEC,aDzCgB","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `LWCJdxS_PlPLVffWnN0V`;
export var h2 = `E02Dwh8YxKR1t2_yz7za`;
export var h3 = `TEmw6AKV0pu8AMUuVCPm`;
export var p0 = `_Uh0GA3PMTmFtXhSUiaC`;
export var p0m = `FvPMcfFfsDEC6vhAO2B5`;
export var p1 = `IfZFabZLu5zgDjCYH9tZ`;
export var p1m = `GQ0s2afKJeLSVyDGZRDp`;
export var p2 = `ANuE4MPhJU7Y6Z6_kLrg`;
export var p2m = `oJpXUy_4IepBnYmVkdXF`;
export var rowShowMoreText = `fPI4JSZ9E0TDhiIWIy9L`;
export var tableTitle = `Pd5jiSWCIvbAXbUmrtDA`;
export var updateCardTextTitle = `_yeNiPr0RZ6aFYKikQrM`;
export var p2n = `sk8Zrecf_JCByparWPur`;
export var p3 = `ixv3Ehn0wVjphmk7k0wE`;
export var updateCardTextBody = `XQzhQu4gBP91QYgfHeG8`;
export var p3m = `aESHeuJEjm1XoQDDpZM_`;
export var itemMargin = `gyA4EcaQUiQMwbBShlz6`;
export var updateCard = `fi13igqtjwDm399TR3VM`;
export var updateCardText = `Bsui0wIbifWVb2EAwAda`;
export var hoverIcon = `T7Iij6UaSfHptU1fZYEQ`;
export var hoverIconShow = `xG_g8LfrwIcEt_6wenF3`;
export var rowShowMore = `zGndfwJxQMDS_6gr3r8y`;
export default ___CSS_LOADER_EXPORT___;
