import React, { useCallback, useMemo } from "react";
import { VelavuDevice, VelavuSite } from "velavu-js-api";
import DynamicBattery from "../../../../elements/dynamic-battery";
import StatusIndicator from "../../../../elements/status-indicator";
import VelavuButton from "../../../../elements/velavu-button";
import {
	useVelavuModal,
	VelavuTitledModal,
} from "../../../../elements/velavu-modal";
import VelavuSimpleTable, {
	CenteredTD,
} from "../../../../elements/velavu-simple-table";
import { mapConnectionStatus } from "../../../../helper/language-helper";
import { getDeviceStatus } from "../../../../helper/status-helper";
import DetailModal from "../../../asset-tag-detail/detail-modal";
import * as styles from "./gateway-properties.module.scss";

interface GatewayPropertiesProps {
	gateway: VelavuDevice;
	site: VelavuSite;
}

function GatewayStatus(props: { gateway: VelavuDevice }) {
	const status = useMemo(() => {
		return getDeviceStatus(props.gateway);
	}, [props.gateway]);

	return (
		<>
			<StatusIndicator status={status} />
			<span>{mapConnectionStatus(status)}</span>
		</>
	);
}

export default function GatewayProperties(props: GatewayPropertiesProps) {
	const pushModal = useVelavuModal();

	const { gateway: propsGateway } = props;

	const inspectGateway = useCallback(() => {
		pushModal(
			(close) => (
				<VelavuTitledModal title="Device details" onClose={close}>
					<DetailModal
						device={propsGateway}
						deviceOnly={true}
						defaultTab={"device"}
					/>
				</VelavuTitledModal>
			),
			{ pinToTop: true },
		);
	}, [pushModal, propsGateway]);
	return (
		<>
			<VelavuSimpleTable>
				<tbody>
					<tr>
						<td>Gateway ID</td>
						<td>{props.gateway.id}</td>
					</tr>
					<tr>
						<td>Status</td>
						<CenteredTD>
							<GatewayStatus gateway={props.gateway} />
						</CenteredTD>
					</tr>
					<tr>
						<td>Battery</td>
						<CenteredTD>
							<DynamicBattery
								resource={props.gateway.state?.power}
							/>
							<span style={{ marginLeft: 6 }}>
								{props.gateway.state?.power?.battery_level !==
								undefined
									? `${props.gateway.state.power.battery_level}%`
									: "N/A"}
							</span>
						</CenteredTD>
					</tr>
					<tr>
						<td>Coordinates</td>
						<td>
							{props.gateway.location
								? `${props.gateway.location.coordinates[0]}, ${props.gateway.location.coordinates[1]}`
								: `N/A`}
						</td>
					</tr>
				</tbody>
			</VelavuSimpleTable>
			<VelavuButton
				className={styles.button}
				label="View details"
				fullWidth
				onClick={inspectGateway}
			/>
		</>
	);
}
