// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.dA5MAT2KBUrCK7v_Ftbt{font-weight:500;font-size:24px}.UZ0yhWT222yPmpn4kYZf{font-weight:500;font-size:20px}.GuE5cljGMfetAiupxLeB{font-weight:500;font-size:12px}.V9c6WLBueMuLwXq0BuAD{font-size:18px}.exftAiboutYkfocO7Co3{font-size:18px;font-weight:500}.uTNzSgwTTFIGqHDPexza{font-size:16px}.IZ5lcYm98A_3vHa4nNWT{font-size:16px;font-weight:500}.HEbhVT6ateME63FyKxFx{font-size:14px}.ZlzRxAnCD2CVsqsFgEsS{font-size:14px;font-weight:500}.h5TMoEug4jUivvCPuBQ9{font-size:14px;font-weight:400}.W09sEgLyQXUogu_BJF1e{font-size:12px}.DcALMStMztcQsPPxesFA{font-size:12px;font-weight:500}.bLP77sZ97pebPqzKnQzM{color:#00255d}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/components/settings/settings-billing/settings-billing-usage-table.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,aDSc","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `dA5MAT2KBUrCK7v_Ftbt`;
export var h2 = `UZ0yhWT222yPmpn4kYZf`;
export var h3 = `GuE5cljGMfetAiupxLeB`;
export var p0 = `V9c6WLBueMuLwXq0BuAD`;
export var p0m = `exftAiboutYkfocO7Co3`;
export var p1 = `uTNzSgwTTFIGqHDPexza`;
export var p1m = `IZ5lcYm98A_3vHa4nNWT`;
export var p2 = `HEbhVT6ateME63FyKxFx`;
export var p2m = `ZlzRxAnCD2CVsqsFgEsS`;
export var p2n = `h5TMoEug4jUivvCPuBQ9`;
export var p3 = `W09sEgLyQXUogu_BJF1e`;
export var p3m = `DcALMStMztcQsPPxesFA`;
export var lineTotal = `bLP77sZ97pebPqzKnQzM`;
export default ___CSS_LOADER_EXPORT___;
