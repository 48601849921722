import React from "react";

export type StyleableIcon = (
	size: number,
	color: string,
	className?: string,
) => React.ReactNode;

export type StyleableIconComponent = React.ComponentType<{
	size: number;
	color: string;
	className?: string;
}>;

export function makeStyleable(
	Component: StyleableIconComponent,
): StyleableIcon {
	return (size: number, color: string, className?: string) => (
		<Component size={size} color={color} className={className} />
	);
}
