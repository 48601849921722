import React, { CSSProperties } from "react";

export default function IconFound(props: {
	className?: string;
	style?: CSSProperties;
	color?: string;
	size?: number;
}) {
	return (
		<svg
			className={props.className}
			style={props.style}
			width={props.size ?? 24}
			height={props.size ?? 24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M9.5 10C9.5 9.44772 9.05228 9 8.5 9H8C5.79086 9 4 10.7909 4 13V17C4 19.2091 5.79086 21 8 21H16C18.2091 21 20 19.2091 20 17V13C20 10.7909 18.2091 9 16 9H15.5C14.9477 9 14.5 9.44772 14.5 10C14.5 10.5523 14.9477 11 15.5 11H16C17.1046 11 18 11.8954 18 13V17C18 18.1046 17.1046 19 16 19L8 19C6.89543 19 6 18.1046 6 17L6 13C6 11.8954 6.89543 11 8 11H8.5C9.05228 11 9.5 10.5523 9.5 10Z"
				fill={props.color ?? "black"}
			/>
			<path
				d="M11 15C11 15.5523 11.4477 16 12 16C12.5523 16 13 15.5523 13 15V7.39922H16L12.7809 3.37531C12.3805 2.8749 11.6195 2.8749 11.2191 3.3753L8 7.39922L11 7.39922V15Z"
				fill={props.color ?? "black"}
			/>
		</svg>
	);
}
