// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.G0hvdFZGSAjAhpSl6w5A{font-weight:500;font-size:24px}.yIZNIEZ8mm_usZLhz0UG{font-weight:500;font-size:20px}.vX6pjZGNZPXFh7B1aCaO{font-weight:500;font-size:12px}.DY8rsthbByhLVk2IAXG3{font-size:18px}.kYJlSJtROup5wyhwpgPi{font-size:18px;font-weight:500}.Zd1E82YC4DQXp_IE9mqN{font-size:16px}.Botha05c9tc9lLrfFFem{font-size:16px;font-weight:500}.KdyRnpXbgqjiD5yxCdw0{font-size:14px}.UGYfE835tStP2EC5JdKP{font-size:14px;font-weight:500}.my8o15PIiHC_6Z4v8mxx{font-size:14px;font-weight:400}._PkmAqt8popvPfJSSqYW{font-size:12px}.iYpvYyGDLtJELzyf0zHk{font-size:12px;font-weight:500}.qOGi44aoaKdmemy0ske_{width:40px;height:40px;border-radius:50%;background-color:#fff;display:flex;align-items:center;justify-content:center;box-shadow:0 0 4px rgba(163,179,204,.25),0 3px 15px rgba(163,179,204,.6)}.qOGi44aoaKdmemy0ske_:hover{cursor:move}.i13Te3eUNsqEAsxU8Q5d{width:16px;height:16px;border-radius:50%;background-color:#fff;border:2px solid #00255d;box-sizing:border-box;box-shadow:0 0 4px rgba(163,179,204,.25),0 3px 15px rgba(163,179,204,.6)}.i13Te3eUNsqEAsxU8Q5d:hover{cursor:move}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/injectable/mapbox/mapbox-injectable-place-image.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,UAAA,CACA,WAAA,CACA,iBAAA,CACA,qBAAA,CAEA,YAAA,CACA,kBAAA,CACA,sBAAA,CAEA,wEACC,CAGD,4BACC,WAAA,CAIF,sBACC,UAAA,CACA,WAAA,CACA,iBAAA,CACA,qBAAA,CAEA,wBAAA,CACA,qBAAA,CAEA,wEACC,CAGD,4BACC,WAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `G0hvdFZGSAjAhpSl6w5A`;
export var h2 = `yIZNIEZ8mm_usZLhz0UG`;
export var h3 = `vX6pjZGNZPXFh7B1aCaO`;
export var p0 = `DY8rsthbByhLVk2IAXG3`;
export var p0m = `kYJlSJtROup5wyhwpgPi`;
export var p1 = `Zd1E82YC4DQXp_IE9mqN`;
export var p1m = `Botha05c9tc9lLrfFFem`;
export var p2 = `KdyRnpXbgqjiD5yxCdw0`;
export var p2m = `UGYfE835tStP2EC5JdKP`;
export var p2n = `my8o15PIiHC_6Z4v8mxx`;
export var p3 = `_PkmAqt8popvPfJSSqYW`;
export var p3m = `iYpvYyGDLtJELzyf0zHk`;
export var markerLarge = `qOGi44aoaKdmemy0ske_`;
export var markerSmall = `i13Te3eUNsqEAsxU8Q5d`;
export default ___CSS_LOADER_EXPORT___;
