import React, { Dispatch, Key, SetStateAction, useState } from "react";
import { AssetCategory } from "velavu-js-api";
import IconArrowDown from "../dynamicicons/icon-arrow-down";
import { classArr } from "../helper/style-helper";
import * as styles from "./chip-filter-menu.module.scss";
import Popover from "./popover";

type AllKey<T> = T extends any
	? T extends Key | null
		? "true"
		: "false"
	: never;
export type ChipFilterMenuProps<T> = {
	title: string;
	options: T[];
	disabledOptions?: T[];
	selected: T;
	setSelected: Dispatch<SetStateAction<T>>;
	label?: (t: T) => string;
	category?: (t: T) => AssetCategory | undefined;
	useDropdown?: boolean;
	dropdownMode?: "column" | "row";
	renderDropdownItem?: (t: T) => React.ReactNode;
} & (AllKey<T> extends "true"
	? {
			keyExtractor?: (t: T) => Key | null;
	  }
	: {
			keyExtractor: (t: T) => Key | null;
	  });

function extractKey<T>(props: ChipFilterMenuProps<T>, item: T): Key | null {
	if (props.keyExtractor !== undefined) {
		return props.keyExtractor(item);
	} else {
		//Item must itself be a key
		return item as unknown as Key | null;
	}
}

interface ChipState {
	category?: AssetCategory;
	selected?: boolean;
	highlighted?: boolean;
	disabled?: boolean;
}

export default function ChipFilterMenu<T>(props: ChipFilterMenuProps<T>) {
	const { dropdownMode = "row" } = props;

	function getChipState(option: T): ChipState {
		return {
			category: props.category?.(option),
			selected: props.selected === option,
			disabled: props.disabledOptions?.includes(option),
		};
	}

	const firstItemSelected =
		props.options.length > 0 && props.selected === props.options[0];

	const [isPopoverOpen, setPopoverOpen] = useState(false);

	return (
		<div className={styles.chipFilter}>
			<span className={styles.chipFilterTitle}>{props.title}</span>

			{props.useDropdown ? (
				<>
					{props.options.length >= 1 && (
						<ChipFilterEntry
							chip={getChipState(props.options[0])}
							onClick={() => props.setSelected(props.options[0])}
						>
							{props.label !== undefined
								? props.label(props.options[0])
								: String(props.options[0])}
						</ChipFilterEntry>
					)}

					{props.options.length >= 2 && (
						<div style={{ position: "relative" }}>
							{firstItemSelected ? (
								<ChipFilterEntry
									chip={{
										highlighted: isPopoverOpen,
									}}
									hasIcon
									onClick={() => setPopoverOpen(true)}
								>
									<span>Select</span>
									<IconArrowDown color="currentColor" />
								</ChipFilterEntry>
							) : (
								<ChipFilterEntry
									chip={{
										...getChipState(props.selected),
										highlighted: true,
									}}
									hasIcon
									onClick={() => setPopoverOpen(true)}
								>
									<span>
										{props.label !== undefined
											? props.label(props.selected)
											: String(props.selected)}
									</span>
									<IconArrowDown color="currentColor" />
								</ChipFilterEntry>
							)}

							<Popover
								isVisible={isPopoverOpen}
								onDismiss={() => setPopoverOpen(false)}
							>
								<div
									className={
										dropdownMode === "column"
											? styles.popoverSelectorColumn
											: styles.popoverSelector
									}
								>
									{props.options.slice(1).map((option) => {
										const chip = getChipState(option);
										//Hide if selected or disabled
										if (
											option === props.selected ||
											chip.disabled
										) {
											return null;
										}

										if (props.renderDropdownItem) {
											return (
												<button
													key={extractKey(
														props,
														option,
													)}
													onClick={() => {
														props.setSelected(
															option,
														);
														setPopoverOpen(false);
													}}
												>
													{props.renderDropdownItem(
														option,
													)}
												</button>
											);
										}

										return (
											<ChipFilterEntry
												key={extractKey(props, option)}
												chip={{
													...chip,
													selected: true,
												}}
												onClick={() => {
													props.setSelected(option);
													setPopoverOpen(false);
												}}
											>
												{props.label !== undefined
													? props.label(option)
													: String(option)}
											</ChipFilterEntry>
										);
									})}
								</div>
							</Popover>
						</div>
					)}
				</>
			) : (
				props.options.map((option) => (
					<ChipFilterEntry
						key={extractKey(props, option)}
						chip={getChipState(option)}
						onClick={() => props.setSelected(option)}
					>
						{props.label !== undefined
							? props.label(option)
							: String(option)}
					</ChipFilterEntry>
				))
			)}
		</div>
	);
}

interface ChipFilterEntryProps {
	className?: string;
	chip: ChipState;
	hasIcon?: boolean;
	onClick?: () => void;
	children?: React.ReactNode;
}

function ChipFilterEntry(props: ChipFilterEntryProps) {
	const { category, highlighted, selected, disabled } = props.chip;

	return (
		<button
			className={classArr(
				styles.chipFilterButton,
				highlighted && styles.highlighted,
				category === undefined && selected && styles.selected,
				category === AssetCategory.Vehicle &&
					selected &&
					styles.vehicleCategory,
				category === AssetCategory.Equipment &&
					selected &&
					styles.equipmentCategory,
				props.hasIcon && styles.chipWithIcon,
				props.className,
			)}
			disabled={disabled}
			onClick={props.onClick}
		>
			{props.children}
		</button>
	);
}
