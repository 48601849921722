// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.o8AFyCxPAb3hUV9MWTBw{font-weight:500;font-size:24px}.YIyfFKC3cNaQwpDRxthP{font-weight:500;font-size:20px}.dNEoX4nwIbYf2haoiE3d{font-weight:500;font-size:12px}.ZVLtar4lgPYiZqWV4SAO{font-size:18px}.a2Le8AJoxxA4grVnMYsm{font-size:18px;font-weight:500}.X0rAYikeMJrsZdr9PR0N{font-size:16px}.X3JKFzTFSMluMygcCkmw{font-size:16px;font-weight:500}.QPgvjAkJs9P5nhM6goU6{font-size:14px}.hsSkoXmG6G_Nk5LOcWjx{font-size:14px;font-weight:500}.ZYag1cSafhijnRAIBKzt{font-size:14px;font-weight:400}.xV6opUfSKJ1GRY5LG4YA{font-size:12px}.JrYaez9n4Dk1NqjoGdjk{font-size:12px;font-weight:500}.AK9Q1I0RgeGpKNm5LtmT{padding:20px;box-sizing:border-box;display:flex;flex-direction:column;gap:32px}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/components/dashboard/dropdown/floor-create/floor-create-place-image.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,YAAA,CACA,qBAAA,CAEA,YAAA,CACA,qBAAA,CACA,QAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `o8AFyCxPAb3hUV9MWTBw`;
export var h2 = `YIyfFKC3cNaQwpDRxthP`;
export var h3 = `dNEoX4nwIbYf2haoiE3d`;
export var p0 = `ZVLtar4lgPYiZqWV4SAO`;
export var p0m = `a2Le8AJoxxA4grVnMYsm`;
export var p1 = `X0rAYikeMJrsZdr9PR0N`;
export var p1m = `X3JKFzTFSMluMygcCkmw`;
export var p2 = `QPgvjAkJs9P5nhM6goU6`;
export var p2m = `hsSkoXmG6G_Nk5LOcWjx`;
export var p2n = `ZYag1cSafhijnRAIBKzt`;
export var p3 = `xV6opUfSKJ1GRY5LG4YA`;
export var p3m = `JrYaez9n4Dk1NqjoGdjk`;
export var content = `AK9Q1I0RgeGpKNm5LtmT`;
export default ___CSS_LOADER_EXPORT___;
