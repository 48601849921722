import React, { useMemo, useState } from "react";
import * as styles from "./geofence-detail-assets.module.scss";
import VelavuInput from "../../../../elements/velavu-input";
import IconSearch from "../../../../dynamicicons/icon-search";
import VelavuIconButton from "../../../../elements/velavu-icon-button";
import IconClose from "../../../../dynamicicons/icon-close";
import IconFilterList from "../../../../dynamicicons/icon-filter-list";
import Divider from "../../../../elements/divider";
import AssetListFilter from "../asset-list/asset-list-filter";
import AssetListItem from "../asset-list/asset-listitem";
import { VelavuAPI, VelavuAsset, VelavuDevice } from "velavu-js-api";
import { useCancellableEffect } from "../../../../helper/hook-helper";
import VelavuAssetDevicePair from "../../../../data/asset-device-pair";
import useAssetFiltering from "../../../../helper/assets-filter-hook";

export interface GeofenceDetailAssetsProps {
	geofenceID: string;
	onSelectAsset?: (assetID: string) => void;
	onHoverAsset?: (asset: VelavuAsset | undefined) => void;
}

export default function GeofenceDetailAssets(props: GeofenceDetailAssetsProps) {
	const [geofenceDevices, setGeofenceDevices] = useState<
		undefined | VelavuDevice[]
	>(undefined);
	useCancellableEffect(
		(addPromise) => {
			addPromise(
				VelavuAPI.devices.getAllDevices({
					geofenceID: props.geofenceID,
					paired: true,
				}),
			).then((result) => setGeofenceDevices(result.data));
		},
		[props.geofenceID],
	);

	const geofenceAssetDevicePairs = useMemo(() => {
		return geofenceDevices
			?.map((device) => {
				return { asset: device.asset, device };
			})
			.filter((it) => it.asset !== undefined) as VelavuAssetDevicePair[];
	}, [geofenceDevices]);

	const {
		assets: filteredAssets,
		filterCategory,
		filterStatus,
		isFiltering,
		searchText,
		setFilterCategory,
		setFilterStatus,
		setSearchText,
		showFilterMenu,
		toggleFilterMenu,
	} = useAssetFiltering(geofenceAssetDevicePairs);

	const {
		onSelectAsset: propsOnSelectAsset,
		onHoverAsset: propsOnHoverAsset,
	} = props;

	const filterColor = isFiltering ? "#3A58E2" : "#5F718C";

	return (
		<>
			<div className={styles.assetCountCard}>
				{geofenceAssetDevicePairs === undefined ? (
					<span>Loading devices...</span>
				) : (
					<React.Fragment>
						<span className={styles.assetCountCardNumber}>
							{geofenceAssetDevicePairs.length}
						</span>
						<span className={styles.assetCountCardLabel}>
							Assets in geofence
						</span>
					</React.Fragment>
				)}
			</div>

			<div className={styles.filterBar}>
				<VelavuInput
					className={styles.search}
					leadingIcon={<IconSearch size={16} color="#00255D" />}
					placeholder="Search assets"
					value={searchText}
					onChange={setSearchText}
					fullWidth
				/>

				<VelavuIconButton
					highlight={isFiltering}
					onClick={toggleFilterMenu}
				>
					{showFilterMenu ? (
						<IconClose color={filterColor} />
					) : (
						<IconFilterList color={filterColor} />
					)}
				</VelavuIconButton>
			</div>

			<Divider />

			{showFilterMenu && (
				<>
					<AssetListFilter
						filterCategory={filterCategory}
						selectCategory={setFilterCategory}
						filterStatus={filterStatus}
						selectStatus={setFilterStatus}
					/>
					<Divider />
				</>
			)}

			<div className={styles.list}>
				{(filteredAssets ?? []).map((asset) => (
					<AssetListItem
						key={asset.id}
						asset={asset}
						onClick={() => propsOnSelectAsset?.(asset.id)}
						onMouseEnter={() => propsOnHoverAsset?.(asset)}
						onMouseLeave={() => propsOnHoverAsset?.(undefined)}
					/>
				))}
			</div>
		</>
	);
}
