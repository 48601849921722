// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.NmLEX25UbU2EtMwZ2CKQ{font-weight:500;font-size:24px}.yxGa0TmfJk3xpTAjyfDf{font-weight:500;font-size:20px}.c1b0zwq6I8b5jjZHD4PH{font-weight:500;font-size:12px}.eNqX5g0yJ4kGvgUO9G44{font-size:18px}.quJEK8oiB7L9IQZyaln1{font-size:18px;font-weight:500}.Z5nTs6ASXqF753ppOk7d{font-size:16px}.GF_GO3ogZM4XRl5s0lwD{font-size:16px;font-weight:500}.OZh0CEqoXhWsB3w_WKLC{font-size:14px}.ewH84qhGtT_5GSOkEsQl{font-size:14px;font-weight:500}.jPNlAK7MvHdpaLAUUPGX{font-size:14px;font-weight:400}.WVq62phz8GpaDBNdVlXL{font-size:12px}.RVrEvsJ8KmNXRMIAgvO9,.bC0cPuQeTVq5tvC1BioS{font-size:12px;font-weight:500}.bC0cPuQeTVq5tvC1BioS{color:#fff;background-color:#da3e52;padding:1px 8px;border-radius:100px}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/elements/alert-counter.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,4CACC,cAAA,CACA,eAAA,CC3GD,sBAEC,UAAA,CACA,wBDuBY,CCtBZ,eAAA,CACA,mBAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `NmLEX25UbU2EtMwZ2CKQ`;
export var h2 = `yxGa0TmfJk3xpTAjyfDf`;
export var h3 = `c1b0zwq6I8b5jjZHD4PH`;
export var p0 = `eNqX5g0yJ4kGvgUO9G44`;
export var p0m = `quJEK8oiB7L9IQZyaln1`;
export var p1 = `Z5nTs6ASXqF753ppOk7d`;
export var p1m = `GF_GO3ogZM4XRl5s0lwD`;
export var p2 = `OZh0CEqoXhWsB3w_WKLC`;
export var p2m = `ewH84qhGtT_5GSOkEsQl`;
export var p2n = `jPNlAK7MvHdpaLAUUPGX`;
export var p3 = `WVq62phz8GpaDBNdVlXL`;
export var p3m = `RVrEvsJ8KmNXRMIAgvO9`;
export var counter = `bC0cPuQeTVq5tvC1BioS`;
export default ___CSS_LOADER_EXPORT___;
