import { DateTime, Duration } from "luxon";
import React, { useContext } from "react";
import { VelavuRoute } from "velavu-js-api";
import IconCircleDeleteFilled from "../../../../dynamicicons/icon-circle-delete-filled";
import IconInfo from "../../../../dynamicicons/icon-info";
import IconSpeed from "../../../../dynamicicons/icon-speed";
import { formatDistance, formatSpeed } from "../../../../helper/unit-helper";
import SignInContext from "../../../../sign-in-context";
import * as styles from "./asset-routes-detail.module.scss";

interface AssetRouteDetailProps {
	route: VelavuRoute;
	onDeleteRoute: VoidFunction;
}

export default function AssetRoutesDetail(props: AssetRouteDetailProps) {
	//Getting the user
	const userContext = useContext(SignInContext);

	//Parsing the dates
	const dateStart = DateTime.fromISO(props.route.data.time_start);
	const dateEnd = props.route.data.time_stop
		? DateTime.fromISO(props.route.data.time_stop)
		: undefined;

	//Creating the date string
	const labelDate = dateStart.toLocaleString(DateTime.DATE_MED);

	//Creating the duration string
	let duration: Duration;
	let labelDuration: string;

	if (dateEnd) duration = dateEnd.diff(dateStart, ["hours", "minutes"]);
	else duration = DateTime.now().diff(dateStart, ["hours", "minutes"]);

	const hours = Math.floor(duration.hours!);
	const minutes = Math.floor(duration.minutes!);

	if (hours >= 1) {
		labelDuration = `${hours}h ${minutes}m`;
	} else {
		if (minutes === 1) labelDuration = "1 min";
		else labelDuration = `${minutes} mins`;
	}

	return (
		<div className={styles.container}>
			<div className={styles.selectionIndicator} />
			<div className={styles.content}>
				<div className={styles.twoLevelIcon}>
					<IconInfo size={16} color="#00255D" />
					<div className={styles.twoLevel}>
						<span className={styles.label}>Date</span>
						<span className={styles.value}>{labelDate}</span>
					</div>
				</div>
				<div className={styles.twoLevel}>
					<span className={styles.label}>Distance</span>
					<span className={styles.value}>
						{formatDistance(
							props.route.data.distance,
							userContext.preferences.measurementSystem,
						)}
					</span>
				</div>
				<div className={styles.twoLevel}>
					<span className={styles.label}>Duration</span>
					<span className={styles.value}>{labelDuration}</span>
				</div>
				<div className={styles.twoLevel}>
					<span className={styles.label}>Events</span>
					<span
						className={`${styles.value} ${
							props.route.data.event_cnt > 0
								? styles.valueWarn
								: ""
						}`}
					>
						{props.route.data.event_cnt}
					</span>
				</div>

				<div className={styles.twoLevelIcon}>
					<IconSpeed size={16} color="#00255D" />
					<div className={styles.twoLevel}>
						<span className={styles.label}>Avg. speed</span>
						<span className={styles.value}>
							{formatSpeed(
								props.route.data.avg_speed,
								userContext.preferences.measurementSystem,
							)}
						</span>
					</div>
				</div>
				<div className={styles.twoLevel}>
					<span className={styles.label}>Max speed</span>
					<span className={styles.value}>
						{formatSpeed(
							props.route.data.max_speed,
							userContext.preferences.measurementSystem,
						)}
					</span>
				</div>
				{props.route.data.location_stop && (
					<button
						className={styles.deleteButton}
						onClick={props.onDeleteRoute}
					>
						<IconCircleDeleteFilled color="#5F718C" size={16} />
						<span className={styles.buttonText}>delete</span>
					</button>
				)}
			</div>
		</div>
	);
}
