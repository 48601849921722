// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.HNc1IyORNgeixojwAQ3y{font-weight:500;font-size:24px}.wS6HnliYIxmVINCzszmA{font-weight:500;font-size:20px}.HDmmNkSPSiGWNizmA6WJ{font-weight:500;font-size:12px}.HiN22vzb1clwQ6nc3lVj{font-size:18px}.B9kdIF9LntiRMxmNCtUw{font-size:18px;font-weight:500}.BQ9LcSm4kKFMfQooV4ER{font-size:16px}.SuGudFV3B0tL4Ipb5hZ9{font-size:16px;font-weight:500}.Lgow5OV6S2lZ3l6pxDNB{font-size:14px}.yC06mI7Q0JsXJ3PgEzfT{font-size:14px;font-weight:500}.Ol_4TABtqbouRZBU5voQ{font-size:14px;font-weight:400}.kbQJ_jSrfc2lws1KrmwE{font-size:12px}.qZPAUgCSh_Wb0EcIqFBi{font-size:12px;font-weight:500}.qqwjMaWR5Lzc1CmKBkvb{cursor:pointer;height:40px;box-sizing:border-box;border-radius:12px;background-color:#f0f5fd;color:#3a58e2;display:flex;flex-direction:row;align-items:center;justify-content:center;gap:12px}._ysRPHvtkx5ViMXXovhZ{width:100%;flex:1}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/elements/input-button.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,cAAA,CAEA,WAAA,CACA,qBAAA,CAEA,kBAAA,CACA,wBDQqB,CCPrB,aDHS,CCKT,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,sBAAA,CACA,QAAA,CAGD,sBACC,UAAA,CACA,MAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `HNc1IyORNgeixojwAQ3y`;
export var h2 = `wS6HnliYIxmVINCzszmA`;
export var h3 = `HDmmNkSPSiGWNizmA6WJ`;
export var p0 = `HiN22vzb1clwQ6nc3lVj`;
export var p0m = `B9kdIF9LntiRMxmNCtUw`;
export var p1 = `BQ9LcSm4kKFMfQooV4ER`;
export var p1m = `SuGudFV3B0tL4Ipb5hZ9`;
export var p2 = `Lgow5OV6S2lZ3l6pxDNB`;
export var p2m = `yC06mI7Q0JsXJ3PgEzfT`;
export var p2n = `Ol_4TABtqbouRZBU5voQ`;
export var p3 = `kbQJ_jSrfc2lws1KrmwE`;
export var p3m = `qZPAUgCSh_Wb0EcIqFBi`;
export var button = `qqwjMaWR5Lzc1CmKBkvb`;
export var fullWidth = `_ysRPHvtkx5ViMXXovhZ`;
export default ___CSS_LOADER_EXPORT___;
