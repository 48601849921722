import { DateTime } from "luxon";
import React, { useCallback, useEffect, useState } from "react";
import { VelavuAlert } from "velavu-js-api";
import IconCloseLeft from "../../dynamicicons/icon-close-left";
import IconDelete from "../../dynamicicons/icon-delete";
import IconMute from "../../dynamicicons/icon-mute";
import IconVelavuAlerts from "../../dynamicicons/icon-velavu-alerts";
import AlertCounter from "../../elements/alert-counter";
import Divider from "../../elements/divider";
import VelavuIconButton from "../../elements/velavu-icon-button";
import VelavuMenu, {
	VelavuMenuItem,
	VelavuOverflowButton,
} from "../../elements/velavu-menu";
import AlertItem, { AlertsMenu } from "./alert-item";
import * as styles from "./alerts.module.scss";
import { NoAlertsIcon } from "../../img/icons/icons";

interface AlertsProps {
	show?: boolean;
	onClose: VoidFunction;

	alerts: VelavuAlert[] | undefined;
	alertCount: number;
	readAlertIDs: string[];
	onDismiss: (alert: VelavuAlert) => void;
	onDelete: (alert: VelavuAlert) => void;

	onDismissAll: VoidFunction;
	onDeleteAll: VoidFunction;
	onOpenAlertsSettings: VoidFunction;
}

function NoAlerts() {
	return (
		<div className={styles.emptyContent}>
			<NoAlertsIcon />
			<span className={styles.heading}>No alerts</span>
			<span className={styles.info}>{"You're all up to date!"}</span>
		</div>
	);
}

export default function Alerts(props: AlertsProps) {
	const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
	const openMenu = useCallback(
		(event: React.MouseEvent<HTMLElement>) => {
			setMenuAnchor(event.currentTarget);
		},
		[setMenuAnchor],
	);
	const closeMenu = useCallback(() => {
		setMenuAnchor(null);
	}, [setMenuAnchor]);

	const [itemMenu, setItemMenu] = useState<AlertsMenu | null>(null);
	const openItemMenu = useCallback(
		(event: AlertsMenu) => {
			setItemMenu(event);
		},
		[setItemMenu],
	);
	const closeItemMenu = useCallback(() => {
		setItemMenu(null);
	}, [setItemMenu]);

	const {
		onDismissAll: propsOnDismissAll,
		onDeleteAll: propsOnDeleteAll,
		onOpenAlertsSettings: propsOnOpenAlertsSettings,
	} = props;
	const dismissAll = useCallback(() => {
		propsOnDismissAll();
		closeMenu();
	}, [propsOnDismissAll, closeMenu]);
	const deleteAll = useCallback(() => {
		propsOnDeleteAll();
		closeMenu();
	}, [propsOnDeleteAll, closeMenu]);
	const openSettings = useCallback(() => {
		propsOnOpenAlertsSettings();
		closeMenu();
	}, [propsOnOpenAlertsSettings, closeMenu]);

	//Update the base time every minute
	const [baseTime, setBaseTime] = useState(
		DateTime.now().set({ millisecond: 0, second: 0 }),
	);
	useEffect(() => {
		const intervalID = setInterval(
			() =>
				setBaseTime(DateTime.now().set({ millisecond: 0, second: 0 })),
			60 * 1000,
		);
		return () => clearInterval(intervalID);
	}, [setBaseTime]);

	return (
		<>
			<VelavuMenu
				open={!!menuAnchor}
				onClose={closeMenu}
				refPosition={menuAnchor}
			>
				{props.alertCount > 0 && (
					<VelavuMenuItem
						icon={(size, color) => (
							<IconMute size={size} color={color} />
						)}
						label="Dismiss all"
						onClick={dismissAll}
					/>
				)}
				{!!props.alerts?.length && (
					<VelavuMenuItem
						icon={(size, color) => (
							<IconDelete size={size} color={color} />
						)}
						label="Delete all"
						onClick={deleteAll}
					/>
				)}
				<VelavuMenuItem
					icon={(size, color) => (
						<IconVelavuAlerts size={size} color={color} />
					)}
					label="Alerts settings"
					onClick={openSettings}
				/>
			</VelavuMenu>

			<VelavuMenu
				open={!!itemMenu}
				onClose={closeItemMenu}
				refPosition={itemMenu ? itemMenu.anchor : null}
			>
				{itemMenu?.unread && (
					<VelavuMenuItem
						icon={(size, color) => (
							<IconMute size={size} color={color} />
						)}
						label="Dismiss"
						onClick={
							itemMenu
								? () => {
										closeItemMenu();
										props.onDismiss(itemMenu.alert);
								  }
								: undefined
						}
					/>
				)}
				<VelavuMenuItem
					icon={(size, color) => (
						<IconDelete size={size} color={color} />
					)}
					label="Delete"
					onClick={
						itemMenu
							? () => {
									closeItemMenu();
									props.onDelete(itemMenu.alert);
							  }
							: undefined
					}
				/>
			</VelavuMenu>

			<div
				className={`${styles.container} ${
					props.show
						? styles.containerExpand
						: styles.containerCollapse
				}`}
			>
				<div className={styles.header}>
					<div className={styles.icon}>
						<IconVelavuAlerts color="#00255D" />
					</div>
					<span className={styles.title}>Alerts</span>
					{props.alertCount > 0 && (
						<AlertCounter count={props.alertCount} />
					)}

					<div className={styles.spacer} />

					<VelavuIconButton tooltip="Close" onClick={props.onClose}>
						<IconCloseLeft color="#5F718C" />
					</VelavuIconButton>
					<VelavuOverflowButton
						onClick={openMenu}
						open={!!menuAnchor}
					/>
				</div>

				<Divider />

				{props.alerts !== undefined ? (
					props.alerts.length > 0 ? (
						<div className={styles.content}>
							{props.alerts.map((alert) => {
								const unread = !props.readAlertIDs.find(
									(readAlertID) => readAlertID === alert.id,
								);

								return (
									<AlertItem
										key={alert.id}
										alert={alert}
										baseTime={baseTime}
										unread={unread}
										onOpenMenu={openItemMenu}
										menuOpen={
											itemMenu?.alert.id === alert.id
										}
										onDismiss={props.onClose}
									/>
								);
							})}
						</div>
					) : (
						<NoAlerts />
					)
				) : (
					<div className={styles.contentEmpty}>
						<span>Please wait&#8230;</span>
					</div>
				)}
			</div>
		</>
	);
}
