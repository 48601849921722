import React, { FormEvent, useCallback, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import VelavuButton from "../../../elements/velavu-button";
import VelavuInput from "../../../elements/velavu-input";
import VelavuNotice from "../../../elements/velavu-notice";
import { checkEmail } from "../../../helper/validation-helper";
import AuthenticationButtonBar from "../authentication-button-bar";
import * as onboardingStyles from "../authentication.module.scss";
import * as styles from "./recover.module.scss";
import { resetPassword } from "aws-amplify/auth";

export default function Recover() {
	const navigate = useNavigate();

	const [email, setEmail] = useState("");

	const [errorText, setErrorText] = useState<string | undefined>(undefined);

	const [isLoading, setLoading] = useState(false);

	const inputOK = checkEmail(email);

	const submitRecover = useCallback(
		(event: FormEvent<HTMLFormElement>) => {
			event.preventDefault();

			//Ignoring if the user hasn't properly entered their username
			if (!inputOK) return;

			setLoading(true);

			resetPassword({ username: email })
				.then(() => {
					navigate("/login/recover/reset", {
						state: { email: email },
					});
				})
				.catch((error) => {
					console.log(error.response);

					setErrorText(error.message);
					setLoading(false);
				});
		},
		[inputOK, email, navigate, setErrorText],
	);

	return (
		<form onSubmit={submitRecover}>
			<p className={onboardingStyles.instructionsText}>
				Password recovery
			</p>

			<VelavuInput
				value={email}
				onChange={setEmail}
				placeholder="Email address"
				autoComplete="email"
				disabled={isLoading}
				fullWidth
				bottomPadding
			/>

			{errorText ? (
				<VelavuNotice
					className={onboardingStyles.noticeMargin}
					type="error"
					body={errorText}
				/>
			) : (
				<VelavuNotice
					className={onboardingStyles.noticeMargin}
					type="info"
					body="Please enter the email address associated with your account"
				/>
			)}

			<AuthenticationButtonBar
				primary={
					<VelavuButton
						label="Reset password"
						disabled={isLoading || !inputOK}
						fullWidth
						submit
					/>
				}
				secondary={
					<Link className={styles.link} to="/login">
						Return to login
					</Link>
				}
			/>
		</form>
	);
}
