import React from "react";
import { Navigate } from "react-router-dom";

interface AuthRouteProps {
	isSignedIn: boolean;
	type: "unauthenticated" | "authenticated";
	children?: React.ReactNode;
}

export default function AuthGate(props: AuthRouteProps): React.JSX.Element {
	if (props.type === "unauthenticated" && props.isSignedIn) {
		return <Navigate to="/" replace />;
	} else if (props.type === "authenticated" && !props.isSignedIn) {
		return <Navigate to="/login" replace />;
	} else {
		return <React.Fragment>{props.children}</React.Fragment>;
	}
}
