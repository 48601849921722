import React, { useCallback, useMemo } from "react";
import * as styles from "./analytics-time-slider.module.scss";
import VelavuSlider from "../../elements/velavu-slider";
import { differenceInDays } from "date-fns";
import { DateRange } from "../../data/date-range";
import { lerp } from "../../helper/math-helper";
import { DateTime } from "luxon";

export default function AnalyticsTimeSlider(props: {
	range: DateRange;
	value: Date;
	onChange: (value: Date) => void;
}) {
	const dayCount = useMemo(
		() => differenceInDays(props.range.end, props.range.start),
		[props.range],
	);

	const sliderMin = props.range.start.getTime();
	const sliderMax = props.range.end.getTime();

	const propsOnChange = props.onChange;
	const sliderValue = useMemo(() => props.value.getTime(), [props.value]);
	const setSliderValue = useCallback(
		(value: number) => propsOnChange(new Date(value)),
		[propsOnChange],
	);

	return (
		<div className={styles.container}>
			<span className={styles.label}>
				<DateText date={props.value} />
			</span>

			<VelavuSlider
				className={styles.slider}
				min={props.range.start.getTime()}
				max={props.range.end.getTime()}
				value={sliderValue}
				onChange={setSliderValue}
				variant="segmented"
				segments={[...new Array(dayCount + 2)].map((_, i) =>
					lerp(i / (dayCount + 1), sliderMin, sliderMax),
				)}
			/>
		</div>
	);
}

function DateText(props: { date: Date }) {
	const dateTime = DateTime.fromJSDate(props.date);

	if (DateTime.now().year === dateTime.year) {
		return (
			<>
				<span>{dateTime.toFormat("MMM d")}</span>
				<span>{dateTime.toFormat("tt")}</span>
			</>
		);
	} else {
		return (
			<span>
				{dateTime.toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)}
			</span>
		);
	}
}
