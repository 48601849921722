import React from "react";
import * as styles from "./velavu-verification-input.module.scss";
import VerificationInput from "react-verification-input";

interface VelavuVerificationInputProps {
	disabled?: boolean;
	onChange?: (value: string) => void;
	value: string;
	onComplete?: (value: string) => void;
}

export default function VelavuVerificationInput(
	props: VelavuVerificationInputProps,
) {
	return (
		<VerificationInput
			classNames={{
				container: styles.container,
				character: styles.character,
				characterInactive: styles.characterInactive,
			}}
			validChars="0-9"
			length={6}
			inputProps={{
				disabled: props.disabled,
			}}
			onChange={props.onChange}
			value={props.value}
			onComplete={props.onComplete}
		/>
	);
}
