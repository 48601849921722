import React, { CSSProperties } from "react";

export default function IconBattery(props: {
	className?: string;
	style?: CSSProperties;
	color?: string;
	size?: number;
}) {
	return (
		<svg
			className={props.className}
			style={props.style}
			xmlns="http://www.w3.org/2000/svg"
			width={props.size ?? 24}
			height={props.size ?? 24}
			viewBox="0 0 24 24"
		>
			<path
				d="M5 6C3.34315 6 2 7.34315 2 9V15C2 16.6569 3.34315 18 5 18H17C18.6569 18 20 16.6569 20 15C21.1046 15 22 14.1046 22 13V11C22 9.89543 21.1046 9 20 9C20 7.34315 18.6569 6 17 6H5Z"
				fill={props.color ?? "black"}
			/>
		</svg>
	);
}
