import React, { CSSProperties, useEffect, useRef } from "react";
import { classArr } from "../helper/style-helper";
import * as styles from "./velavu-header-table.module.scss";

export function VelavuTableRoundContainer(props: {
	style?: CSSProperties;
	className?: string;
	children?: React.ReactNode;
}) {
	return (
		<div
			style={props.style}
			className={`${styles.roundContainer} ${props.className ?? ""}`}
		>
			{props.children}
		</div>
	);
}

export function VelavuTableHeader(props: {
	children?: React.ReactNode;
	className?: string;
}) {
	return (
		<div className={classArr(styles.tableHeaderRow, props.className)}>
			{props.children}
		</div>
	);
}

export function VelavuTableHeaderLabel(props: {
	type?: ColumnType;
	children?: React.ReactNode;
	flex?: number;
	width?: number;
}) {
	return (
		<div
			className={`${styles.tableHeader} ${mapColumnStyle(props.type)}`}
			style={{ width: props.width, flexGrow: props.flex }}
		>
			{props.children}
		</div>
	);
}

export function VelavuTableDivider() {
	return <div />;
}

export function VelavuTableBody(props: {
	children?: React.ReactNode;
	isEmpty?: boolean;
	styles?: CSSProperties;
	className?: string;
	scrollToTop?: boolean;
	setScrollToTop?: React.Dispatch<React.SetStateAction<boolean>>;
	onScroll?: (event: React.UIEvent<HTMLDivElement>) => void;
}) {
	const scrollableBodyRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (props.scrollToTop) {
			scrollableBodyRef.current?.scrollTo(0, 0);
			props.setScrollToTop?.(false);
		}
	}, [props, props.scrollToTop]);

	return (
		<div
			className={classArr(
				props.className,
				styles.tableList,
				props.isEmpty && styles.empty,
			)}
			ref={scrollableBodyRef}
			style={props.styles}
			onScroll={props.onScroll}
		>
			{props.children}
		</div>
	);
}

export function VelavuTableRow(props: {
	className?: string;
	style?: CSSProperties;
	selectable?: boolean;
	selected?: boolean;
	onClick?: VoidFunction;
	children?: React.ReactNode;
	onMouseEnter?: VoidFunction;
	onMouseLeave?: VoidFunction;
}) {
	return (
		<div
			className={`${styles.tableRow} ${styles.contentRow} ${
				props.selectable ? styles.contentRowSelectable : ""
			} ${props.className ?? ""}`}
			style={props.style}
			onClick={props.onClick}
			onMouseEnter={props.onMouseEnter}
			onMouseLeave={props.onMouseLeave}
		>
			{props.children}
		</div>
	);
}

export function VelavuTableColumn(props: {
	type?: ColumnType;
	children?: React.ReactNode;
	flex?: number;
	width?: number;
	onClick?: VoidFunction;
}) {
	return (
		<div
			className={mapColumnStyle(props.type)}
			style={{ width: props.width, flexGrow: props.flex }}
			onClick={props.onClick}
		>
			{props.children}
		</div>
	);
}

type ColumnType = "normal" | "center" | "narrow" | "edge" | "fixed";
function mapColumnStyle(type?: ColumnType) {
	switch (type) {
		default:
			return styles.tableColumn;
		case "center":
			return styles.tableColumnCenter;
		case "narrow":
			return styles.tableColumnNarrow;
		case "edge":
			return styles.tableColumnEdge;
		case "fixed":
			return styles.tableColumnFixed;
	}
}
