import React, { useCallback, useMemo, useState } from "react";
import { VelavuDevice } from "velavu-js-api";
import Divider from "../../../../elements/divider";
import DynamicBattery from "../../../../elements/dynamic-battery";
import SelectableCard from "../../../../elements/selectable-card";
import StatusIndicator from "../../../../elements/status-indicator";
import { getLocationFloorID } from "../../../../helper/floor-helper";
import { getDeviceStatus } from "../../../../helper/status-helper";
import DevicesFilter from "./devices-filter/devices-filter";
import * as styles from "./site-detail-gateways.module.scss";
import FloorFilter from "../../../../data/floor-filter";
import DevicesToolbar from "./devices-toolbar/devices-toolbar";

interface SiteDetailGatewaysProps {
	gateways: VelavuDevice[] | undefined;
	onSelectGateway?: (gateway: VelavuDevice | undefined) => void;
	onHoverGateway?: (gateway: VelavuDevice | undefined) => void;
	activeFloorID: string | undefined;
}

function Gateway(props: { gateway: VelavuDevice }) {
	return (
		<>
			<StatusIndicator
				status={getDeviceStatus(props.gateway)}
				className={styles.indicator}
			/>
			<span className={styles.gatewayName}>{props.gateway.id}</span>
			<div className={styles.battery}>
				<span
					style={{ marginRight: 4 }}
					className={styles.batteryLevel}
				>
					{props.gateway.state?.power?.battery_level !== undefined
						? `${props.gateway.state.power.battery_level}%`
						: "N/A"}
				</span>
				<DynamicBattery
					level={
						props.gateway.state?.power?.battery_level !== undefined
							? props.gateway.state?.power.battery_level / 100
							: -1
					}
					charging={false}
				/>
			</div>
		</>
	);
}

export default function SiteDetailGateways(props: SiteDetailGatewaysProps) {
	const [searchText, setSearchText] = useState("");
	const [showFilterMenu, setShowFilterMenu] = useState(false);
	const [filterBattery, setFilterBattery] = useState<boolean | undefined>(
		undefined,
	);
	const [filterStatus, setFilterStatus] = useState<boolean | undefined>(
		undefined,
	);
	const [floorFilter, setFloorFilter] = useState(FloorFilter.All);
	const toggleFilterMenu = useCallback(
		() => setShowFilterMenu((it) => !it),
		[setShowFilterMenu],
	);
	const filterSelected =
		filterBattery !== undefined || filterStatus !== undefined;
	const filterColor = filterSelected ? "#3A58E2" : "#5F718C";

	const filteredGateways = useMemo(() => {
		let gateways = props.gateways ?? [];

		if (filterStatus !== undefined) {
			gateways = gateways.filter(
				(gateway) => gateway.online === filterStatus,
			);
		}

		if (filterBattery !== undefined) {
			gateways = gateways.filter(
				(gateway) =>
					gateway.state?.power?.battery_level &&
					gateway.state?.power?.battery_level < 15,
			);
		}

		gateways = gateways.filter((gateway) =>
			gateway.id.toLowerCase().includes(searchText.toLowerCase()),
		);

		if (floorFilter === FloorFilter.Current)
			gateways = gateways.filter(
				(g) => getLocationFloorID(g?.location) === props.activeFloorID,
			);

		return gateways;
	}, [
		floorFilter,
		props.activeFloorID,
		props.gateways,
		searchText,
		filterStatus,
		filterBattery,
	]);

	return (
		<div className={styles.gateways}>
			<DevicesToolbar
				searchText={searchText}
				setSearchText={setSearchText}
				floorSelection={floorFilter}
				onChangeFloor={setFloorFilter}
				highlightFilter={
					filterBattery !== undefined || filterStatus !== undefined
				}
				showFilterMenu={showFilterMenu}
				onClickShowFilterMenu={toggleFilterMenu}
			/>
			<Divider />
			{showFilterMenu && (
				<>
					<DevicesFilter
						filterStatus={filterStatus}
						selectStatus={setFilterStatus}
						filterBattery={filterBattery}
						selectBattery={setFilterBattery}
					/>
					<Divider />
				</>
			)}
			<div>
				{filteredGateways.length > 0 ? (
					filteredGateways.map((gateway) => (
						<SelectableCard
							key={gateway.id}
							className={styles.gatewayCard}
							onClick={() => {
								props.onSelectGateway?.(gateway);
								props.onHoverGateway?.(undefined);
							}}
							onMouseEnter={() => props.onHoverGateway?.(gateway)}
							onMouseLeave={() =>
								props.onHoverGateway?.(undefined)
							}
						>
							<Gateway gateway={gateway} />
						</SelectableCard>
					))
				) : (
					<div className={styles.emptyLabel}>No results</div>
				)}
			</div>
		</div>
	);
}
