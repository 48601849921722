import React from "react";
import VelavuButton from "../../elements/velavu-button";
import VelavuNotice from "../../elements/velavu-notice";
import * as styles from "./onboarding-frame.module.scss";

interface OnboardingFrameButtonEntry {
	label: string;
	onClick: VoidFunction;
	disabled?: boolean;
}

interface OnboardingFrameProps {
	title: string;
	description: React.ReactNode;
	buttonSecondary?: OnboardingFrameButtonEntry;
	buttonPrimary: OnboardingFrameButtonEntry;
	notice?: string;

	centerContent?: boolean;
	children?: React.ReactNode;
}

export default function OnboardingFrame(props: OnboardingFrameProps) {
	return (
		<div className={styles.container}>
			<span className={styles.title}>{props.title}</span>
			<span className={styles.description}>{props.description}</span>
			<div
				className={`${styles.content} ${
					props.centerContent ? styles.contentCenter : ""
				}`}
			>
				{props.children}
			</div>
			{props.notice && (
				<VelavuNotice
					className={styles.notice}
					type="info"
					body={props.notice}
				/>
			)}
			<div className={styles.buttonBar}>
				{props.buttonSecondary && (
					<>
						<VelavuButton
							label={props.buttonSecondary.label}
							onClick={props.buttonSecondary.onClick}
							disabled={props.buttonSecondary.disabled}
							outlined
							fullWidth
						/>
						<div className={styles.buttonBarSpacer} />
					</>
				)}

				<VelavuButton
					label={props.buttonPrimary.label}
					onClick={props.buttonPrimary.onClick}
					disabled={props.buttonPrimary.disabled}
					fullWidth
				/>
			</div>
		</div>
	);
}
