// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.BINhIi3GqiBDhvsmr7Jz{font-weight:500;font-size:24px}.FlgzvHd_cwhhJ22PEWvQ{font-weight:500;font-size:20px}.iQ9iOPoGYTgtmt123l2I{font-weight:500;font-size:12px}.okBerpx74CiCWrB0bgRQ{font-size:18px}.BMaiwKqxUUz4aXW0LBZS{font-size:18px;font-weight:500}.ZLo62AcZWznxlCoHORlo{font-size:16px}.pRjsRx03VpxvU1KLveRO{font-size:16px;font-weight:500}.lxhs0YqkZLqQV_635Wdw,.SavZ8R1F_vuC7nKup4eg{font-size:14px}.MGCkHpBGByzWzImKZH3f{font-size:14px;font-weight:500}.j1sqQIkWMr247LzSjULc{font-size:14px;font-weight:400}.swkOKFwVuIOhLLA2rxD_{font-size:12px}.sAZCxRdWcgNA8l3cap0o{font-size:12px;font-weight:500}.SavZ8R1F_vuC7nKup4eg{color:#5f718c;text-decoration:none;display:block;width:100%;text-align:center}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/components/authentication/log-in/log-in.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,4CACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBAEC,aDSgB,CCRhB,oBAAA,CACA,aAAA,CACA,UAAA,CACA,iBAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `BINhIi3GqiBDhvsmr7Jz`;
export var h2 = `FlgzvHd_cwhhJ22PEWvQ`;
export var h3 = `iQ9iOPoGYTgtmt123l2I`;
export var p0 = `okBerpx74CiCWrB0bgRQ`;
export var p0m = `BMaiwKqxUUz4aXW0LBZS`;
export var p1 = `ZLo62AcZWznxlCoHORlo`;
export var p1m = `pRjsRx03VpxvU1KLveRO`;
export var p2 = `lxhs0YqkZLqQV_635Wdw`;
export var link = `SavZ8R1F_vuC7nKup4eg`;
export var p2m = `MGCkHpBGByzWzImKZH3f`;
export var p2n = `j1sqQIkWMr247LzSjULc`;
export var p3 = `swkOKFwVuIOhLLA2rxD_`;
export var p3m = `sAZCxRdWcgNA8l3cap0o`;
export default ___CSS_LOADER_EXPORT___;
