// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.DnsxHqpc9SqoVWjQHc1A{font-weight:500;font-size:24px}.i6ZcbwRXzp2wigMphaGR{font-weight:500;font-size:20px}.u6adGZJ11PImLvGCEXRj{font-weight:500;font-size:12px}.XyUqsh_sCIDUu9IjndSF{font-size:18px}.G_062aHiiGmAIhNLvz3v{font-size:18px;font-weight:500}.X1H_rw4Q2_99XTV5t9n2{font-size:16px}.YccDKgZGZJwzvQJxKEgK{font-size:16px;font-weight:500}.yO9pNL1XA3TbEaxi4Srw{font-size:14px}.h5R9Ak8mOtWJmMDQbBeu,.v3T2PaVsVyviNUWEL0Ps{font-size:14px;font-weight:500}.weoW7D8CKMFWzb05Kk0w{font-size:14px;font-weight:400}.iwIg0b2oselrQsf6qnPC{font-size:12px}.btnON87z0xfgnVZ6QiHl{font-size:12px;font-weight:500}.bRd6MMFZRdNNeqoGqv8i{display:flex;flex-direction:column}.v3T2PaVsVyviNUWEL0Ps{color:#5f718c;margin-bottom:8px;display:flex;flex-direction:row;align-items:center}.j5UYkccC4_JLv_5N_GLj{margin-right:8px}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/elements/velavu-categorization-autocomplete.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,4CACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,YAAA,CACA,qBAAA,CAGD,sBAEC,aDIgB,CCFhB,iBAAA,CAEA,YAAA,CACA,kBAAA,CACA,kBAAA,CAGD,sBACC,gBAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `DnsxHqpc9SqoVWjQHc1A`;
export var h2 = `i6ZcbwRXzp2wigMphaGR`;
export var h3 = `u6adGZJ11PImLvGCEXRj`;
export var p0 = `XyUqsh_sCIDUu9IjndSF`;
export var p0m = `G_062aHiiGmAIhNLvz3v`;
export var p1 = `X1H_rw4Q2_99XTV5t9n2`;
export var p1m = `YccDKgZGZJwzvQJxKEgK`;
export var p2 = `yO9pNL1XA3TbEaxi4Srw`;
export var p2m = `h5R9Ak8mOtWJmMDQbBeu`;
export var label = `v3T2PaVsVyviNUWEL0Ps`;
export var p2n = `weoW7D8CKMFWzb05Kk0w`;
export var p3 = `iwIg0b2oselrQsf6qnPC`;
export var p3m = `btnON87z0xfgnVZ6QiHl`;
export var container = `bRd6MMFZRdNNeqoGqv8i`;
export var labelText = `j5UYkccC4_JLv_5N_GLj`;
export default ___CSS_LOADER_EXPORT___;
