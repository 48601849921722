/*------------------------------*
 | @author  Cameron Slupeiks    |
 | @date    2020.20.26          |
 | Copyright Brash Inc. (2020)  |
 *------------------------------*/

import { Amplify } from "aws-amplify";
import { fetchAuthSession } from "aws-amplify/auth";
import { Settings } from "luxon";
//Import Mapbox CSS
import "mapbox-gl/dist/mapbox-gl.css";
import { GlobalWorkerOptions as pdfjsWorkerOptions } from "pdfjs-dist";
import React from "react";
import ReactDOM from "react-dom";
import App from "./app";
import appConfig from "@config/app-config.json";
import { API_NAME_MAPBOX } from "./dao/amplify-utils";
import { API_NAME_VELAVU } from "velavu-js-api";
import { createRoot } from "react-dom/client";

//Configure PDF.js worker
pdfjsWorkerOptions.workerSrc = "pdf.worker.js";

Amplify.configure(
	{
		Auth: {
			Cognito: {
				identityPoolId: appConfig.auth.identityPoolId,
				userPoolId: appConfig.auth.userPoolId,
				userPoolClientId: appConfig.auth.userPoolWebClientId,
			},
		},
		API: {
			REST: {
				[API_NAME_VELAVU]: {
					endpoint: appConfig.velavuEndpoint,
				},
				[API_NAME_MAPBOX]: {
					endpoint: appConfig.mapboxEndpoint,
				},
			},
		},
	},
	{
		API: {
			REST: {
				headers: async ({
					apiName,
				}): Promise<Record<string, string>> => {
					if (apiName === API_NAME_VELAVU) {
						const authToken = (
							await fetchAuthSession()
						).tokens?.idToken?.toString();

						if (authToken !== undefined) {
							return {
								Authorization: `Bearer ${authToken}`,
							};
						}

						throw new Error(
							"Attempt to send request to Velavu-API without authorization",
						);
					}

					return {};
				},
			},
		},
	},
);

Settings.defaultLocale = "en";

//Register service worker
if (WPEnv.ENVIRONMENT === "production" && "serviceWorker" in navigator) {
	window.addEventListener("load", () => {
		navigator.serviceWorker
			.register("/service-worker.js")
			.then((registration) => {
				console.log("SW registered: ", registration);
			})
			.catch((registrationError) => {
				console.log("SW registration failed: ", registrationError);
			});
	});
}

const root = createRoot(document.getElementById("root")!);
root.render(<App />);
