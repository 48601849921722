import React, { CSSProperties } from "react";

export default function IconBleTag(props: {
	className?: string;
	style?: CSSProperties;
	color?: string;
	size?: number;
}) {
	return (
		<svg
			width={props.size ?? 24}
			height={props.size ?? 24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M13 11H11C10.4477 11 10 11.4477 10 12C10 12.5523 10.4477 13 11 13H13C13.5523 13 14 12.5523 14 12C14 11.4477 13.5523 11 13 11ZM11 9C9.34315 9 8 10.3431 8 12C8 13.6569 9.34315 15 11 15H13C14.6569 15 16 13.6569 16 12C16 10.3431 14.6569 9 13 9H11Z"
				fill={props.color ?? "black"}
			/>
		</svg>
	);
}
