import React, {
	FormEvent,
	useCallback,
	useContext,
	useRef,
	useState,
} from "react";
import { VelavuAPI, VelavuUser } from "velavu-js-api";
import BottomTooltip from "../../elements/bottom-tooltip";
import VelavuButton from "../../elements/velavu-button";
import {
	VelavuTableBody,
	VelavuTableColumn,
	VelavuTableDivider,
	VelavuTableHeader,
	VelavuTableHeaderLabel,
	VelavuTableRoundContainer,
	VelavuTableRow,
} from "../../elements/velavu-header-table";
import VelavuInput from "../../elements/velavu-input";
import { useVelavuModal, VelavuModalPrompt } from "../../elements/velavu-modal";
import VelavuNotice from "../../elements/velavu-notice";
import { useCancellableEffect, useDelayHover } from "../../helper/hook-helper";
import { mapUserRegistrationStatus } from "../../helper/language-helper";
import { checkEmail } from "../../helper/validation-helper";
import SignInContext from "../../sign-in-context";
import * as styles from "./settings-organization.module.scss";

export default function SettingsOrganization() {
	const userContext = useContext(SignInContext);
	const userOrganization = userContext.organization?.name ?? "ORGANIZATION";

	//Fetching users
	const [users, setUsers] = useState<VelavuUser[]>([]);

	useCancellableEffect((addPromise) => {
		addPromise(VelavuAPI.users.getAllUsers())
			.then(setUsers)
			.catch((error) => console.log(error.response));
	}, []);

	const addUser = useCallback(
		(user: VelavuUser) => {
			setUsers(users.concat(user));
		},
		[setUsers, users],
	);

	const pushModal = useVelavuModal();

	const removeUser = useCallback(
		(user: VelavuUser) => {
			pushModal((resolve) => (
				<VelavuModalPrompt
					onSelect={resolve}
					title={"Remove the following user?"}
					object={user.name}
					labelConfirm="Remove"
				>
					{`${user.email} will no longer be able to access resources for ${userOrganization}.`}
				</VelavuModalPrompt>
			)).then((result) => {
				if (!result) return;

				//Removing the user
				VelavuAPI.users
					.deleteUser(user.email)
					.then(() =>
						setUsers(
							users.filter(
								(allUsers) => allUsers.email !== user.email,
							),
						),
					)
					.catch((error) => console.log(error.response));
			});
		},
		[pushModal, setUsers, users, userOrganization],
	);

	const userEmail = useContext(SignInContext).velavuUser?.email;

	return (
		<div className={styles.container}>
			<ListMembers
				members={users}
				onRemove={removeUser}
				currentUserEmail={userEmail}
			/>
			<InviteUser onUserAdd={addUser} organization={userOrganization} />
		</div>
	);
}

function ListMembers(props: {
	members: VelavuUser[];
	onRemove: (user: VelavuUser) => void;
	currentUserEmail?: string;
}) {
	return (
		<div>
			<span className={styles.title}>Organization members</span>
			<VelavuTableRoundContainer className={styles.containerTable}>
				<VelavuTableHeader>
					<VelavuTableHeaderLabel flex={1}>
						Name
					</VelavuTableHeaderLabel>
					<VelavuTableHeaderLabel flex={1}>
						Email address
					</VelavuTableHeaderLabel>
					<VelavuTableHeaderLabel flex={0.4}>
						Status
					</VelavuTableHeaderLabel>
					<VelavuTableHeaderLabel type="edge" width={95} />
				</VelavuTableHeader>
				<VelavuTableDivider />
				<VelavuTableBody styles={{ maxHeight: 240 }}>
					{props.members.map((user) => (
						<MemberTableRow
							user={user}
							key={user.email}
							disabled={user.email === props.currentUserEmail}
							onRemove={() => props.onRemove(user)}
						/>
					))}
				</VelavuTableBody>
			</VelavuTableRoundContainer>
		</div>
	);
}

function MemberTableRow(props: {
	user: VelavuUser;
	disabled: boolean;
	onRemove: VoidFunction;
}) {
	const [isHoverName, enableHoverName, disableHoverName] = useDelayHover();
	const [isHoverEmail, enableHoverEmail, disableHoverEmail] = useDelayHover();
	const nameRef = useRef<HTMLElement | null>(null);
	const emailRef = useRef<HTMLElement | null>(null);

	return (
		<VelavuTableRow>
			<VelavuTableColumn flex={1}>
				{isHoverName && (
					<BottomTooltip refPosition={nameRef.current!}>
						{props.user.name}
					</BottomTooltip>
				)}
				<span
					className={styles.overflow}
					onMouseEnter={enableHoverName}
					onMouseLeave={disableHoverName}
					ref={nameRef}
				>
					{props.user.name}
				</span>
			</VelavuTableColumn>
			<VelavuTableColumn flex={1}>
				{isHoverEmail && (
					<BottomTooltip refPosition={emailRef.current!}>
						{props.user.email}
					</BottomTooltip>
				)}
				<span
					className={styles.overflow}
					onMouseEnter={enableHoverEmail}
					onMouseLeave={disableHoverEmail}
					ref={emailRef}
				>
					{props.user.email}
				</span>
			</VelavuTableColumn>
			<VelavuTableColumn flex={0.4}>
				{mapUserRegistrationStatus(props.user.status)}
			</VelavuTableColumn>
			<VelavuTableColumn type="edge" width={80}>
				{
					<VelavuButton
						label="Remove"
						size="small"
						outlined
						onClick={props.onRemove}
						disabled={props.disabled}
					/>
				}
			</VelavuTableColumn>
		</VelavuTableRow>
	);
}

function InviteUser(props: {
	onUserAdd: (user: VelavuUser) => void;
	organization: string;
}) {
	const [nameInput, setNameInput] = useState("");
	const [emailInput, setEmailInput] = useState("");
	const [isLoading, setLoading] = useState(false);

	const [result, setResult] = useState<
		{ success: boolean; description: string } | undefined
	>(undefined);

	const onUserAdd = props.onUserAdd;
	const submit = useCallback(
		(event: FormEvent<HTMLFormElement>) => {
			event.preventDefault();

			setLoading(true);

			VelavuAPI.users
				.registerNewUser({
					name: nameInput,
					email: emailInput,
				})
				.then((result) => {
					//Setting the result and clearing the inputs
					setResult({
						success: true,
						description: `${emailInput} has been invited to ${props.organization}`,
					});
					setNameInput("");
					setEmailInput("");

					//Calling the callback
					onUserAdd(result);
				})
				.catch((error) => {
					console.log(error.response);

					if (error.response.status === 409) {
						setResult({
							success: false,
							description: `${emailInput} is already a member of ${props.organization}`,
						});
					} else {
						setResult({
							success: false,
							description: `Couldn't invite ${emailInput} to ${props.organization}`,
						});
					}
				})
				.finally(() => {
					setLoading(false);
				});
		},
		[props.organization, nameInput, emailInput, setLoading, onUserAdd],
	);

	return (
		<form className={styles.sectionInvite} onSubmit={submit}>
			<span className={`${styles.inviteTitle}`}>Invite member</span>

			<div className={styles.sectionInputs}>
				<VelavuInput
					placeholder="Name"
					value={nameInput}
					onChange={setNameInput}
					disabled={isLoading}
					autoComplete="name"
					fullWidth
					bottomPadding
				/>

				<VelavuInput
					placeholder="Email address"
					value={emailInput}
					onChange={setEmailInput}
					disabled={isLoading}
					autoComplete="email"
					fullWidth
					bottomPadding
				/>
			</div>

			<VelavuButton
				label="Send invite"
				disabled={
					isLoading ||
					nameInput.length === 0 ||
					!checkEmail(emailInput)
				}
				style={{ alignSelf: "flex-end" }}
				submit
			/>

			{result && (
				<VelavuNotice
					className={styles.statusNotice}
					type={result.success ? "confirm" : "error"}
					title={
						result.success ? "User invited" : "User invite failed"
					}
					body={result.description}
				/>
			)}
		</form>
	);
}
