// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.M16HsLAra7SbNzKAsya7{border-radius:8px;border-width:1px;border-style:solid;box-shadow:0 0 2px rgba(40,41,61,.05),0 2px 8px rgba(96,97,112,.1)}.IQRCax8HfhDZ7YVJaq6D{cursor:pointer}`, "",{"version":3,"sources":["webpack://./src/elements/velavu-card.module.scss"],"names":[],"mappings":"AAAA,sBACC,iBAAA,CACA,gBAAA,CACA,kBAAA,CACA,kEACC,CAIF,sBACC,cAAA","sourceRoot":""}]);
// Exports
export var card = `M16HsLAra7SbNzKAsya7`;
export var selectable = `IQRCax8HfhDZ7YVJaq6D`;
export default ___CSS_LOADER_EXPORT___;
