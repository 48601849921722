import React, { CSSProperties } from "react";
import IconFilterList from "../dynamicicons/icon-filter-list";
import * as styles from "./velavu-menu-filter.module.scss";
import VelavuRadio from "./velavu-radio";
import { VelavuSwitchDisplay } from "./velavu-switch";

interface VelavuMenuFilterProps {
	className?: string;
	style?: CSSProperties;

	onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	focus?: boolean;
	highlight?: boolean | string;
}

export default function VelavuMenuFilter(props: VelavuMenuFilterProps) {
	return (
		<>
			<button
				style={props.style}
				className={`${styles.container} ${
					props.focus ? styles.focus : ""
				} ${props.highlight ? styles.highlight : ""} ${
					props.className ? props.className : ""
				}`}
				onClick={props.onClick}
			>
				{<IconFilterList size={16} color="unset" />}
				<span className={styles.label}>
					{typeof props.highlight === "string"
						? `Filter: ${props.highlight}`
						: "Filters"}
				</span>
			</button>
		</>
	);
}

interface VelavuMenuFilterItemProps {
	checked: boolean;
	children: string | React.ReactNode;
	type?: "radio" | "toggle";
	onClick?: VoidFunction;
}

export function VelavuMenuFilterItem(props: VelavuMenuFilterItemProps) {
	return (
		<div className={styles.item} onClick={props.onClick}>
			{props.type === "toggle" ? (
				<VelavuSwitchDisplay toggled={props.checked} />
			) : (
				<VelavuRadio checked={props.checked} />
			)}
			<span className={styles.itemLabel}>{props.children}</span>
		</div>
	);
}
