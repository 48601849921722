// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.FI3qsAM7P8yCasawx9yi{font-weight:500;font-size:24px}.P1ZXf65WDmURLOIMKIP1{font-weight:500;font-size:20px}.J5nZv51i8i_EeaveAKyK{font-weight:500;font-size:12px}.FlZKgD4DaS4ZI8vUKJXB{font-size:18px}.n_ILBF2JfltOL_gM2UwA{font-size:18px;font-weight:500}.Ftf4rLDCLCtEglXXebLE{font-size:16px}.N2xNxrBIEOoZxVW5kuuR{font-size:16px;font-weight:500}.faLpALuJEM8ywOkr6b25{font-size:14px}.KlV4dk97WrUsihidgeDZ{font-size:14px;font-weight:500}.tfemK7CdnbA0eLwIVLzq{font-size:14px;font-weight:400}.JqcNztqF4on7J2NZKKJS{font-size:12px}.wHXJAjSl8vgOVU7TDfYR{font-size:12px;font-weight:500}.b5cvDHPggDduwcM749HU{height:40px;margin-top:8px;display:flex;flex-direction:column;justify-content:center;will-change:filter}.uZhiGlVPKo0KO4f0pICI{width:154px;align-self:flex-end}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/components/onboarding/onboarding-step-invite.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,WAAA,CACA,cAAA,CAEA,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,kBAAA,CAGD,sBACC,WAAA,CACA,mBAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `FI3qsAM7P8yCasawx9yi`;
export var h2 = `P1ZXf65WDmURLOIMKIP1`;
export var h3 = `J5nZv51i8i_EeaveAKyK`;
export var p0 = `FlZKgD4DaS4ZI8vUKJXB`;
export var p0m = `n_ILBF2JfltOL_gM2UwA`;
export var p1 = `Ftf4rLDCLCtEglXXebLE`;
export var p1m = `N2xNxrBIEOoZxVW5kuuR`;
export var p2 = `faLpALuJEM8ywOkr6b25`;
export var p2m = `KlV4dk97WrUsihidgeDZ`;
export var p2n = `tfemK7CdnbA0eLwIVLzq`;
export var p3 = `JqcNztqF4on7J2NZKKJS`;
export var p3m = `wHXJAjSl8vgOVU7TDfYR`;
export var submitBar = `b5cvDHPggDduwcM749HU`;
export var submitButton = `uZhiGlVPKo0KO4f0pICI`;
export default ___CSS_LOADER_EXPORT___;
