import React, { CSSProperties } from "react";
import {
	DeviceCategory,
	MeasurementSystem,
	normalizeDeviceHardware,
	VelavuAsset,
	VelavuDevice,
} from "velavu-js-api";
import ConnectionStatus from "../../data/connection-status";
import IconHumidity from "../../dynamicicons/icon-humidity";
import IconTemperature from "../../dynamicicons/icon-temperature";
import CategorizationTag from "../../elements/categorization-tag";
import IconLabelAttribute, {
	BatteryIconLabelAttribute,
} from "../../elements/icon-label-attribute";
import SelectableCard from "../../elements/selectable-card";
import StatusIndicator from "../../elements/status-indicator";
import { makeStyleable } from "../../helper/icon-helper";
import { mapDeviceHardware } from "../../helper/language-helper";
import { getDeviceStatus } from "../../helper/status-helper";
import { classArr } from "../../helper/style-helper";
import { formatTemperature } from "../../helper/unit-helper";
import * as styles from "./analytics-sidebar-device.module.scss";

function Card({
	style,
	isSelected,
	isNonApplicable,
	className,
	onClick,
	title,
	battery,
	category,
	deviceName,
	status,
	icons,
}: {
	asset: VelavuAsset;
	style?: CSSProperties;
	isSelected: boolean;
	isNonApplicable: boolean;
	className: string;
	onClick?: VoidFunction;
	title: string;
	battery?: number;
	category?: React.ReactNode;
	deviceName: string;
	status: ConnectionStatus;
	icons: React.ReactNode[];
}) {
	return (
		<SelectableCard
			style={style}
			className={classArr(
				styles.container,
				isSelected && styles.containerSelected,
				isNonApplicable && styles.containerNonApplicable,
				className,
			)}
			onClick={onClick}
		>
			<div className={styles.row}>
				<StatusIndicator status={status} viewBox={"6 0 24 24"} />
				<span className={styles.title}>{title}</span>

				{category}
			</div>
			<div className={styles.row}>
				<span className={styles.subtitle}>{deviceName}</span>

				<div style={{ flexGrow: 1 }} />

				{icons}

				<BatteryIconLabelAttribute
					className={styles.attribute}
					resource={{ battery_level: battery }}
				/>
			</div>
		</SelectableCard>
	);
}

export function Anchor(props: {
	style?: CSSProperties;
	className?: string;
	measurementSystem: MeasurementSystem;
	asset: VelavuAsset;
	anchor: VelavuDevice;
	isSelected: boolean;
	isNonApplicable: boolean;
	onClick?: VoidFunction;
}) {
	const icons = [];
	if (props.anchor?.environment) {
		icons.push(
			<IconLabelAttribute
				key={1}
				className={styles.attribute}
				icon={makeStyleable(IconTemperature)}
				label={`${Math.round(
					formatTemperature(
						props.anchor.environment.temperature_c,
						props.measurementSystem,
					),
				)}º`}
			/>,
		);
	}

	if (props.anchor?.environment?.humidity)
		icons.push(
			<IconLabelAttribute
				key={2}
				className={styles.attribute}
				icon={makeStyleable(IconHumidity)}
				label={`${Math.round(props.anchor.environment.humidity)}%`}
			/>,
		);

	return (
		<Card
			asset={props.asset}
			style={props.style}
			isSelected={props.isSelected}
			isNonApplicable={props.isNonApplicable}
			className={classArr(
				styles.container,
				props.isSelected && styles.containerSelected,
				props.isNonApplicable && styles.containerNonApplicable,
				props.className,
			)}
			onClick={props.onClick}
			title={
				props.anchor.asset?.name ??
				props.anchor.id +
					(props.anchor.category === DeviceCategory.Tag
						? " (no asset)"
						: "")
			}
			battery={props.anchor.state?.power?.battery_level}
			deviceName={mapDeviceHardware(
				normalizeDeviceHardware(props.anchor.hardware),
			)}
			status={getDeviceStatus(props.anchor)}
			icons={icons}
		/>
	);
}

export function Device({
	style,
	className,
	measurementSystem,
	asset,
	device,
	isSelected,
	isNonApplicable,
	onClick,
}: {
	style?: CSSProperties;
	className?: string;
	measurementSystem: MeasurementSystem;
	asset: VelavuAsset;
	device?: VelavuDevice;
	isSelected: boolean;
	isNonApplicable: boolean;
	onClick?: VoidFunction;
}) {
	const icons = [];
	if (device?.environment) {
		icons.push(
			<IconLabelAttribute
				key={1}
				className={styles.attribute}
				icon={makeStyleable(IconTemperature)}
				label={`${Math.round(
					formatTemperature(
						device?.environment.temperature_c,
						measurementSystem,
					),
				)}º`}
			/>,
		);
	}

	if (device?.environment?.humidity)
		icons.push(
			<IconLabelAttribute
				key={2}
				className={styles.attribute}
				icon={makeStyleable(IconHumidity)}
				label={`${Math.round(device.environment.humidity)}%`}
			/>,
		);

	return (
		<Card
			asset={asset}
			style={style}
			isSelected={isSelected}
			isNonApplicable={isNonApplicable}
			className={classArr(
				styles.container,
				isSelected && styles.containerSelected,
				isNonApplicable && styles.containerNonApplicable,
				className,
			)}
			onClick={onClick}
			title={asset?.name ?? asset?.id}
			battery={device?.state?.power?.battery_level}
			category={
				asset.category &&
				asset.group && (
					<CategorizationTag
						className={styles.class}
						categorization={{
							category: asset.category,
							group: asset.group,
						}}
					/>
				)
			}
			deviceName={mapDeviceHardware(
				normalizeDeviceHardware(device?.hardware),
			)}
			status={getDeviceStatus(device)}
			icons={icons}
		/>
	);
}
