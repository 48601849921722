// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.kMBOZqgjdf_v_1UpazSt{font-weight:500;font-size:24px}.DaGTKO9KolP9D4aRGm2q{font-weight:500;font-size:20px}.NNTU01zWNkzDgbgBOA1F{font-weight:500;font-size:12px}.dKHrubKO48tp9JILK6lD{font-size:18px}.oYVUZ3_AyrTitI15wipU{font-size:18px;font-weight:500}.za8Bkjxibonx9qXR5MWh{font-size:16px}.Yu2ayCiWjEQES29d2nSj{font-size:16px;font-weight:500}.mg42Q2ouoQq06z8AGNGw{font-size:14px}.G24T7mJvAjD6Q916Pw3a{font-size:14px;font-weight:500}.gEoCBZrFHBlQDnab5PDb{font-size:14px;font-weight:400}.zMaUtCEIyhns7HAe0nHL{font-size:12px}.CIN2a7WvVPNthXs_OSKv{font-size:12px;font-weight:500}.T94KWvjTiqcAlJkQTVsK{width:150px;height:119px;border-radius:12px;border:1px solid #3a58e2;background-color:#e5eaf0;display:flex;justify-content:center;align-items:center}.mbgWHLRyUplPbZwIhmGP{display:flex;flex-direction:row;align-items:center}.ojJ7YJXQnK2NwVhV5KEy{display:flex;flex-direction:column;align-items:center}.wyDAVR88xijqoNOMsjwj{color:#3a58e2;padding:0 0 4px 0;font-size:28px;font-weight:500}.KuLCa_Tykx9eAxhBFfGX{color:#00255d;font-size:14px;font-weight:500}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/components/dashboard/dropdown/anchor-detail/anchor-environment.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,WAAA,CACA,YAAA,CACA,kBAAA,CACA,wBAAA,CACA,wBDSY,CCPZ,YAAA,CACA,sBAAA,CACA,kBAAA,CAGD,sBACC,YAAA,CACA,kBAAA,CACA,kBAAA,CAGD,sBACC,YAAA,CACA,qBAAA,CACA,kBAAA,CAGD,sBACC,aDpBS,CCsBT,iBAAA,CACA,cAAA,CACA,eAAA,CAGD,sBACC,aDzBW,CC2BX,cAAA,CACA,eAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `kMBOZqgjdf_v_1UpazSt`;
export var h2 = `DaGTKO9KolP9D4aRGm2q`;
export var h3 = `NNTU01zWNkzDgbgBOA1F`;
export var p0 = `dKHrubKO48tp9JILK6lD`;
export var p0m = `oYVUZ3_AyrTitI15wipU`;
export var p1 = `za8Bkjxibonx9qXR5MWh`;
export var p1m = `Yu2ayCiWjEQES29d2nSj`;
export var p2 = `mg42Q2ouoQq06z8AGNGw`;
export var p2m = `G24T7mJvAjD6Q916Pw3a`;
export var p2n = `gEoCBZrFHBlQDnab5PDb`;
export var p3 = `zMaUtCEIyhns7HAe0nHL`;
export var p3m = `CIN2a7WvVPNthXs_OSKv`;
export var box = `T94KWvjTiqcAlJkQTVsK`;
export var container = `mbgWHLRyUplPbZwIhmGP`;
export var headers = `ojJ7YJXQnK2NwVhV5KEy`;
export var ratingHeader = `wyDAVR88xijqoNOMsjwj`;
export var textHeader = `KuLCa_Tykx9eAxhBFfGX`;
export default ___CSS_LOADER_EXPORT___;
