import React from "react";
import { getStatusColors, StatusType } from "../helper/status-helper";
import { classArr } from "../helper/style-helper";
import * as styles from "./velavu-card.module.scss";

/**
 * A reuseable box component with a color to draw attention
 * @param props.type The card color to use
 */
export default function VelavuCard(
	props: React.DetailedHTMLProps<
		React.HTMLAttributes<HTMLDivElement>,
		HTMLDivElement
	> & {
		type: StatusType;
		selectable?: boolean;
	},
) {
	const { type, selectable, ...domProps } = props;

	const colors = getStatusColors(type);
	return (
		<div
			{...domProps}
			style={{
				borderColor: colors.foreground,
				backgroundColor: colors.background,
				...domProps.style,
			}}
			className={classArr(
				styles.card,
				selectable && styles.selectable,
				domProps.className,
			)}
		/>
	);
}
