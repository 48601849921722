// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.gF2N2k6SdO6RbVZ3aFSa{font-weight:500;font-size:24px}.HcIbCGg05PAeYPYrVelD{font-weight:500;font-size:20px}._vYJ4T3BcC94n7RlDNvB{font-weight:500;font-size:12px}.toYtLgTbsfS7ia7dBSwg{font-size:18px}.DxS6BReuCNvZZdgMT_Bj{font-size:18px;font-weight:500}.s9r4e31E7ct6FcuMhmrN{font-size:16px}.Ej5dfUGITBxMVMZ4ZCBS{font-size:16px;font-weight:500}.QZOMmsWqFzV6y9H0c8ag{font-size:14px}.YGHz1X_otoHRJkJPphvM{font-size:14px;font-weight:500}.HwQealc3IQrrdFiwQx9H{font-size:14px;font-weight:400}.sUJzEq61XMlkPGLmgGSZ{font-size:12px}.O6E67Ac5U6D1gMUs1ouw{font-size:12px;font-weight:500}.DXqAI83wTOmQPERsjqiw{width:100%;box-sizing:border-box;padding-left:20px;padding-right:20px;padding-top:20px;display:flex;flex-direction:row;align-items:center;justify-content:space-between}.oi6vjMbuLQWM4kOVixe2{overflow-y:hidden;transition:height 250ms ease,opacity 250ms ease}.ISJfoASD1C5AfcmSiZLd{opacity:0;height:0}._rWDqqT9lyOhWN1llvGN{opacity:1;height:80px}.VrhtUpFwv8dwMgrDTEtv{padding-top:12px;padding-left:20px;padding-right:20px}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/components/dashboard/dropdown/map-list/map-list-selector.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,UAAA,CACA,qBAAA,CACA,iBAAA,CACA,kBAAA,CACA,gBAAA,CAEA,YAAA,CACA,kBAAA,CAEA,kBAAA,CACA,6BAAA,CAGD,sBACC,iBAAA,CACA,+CACC,CAIF,sBACC,SAAA,CACA,QAAA,CAGD,sBACC,SAAA,CACA,WAAA,CAGD,sBACC,gBAAA,CACA,iBAAA,CACA,kBAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `gF2N2k6SdO6RbVZ3aFSa`;
export var h2 = `HcIbCGg05PAeYPYrVelD`;
export var h3 = `_vYJ4T3BcC94n7RlDNvB`;
export var p0 = `toYtLgTbsfS7ia7dBSwg`;
export var p0m = `DxS6BReuCNvZZdgMT_Bj`;
export var p1 = `s9r4e31E7ct6FcuMhmrN`;
export var p1m = `Ej5dfUGITBxMVMZ4ZCBS`;
export var p2 = `QZOMmsWqFzV6y9H0c8ag`;
export var p2m = `YGHz1X_otoHRJkJPphvM`;
export var p2n = `HwQealc3IQrrdFiwQx9H`;
export var p3 = `sUJzEq61XMlkPGLmgGSZ`;
export var p3m = `O6E67Ac5U6D1gMUs1ouw`;
export var header = `DXqAI83wTOmQPERsjqiw`;
export var options = `oi6vjMbuLQWM4kOVixe2`;
export var optionsCollapsed = `ISJfoASD1C5AfcmSiZLd`;
export var optionsExpanded = `_rWDqqT9lyOhWN1llvGN`;
export var optionsContainer = `VrhtUpFwv8dwMgrDTEtv`;
export default ___CSS_LOADER_EXPORT___;
