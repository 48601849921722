import React, { CSSProperties } from "react";
import AssetCategorization, {
	mapCategoryColor,
	mapCategoryName,
} from "../data/asset-categorization";
import { AssetCategory } from "velavu-js-api";
import IconArrowRight from "../dynamicicons/icon-arrow-right";
import { classArr } from "../helper/style-helper";
import * as styles from "./categorization-tag.module.scss";

//Category > Group
export default function CategorizationTag(props: {
	style?: CSSProperties;
	className?: string;

	size?: "normal" | "large";
	categorization: AssetCategorization;

	selectable?: boolean;
	selected?: boolean;
	onClick?: () => void;
}) {
	const [bg, fg] = getColors(props.categorization.category, !!props.selected);

	return (
		<div
			style={{ backgroundColor: bg, color: fg, ...props.style }}
			className={classArr(
				props.size === "large" ? styles.tagLarge : styles.tagNormal,
				props.selectable && styles.selectable,
				props.className,
			)}
			onClick={props.onClick}
		>
			<span className={styles.class}>
				{mapCategoryName(props.categorization.category)}
			</span>
			<IconArrowRight className={styles.separator} color={fg} size={16} />
			<span className={styles.type}>{props.categorization.group}</span>
		</div>
	);
}

//Category
export function CategoryTag(props: {
	style?: CSSProperties;
	className?: string;

	size?: "normal" | "large";
	category: AssetCategory;

	selectable?: boolean;
	selected?: boolean;
	onClick?: () => void;
}) {
	const [bg, fg] = getColors(props.category, !!props.selected);

	return (
		<div
			style={{
				backgroundColor: bg,
				color: fg,
				borderColor: fg,
				...props.style,
			}}
			className={classArr(
				styles.border,
				props.size === "large" ? styles.tagLarge : styles.tagNormal,
				props.selectable && styles.selectable,
				props.className,
			)}
			onClick={props.onClick}
		>
			<span className={styles.class}>
				{mapCategoryName(props.category)}
			</span>
		</div>
	);
}

//Group
export function GroupTag(props: {
	style?: CSSProperties;
	className?: string;

	size?: "normal" | "large";
	categorization: AssetCategorization;

	selectable?: boolean;
	selected?: boolean;
	onClick?: () => void;
}) {
	const [bg, fg] = getColors(props.categorization.category, !!props.selected);

	return (
		<div
			style={{ backgroundColor: bg, color: fg, ...props.style }}
			className={classArr(
				props.size === "large" ? styles.tagLarge : styles.tagNormal,
				props.selectable && styles.selectable,
				props.className,
			)}
			onClick={props.onClick}
		>
			<span className={styles.type}>{props.categorization.group}</span>
		</div>
	);
}

function getColors(
	category: AssetCategory,
	selected: boolean,
): [string, string] {
	const [bg, fg] = mapCategoryColor(category);

	if (selected) {
		return [fg, "#FFFFFF"];
	} else {
		return [bg, fg];
	}
}
