import React, { useState } from "react";
import { Link, Location, useLocation } from "react-router-dom";
import {
	DeviceCategory,
	normalizeDeviceHardware,
	VelavuAPI,
	VelavuDevice,
} from "velavu-js-api";
import IconSearch from "../../../dynamicicons/icon-search";
import AssetAddWizard from "../../../elements/asset-add-wizard";
import * as wizardStyles from "../../../elements/asset-add-wizard.module.scss";
import DynamicBattery from "../../../elements/dynamic-battery";
import StatusIndicator from "../../../elements/status-indicator";
import VelavuArrowButton from "../../../elements/velavu-arrow-button";
import VelavuButton from "../../../elements/velavu-button";
import VelavuInput from "../../../elements/velavu-input";
import { useDebouncedEffect } from "../../../helper/hook-helper";
import { mapDeviceHardware } from "../../../helper/language-helper";
import { getDeviceStatus } from "../../../helper/status-helper";
import { AssetAddData } from "./asset-add-data";
import * as styles from "./asset-new-tag.module.scss";

export default function AssetNewTag() {
	const location = useLocation() as Location<{
		wizardData: AssetAddData;
	} | null>;
	const wizardData = location?.state?.wizardData;

	const [selectedTag, setSelectedTag] = useState<VelavuDevice | undefined>(
		wizardData?.tag,
	);

	const [filterText, setFilterText] = useState("");
	const searchText = filterText.trim();

	const [tags, setTags] = useState<VelavuDevice[]>([]);
	useDebouncedEffect(
		() => {
			let isValid = true;
			let effectiveSearchText: string | undefined;
			if (searchText.length === 0) {
				effectiveSearchText = undefined;
			} else {
				effectiveSearchText = searchText;
			}

			VelavuAPI.devices
				.getAllDevices({
					category: DeviceCategory.Tag,
					paired: false,
					id: effectiveSearchText,
				})
				.then((result) => {
					//Make sure this invocation is still valid
					if (!isValid) {
						return;
					}

					//Set the tags
					setTags(result.data);
				});

			//Invalidate on unmount
			return () => {
				isValid = false;
			};
		},
		400,
		true,
		[searchText, setTags],
	);

	return (
		<AssetAddWizard
			previous={
				<Link
					className={wizardStyles.navButton}
					to="/assets/create"
					state={{
						wizardData: {
							...wizardData,
							tag: selectedTag,
						} as AssetAddData,
					}}
				>
					<VelavuArrowButton direction="left" />
				</Link>
			}
			next={
				<Link
					className={wizardStyles.navButton}
					to="/assets/create/confirm"
					state={{
						wizardData: {
							...wizardData,
							tag: selectedTag,
						} as AssetAddData,
					}}
					onClick={(event) => !selectedTag && event.preventDefault()}
				>
					<VelavuArrowButton
						direction="right"
						disabled={!selectedTag}
					/>
				</Link>
			}
			stageCount={3}
			currentStage={1}
		>
			<div className={styles.container}>
				<div className={styles.header}>
					<h1>Pair with tag</h1>
					<Link
						style={{ marginLeft: 16, textDecoration: "none" }}
						to="/assets/create/confirm"
						state={{
							wizardData: wizardData,
						}}
					>
						<VelavuButton label="Skip" outlined size="small" />
					</Link>
				</div>

				<VelavuInput
					style={{ margin: "25px 20px" }}
					leadingIcon={<IconSearch size={16} color="#00255D" />}
					placeholder="Search Tags"
					round
					value={filterText}
					onChange={setFilterText}
					fullWidth
				/>

				<div className={styles.tableRow}>
					<div
						className={`${styles.tableColumn} ${styles.tableHeader}`}
					>
						Tag ID
					</div>
					<div
						className={`${styles.tableColumn} ${styles.tableHeader}`}
					>
						Tag type
					</div>
					<div
						className={`${styles.tableColumnNarrow} ${styles.tableHeader}`}
					>
						Battery
					</div>
				</div>
				<hr className={styles.divider} />
				<div className={styles.tableList}>
					{tags.map((tag) => (
						<TagRow
							key={tag.id}
							tag={tag}
							selected={selectedTag?.id === tag.id}
							onSelect={() => setSelectedTag(tag)}
						/>
					))}
				</div>
			</div>
		</AssetAddWizard>
	);
}

function TagRow(props: {
	tag: VelavuDevice;
	selected?: boolean;
	onSelect: () => void;
}) {
	return (
		<div
			className={`${styles.tableRow} ${styles.contentRow} ${
				props.selected
					? styles.tableRowSelected
					: styles.tableRowDeselected
			}`}
			onClick={props.onSelect}
		>
			<div className={styles.tableColumn}>
				<StatusIndicator status={getDeviceStatus(props.tag)} />
				<span style={{ marginLeft: 12 }}>{props.tag.id}</span>
			</div>
			<div className={styles.tableColumn}>
				{mapDeviceHardware(normalizeDeviceHardware(props.tag.hardware))}
			</div>
			<div className={styles.tableColumnNarrow}>
				<DynamicBattery resource={props.tag.state?.power} />
			</div>
		</div>
	);
}
