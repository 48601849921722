// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.i4WpeNmNW513vrd_rEgH{font-weight:500;font-size:24px}.QP97baDlhawHFCHUJRWB{font-weight:500;font-size:20px}.RDsxUtLGhmEcOjtQsf9k{font-weight:500;font-size:12px}.znNh4dp7Wc5jj2KRLJjl{font-size:18px}.mlYV0uAsa6JY0fUUqHIY{font-size:18px;font-weight:500}.X8bLmmQuzquBsRV3P74m{font-size:16px}.sF9AGXUM3s2_0uLaj9Rr{font-size:16px;font-weight:500}.IhRgKMuxM9B09DPHJrQ5{font-size:14px}.Q3zB81fFl_BxWDNovhGI{font-size:14px;font-weight:500}.HP39rNz0VLFvrWb6nIKN{font-size:14px;font-weight:400}.PDjkxhDHmwlEMkWvu0wG{font-size:12px}.DjQCh1vrMOdj1Tw4pg4L{font-size:12px;font-weight:500}.fWXWGu5b9fujrA8rJ_pJ{width:360px;max-height:100%;border-radius:20px;display:flex;flex-direction:column;background-color:#fff;box-shadow:2px 5px 10px 0 rgba(0,37,93,.15)}.fWXWGu5b9fujrA8rJ_pJ>*{flex-shrink:0}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/components/dashboard/dropdown/pinned-list.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,WD6HgB,CC5HhB,eAAA,CACA,kBAAA,CAEA,YAAA,CACA,qBAAA,CAEA,qBAAA,CACA,2CAAA,CAEA,wBACC,aAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `i4WpeNmNW513vrd_rEgH`;
export var h2 = `QP97baDlhawHFCHUJRWB`;
export var h3 = `RDsxUtLGhmEcOjtQsf9k`;
export var p0 = `znNh4dp7Wc5jj2KRLJjl`;
export var p0m = `mlYV0uAsa6JY0fUUqHIY`;
export var p1 = `X8bLmmQuzquBsRV3P74m`;
export var p1m = `sF9AGXUM3s2_0uLaj9Rr`;
export var p2 = `IhRgKMuxM9B09DPHJrQ5`;
export var p2m = `Q3zB81fFl_BxWDNovhGI`;
export var p2n = `HP39rNz0VLFvrWb6nIKN`;
export var p3 = `PDjkxhDHmwlEMkWvu0wG`;
export var p3m = `DjQCh1vrMOdj1Tw4pg4L`;
export var container = `fWXWGu5b9fujrA8rJ_pJ`;
export default ___CSS_LOADER_EXPORT___;
