// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.h1{font-weight:500;font-size:24px}.h2{font-weight:500;font-size:20px}.h3{font-weight:500;font-size:12px}.p0{font-size:18px}.p0m{font-size:18px;font-weight:500}.p1{font-size:16px}.p1m{font-size:16px;font-weight:500}.p2{font-size:14px}.p2m{font-size:14px;font-weight:500}.p2n{font-size:14px;font-weight:400}.p3{font-size:12px}.p3m{font-size:12px;font-weight:500}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,IACC,eAAA,CACA,cAAA,CAGD,IACC,eAAA,CACA,cAAA,CAGD,IACC,eAAA,CACA,cAAA,CAGD,IACC,cAAA,CAGD,KACC,cAAA,CACA,eAAA,CAGD,IACC,cAAA,CAGD,KACC,cAAA,CACA,eAAA,CAGD,IACC,cAAA,CAGD,KACC,cAAA,CACA,eAAA,CAGD,KACC,cAAA,CACA,eAAA,CAGD,IACC,cAAA,CAGD,KACC,cAAA,CACA,eAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export default ___CSS_LOADER_EXPORT___;
