// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.NJQEuEefKCZek3Z7QwX3{font-weight:500;font-size:24px}.x_98qazwvbtZlw87NQtn{font-weight:500;font-size:20px}.Ar3xNetwYSRG73MWLOgv{font-weight:500;font-size:12px}.JHeVfpcw29ccyJv40Ytx{font-size:18px}.xEYMqzVoqUAN5sEJGGU0{font-size:18px;font-weight:500}.nri3O_WujeCRiEzuvXyS{font-size:16px}.mOXw9Qlr3XFkT6KOFW_p{font-size:16px;font-weight:500}.WfFAxxl_SB4zDs_ZFJUu{font-size:14px}.IniLcvDiYclgyFHBvJlJ{font-size:14px;font-weight:500}.x1Fd5cp0lE1qOc1kZ3pi{font-size:14px;font-weight:400}.AZ2e4qzMPf17AuVURG2Z{font-size:12px}.LZTeE4el2Ga5syRM5lk2{font-size:12px;font-weight:500}.xnfiUb99SHGp7rCIC_FJ{background-color:rgba(240,245,253,.8509803922);border:1px solid #3a58e2;color:#3a58e2;border-radius:20px;padding:8px;box-sizing:border-box;display:flex;flex-direction:row;gap:12px;align-items:center;justify-content:center;transition:transform 250ms ease;position:absolute;top:0;left:50%;transform:translateX(-50%)}.IwghXmP_WuJxfWnkARZL{transform:translate(-50%, -100%)}.PQShcLvZhHQrjCpdTt22{transform:translate(-50%, 20px)}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/elements/floating-popup-panel.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,8CAAA,CACA,wBAAA,CACA,aDES,CCDT,kBAAA,CAEA,WAAA,CACA,qBAAA,CAEA,YAAA,CACA,kBAAA,CACA,QAAA,CACA,kBAAA,CACA,sBAAA,CAEA,+BAAA,CAEA,iBAAA,CACA,KAAA,CACA,QAAA,CACA,0BAAA,CAGD,sBACC,gCAAA,CAGD,sBACC,+BAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `NJQEuEefKCZek3Z7QwX3`;
export var h2 = `x_98qazwvbtZlw87NQtn`;
export var h3 = `Ar3xNetwYSRG73MWLOgv`;
export var p0 = `JHeVfpcw29ccyJv40Ytx`;
export var p0m = `xEYMqzVoqUAN5sEJGGU0`;
export var p1 = `nri3O_WujeCRiEzuvXyS`;
export var p1m = `mOXw9Qlr3XFkT6KOFW_p`;
export var p2 = `WfFAxxl_SB4zDs_ZFJUu`;
export var p2m = `IniLcvDiYclgyFHBvJlJ`;
export var p2n = `x1Fd5cp0lE1qOc1kZ3pi`;
export var p3 = `AZ2e4qzMPf17AuVURG2Z`;
export var p3m = `LZTeE4el2Ga5syRM5lk2`;
export var panel = `xnfiUb99SHGp7rCIC_FJ`;
export var panel__hide = `IwghXmP_WuJxfWnkARZL`;
export var panel__show = `PQShcLvZhHQrjCpdTt22`;
export default ___CSS_LOADER_EXPORT___;
