import {
	AnyVelavuEvent,
	GetEssentialEventsParams,
	VelavuAPI,
} from "velavu-js-api";
import { EventGroup, filterEventsByGroup } from "../data/event-group";
import { useMemo, useState } from "react";
import { useCancellableEffect } from "./hook-helper";
import { compareEventAsc, compareEventDesc } from "./events-helper";

/**
 * Custom hook to fetch, filter, and sort essential events.
 *
 * @param eventsParams - Parameters for fetching essential events.
 * @param group - The group to filter events by.
 * @param sortNew - Boolean indicating whether to sort events in descending order.
 * @returns An array of filtered and sorted events or undefined if events are not yet fetched.
 */
export function useFilteredEssentialEvents(
	eventsParams: GetEssentialEventsParams,
	group: EventGroup,
	sortNew: boolean,
): AnyVelavuEvent[] | undefined {
	//Fetch events
	const [events, setEvents] = useState<AnyVelavuEvent[] | undefined>(
		undefined,
	);
	useCancellableEffect(
		(addPromise) => {
			setEvents(undefined);

			addPromise(VelavuAPI.events.getEssentialEvents(eventsParams))
				.then((result) => setEvents(result))
				.catch(console.warn);
		},
		[setEvents, eventsParams],
	);

	//Filter the events by the group
	const filteredEvents = useMemo(() => {
		return filterEventsByGroup(events, group);
	}, [events, group]);

	//Sort the events
	const sortedEvents = useMemo(() => {
		return filteredEvents?.sort(
			sortNew ? compareEventDesc : compareEventAsc,
		);
	}, [filteredEvents, sortNew]);

	return sortedEvents;
}
