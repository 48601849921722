import React, { useMemo, useState } from "react";
import * as styles from "./events-detail-list.module.scss";
import { useDateRange } from "../data/date-range";
import { EventGroup } from "../data/event-group";
import { useFilteredEssentialEvents } from "../helper/events-hook";
import EventSelect from "./event-select";
import DatePicker from "./date-picker";
import Divider from "./divider";
import EventList from "./event-list";

export type EventsDetailListProps = {
	groups?: readonly EventGroup[];
} & EventsDetailListTarget;

export type EventsDetailListTarget =
	| {
			deviceID: string;
	  }
	| {
			assetID: string;
	  };

/// A component for a device detail panel that shows filterable and sortable events
export default function EventsDetailList(props: EventsDetailListProps) {
	//Last week -> Today
	const [dateRange, setDateRange] = useDateRange(7);
	const [sortNew, setSortNew] = useState(true);

	//Selected group
	const [group, setGroup] = useState(EventGroup.All);

	//Fetch events
	const events = useFilteredEssentialEvents(
		useMemo(() => {
			if ("deviceID" in props) {
				return {
					deviceID: props.deviceID,
					since: dateRange.start,
					until: dateRange.end,
				};
			} else {
				return {
					assetID: props.assetID,
					since: dateRange.start,
					until: dateRange.end,
				};
			}
			//eslint-disable-next-line react-hooks/exhaustive-deps
		}, ["deviceID" in props ? props.deviceID : props.assetID, dateRange]),
		group,
		sortNew,
	);

	return (
		<>
			<EventSelect
				className={styles.dropdown}
				options={props.groups}
				value={group}
				onChange={setGroup}
				fullWidth
			/>

			<DatePicker
				range={dateRange}
				onUpdate={setDateRange}
				sortNew={sortNew}
				setSortNew={setSortNew}
			/>

			<Divider />

			<EventList events={events} />
		</>
	);
}
