import { DateTime } from "luxon";
import React, {
	CSSProperties,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from "react";
import {
	DFUImage,
	DFURequest,
	parseDFUName,
	VelavuAPI,
	VelavuDeviceVesta,
} from "velavu-js-api";
import IconClose from "../../dynamicicons/icon-close";
import IconDownload from "../../dynamicicons/icon-download";
import VelavuCard from "../../elements/velavu-card";
import VelavuExpandMenu from "../../elements/velavu-expand-menu";
import {
	VelavuTableBody,
	VelavuTableColumn,
	VelavuTableDivider,
	VelavuTableHeader,
	VelavuTableHeaderLabel,
	VelavuTableRoundContainer,
	VelavuTableRow,
} from "../../elements/velavu-header-table";
import { useVelavuModal } from "../../elements/velavu-modal";
import { useCancellableEffect, useToggleable } from "../../helper/hook-helper";
import { mapDFURequestStatus } from "../../helper/language-helper";
import { StatusType } from "../../helper/status-helper";
import * as globals from "../../styles/global.icss.scss";
import TabTagDFUInstallModal from "./tab-tag-dfu-install-modal";
import * as styles from "./tab-tag-dfu.module.scss";

const displayLimitDefault = 2;
const displayLimitMore = 4;

function ImageTableRow(props: { image: DFUImage; onInstall?: VoidFunction }) {
	const [hoverState, enterHoverState, exitHoverState] = useToggleable();

	return (
		<VelavuTableRow
			onMouseEnter={enterHoverState}
			onMouseLeave={exitHoverState}
			selectable
			onClick={props.onInstall}
		>
			<VelavuTableColumn flex={15}>
				{props.image.version}
			</VelavuTableColumn>
			<VelavuTableColumn flex={35}>
				{DateTime.fromISO(props.image.release_date).toLocaleString(
					DateTime.DATE_MED,
				)}
			</VelavuTableColumn>
			<VelavuTableColumn flex={50}>
				{props.image.comment}
			</VelavuTableColumn>
			<VelavuTableHeaderLabel type="edge" width={24}>
				<IconDownload
					className={`${styles.hoverIcon} ${
						hoverState ? styles.hoverIconShow : ""
					}`}
					color={globals.textSecondary}
				/>
			</VelavuTableHeaderLabel>
		</VelavuTableRow>
	);
}

function RequestTableRow(props: {
	request: DFURequest;
	onRemove?: VoidFunction;
}) {
	const [hoverState, enterHoverState, exitHoverState] = useToggleable();

	return (
		<VelavuTableRow
			onMouseEnter={enterHoverState}
			onMouseLeave={exitHoverState}
			selectable
			onClick={props.onRemove}
		>
			<VelavuTableColumn flex={15}>
				{parseDFUName(props.request.data.name).version}
			</VelavuTableColumn>
			<VelavuTableColumn flex={35}>
				{DateTime.fromISO(props.request.timestamp).toLocaleString(
					DateTime.DATETIME_MED,
				)}
			</VelavuTableColumn>
			<VelavuTableColumn flex={50}>
				{mapDFURequestStatus(props.request.data.status)}
			</VelavuTableColumn>
			<VelavuTableHeaderLabel type="edge" width={24}>
				<IconClose
					className={`${styles.hoverIcon} ${
						hoverState ? styles.hoverIconShow : ""
					}`}
					color={globals.textSecondary}
				/>
			</VelavuTableHeaderLabel>
		</VelavuTableRow>
	);
}

export default function TabTagDFU(props: {
	style?: CSSProperties;
	className?: string;
	tag: VelavuDeviceVesta;
}) {
	const tagID = props.tag.id;
	const tagVersionName = props.tag.state?.app_version;

	//Images (newest to oldest)
	const [images, setImages] = useState<DFUImage[] | undefined>(undefined);
	useCancellableEffect(
		(addPromise) => {
			addPromise(VelavuAPI.dfu.getDFUImages(tagID)).then(setImages);
		},
		[tagID, setImages],
	);
	const [displayLimitImages, setDisplayLimitImages] =
		useState(displayLimitDefault);
	const increaseDisplayLimitImages = useCallback(
		() => setDisplayLimitImages((limit) => limit + displayLimitMore),
		[setDisplayLimitImages],
	);
	//If we're only hiding one item under "show more", we're not saving space, so we might as well show it
	const adjustedDisplayLimitImages = useMemo(
		() =>
			displayLimitImages + 1 === images?.length
				? images.length
				: displayLimitImages,
		[displayLimitImages, images],
	);

	//Requests (newest to oldest)
	const [requests, setRequests] = useState<DFURequest[] | undefined>(
		undefined,
	);
	useCancellableEffect(
		(addPromise) => {
			addPromise(VelavuAPI.dfu.getDFURequests(tagID)).then(setRequests);
		},
		[tagID, setRequests],
	);
	const [displayLimitRequests, setDisplayLimitRequests] =
		useState(displayLimitDefault);
	const increaseDisplayLimitRequests = useCallback(
		() => setDisplayLimitRequests((limit) => limit + displayLimitMore),
		[setDisplayLimitRequests],
	);
	const adjustedDisplayLimitRequests = useMemo(
		() =>
			displayLimitRequests + 1 === requests?.length
				? requests.length
				: displayLimitRequests,
		[displayLimitRequests, requests],
	);

	//Suggest the user to update to a newer version
	const [updateSuggestion, setUpdateSuggestion] = useState(false);
	useEffect(() => {
		//Wait for data to load
		if (images === undefined || requests === undefined) return;

		//Don't suggest an upgrade if there are no images available
		if (images.length === 0) {
			setUpdateSuggestion(false);
			return;
		}

		//Get the latest image
		const latestImage = images[0];

		//If we've already put in a request, don't suggest an upgrade
		if (requests.length > 0 && requests[0].data.name === latestImage.name) {
			setUpdateSuggestion(false);
			return;
		}

		//If we're already on the latest version, don't suggest an upgrade
		if (latestImage.name === tagVersionName) {
			setUpdateSuggestion(false);
			return;
		}

		//Suggest to upgrade to the latest image
		setUpdateSuggestion(true);
	}, [images, requests, tagVersionName, setUpdateSuggestion]);
	const updateSuggestionFirmware = images?.[0];

	const pushModal = useVelavuModal();
	const onInstallFirmware = useCallback(
		(image: DFUImage) => {
			pushModal((resolve) => (
				<TabTagDFUInstallModal
					tag={props.tag}
					image={image}
					onConfirm={() => resolve(true)}
					onCancel={() => resolve(false)}
				/>
			))
				.then((result) => {
					if (!result) throw new Error("User cancelled request");

					//Put in a request
					return VelavuAPI.dfu.createDFURequest(
						props.tag.id,
						image.name,
					);
				})
				.then((request: DFURequest) => {
					//Update the request list
					setRequests((requests) =>
						requests === undefined
							? undefined
							: [request].concat(requests),
					);
				})
				.catch(console.log);
		},
		[setRequests, pushModal, props.tag],
	);

	const onDeleteRequest = useCallback(
		(requestID: string) => {
			VelavuAPI.dfu.deleteDFURequest(requestID);

			setRequests((requests) => {
				if (requests === undefined) return undefined;

				const index = requests.findIndex(
					(request) => request.id === requestID,
				);
				if (index === -1) return requests;

				const newRequests = [...requests];
				newRequests.splice(index, 1);
				return newRequests;
			});
		},
		[setRequests],
	);

	return (
		<VelavuExpandMenu
			style={props.style}
			className={props.className}
			label="Device firmware update"
			indicator={updateSuggestion ? StatusType.blue : undefined}
		>
			{updateSuggestion && updateSuggestionFirmware !== undefined && (
				<VelavuCard
					className={`${styles.itemMargin} ${styles.updateCard}`}
					type={StatusType.blue}
					selectable
					onClick={() => onInstallFirmware(updateSuggestionFirmware)}
				>
					<div className={styles.updateCardText}>
						<div
							className={styles.updateCardTextTitle}
						>{`Firmware update to ${updateSuggestionFirmware.version} available`}</div>
						<div
							className={styles.updateCardTextBody}
						>{`${DateTime.fromISO(
							updateSuggestionFirmware.release_date,
						).toLocaleString(DateTime.DATE_MED)} • ${
							updateSuggestionFirmware.comment
						}`}</div>
					</div>

					<IconDownload color={globals.textPrimary} />
				</VelavuCard>
			)}

			{images !== undefined && images.length > 0 && (
				<>
					<span className={styles.tableTitle}>
						Available releases
					</span>

					<VelavuTableRoundContainer className={styles.itemMargin}>
						<VelavuTableHeader>
							<VelavuTableHeaderLabel flex={15}>
								Version
							</VelavuTableHeaderLabel>
							<VelavuTableHeaderLabel flex={35}>
								Release date
							</VelavuTableHeaderLabel>
							<VelavuTableHeaderLabel flex={50}>
								Notes
							</VelavuTableHeaderLabel>
							<VelavuTableHeaderLabel type="edge" width={24} />
						</VelavuTableHeader>

						<VelavuTableDivider />

						<VelavuTableBody>
							{images
								.slice(0, adjustedDisplayLimitImages)
								.map((image) => (
									<ImageTableRow
										key={image.name}
										image={image}
										onInstall={() =>
											onInstallFirmware(image)
										}
									/>
								))}
							{images.length > adjustedDisplayLimitImages && (
								<VelavuTableRow
									className={styles.rowShowMore}
									selectable
									onClick={increaseDisplayLimitImages}
								>
									<span className={styles.rowShowMoreText}>
										Show more
									</span>
								</VelavuTableRow>
							)}
						</VelavuTableBody>
					</VelavuTableRoundContainer>
				</>
			)}

			{requests !== undefined && requests.length > 0 && (
				<>
					<span className={styles.tableTitle}>Update tasks</span>

					<VelavuTableRoundContainer className={styles.itemMargin}>
						<VelavuTableHeader>
							<VelavuTableHeaderLabel flex={15}>
								Version
							</VelavuTableHeaderLabel>
							<VelavuTableHeaderLabel flex={35}>
								Install date
							</VelavuTableHeaderLabel>
							<VelavuTableHeaderLabel flex={50}>
								Status
							</VelavuTableHeaderLabel>
							<VelavuTableHeaderLabel type="edge" width={24} />
						</VelavuTableHeader>

						<VelavuTableDivider />

						<VelavuTableBody>
							{requests
								.slice(0, adjustedDisplayLimitRequests)
								.map((request) => (
									<RequestTableRow
										key={request.id}
										request={request}
										onRemove={() =>
											onDeleteRequest(request.id)
										}
									/>
								))}
							{requests.length > adjustedDisplayLimitRequests && (
								<VelavuTableRow
									className={styles.rowShowMore}
									selectable
									onClick={increaseDisplayLimitRequests}
								>
									<span className={styles.rowShowMoreText}>
										Show more
									</span>
								</VelavuTableRow>
							)}
						</VelavuTableBody>
					</VelavuTableRoundContainer>
				</>
			)}
		</VelavuExpandMenu>
	);
}
