import React, { Dispatch, SetStateAction, useMemo } from "react";
import { DeviceCategory, NormalizedDeviceHardware } from "velavu-js-api";
import ConnectionStatus from "../data/connection-status";
import IconCircleDelete from "../dynamicicons/icon-circle-delete";
import IconReboot from "../dynamicicons/icon-reboot";
import {
	mapConnectionStatus,
	mapDeviceCategory,
	mapDeviceHardware,
} from "../helper/language-helper";
import ChipFilterDivider from "./chip-filter-divider";
import ChipFilterMenu from "./chip-filter-menu";
import * as styles from "./device-filter-menu.module.scss";
import DeviceIcon from "./device-icon";
import StatusIndicator from "./status-indicator";
import VelavuButton from "./velavu-button";
import VelavuSwitch from "./velavu-switch";

export default function DeviceFilterMenu(props: {
	filterCategory: DeviceCategory | null;
	setFilterCategory: Dispatch<SetStateAction<DeviceCategory | null>>;
	filterHardware: NormalizedDeviceHardware | null;
	setFilterHardware: Dispatch<
		SetStateAction<NormalizedDeviceHardware | null>
	>;
	filterStatus: ConnectionStatus | null;
	setFilterStatus: Dispatch<SetStateAction<ConnectionStatus | null>>;
	filterUnpaired: boolean;
	setFilterUnpaired: Dispatch<SetStateAction<boolean>>;
	filterUnconfigured: boolean;
	setFilterUnconfigured: Dispatch<SetStateAction<boolean>>;
	selectedDevices: string[];
	isDirty: boolean;
	onApply: VoidFunction;
	onReboot: VoidFunction;
	onUnregister: VoidFunction;
}) {
	const propsFilterCategory = props.filterCategory;

	const disabledHardware = useMemo(() => {
		if (propsFilterCategory === DeviceCategory.Anchor) {
			return [
				NormalizedDeviceHardware.Juno,
				NormalizedDeviceHardware.Pisces,
				NormalizedDeviceHardware.Argo,
			];
		}
		return [];
	}, [propsFilterCategory]);

	return (
		<div className={styles.wrapper}>
			<div className={styles.container}>
				{props.selectedDevices.length > 0 ? (
					<>
						<div className={styles.selectedDevices}>
							<label>Selected: </label>
							<div className={styles.selectedDevicesTally}>
								<label>{props.selectedDevices.length}</label>
							</div>
						</div>
						<ChipFilterDivider />
						<div className={styles.actionButtons}>
							<button
								className={styles.rebootButton}
								onClick={props.onReboot}
							>
								<IconReboot color="#00255D" size={16} />
								<span>Reboot</span>
							</button>
							<button
								className={styles.unregisterButton}
								onClick={props.onUnregister}
							>
								<IconCircleDelete color="#da3e52" size={16} />
								<span>Unregister</span>
							</button>
						</div>
					</>
				) : (
					<>
						<ChipFilterMenu
							title={"Type"}
							options={[null, ...Object.values(DeviceCategory)]}
							selected={props.filterCategory}
							setSelected={props.setFilterCategory}
							label={(category) =>
								category === null
									? "All"
									: mapDeviceCategory(category)
							}
							keyExtractor={(category) =>
								category === null ? "all" : category
							}
						/>
						<ChipFilterDivider />
						<ChipFilterMenu
							title={"Device"}
							options={[
								null,
								...Object.values(NormalizedDeviceHardware),
							]}
							selected={props.filterHardware}
							setSelected={props.setFilterHardware}
							disabledOptions={disabledHardware}
							label={(hardware) =>
								hardware === null
									? "All"
									: mapDeviceHardware(hardware)
							}
							useDropdown
							dropdownMode="column"
							renderDropdownItem={(hardware) => (
								<div className={styles.deviceFilterItem}>
									<DeviceIcon hardware={hardware!} />
									<span style={{ marginLeft: 8 }}>
										{mapDeviceHardware(hardware!)}
									</span>
								</div>
							)}
							keyExtractor={(hardware) =>
								hardware === null ? "all" : hardware
							}
						/>
						<ChipFilterDivider />
						<ChipFilterMenu
							title={"Status"}
							options={[null, "active", "inactive", "registered"]}
							selected={props.filterStatus}
							setSelected={props.setFilterStatus}
							label={(status) =>
								status === null
									? "All"
									: mapConnectionStatus(status)
							}
							useDropdown
							dropdownMode="column"
							renderDropdownItem={(status) => (
								<div className={styles.deviceFilterItem}>
									<StatusIndicator
										status={status!}
										size={12}
									/>
									<span style={{ marginLeft: 8 }}>
										{mapConnectionStatus(status!)}
									</span>
								</div>
							)}
						/>
						<ChipFilterDivider />
						<VelavuSwitch
							className={styles.devicePairedSwitch}
							label="Unpaired"
							toggled={props.filterUnpaired}
							onChange={() =>
								props.setFilterUnpaired(!props.filterUnpaired)
							}
						/>
						<VelavuSwitch
							className={styles.devicePairedSwitch}
							label="Unconfigured"
							toggled={props.filterUnconfigured}
							onChange={() =>
								props.setFilterUnconfigured(
									!props.filterUnconfigured,
								)
							}
						/>
						{props.isDirty && (
							<VelavuButton
								outlined
								label="Apply"
								onClick={props.onApply}
								className={styles.applyFiltersButton}
							/>
						)}
					</>
				)}
			</div>
		</div>
	);
}
