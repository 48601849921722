// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.dWA_UctLwVe_Aspv2dXH{font-weight:500;font-size:24px}.WfWQk8Zot7tqL3qDUca5{font-weight:500;font-size:20px}.ANMftYqtGxm8FEiwxZm3{font-weight:500;font-size:12px}.dMTUgGXIgSaolxNlVssE{font-size:18px}.cJN50NMGih8W7dqkl7Jv{font-size:18px;font-weight:500}.yfHuc2NsIZCMfKemAWd8{font-size:16px}.JcVsTTNwNPEHzjSi5kjw{font-size:16px;font-weight:500}.NwOrLiAdJex_quBPbTU3{font-size:14px}.xOjHXkjmipnHrLILAT9k{font-size:14px;font-weight:500}.NB92px6PcfrBV23kKC1C{font-size:14px;font-weight:400}.Hm0PyfNTVObS6IscC7fA{font-size:12px}.txUp7FVAq3awysdi3jtX{font-size:12px;font-weight:500}.W2xnQlnxC13fZIf42bHN{position:relative;background-color:#e5eaf0;height:100%;width:100%;padding:20px;box-sizing:border-box;display:flex;flex-direction:row;align-items:stretch}.E_gz3ZVCeT5DQjjWX3Xb{border-radius:20px;background-color:#fff;display:flex;flex-direction:column}.Sb6gR_hrDEtkK37lZyF7{flex:1 1 0}.RXubG8kuTsxAAP096vMm{flex:1 1 0;max-width:460px}.cB9dVSsUDtoUaJRkBJio{height:100%;flex:1.6 1 0;margin-left:20px;display:flex;flex-direction:column}.cB9dVSsUDtoUaJRkBJio .E_gz3ZVCeT5DQjjWX3Xb:not(:first-child){margin-top:20px}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/components/analytics/analytics.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CCzGD,sBACC,iBAAA,CACA,wBAAA,CACA,WAAA,CACA,UAAA,CAEA,YARS,CAST,qBAAA,CAEA,YAAA,CACA,kBAAA,CACA,mBAAA,CAGD,sBACC,kBAAA,CACA,qBAAA,CAEA,YAAA,CACA,qBAAA,CAGD,sBACC,UAAA,CAGD,sBACC,UAAA,CACA,eAAA,CAGD,sBACC,WAAA,CAEA,YAAA,CAEA,gBAAA,CAEA,YAAA,CACA,qBAAA,CAEA,8DACC,eAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `dWA_UctLwVe_Aspv2dXH`;
export var h2 = `WfWQk8Zot7tqL3qDUca5`;
export var h3 = `ANMftYqtGxm8FEiwxZm3`;
export var p0 = `dMTUgGXIgSaolxNlVssE`;
export var p0m = `cJN50NMGih8W7dqkl7Jv`;
export var p1 = `yfHuc2NsIZCMfKemAWd8`;
export var p1m = `JcVsTTNwNPEHzjSi5kjw`;
export var p2 = `NwOrLiAdJex_quBPbTU3`;
export var p2m = `xOjHXkjmipnHrLILAT9k`;
export var p2n = `NB92px6PcfrBV23kKC1C`;
export var p3 = `Hm0PyfNTVObS6IscC7fA`;
export var p3m = `txUp7FVAq3awysdi3jtX`;
export var page = `W2xnQlnxC13fZIf42bHN`;
export var card = `E_gz3ZVCeT5DQjjWX3Xb`;
export var cardData = `Sb6gR_hrDEtkK37lZyF7`;
export var sidebar = `RXubG8kuTsxAAP096vMm`;
export var content = `cB9dVSsUDtoUaJRkBJio`;
export default ___CSS_LOADER_EXPORT___;
