import React, { FormEvent, useCallback, useState } from "react";
import VelavuButton from "../../elements/velavu-button";
import VelavuInput from "../../elements/velavu-input";
import VelavuNotice from "../../elements/velavu-notice";
import { checkEmail } from "../../helper/validation-helper";
import OnboardingFrame from "./onboarding-frame";
import { OnboardingFrameProps } from "./onboarding-shared-data";
import * as styles from "./onboarding-step-invite.module.scss";
import { VelavuAPI } from "velavu-js-api";

interface OnboardingStepInviteProps extends OnboardingFrameProps {
	organizationName: string;
}

export default function OnboardingStepInvite(props: OnboardingStepInviteProps) {
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");

	const [userInvited, setUserInvited] = useState(false);
	const [isLoading, setLoading] = useState(false);

	const [result, setResult] = useState<
		{ success: boolean; description: string } | undefined
	>(undefined);

	const clearResult = useCallback(() => {
		if (result) setResult(undefined);
	}, [result, setResult]);
	const updateName = useCallback(
		(value: string) => {
			//Clear the result
			clearResult();
			setName(value);
		},
		[clearResult, setName],
	);
	const updateEmail = useCallback(
		(value: string) => {
			//Clear the result
			clearResult();
			setEmail(value);
		},
		[clearResult, setEmail],
	);

	const submit = useCallback(
		(event: FormEvent<HTMLFormElement>) => {
			event.preventDefault();

			setLoading(true);

			VelavuAPI.users
				.registerNewUser({
					name: name,
					email: email,
				})
				.then(() => {
					//Setting the result and clearing the inputs
					setResult({
						success: true,
						description: `${email} has been invited`,
					});
					setName("");
					setEmail("");
					setUserInvited(true);
				})
				.catch((error) => {
					console.log(error.response);

					if (error.response?.status === 409) {
						setResult({
							success: false,
							description: `${email} is already a member`,
						});
					} else {
						setResult({
							success: false,
							description: `${email} couldn't be invited`,
						});
					}
				})
				.finally(() => {
					setLoading(false);
				});
		},
		[name, email, setResult, setName, setEmail, setLoading, setUserInvited],
	);

	return (
		<OnboardingFrame
			title="Invite members"
			description="Input the name and email of additional people that will require access to the dashboard."
			buttonSecondary={{
				label: "Back",
				onClick: props.navigateBackwards,
			}}
			buttonPrimary={{
				label: userInvited ? "Next" : "Skip",
				onClick: props.navigateForwards,
			}}
			notice="You can also invite or manage members within the settings menu at any point."
		>
			<form onSubmit={submit}>
				<VelavuInput
					value={name}
					onChange={updateName}
					onFocus={clearResult}
					titleText="Name"
					autoComplete="name"
					fullWidth
					bottomPadding
				/>
				<VelavuInput
					value={email}
					onChange={updateEmail}
					onFocus={clearResult}
					titleText="Email address"
					autoComplete="email"
					fullWidth
				/>
				<div className={styles.submitBar}>
					{result ? (
						<VelavuNotice
							type={result.success ? "confirm" : "error"}
							body={result.description}
						/>
					) : (
						<VelavuButton
							className={styles.submitButton}
							label="Send"
							disabled={
								isLoading ||
								name.length === 0 ||
								!checkEmail(email)
							}
							submit
							fullWidth
						/>
					)}
				</div>
			</form>
		</OnboardingFrame>
	);
}
