import React from "react";
import * as styles from "./map-list-selector-dropdown.module.scss";
import {
	MapListDisplayType,
	mapListDisplayTypeToIcon,
} from "./map-list-display-type";
import InsetIcon from "../../../../elements/inset-icon";
import { mapMapListDisplayType } from "../../../../helper/language-helper";
import IconArrowDown from "../../../../dynamicicons/icon-arrow-down";
import IconArrowUp from "../../../../dynamicicons/icon-arrow-up";

export interface MapListSelectorDropdownProps {
	displayType: MapListDisplayType;
	isOpen?: boolean;
	onClick?: () => void;
}

/**
 * The active dropdown component of the map list header menu
 */
export default function MapListDropdownSelector(
	props: MapListSelectorDropdownProps,
) {
	return (
		<button className={styles.dropdown} onClick={props.onClick}>
			<InsetIcon icon={mapListDisplayTypeToIcon(props.displayType)} />
			<span className={styles.title}>
				{mapMapListDisplayType(props.displayType)}
			</span>
			{props.isOpen ? (
				<IconArrowUp color="#00255D" />
			) : (
				<IconArrowDown color="#00255D" />
			)}
		</button>
	);
}
