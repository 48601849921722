import React, { CSSProperties } from "react";
import * as styles from "./selectable-card.module.scss";
import { classArr } from "../helper/style-helper";

interface SelectableCardProps {
	style?: CSSProperties;
	className?: string;
	disableHover?: boolean;
	onClick?: VoidFunction;
	onMouseEnter?: VoidFunction;
	onMouseLeave?: VoidFunction;
	children?: React.ReactNode;
}

export default function SelectableCard(props: SelectableCardProps) {
	return (
		<div
			style={props.style}
			className={classArr(
				props.className,
				styles.container,
				props.disableHover && styles.disableHover,
			)}
			onClick={props.onClick}
			onMouseEnter={props.onMouseEnter}
			onMouseLeave={props.onMouseLeave}
		>
			{props.children}
		</div>
	);
}
