import React, { CSSProperties } from "react";

export default function IconFloorplan3D(props: {
	className?: string;
	style?: CSSProperties;
	color?: string;
	size?: number;
}) {
	return (
		<svg
			width={props.size ?? "54"}
			height={props.size ?? "47"}
			style={props.style}
			viewBox="0 0 54 47"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect
				width="29.6429"
				height="29.6429"
				transform="matrix(0.866025 0.5 -0.866025 0.5 27 16.0898)"
				fill="#B0BCF3"
			/>
			<rect
				width="29.6429"
				height="14.8214"
				transform="matrix(0.866025 -0.5 -4.36336e-08 -1 1.43262 30.9102)"
				fill="#6681FF"
			/>
			<rect
				width="29.6429"
				height="14.8214"
				transform="matrix(0.866025 -0.5 -4.36336e-08 -1 1.43262 30.9102)"
				stroke="#00255D"
				strokeLinejoin="round"
			/>
			<rect
				width="29.6429"
				height="14.8214"
				transform="matrix(0.866025 0.5 -5.96046e-08 1 1.43262 16.0898)"
				fill="#6681FF"
				fillOpacity="0.8"
			/>
			<rect
				width="29.6429"
				height="14.8214"
				transform="matrix(0.866025 0.5 -5.96046e-08 1 1.43262 16.0898)"
				stroke="#00255D"
				strokeLinejoin="round"
			/>
			<rect
				width="29.6429"
				height="14.8214"
				transform="matrix(0.866025 0.5 -5.96046e-08 1 27 1.26758)"
				fill="#6681FF"
			/>
			<rect
				width="29.6429"
				height="14.8214"
				transform="matrix(0.866025 0.5 -5.96046e-08 1 27 1.26758)"
				stroke="#00255D"
				strokeLinejoin="round"
			/>
			<rect
				width="29.6429"
				height="14.8214"
				transform="matrix(0.866025 -0.5 -4.36336e-08 -1 27 45.7324)"
				fill="#6681FF"
				fillOpacity="0.8"
			/>
			<rect
				width="29.6429"
				height="14.8214"
				transform="matrix(0.866025 -0.5 -4.36336e-08 -1 27 45.7324)"
				stroke="#00255D"
				strokeLinejoin="round"
			/>
			<path
				d="M1.41748 30.9099L27.0259 45.5598L52.6342 30.9099L52.6342 16.0896L27.0259 1.26953L1.41748 16.0896L1.41748 30.9099Z"
				stroke="#00255D"
				strokeWidth="1.5"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
