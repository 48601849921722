// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.amJFs3dPy6G9exu9muaO{font-weight:500;font-size:24px}.YtinawR62TN_DAwg36I0{font-weight:500;font-size:20px}.SXIat097weOOKdsK9cAi{font-weight:500;font-size:12px}.RFLyk0ASn_wgpfIgOHvL{font-size:18px}.NISO4BYQGCeGO_6sXE3L{font-size:18px;font-weight:500}.SR7HPjoHoxnHnbQ5gPJQ{font-size:16px}.Kj9qSfTfb3xc_zY2kHIG{font-size:16px;font-weight:500}.CB9jb3YryhW1Q4ckmUnM{font-size:14px}.fyaVHLz1lUtgndwEetGL,.RWL1Egm1RguzBp5uVgAP{font-size:14px;font-weight:500}.MJ8lQUpYUYB7mhQZ57Zb{font-size:14px;font-weight:400}.oaO2hl07jQdCNjziL63j{font-size:12px}.PB8vPDDwCZQq4EgjriDN{font-size:12px;font-weight:500}.mEMGsJiAEGkiJLMhdLmf{width:100%;flex:1;min-width:0}.PcqywpuyBzyDVrUirJJR{display:flex;flex-direction:column}.EUZFRwFrgA02a2FBv3GB{border:1px solid #e2e4e5;border-radius:8px;min-height:40px;height:40px;padding:0 12px;box-sizing:border-box;display:flex;flex-direction:row;align-items:center;justify-content:center}.EUZFRwFrgA02a2FBv3GB span{flex:1;min-width:0;white-space:nowrap;overflow:hidden;text-overflow:ellipsis}.yd_LtFj_dOYn4HESZ2V5{display:none}.RWL1Egm1RguzBp5uVgAP{color:#5f718c;margin-bottom:8px}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/elements/file-input-button.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,4CACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,UAAA,CACA,MAAA,CACA,WAAA,CAGD,sBACC,YAAA,CACA,qBAAA,CAGD,sBACC,wBAAA,CACA,iBAAA,CAEA,eAAA,CACA,WAAA,CACA,cAAA,CACA,qBAAA,CAEA,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,sBAAA,CAEA,2BACC,MAAA,CACA,WAAA,CACA,kBAAA,CACA,eAAA,CACA,sBAAA,CAIF,sBACC,YAAA,CAGD,sBAEC,aD7BgB,CC+BhB,iBAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `amJFs3dPy6G9exu9muaO`;
export var h2 = `YtinawR62TN_DAwg36I0`;
export var h3 = `SXIat097weOOKdsK9cAi`;
export var p0 = `RFLyk0ASn_wgpfIgOHvL`;
export var p0m = `NISO4BYQGCeGO_6sXE3L`;
export var p1 = `SR7HPjoHoxnHnbQ5gPJQ`;
export var p1m = `Kj9qSfTfb3xc_zY2kHIG`;
export var p2 = `CB9jb3YryhW1Q4ckmUnM`;
export var p2m = `fyaVHLz1lUtgndwEetGL`;
export var titleText = `RWL1Egm1RguzBp5uVgAP`;
export var p2n = `MJ8lQUpYUYB7mhQZ57Zb`;
export var p3 = `oaO2hl07jQdCNjziL63j`;
export var p3m = `PB8vPDDwCZQq4EgjriDN`;
export var fullWidth = `mEMGsJiAEGkiJLMhdLmf`;
export var wrapper = `PcqywpuyBzyDVrUirJJR`;
export var field = `EUZFRwFrgA02a2FBv3GB`;
export var input = `yd_LtFj_dOYn4HESZ2V5`;
export default ___CSS_LOADER_EXPORT___;
