import React, { CSSProperties } from "react";
import { getStatusColors, StatusType } from "../helper/status-helper";
import * as styles from "./velavu-indicator.module.scss";

/**
 * A small dot that can be used as an indicator
 */
export default function VelavuIndicator(props: {
	style?: CSSProperties;
	className?: string;
	type: StatusType;
}) {
	const color = getStatusColors(props.type).foreground;

	return (
		<span
			style={{ backgroundColor: color, ...props.style }}
			className={`${styles.indicator} ${props.className}`}
		/>
	);
}
