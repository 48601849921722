import React, {
	memo,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { VelavuDevice } from "velavu-js-api";
import IconWarning from "../../../../dynamicicons/icon-warning";
import BottomTooltip from "../../../../elements/bottom-tooltip";
import Divider from "../../../../elements/divider";
import DynamicBattery from "../../../../elements/dynamic-battery";
import SelectableCard from "../../../../elements/selectable-card";
import StatusIndicator from "../../../../elements/status-indicator";
import { getLocationFloorID } from "../../../../helper/floor-helper";
import { useDelayHover } from "../../../../helper/hook-helper";
import { getDeviceStatus } from "../../../../helper/status-helper";
import DevicesFilter from "./devices-filter/devices-filter";
import * as styles from "./site-detail-anchors.module.scss";
import DevicesToolbar from "./devices-toolbar/devices-toolbar";
import FloorFilter from "../../../../data/floor-filter";

interface SiteDetailAnchorProps {
	anchors: VelavuDevice[] | undefined;
	onSelectAnchor?: (anchor: VelavuDevice | undefined) => void;
	onHoverAnchor?: (anchor: VelavuDevice | undefined) => void;
	activeFloorID: string | undefined;
}

const Anchor = memo((props: { anchor: VelavuDevice }) => {
	return (
		<>
			<div className={styles.row}>
				<StatusIndicator
					status={getDeviceStatus(props.anchor)}
					className={styles.indicator}
				/>
				<span className={styles.anchorName}>{props.anchor.id}</span>
				{!getLocationFloorID(props.anchor.location) && <Warning />}
			</div>
			<div className={styles.spacer} />
			<div className={styles.battery}>
				<span
					style={{ marginRight: 4 }}
					className={styles.batteryLevel}
				>
					{props.anchor.state?.power?.battery_level !== undefined
						? `${props.anchor.state.power.battery_level}%`
						: "N/A"}
				</span>
				<DynamicBattery
					level={
						props.anchor.state?.power?.battery_level !== undefined
							? props.anchor.state?.power.battery_level / 100
							: -1
					}
					charging={false}
				/>
			</div>
		</>
	);
});

function Warning() {
	const [isHover, enableHover, disableHover] = useDelayHover();
	const ref = useRef<HTMLElement | null>(null);

	return (
		<>
			{isHover && (
				<BottomTooltip
					className={styles.tooltip}
					refPosition={ref.current!}
				>
					<span>Anchor not being used for positioning</span>
					<div className={styles.divider} />
					<span className={styles.bottom}>
						Reposition this anchor using the mobile app and ensure
						the correct floor is selected.
					</span>
				</BottomTooltip>
			)}
			<span
				className={styles.warning}
				onMouseEnter={enableHover}
				onMouseLeave={disableHover}
				ref={ref}
			>
				<IconWarning />
			</span>
		</>
	);
}

export default function SiteDetailAnchors(props: SiteDetailAnchorProps) {
	const [searchText, setSearchText] = useState("");
	const [showFilterMenu, setShowFilterMenu] = useState(false);
	const [filterBattery, setFilterBattery] = useState<boolean | undefined>(
		undefined,
	);
	const [filterStatus, setFilterStatus] = useState<boolean | undefined>(
		undefined,
	);
	const [floorFilter, setFloorFilter] = useState(FloorFilter.All);

	const toggleFilterMenu = useCallback(
		() => setShowFilterMenu((it) => !it),
		[setShowFilterMenu],
	);
	const filterSelected =
		filterBattery !== undefined || filterStatus !== undefined;
	const filterColor = filterSelected ? "#3A58E2" : "#5F718C";

	const filteredAnchors = useMemo(() => {
		let anchors = props.anchors ?? [];

		if (filterStatus !== undefined) {
			anchors = anchors.filter(
				(anchor) => anchor.online === filterStatus,
			);
		}

		if (filterBattery !== undefined) {
			anchors = anchors.filter(
				(anchor) =>
					anchor.state?.power?.battery_level &&
					anchor.state?.power?.battery_level < 15,
			);
		}

		anchors = anchors.filter((anchor) =>
			anchor.id.toLowerCase().includes(searchText.toLowerCase()),
		);
		if (floorFilter === FloorFilter.Current)
			anchors = anchors.filter(
				(a) => getLocationFloorID(a?.location) === props.activeFloorID,
			);

		return anchors;
	}, [
		floorFilter,
		props.activeFloorID,
		props.anchors,
		searchText,
		filterStatus,
		filterBattery,
	]);

	const propsOnSelectAnchor = props.onSelectAnchor;
	const propsOnHoverAnchor = props.onHoverAnchor;
	const listContent = useMemo(
		() =>
			filteredAnchors.length > 0 ? (
				filteredAnchors.map((anchor) => (
					<SelectableCard
						key={anchor.id}
						className={styles.anchorCard}
						onClick={() => {
							propsOnSelectAnchor?.(anchor);
							propsOnHoverAnchor?.(undefined);
						}}
						onMouseEnter={() => propsOnHoverAnchor?.(anchor)}
						onMouseLeave={() => propsOnHoverAnchor?.(undefined)}
					>
						<Anchor anchor={anchor} />
					</SelectableCard>
				))
			) : (
				<div className={styles.emptyLabel}>No results</div>
			),
		[filteredAnchors, propsOnSelectAnchor, propsOnHoverAnchor],
	);

	return (
		<div className={styles.anchors}>
			<DevicesToolbar
				searchText={searchText}
				setSearchText={setSearchText}
				floorSelection={floorFilter}
				onChangeFloor={setFloorFilter}
				highlightFilter={
					filterBattery !== undefined || filterStatus !== undefined
				}
				showFilterMenu={showFilterMenu}
				onClickShowFilterMenu={toggleFilterMenu}
			/>

			<Divider />
			{showFilterMenu && (
				<>
					<DevicesFilter
						filterStatus={filterStatus}
						selectStatus={setFilterStatus}
						filterBattery={filterBattery}
						selectBattery={setFilterBattery}
					/>
					<Divider />
				</>
			)}
			<div className={styles.content}>{listContent}</div>
		</div>
	);
}
