// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.umz7rUuNxIcT7r13PEOf,.KIGA4md3X6bzWqSU6PO8{font-weight:500;font-size:24px}.Rg4joXTlarIGw7XjnVJf{font-weight:500;font-size:20px}.Ft2S5rUosA4liHfYs8vw{font-weight:500;font-size:12px}.tMwwkLCELof_cA82i69F{font-size:18px}.xYVV1D8G0CKIJaqGSPG9{font-size:18px;font-weight:500}.zlT2sPEs3K_Dwt5fbzH8{font-size:16px}.vEpo76ohlYstR5MuPFQQ,.cT72cKOm8tbZyEAu0jTI{font-size:16px;font-weight:500}.pHjPG6SwNBPcp5EaHmBo,.hcqrFuFtCQOnaf_9yXP5{font-size:14px}.snkRMiAoYm7PLX1sKIEW{font-size:14px;font-weight:500}.MJBVZIeh8a1s8zul_Byg{font-size:14px;font-weight:400}.mAopQBBzY4Q_ZeZfwDpY{font-size:12px}._9zOsP_qsHNpaD5hAwyGe{font-size:12px;font-weight:500}.dwEAyllKF7SlL_AGZ1po{display:flex;flex-direction:row;align-items:center;padding:20px;color:#3a58e2;background-color:#f0f5fd;border:1px solid #3a58e2;border-radius:12px}.hcqrFuFtCQOnaf_9yXP5{color:#b0bcf3;margin-inline-end:12px}.Vig8BDx0tcHMdlPD223w{flex:1 1 0}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/components/settings/settings-billing/settings-billing-summary-card.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,4CACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,4CACC,cAAA,CACA,eAAA,CAGD,4CACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,uBACC,cAAA,CACA,eAAA,CC3GD,sBACC,YAAA,CACA,kBAAA,CACA,kBAAA,CAEA,YAAA,CAEA,aDFS,CCGT,wBAAA,CACA,wBAAA,CACA,kBAAA,CAOD,sBAEC,aAAA,CAEA,sBAAA,CAOD,sBACC,UAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `umz7rUuNxIcT7r13PEOf`;
export var price = `KIGA4md3X6bzWqSU6PO8`;
export var h2 = `Rg4joXTlarIGw7XjnVJf`;
export var h3 = `Ft2S5rUosA4liHfYs8vw`;
export var p0 = `tMwwkLCELof_cA82i69F`;
export var p0m = `xYVV1D8G0CKIJaqGSPG9`;
export var p1 = `zlT2sPEs3K_Dwt5fbzH8`;
export var p1m = `vEpo76ohlYstR5MuPFQQ`;
export var period = `cT72cKOm8tbZyEAu0jTI`;
export var p2 = `pHjPG6SwNBPcp5EaHmBo`;
export var toDate = `hcqrFuFtCQOnaf_9yXP5`;
export var p2m = `snkRMiAoYm7PLX1sKIEW`;
export var p2n = `MJBVZIeh8a1s8zul_Byg`;
export var p3 = `mAopQBBzY4Q_ZeZfwDpY`;
export var p3m = `_9zOsP_qsHNpaD5hAwyGe`;
export var card = `dwEAyllKF7SlL_AGZ1po`;
export var spacer = `Vig8BDx0tcHMdlPD223w`;
export default ___CSS_LOADER_EXPORT___;
