import React, {
	CSSProperties,
	useCallback,
	useEffect,
	useRef,
	useState,
} from "react";
import VelavuItemIcon from "../../elements/velavu-item-icon";
import { getBase64FromUrl } from "../../helper/image-helper";
import * as styles from "./tab-asset-manage-image.module.scss";
import { AssetCategory, VelavuAPI } from "velavu-js-api";

interface TabAssetManageImageProps {
	id: string;
	style?: CSSProperties;
	image?: string;
	category?: string;
	updateImage: (url?: string) => void;
}

export default function TabAssetManageImage(props: TabAssetManageImageProps) {
	const [image, setImage] = useState<string | undefined>(undefined);
	const imageInput = useRef<HTMLInputElement>(null);

	const deleteImage = useCallback(
		(id: string) => {
			VelavuAPI.assets
				.deleteProfilePicture(id)
				.then(() => props.updateImage(undefined))
				.catch((error) => console.log(error));
		},
		[props],
	);

	const uploadImage = useCallback(
		(base64Data: string, id: string) => {
			VelavuAPI.assets
				.uploadProfilePicture(id, base64Data)
				.then((response) => props.updateImage(response.profile_img_url))
				.catch((error) => console.log(error));
		},
		[props],
	);

	const onChangeImage = useCallback(
		(event: React.FormEvent<HTMLInputElement>) => {
			const imageURL = URL.createObjectURL(event.currentTarget.files![0]);

			getBase64FromUrl(imageURL!).then((base64Data) =>
				uploadImage(base64Data!, props.id),
			);
		},
		[props.id, uploadImage],
	);

	const renderImage = useCallback(() => {
		if (image) {
			return <img className={styles.image} src={image} alt="" />;
		} else if (props.category === "VEHICLE") {
			return <VelavuItemIcon data={AssetCategory.Vehicle} />;
		} else {
			return <VelavuItemIcon data={AssetCategory.Equipment} />;
		}
	}, [image, props.category]);

	useEffect(() => setImage(props.image), [props.image]);

	return (
		<div className={styles.container} style={props.style}>
			<div className={styles.content}>{renderImage()}</div>
			<button
				className={styles.imageButton}
				onClick={() => imageInput.current?.click()}
			>
				{image ? "Upload new photo" : "Upload photo"}
			</button>
			{image && (
				<div>
					<span className={styles.bulletPoint} />
					<button
						className={styles.imageButton}
						onClick={() => deleteImage(props.id)}
					>
						Remove photo
					</button>
				</div>
			)}
			<input
				className={styles.imageInput}
				ref={imageInput}
				onChange={onChangeImage}
				type="file"
				name="image"
				accept="image/png, image/jpeg"
			/>
		</div>
	);
}
