// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.AFEnCcEc6z4cTs42OC1k{font-weight:500;font-size:24px}.N_TLXAlxlj0Y18J4tdeL,.VYDEFak8VKszNuQopI7s{font-weight:500;font-size:20px}.PBvjAot9LPJt2zXbc551{font-weight:500;font-size:12px}.jg9GYFn50LLrUnqQqgDM{font-size:18px}.PcGSkV5W14LaknHZxcbk{font-size:18px;font-weight:500}.Tol8H3QODUzgvBHSAwGw{font-size:16px}.kzFuez5mhW3iIVhHxML5{font-size:16px;font-weight:500}.M0sbiqE4piN4gXtKpc1M{font-size:14px}.DUfdjlCT7vMGqDleFdnt{font-size:14px;font-weight:500}.QwT2aDUpomPEFg0P61gA{font-size:14px;font-weight:400}.VELS7xQqGKf6SSeZKrBT{font-size:12px}.hokrK0SbybhFhUlyG9kY{font-size:12px;font-weight:500}.DCmeU919GJ7KvkkQtAQw{width:100%;height:44px;box-sizing:border-box;padding:0 20px;display:flex;flex-direction:row;align-items:flex-end;justify-content:space-between}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/components/dashboard/dropdown/pinned-list-header.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,4CACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,UAAA,CACA,WAAA,CACA,qBAAA,CACA,cAAA,CAEA,YAAA,CACA,kBAAA,CAEA,oBAAA,CACA,6BAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `AFEnCcEc6z4cTs42OC1k`;
export var h2 = `N_TLXAlxlj0Y18J4tdeL`;
export var title = `VYDEFak8VKszNuQopI7s`;
export var h3 = `PBvjAot9LPJt2zXbc551`;
export var p0 = `jg9GYFn50LLrUnqQqgDM`;
export var p0m = `PcGSkV5W14LaknHZxcbk`;
export var p1 = `Tol8H3QODUzgvBHSAwGw`;
export var p1m = `kzFuez5mhW3iIVhHxML5`;
export var p2 = `M0sbiqE4piN4gXtKpc1M`;
export var p2m = `DUfdjlCT7vMGqDleFdnt`;
export var p2n = `QwT2aDUpomPEFg0P61gA`;
export var p3 = `VELS7xQqGKf6SSeZKrBT`;
export var p3m = `hokrK0SbybhFhUlyG9kY`;
export var container = `DCmeU919GJ7KvkkQtAQw`;
export default ___CSS_LOADER_EXPORT___;
