import React, { CSSProperties } from "react";

export default function IconAddFile(props: {
	className?: string;
	style?: CSSProperties;
	color?: string;
	size?: number;
}) {
	return (
		<svg
			width={props.size ?? "42"}
			height={props.size ?? "57"}
			viewBox="0 0 42 57"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M29.2566 0C30.4584 0 31.6102 0.480704 32.4555 1.33499L40.1989 9.16121C41.0324 10.0037 41.5 11.141 41.5 12.3262L41.5 52.5C41.5 54.9853 39.4853 57 37 57L5 57C2.51472 57 0.500003 54.9853 0.500003 52.5L0.5 4.5C0.5 2.01472 2.51472 3.4082e-06 5 3.19093e-06L29.2566 0ZM29.5 1.00847V9.5C29.5 10.8807 30.6193 12 32 12H40.4848C40.4097 11.1977 40.0594 10.4421 39.488 9.86455L31.7446 2.03833C31.1445 1.4318 30.3457 1.06739 29.5 1.00847ZM40.5 13H32C30.067 13 28.5 11.433 28.5 9.5V1L5 1C3.067 1 1.5 2.56701 1.5 4.5L1.5 52.5C1.5 54.433 3.06701 56 5 56L37 56C38.933 56 40.5 54.433 40.5 52.5L40.5 13Z"
				fill={props.color ?? "#3A58E2"}
			/>
			<path
				d="M27.5 30.498H22.5V35.498C22.5 35.7633 22.3946 36.0176 22.2071 36.2052C22.0196 36.3927 21.7652 36.498 21.5 36.498C21.2348 36.498 20.9804 36.3927 20.7929 36.2052C20.6054 36.0176 20.5 35.7633 20.5 35.498V30.498H15.5C15.2348 30.498 14.9804 30.3927 14.7929 30.2052C14.6054 30.0176 14.5 29.7633 14.5 29.498C14.5 29.2328 14.6054 28.9785 14.7929 28.7909C14.9804 28.6034 15.2348 28.498 15.5 28.498H20.5V23.498C20.5 23.2328 20.6054 22.9785 20.7929 22.7909C20.9804 22.6034 21.2348 22.498 21.5 22.498C21.7652 22.498 22.0196 22.6034 22.2071 22.7909C22.3946 22.9785 22.5 23.2328 22.5 23.498V28.498H27.5C27.7652 28.498 28.0196 28.6034 28.2071 28.7909C28.3946 28.9785 28.5 29.2328 28.5 29.498C28.5 29.7633 28.3946 30.0176 28.2071 30.2052C28.0196 30.3927 27.7652 30.498 27.5 30.498Z"
				fill={props.color ?? "#3A58E2"}
			/>
		</svg>
	);
}
