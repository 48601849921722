// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.WWlpl5N8AskPpm7cTGZ8{font-weight:500;font-size:24px}.ogynEgfOMooAEDb1ysfO{font-weight:500;font-size:20px}.XyzIcb89lJ9xE15doj8v{font-weight:500;font-size:12px}.nE7_NgVw6REZ11mIdEJz{font-size:18px}.wQcdTUpTai9LOTy87ztu{font-size:18px;font-weight:500}.rvTgneKAQNhrMzIPaD24{font-size:16px}.ExRzG_4_rO0P0riBJxhy{font-size:16px;font-weight:500}.oVrl0IKJoCDiYoMZiNex,.j2DWInXWOUjnZngQpTMR{font-size:14px}.SdaggERuNeUOpIex_6Ai,.iC8IFkTggZNmm5FtzQJy,.c6KD0_MBBBnMrlf4XVAd{font-size:14px;font-weight:500}.EpgiJZxjKoPymyrET5R3{font-size:14px;font-weight:400}.aGluwtG8g5XosU9BlwjU{font-size:12px}.rXdGle9U_F8PUB68TSoo{font-size:12px;font-weight:500}.OZmMZ_eqeNpoM9fmA9Ws{display:flex;flex-direction:column;padding:20px}.c6KD0_MBBBnMrlf4XVAd{color:#5f718c;margin-bottom:8px}.iC8IFkTggZNmm5FtzQJy{color:#5f718c;margin-top:12px;display:flex;align-items:center}.j2DWInXWOUjnZngQpTMR{color:#a3b3cc;margin-left:auto;margin-right:auto;margin-top:8px}.wX9n_CQeOFhOXwvGw4zw{margin-top:20px}.WaIJq43e6MG5PS8FUydY{margin-top:20px;display:flex;flex-direction:row}.WaIJq43e6MG5PS8FUydY>button{flex-grow:1;flex-basis:0}.WaIJq43e6MG5PS8FUydY>button:first-child{margin-right:12px}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/components/dashboard/dropdown/site-create/site-create.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,4CACC,cAAA,CAGD,kEACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,YAAA,CACA,qBAAA,CAEA,YAAA,CAGD,sBAEC,aDEgB,CAAA,iBAAA,CCGjB,sBAEC,aDLgB,CCMhB,eAAA,CACA,YAAA,CACA,kBAAA,CAGD,sBAEC,aDfY,CCgBZ,gBAAA,CACA,iBAAA,CACA,cAAA,CAGD,sBACC,eAAA,CAGD,sBACC,eAAA,CACA,YAAA,CACA,kBAAA,CAEA,6BACC,WAAA,CACA,YAAA,CACA,yCACC,iBAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `WWlpl5N8AskPpm7cTGZ8`;
export var h2 = `ogynEgfOMooAEDb1ysfO`;
export var h3 = `XyzIcb89lJ9xE15doj8v`;
export var p0 = `nE7_NgVw6REZ11mIdEJz`;
export var p0m = `wQcdTUpTai9LOTy87ztu`;
export var p1 = `rvTgneKAQNhrMzIPaD24`;
export var p1m = `ExRzG_4_rO0P0riBJxhy`;
export var p2 = `oVrl0IKJoCDiYoMZiNex`;
export var sliderLabel = `j2DWInXWOUjnZngQpTMR`;
export var p2m = `SdaggERuNeUOpIex_6Ai`;
export var labelRow = `iC8IFkTggZNmm5FtzQJy`;
export var label = `c6KD0_MBBBnMrlf4XVAd`;
export var p2n = `EpgiJZxjKoPymyrET5R3`;
export var p3 = `aGluwtG8g5XosU9BlwjU`;
export var p3m = `rXdGle9U_F8PUB68TSoo`;
export var container = `OZmMZ_eqeNpoM9fmA9Ws`;
export var buttonSubmit = `wX9n_CQeOFhOXwvGw4zw`;
export var buttonBar = `WaIJq43e6MG5PS8FUydY`;
export default ___CSS_LOADER_EXPORT___;
