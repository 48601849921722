import React from "react";
import { Link } from "react-router-dom";
import IconAdd from "../dynamicicons/icon-add";
import IconSearch from "../dynamicicons/icon-search";
import { StyleableIcon } from "../helper/icon-helper";
import * as styles from "./list-header.module.scss";
import SectionTitle from "./section-title";
import VelavuButton from "./velavu-button";
import VelavuInput from "./velavu-input";
import VelavuMenuFilter from "./velavu-menu-filter";
import VelavuSwitch from "./velavu-switch";

interface ListHeaderProps {
	icon: StyleableIcon;
	title: string;

	search?: {
		searchPlaceholder: string;
		searchText: string;
		setSearchText: (value: string) => void;
	};

	filterType?: {
		focus: boolean;
		highlight?: boolean | string;
		onClick: (
			event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		) => void;
	};

	filterUnpaired?: {
		filterUnpaired: boolean;
		setFilterUnpaired: (value: boolean) => void;
	};

	add?:
		| {
				label: string;
				route: string;
		  }
		| {
				label: string;
				onClick: VoidFunction;
		  };
}

export default function ListHeader(props: ListHeaderProps) {
	return (
		<div className={styles.container}>
			<SectionTitle icon={props.icon} label={props.title} />

			<div className={styles.spacer} />

			{props.search && (
				<VelavuInput
					placeholder={props.search.searchPlaceholder}
					value={props.search.searchText}
					onChange={props.search.setSearchText}
					leadingIcon={<IconSearch size={16} color="#00255D" />}
					height={32}
					round
				/>
			)}

			{props.filterType && (
				<VelavuMenuFilter
					className={styles.menuSpacing}
					focus={props.filterType.focus}
					highlight={props.filterType.highlight}
					onClick={props.filterType.onClick}
				/>
			)}

			{props.filterUnpaired && (
				<VelavuSwitch
					className={styles.menuSpacing}
					label="Unpaired"
					toggled={props.filterUnpaired.filterUnpaired}
					onChange={props.filterUnpaired.setFilterUnpaired}
				/>
			)}

			{props.add &&
				("route" in props.add ? (
					<Link className={styles.link} to={props.add.route}>
						<VelavuButton
							className={styles.menuSpacing}
							label={props.add.label}
							icon={(size, color, className) => (
								<IconAdd
									size={size}
									color={color}
									className={className}
								/>
							)}
						/>
					</Link>
				) : (
					<VelavuButton
						className={styles.menuSpacing}
						label={props.add.label}
						icon={(size, color, className) => (
							<IconAdd
								size={size}
								color={color}
								className={className}
							/>
						)}
						onClick={props.add.onClick}
					/>
				))}
		</div>
	);
}
