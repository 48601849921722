import { AnyVelavuEvent, EventCategory } from "velavu-js-api";

export enum EventGroup {
	All = "All",
	LowBattery = "LowBattery",
	Connectivity = "Connectivity",
	SitesAndGeofences = "SitesAndGeofences",
	Motion = "Motion",
	Custom = "Custom",
}

const EVENT_GROUP_TO_CATEGORIES: Readonly<
	Record<EventGroup, ReadonlySet<EventCategory> | null>
> = {
	[EventGroup.All]: null,
	[EventGroup.LowBattery]: new Set([EventCategory.LowBattery]),
	[EventGroup.Connectivity]: new Set([
		EventCategory.DeviceConnect,
		EventCategory.DeviceDisconnect,
	]),
	[EventGroup.SitesAndGeofences]: new Set([
		EventCategory.SiteEntered,
		EventCategory.SiteExited,
		EventCategory.GeofenceEntered,
		EventCategory.GeofenceExited,
	]),
	[EventGroup.Motion]: new Set([EventCategory.Shock, EventCategory.Motion]),
	[EventGroup.Custom]: new Set([EventCategory.CustomEvent]),
};

/// Returns a set of event categories that belong to the specified event group
export function eventGroupToEventCategorySet(
	group: EventGroup,
): ReadonlySet<EventCategory> | null {
	return EVENT_GROUP_TO_CATEGORIES[group];
}

/**
 * Filters out events not included in the specified group.
 *
 * @param events - An array of events to filter. If undefined, returns undefined.
 * @param group - The group to filter events by.
 * @returns An array of events that belong to the specified group, or undefined if events are undefined.
 */
export function filterEventsByGroup(
	events: AnyVelavuEvent[] | undefined,
	group: EventGroup,
): AnyVelavuEvent[] | undefined {
	if (events === undefined) {
		return undefined;
	}

	const allowedCategories = eventGroupToEventCategorySet(group);
	if (allowedCategories === null) {
		return events;
	}

	return events.filter((event) => allowedCategories.has(event.category));
}
