// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.rMAd7wnS39JDjNGf2AWF{font-weight:500;font-size:24px}.zuRnonT_sRmlyitg7LXr{font-weight:500;font-size:20px}.GxTYipCjxPmbWPH3yZd7{font-weight:500;font-size:12px}.YrVxdQ0zxQc7dQRxyu4y{font-size:18px}.rtBPrpIyqL0fEeYFhMJO{font-size:18px;font-weight:500}.nOPZK4bzi0yaqFOXa56C{font-size:16px}.Z9ngLyhfvhrE5jj781dK{font-size:16px;font-weight:500}.s1aBoQwjaN9qx6wq7RLr{font-size:14px}.tctNh5A63zWJclrnOcI8{font-size:14px;font-weight:500}.dXRmiMfgS6iO2jQx24YB{font-size:14px;font-weight:400}.IBGy8IdODAe1UdLpsmJA,.JTN2mN8qrRrCM57_VaQF{font-size:12px}.Oy6s0m9S17rXS4ytrEGx{font-size:12px;font-weight:500}.JTN2mN8qrRrCM57_VaQF{color:#5f718c;text-align:center}.bduv1gTn41TI16oKi9Ha{color:#da3e52}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/components/settings/settings-billing/settings-billing-status-text.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,4CACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBAEC,aDSgB,CCRhB,iBAAA,CAGD,sBACC,aDmBY","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `bduv1gTn41TI16oKi9Ha`;
export var errorBackground = `#fceff1`;
export var h1 = `rMAd7wnS39JDjNGf2AWF`;
export var h2 = `zuRnonT_sRmlyitg7LXr`;
export var h3 = `GxTYipCjxPmbWPH3yZd7`;
export var p0 = `YrVxdQ0zxQc7dQRxyu4y`;
export var p0m = `rtBPrpIyqL0fEeYFhMJO`;
export var p1 = `nOPZK4bzi0yaqFOXa56C`;
export var p1m = `Z9ngLyhfvhrE5jj781dK`;
export var p2 = `s1aBoQwjaN9qx6wq7RLr`;
export var p2m = `tctNh5A63zWJclrnOcI8`;
export var p2n = `dXRmiMfgS6iO2jQx24YB`;
export var p3 = `IBGy8IdODAe1UdLpsmJA`;
export var text = `JTN2mN8qrRrCM57_VaQF`;
export var p3m = `Oy6s0m9S17rXS4ytrEGx`;
export default ___CSS_LOADER_EXPORT___;
