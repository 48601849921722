// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.kutIMxwiID77Czt2p6qx{font-weight:500;font-size:24px}.eSvpHCSrJxh8H6M7Tj5V{font-weight:500;font-size:20px}.RW0iFVbTGS5Bv4ABIrE3{font-weight:500;font-size:12px}.jrlzbayM6zahpS9rI0J9{font-size:18px}.DFU32vdJMt5NRbLsVrsu{font-size:18px;font-weight:500}.zxlVlsZZNB_TGFGGUNvE{font-size:16px}.DVpu2FIMBjIfNferOgEq,.b4SlXDhelVLbYX8u4RX_{font-size:16px;font-weight:500}.eFKqiPlvXDX7Cn_e4Nol{font-size:14px}.Wra7WtSB90eq0s1B85gm{font-size:14px;font-weight:500}.M6xjHShJpQq30BkwMhog{font-size:14px;font-weight:400}.T6RmufMo1t76ajEs9duY{font-size:12px}.nuX_G25H9qlqGpHUMwxx{font-size:12px;font-weight:500}.b4SlXDhelVLbYX8u4RX_{height:40px;border-radius:20px;box-sizing:border-box;padding:0 20px;background-color:#fff;box-shadow:2px 5px 10px 0 rgba(0,37,93,.15);transition:250ms ease;color:#3a58e2}.b4SlXDhelVLbYX8u4RX_:hover{color:#6681ff}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/components/dashboard/dropdown/button-assets.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,4CACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,WAAA,CACA,kBAAA,CACA,qBAAA,CACA,cAAA,CAIA,qBAAA,CACA,2CAAA,CAEA,qBDqGoB,CCnGpB,aDRS,CCST,4BACC,aDTc","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `kutIMxwiID77Czt2p6qx`;
export var h2 = `eSvpHCSrJxh8H6M7Tj5V`;
export var h3 = `RW0iFVbTGS5Bv4ABIrE3`;
export var p0 = `jrlzbayM6zahpS9rI0J9`;
export var p0m = `DFU32vdJMt5NRbLsVrsu`;
export var p1 = `zxlVlsZZNB_TGFGGUNvE`;
export var p1m = `DVpu2FIMBjIfNferOgEq`;
export var button = `b4SlXDhelVLbYX8u4RX_`;
export var p2 = `eFKqiPlvXDX7Cn_e4Nol`;
export var p2m = `Wra7WtSB90eq0s1B85gm`;
export var p2n = `M6xjHShJpQq30BkwMhog`;
export var p3 = `T6RmufMo1t76ajEs9duY`;
export var p3m = `nuX_G25H9qlqGpHUMwxx`;
export default ___CSS_LOADER_EXPORT___;
