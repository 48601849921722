import React from "react";
import { VelavuDeviceVesta } from "velavu-js-api";
import IconCheckCircle from "../../../../dynamicicons/icon-check-circle";
import IconError from "../../../../dynamicicons/icon-error";
import * as styles from "./site-detail-status.module.scss";

interface SiteDetailStatusProps {
	anchorCountOnline?: number;
	anchorCountTotal?: number;
	assetCountOnline?: number;
	assetCountTotal?: number;
	gatewayCountOnline?: number;
	gatewayCountTotal?: number;
	gateways?: VelavuDeviceVesta[];
	online?: boolean;
	onSelectAnchors?: VoidFunction;
	onSelectAssets?: VoidFunction;
	onSelectGateways?: VoidFunction;
}

export default function SiteDetailStatus(props: SiteDetailStatusProps) {
	const status = props.online ? "active" : "inactive";

	return (
		<div className={styles.header}>
			<div className={styles.statusRow}>
				<div
					style={{ height: 48 }}
					className={`${
						status === "inactive"
							? styles.statusCardRed
							: styles.statusCardGreen
					} ${styles.statusCardHorizontal}`}
				>
					{status === "inactive" && (
						<IconError
							style={{ marginRight: 4 }}
							color={undefined}
						/>
					)}
					{status === "inactive" && (
						<span className={styles.statusTextMed}>Offline</span>
					)}

					{status === "active" && (
						<IconCheckCircle
							style={{ marginRight: 4 }}
							color={undefined}
						/>
					)}
					{status === "active" && (
						<span className={styles.statusTextMed}>Online</span>
					)}
				</div>
			</div>

			<div className={styles.statusRow}>
				<button
					onClick={props.onSelectGateways}
					style={{ height: 100 }}
					className={`${styles.statusCardBlue} ${styles.statusCardVertical}`}
				>
					<span
						style={{ marginBottom: 4 }}
						className={styles.statusTextMed}
					>
						{props.gatewayCountOnline ?? "-"}/
						{props.gatewayCountTotal ?? "-"}
					</span>
					<span className={styles.statusTextMed}>Gateways</span>
				</button>

				<button
					onClick={props.onSelectAnchors}
					style={{ height: 100 }}
					className={`${styles.statusCardBlue} ${styles.statusCardVertical}`}
				>
					<span
						style={{ marginBottom: 4 }}
						className={styles.statusTextMed}
					>
						{props.anchorCountOnline ?? "-"}/
						{props.anchorCountTotal ?? "-"}
					</span>
					<span className={styles.statusTextMed}>Anchors</span>
				</button>

				<button
					onClick={props.onSelectAssets}
					style={{ height: 100 }}
					className={`${styles.statusCardBlue} ${styles.statusCardVertical}`}
				>
					<span
						style={{ marginBottom: 4 }}
						className={styles.statusTextMed}
					>
						{props.assetCountOnline ?? "-"}/
						{props.assetCountTotal ?? "-"}
					</span>
					<span className={styles.statusTextMed}>Assets</span>
				</button>
			</div>
		</div>
	);
}
