// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.hG7WSeTQNOXdzBN_LVZt{font-weight:500;font-size:24px}.hudR9udxLKSA4VVtN8In{font-weight:500;font-size:20px}.S2VZhPjrEFJ16B0RMBzL{font-weight:500;font-size:12px}.KUsmYKnVsyJudYtOFbPw{font-size:18px}.fKNK9aSF7oOmX2na1JMC{font-size:18px;font-weight:500}.eOyo5oIdUDk3uYagz3gj{font-size:16px}.I5mA5yNCkHchiRPVp77l{font-size:16px;font-weight:500}.S4tileXdUZ6IS2fWWTpw{font-size:14px}.au7st0co44AvW6AwwEmy,.Lxp1SmNBN5IP8C0qRGi1{font-size:14px;font-weight:500}.l7QxC0W4ZYr4cKq2sRJz{font-size:14px;font-weight:400}.AHOJtHB9LA6kjO3FQAK6{font-size:12px}.mHPgRGavLxiC9ZVU5P8v{font-size:12px;font-weight:500}.Yk7m8p4UnXLxs6QfXdsj{width:100%;border-radius:8px;border:1px solid #e2e4e5;box-sizing:border-box}.m_1qXfoi_ONVSzaMKjYr{height:40px;width:100%;padding:0 12px;border-radius:8px;display:flex;flex-direction:row;align-items:center;justify-content:space-between;transition:background-color 250ms ease;cursor:pointer}.m_1qXfoi_ONVSzaMKjYr:hover{background-color:#f5f8f9}.YHV0DYrT1Nb245SZkpEw{border-radius:8px 8px 0 0}.Lxp1SmNBN5IP8C0qRGi1{transition:color 250ms ease}.VwlirtJtdLexsQjUICfI{color:#00255d}.clLaTDapDqedvlvg3Tz5{color:#5f718c}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/elements/velavu-switch-block.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,4CACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,UAAA,CAEA,iBAAA,CACA,wBAAA,CACA,qBAAA,CAGD,sBACC,WAAA,CACA,UAAA,CAEA,cAAA,CAEA,iBAAA,CAEA,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,6BAAA,CDiGA,sCAAA,CAEA,cAAA,CAEA,4BACC,wBAxGM,CCOR,sBACC,yBAAA,CAGD,sBAGC,2BAAA,CAGD,sBACC,aDzBc,CC4Bf,sBACC,aD5BgB","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `hG7WSeTQNOXdzBN_LVZt`;
export var h2 = `hudR9udxLKSA4VVtN8In`;
export var h3 = `S2VZhPjrEFJ16B0RMBzL`;
export var p0 = `KUsmYKnVsyJudYtOFbPw`;
export var p0m = `fKNK9aSF7oOmX2na1JMC`;
export var p1 = `eOyo5oIdUDk3uYagz3gj`;
export var p1m = `I5mA5yNCkHchiRPVp77l`;
export var p2 = `S4tileXdUZ6IS2fWWTpw`;
export var p2m = `au7st0co44AvW6AwwEmy`;
export var label = `Lxp1SmNBN5IP8C0qRGi1`;
export var p2n = `l7QxC0W4ZYr4cKq2sRJz`;
export var p3 = `AHOJtHB9LA6kjO3FQAK6`;
export var p3m = `mHPgRGavLxiC9ZVU5P8v`;
export var container = `Yk7m8p4UnXLxs6QfXdsj`;
export var button = `m_1qXfoi_ONVSzaMKjYr`;
export var buttonTop = `YHV0DYrT1Nb245SZkpEw`;
export var labelChecked = `VwlirtJtdLexsQjUICfI`;
export var labelUnchecked = `clLaTDapDqedvlvg3Tz5`;
export default ___CSS_LOADER_EXPORT___;
