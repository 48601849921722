import appConfig from "@config/app-config.json";
import { fetchAuthSession } from "aws-amplify/auth";
import { mqtt, iot, CrtError } from "aws-iot-device-sdk-v2";
import React, { useContext, useEffect, useRef } from "react";
import { VelavuDevice, VelavuAlert } from "velavu-js-api";
import { MQTTLocationUpdate } from "../../data/mqtt";
import SignInContext from "../../sign-in-context";

type MoveEvent = {
	type: "move";
	deviceID: string;
	coordinates: [number, number];
};

export interface MQTTListener {
	onAssetMove?: (event: MoveEvent) => void;
	onAlertReceive?: (alert: VelavuAlert) => void;
	onDeviceStateUpdate?: (
		deviceID: string,
		state: VelavuDevice["state"],
	) => void;
}

interface MQTTContextProps {
	addListener: (listener: MQTTListener) => void;
	removeListener: (listener: MQTTListener) => void;
}

export const MQTTContext = React.createContext<MQTTContextProps>({
	addListener() {
		throw new Error("No MQTT context available");
	},
	removeListener() {
		throw new Error("No MQTT context available");
	},
});

export default function MQTTProvider(props: { children?: React.ReactNode }) {
	const userContext = useContext(SignInContext);

	const listenerArray = useRef<MQTTListener[]>([]).current;
	const contextValue = useRef<MQTTContextProps>({
		addListener(listener: MQTTListener): void {
			listenerArray.push(listener);
		},
		removeListener(listener: MQTTListener): void {
			const i = listenerArray.indexOf(listener);
			if (i !== -1) listenerArray.splice(i, 1);
		},
	}).current;

	useEffect(() => {
		//Ignoring if data isn't loaded
		if (!userContext.organization || !userContext.isMQTTRegistered) return;

		console.log("Connecting to MQTT");
		const organization = userContext.organization;
		let connection: mqtt.MqttClientConnection;
		(async function connect() {
			const authSession = await fetchAuthSession();
			const credentials = authSession.credentials;
			if (credentials === undefined) {
				console.warn(
					"Failed to connect to MQTT; credentials are undefined",
				);
				return;
			}

			const config =
				iot.AwsIotMqttConnectionConfigBuilder.new_builder_for_websocket()
					.with_credentials(
						appConfig.region,
						credentials.accessKeyId,
						credentials.secretAccessKey,
						credentials.sessionToken,
					)
					.with_clean_session(true)
					.with_client_id(
						"web-" + Math.floor(Math.random() * 100000000),
					)
					.with_endpoint(appConfig.mqttEndpoint)
					.build();

			const client = new mqtt.MqttClient();
			connection = client.new_connection(config);
			connection.on("connect", () => {
				console.log("Connected!");

				//Getting the customer ID
				const customerID = organization.customer_id;

				//Creating the text decoder
				const decoder = new TextDecoder("utf8");

				//Subscribing to alert updates
				connection
					.subscribe(
						`backend/Alert/${customerID}`,
						mqtt.QoS.AtMostOnce,
						(topic, payload) => {
							const alert: VelavuAlert = JSON.parse(
								decoder.decode(payload),
							);
							console.log(`Received alert update ID ${alert.id}`);

							//Dispatching the update
							for (const listener of listenerArray) {
								if (listener.onAlertReceive)
									listener.onAlertReceive(alert);
							}
						},
					)
					.then((result) => {
						console.log(`Connected to MQTT topic ${result.topic}`);
					})
					.catch(console.log);

				//Subscribing to location updates
				connection
					.subscribe(
						`backend/Location/${customerID}/#`,
						mqtt.QoS.AtMostOnce,
						(topic, payload) => {
							const deviceID = topic.split("/").slice(-1)[0];
							const message: MQTTLocationUpdate = JSON.parse(
								decoder.decode(payload),
							);
							console.log(
								`Received location update ${message.coordinates} from device ${deviceID}`,
							);

							//Dispatching the update
							const update: MoveEvent = {
								type: "move",
								deviceID: deviceID,
								coordinates: message.coordinates,
							};
							for (const listener of listenerArray) {
								if (listener.onAssetMove) {
									listener.onAssetMove(update);
								}
							}
						},
					)
					.then((result) => {
						console.log(`Connected to MQTT topic ${result.topic}`);
					})
					.catch(console.log);
			});
			connection.on("interrupt", (error: CrtError) => {
				console.log(`Connection interrupted: error=${error}`);
			});
			connection.on(
				"resume",
				(return_code: number, session_present: boolean) => {
					console.log(
						`Resumed: rc: ${return_code} existing session: ${session_present}`,
					);
				},
			);
			connection.on("disconnect", () => {
				console.log("Disconnected");
			});
			connection.on("error", (error) => {
				console.log(error);
			});

			await connection.connect();
		})();

		//Disconnect on cleanup
		return () => {
			if (connection) connection.disconnect();
		};
	}, [userContext.organization, userContext.isMQTTRegistered, listenerArray]);

	return (
		<MQTTContext.Provider value={contextValue}>
			{props.children}
		</MQTTContext.Provider>
	);
}
