import React, { CSSProperties } from "react";
import * as styles from "./inset-icon.module.scss";
import { StyleableIcon } from "../helper/icon-helper";
import * as globals from "../styles/global.icss.scss";
import { classArr } from "../helper/style-helper";

export interface InsetIconProps {
	style?: CSSProperties;
	className?: string;
	icon: StyleableIcon;
}

/**
 * InsetIcon renders an icon inset into a gray circle, commonly used to denote section headers
 */
export default function InsetIcon(props: InsetIconProps) {
	return (
		<div
			style={props.style}
			className={classArr(props.className, styles.icon)}
		>
			{props.icon(24, globals.textPrimary)}
		</div>
	);
}
