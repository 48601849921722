// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.cTaMJ9y0AvK2uLCzbSmc{font-weight:500;font-size:24px}.V2HxSsK6q5u3_siDKZLK{font-weight:500;font-size:20px}.ftKHT7RjmHVZ94wqZ3_P{font-weight:500;font-size:12px}.ids5JFpsLwPNg3lT2Qba{font-size:18px}.o7dlizY7kNk2QKH8YKl3{font-size:18px;font-weight:500}.pnzS374hkAw4IMCSBnx1{font-size:16px}.TutP0Le4B7GTT6W41aH6{font-size:16px;font-weight:500}.xY3eoZ6360Kdam6L5OZe{font-size:14px}.JMc8IOE5HL0xPxkiSpmh{font-size:14px;font-weight:500}.Pr4EbqAd67Wh_Cih6AzP{font-size:14px;font-weight:400}.XpnmNpwrBTyoF0iyTK2Q{font-size:12px}.UpGP8ixO5MRwinVv6FHd{font-size:12px;font-weight:500}.dzr5p5qASKWvy5SGQjlW{transform-origin:center;transition:transform 250ms ease}.Qedv8SMhUfVkBvyBxvmb{transform:scale(1)}.j0gROrH76_cR_zVjowYa{transform:scale(0)}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/elements/velavu-radio.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,uBAAA,CACA,+BAAA,CAGD,sBACC,kBAAA,CAGD,sBACC,kBAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `cTaMJ9y0AvK2uLCzbSmc`;
export var h2 = `V2HxSsK6q5u3_siDKZLK`;
export var h3 = `ftKHT7RjmHVZ94wqZ3_P`;
export var p0 = `ids5JFpsLwPNg3lT2Qba`;
export var p0m = `o7dlizY7kNk2QKH8YKl3`;
export var p1 = `pnzS374hkAw4IMCSBnx1`;
export var p1m = `TutP0Le4B7GTT6W41aH6`;
export var p2 = `xY3eoZ6360Kdam6L5OZe`;
export var p2m = `JMc8IOE5HL0xPxkiSpmh`;
export var p2n = `Pr4EbqAd67Wh_Cih6AzP`;
export var p3 = `XpnmNpwrBTyoF0iyTK2Q`;
export var p3m = `UpGP8ixO5MRwinVv6FHd`;
export var circle = `dzr5p5qASKWvy5SGQjlW`;
export var circleChecked = `Qedv8SMhUfVkBvyBxvmb`;
export var circleUnchecked = `j0gROrH76_cR_zVjowYa`;
export default ___CSS_LOADER_EXPORT___;
