import React, {
	FormEvent,
	useCallback,
	useEffect,
	useRef,
	useState,
} from "react";
import {
	CreatedVelavuToken,
	CreateNewTokenParams,
	VelavuAPI,
	VelavuToken,
} from "velavu-js-api";
import IconClose from "../../dynamicicons/icon-close";
import BottomTooltip from "../../elements/bottom-tooltip";
import VelavuButton from "../../elements/velavu-button";
import {
	VelavuTableBody,
	VelavuTableColumn,
	VelavuTableDivider,
	VelavuTableHeader,
	VelavuTableHeaderLabel,
	VelavuTableRoundContainer,
	VelavuTableRow,
} from "../../elements/velavu-header-table";
import VelavuIconButton from "../../elements/velavu-icon-button";
import VelavuInput from "../../elements/velavu-input";
import { useVelavuModal, VelavuModalPrompt } from "../../elements/velavu-modal";
import VelavuNotice from "../../elements/velavu-notice";
import VelavuSelect from "../../elements/velavu-select";
import {
	useCancellableEffect,
	useDelayHover,
	useToggleable,
} from "../../helper/hook-helper";
import { formatDate } from "../../helper/language-helper";
import * as styles from "./settings-tokens.module.scss";

export default function SettingsTokens() {
	const [tokens, setTokens] = useState<VelavuToken[]>([]);
	const pushModal = useVelavuModal();

	const addToken = useCallback(
		(token: VelavuToken) => {
			setTokens((tokens) => tokens.concat(token));
		},
		[setTokens],
	);

	const deleteToken = useCallback(
		(token: VelavuToken) => {
			pushModal((resolve) => (
				<VelavuModalPrompt
					title={`Delete the following token?`}
					object={token.name}
					labelConfirm="Delete"
					confirmDanger
					onSelect={resolve}
				>
					The token and all associated data will be permanently
					deleted.
				</VelavuModalPrompt>
			)).then((result) => {
				if (result) {
					VelavuAPI.apiTokens
						.deleteToken(token.id)
						.then(() => {
							setTokens(
								(allTokens) =>
									allTokens?.filter(
										(predicateToken) =>
											predicateToken.id !== token.id,
									),
							);
						})
						.catch(console.log);
				}
			});
		},
		[pushModal],
	);

	useCancellableEffect((addPromise) => {
		addPromise(VelavuAPI.apiTokens.getAllTokens())
			.then(setTokens)
			.catch((error) => console.log(error.response));
	}, []);

	return (
		<div className={styles.container}>
			<ListTokens tokens={tokens} onTokenDelete={deleteToken} />
			<CreateToken onTokenCreation={addToken} />
		</div>
	);
}

function ListTokens(props: {
	tokens: VelavuToken[];
	onTokenDelete: (token: VelavuToken) => void;
}) {
	return (
		<div>
			<span className={styles.title}>API tokens</span>
			<VelavuTableRoundContainer className={styles.containerTable}>
				<VelavuTableHeader>
					<VelavuTableHeaderLabel flex={1}>
						Name
					</VelavuTableHeaderLabel>
					<VelavuTableHeaderLabel flex={1}>
						Creator
					</VelavuTableHeaderLabel>
					<VelavuTableHeaderLabel flex={0.4}>
						Created
					</VelavuTableHeaderLabel>
					<VelavuTableHeaderLabel flex={0.4}>
						Expiry
					</VelavuTableHeaderLabel>
					<VelavuTableHeaderLabel flex={0.2}>
						Status
					</VelavuTableHeaderLabel>
					<VelavuTableHeaderLabel flex={0.2} />
				</VelavuTableHeader>
				<VelavuTableDivider />
				<VelavuTableBody styles={{ maxHeight: 240 }}>
					{props.tokens.map((token) => (
						<TokenTableRow
							token={token}
							key={token.id}
							onDelete={() => props.onTokenDelete(token)}
						/>
					))}
				</VelavuTableBody>
			</VelavuTableRoundContainer>
		</div>
	);
}

function TokenStatus(props: { token: VelavuToken }) {
	if (props.token.expiry) {
		const currentDate = new Date();
		const expiryDate = new Date(props.token.expiry);

		if (expiryDate.getTime() > currentDate.getTime()) {
			return <span className={styles.active}>Active</span>;
		} else {
			return <span className={styles.inactive}>Inactive</span>;
		}
	} else {
		return <span className={styles.active}>Active</span>;
	}
}

function TokenTableRow(props: { token: VelavuToken; onDelete: VoidFunction }) {
	const [hoverState, enterHoverState, exitHoverState] = useToggleable();
	const [isHoverName, enableHoverName, disableHoverName] = useDelayHover();
	const [isHoverCreator, enableHoverCreator, disableHoverCreator] =
		useDelayHover();
	const nameRef = useRef<HTMLElement | null>(null);
	const creatorRef = useRef<HTMLElement | null>(null);

	return (
		<VelavuTableRow
			className={styles.tableRow}
			key={props.token.id}
			onMouseEnter={enterHoverState}
			onMouseLeave={exitHoverState}
		>
			<VelavuTableColumn flex={1}>
				{isHoverName && (
					<BottomTooltip refPosition={nameRef.current!}>
						{props.token.name}
					</BottomTooltip>
				)}
				<span
					className={styles.overflow}
					onMouseEnter={enableHoverName}
					onMouseLeave={disableHoverName}
					ref={nameRef}
				>
					{props.token.name}
				</span>
			</VelavuTableColumn>
			<VelavuTableColumn flex={1}>
				{isHoverCreator && (
					<BottomTooltip refPosition={creatorRef.current!}>
						{props.token.creator}
					</BottomTooltip>
				)}
				<span
					className={styles.overflow}
					onMouseEnter={enableHoverCreator}
					onMouseLeave={disableHoverCreator}
					ref={creatorRef}
				>
					{props.token.creator}
				</span>
			</VelavuTableColumn>
			<VelavuTableColumn flex={0.4}>
				{formatDate(props.token.created)}
			</VelavuTableColumn>
			<VelavuTableColumn flex={0.4}>
				{props.token.expiry ? formatDate(props.token.expiry) : "N/A"}
			</VelavuTableColumn>
			<VelavuTableColumn flex={0.2}>
				<TokenStatus token={props.token} />
			</VelavuTableColumn>
			<VelavuTableColumn flex={0.2}>
				<VelavuIconButton
					className={`${styles.tableButton} ${
						hoverState ? styles.tableButtonHover : ""
					}`}
					onClick={props.onDelete}
				>
					<IconClose color="#A3B3CC" />
				</VelavuIconButton>
			</VelavuTableColumn>
		</VelavuTableRow>
	);
}

function CreateToken(props: {
	onTokenCreation: (token: CreatedVelavuToken) => void;
}) {
	const [name, setName] = useState("");
	const [isLoading, setLoading] = useState(false);
	const [expiry, setExpiry] = useState<"none" | "week" | "month" | "3month">(
		"none",
	);
	const [result, setResult] = useState<
		{ success: boolean; description: string } | undefined
	>(undefined);
	const onTokenCreation = props.onTokenCreation;

	const submit = useCallback(
		(event: FormEvent<HTMLFormElement>) => {
			event.preventDefault();

			if (name && expiry) {
				const expiryDate = new Date();
				let body: CreateNewTokenParams;

				switch (expiry) {
					case "week":
						expiryDate.setDate(expiryDate.getDate() + 1 * 7);
						break;
					case "month":
						expiryDate.setMonth(expiryDate.getMonth() + 1);
						break;
					case "3month":
						expiryDate.setMonth(expiryDate.getMonth() + 3);
						break;
				}

				if (expiry === "none") {
					body = {
						name: name,
					};
				} else {
					body = {
						name: name,
						expiry: expiryDate.toISOString(),
					};
				}

				setLoading(true);

				VelavuAPI.apiTokens
					.createNewToken(body)
					.then((result) => {
						setResult({ success: true, description: result.token });
						setName("");
						setExpiry("none");
						onTokenCreation(result);
					})
					.catch((error) => {
						console.log(error.response);
						setResult({
							success: false,
							description: `Could not create token: ${name}.`,
						});
					})
					.finally(() => {
						setLoading(false);
					});
			}
		},
		[expiry, name, onTokenCreation, setResult, setName, setLoading],
	);

	// Clear token creation result after 5 seconds
	useEffect(() => {
		let timeout: NodeJS.Timer;
		if (result) timeout = setTimeout(() => setResult(undefined), 5000);
		return () => clearTimeout(timeout);
	}, [result, setResult]);

	return (
		<form className={styles.sectionInvite} onSubmit={submit}>
			<span className={`${styles.inviteTitle}`}>New token</span>

			<div className={styles.sectionInputs}>
				<VelavuInput
					placeholder="Token Name"
					maxLength={20}
					value={name}
					onChange={setName}
					disabled={isLoading}
					bottomPadding
					fullWidth
				/>
				<VelavuSelect
					value={expiry}
					onChange={setExpiry}
					disabled={isLoading}
					fullWidth
				>
					<option value="none">No Expiry</option>
					<option value="week">1 Week</option>
					<option value="month">1 Month</option>
					<option value="3month">3 Months</option>
				</VelavuSelect>
			</div>

			<VelavuButton
				label="Create"
				disabled={isLoading || name.trim().length === 0}
				style={{ alignSelf: "flex-end" }}
				submit
			/>

			{result && (
				<VelavuNotice
					className={styles.statusNotice}
					type={result.success ? "confirm" : "error"}
					title={
						result.success
							? "API Token Created"
							: "API Token Creation Failed"
					}
					body={result.description}
				/>
			)}
		</form>
	);
}
