// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.vAKcrQFiWvN_vk3x381H{font-weight:500;font-size:24px}.XyFq7LVtoyivRUJMPZJw{font-weight:500;font-size:20px}.gwNfnTgqi1fTh4j7L36J{font-weight:500;font-size:12px}.M1IIRMjBtpPzvbpAGC7g{font-size:18px}.kklaTZzXs0WYp59jWOJF{font-size:18px;font-weight:500}.YE2q3KPMG0BHIH5ZNjMD{font-size:16px}.E3_fhHNLMfWkxBJLJQrG{font-size:16px;font-weight:500}.EQ37iAZZMQaqjMS6RLGS{font-size:14px}.dKUnelLCyzfwlIe_FtVJ,.SjiNHrhJ0tr1btwmu_c_{font-size:14px;font-weight:500}.lRyHP9DCPW5S4ItVyHzH{font-size:14px;font-weight:400}.gNgnJgVgGTMSlZFmLNfT{font-size:12px}.qtA_YLVDWHsBVFLC3tkP{font-size:12px;font-weight:500}.ZkkI2wAgfi3Oj3X322fD{display:flex;flex-direction:row;align-items:center}.SjiNHrhJ0tr1btwmu_c_{margin-left:4px;color:#00255d}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/elements/icon-label-attribute.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,4CACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,YAAA,CACA,kBAAA,CACA,kBAAA,CAGD,sBACC,eAAA,CAGA,aAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `vAKcrQFiWvN_vk3x381H`;
export var h2 = `XyFq7LVtoyivRUJMPZJw`;
export var h3 = `gwNfnTgqi1fTh4j7L36J`;
export var p0 = `M1IIRMjBtpPzvbpAGC7g`;
export var p0m = `kklaTZzXs0WYp59jWOJF`;
export var p1 = `YE2q3KPMG0BHIH5ZNjMD`;
export var p1m = `E3_fhHNLMfWkxBJLJQrG`;
export var p2 = `EQ37iAZZMQaqjMS6RLGS`;
export var p2m = `dKUnelLCyzfwlIe_FtVJ`;
export var label = `SjiNHrhJ0tr1btwmu_c_`;
export var p2n = `lRyHP9DCPW5S4ItVyHzH`;
export var p3 = `gNgnJgVgGTMSlZFmLNfT`;
export var p3m = `qtA_YLVDWHsBVFLC3tkP`;
export var container = `ZkkI2wAgfi3Oj3X322fD`;
export default ___CSS_LOADER_EXPORT___;
