// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.v61hfFmJ6iyoCKCmq4zG{font-weight:500;font-size:24px}.W_pmPBelSv6JzD_u59Ii,.svq4Mk3wgAJ9VcO5iG0M{font-weight:500;font-size:20px}.vbzSCk0nlju0b6tYcbXd{font-weight:500;font-size:12px}.BhfDghGIvdhWINleJpJw{font-size:18px}.T7cUGM6WneSAJVgdFolH{font-size:18px;font-weight:500}.axsdyfexWqJNkay8ncoE{font-size:16px}.zB9nUmVXotUs46_6G8Px{font-size:16px;font-weight:500}.bXvlrDmB2BngSBJ5cpDm{font-size:14px}.pv64JpcA7myl9Hy9eBN6{font-size:14px;font-weight:500}.FB9CYdSoKF8KpuMNoZ7M{font-size:14px;font-weight:400}.mwV4_6rbqWvT4H72epKd{font-size:12px}.mK_yJUpbt1hitJBebA9I{font-size:12px;font-weight:500}.PICUq2p8aQVK0syBvYVa{height:40px}.svq4Mk3wgAJ9VcO5iG0M{line-height:40px;font-family:"Poppins",sans-serif !important;margin-right:8px;border:1px solid #e2e4e5 !important;color:#00255d !important;caret-color:#00255d !important;border-radius:8px !important}.Dw3bA88YFQnC5ky6hOto{background-color:#f5f8f9}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/elements/velavu-verification-input.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,4CACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CCzGD,sBACC,WAHQ,CAMT,sBACC,gBAPQ,CAQR,2CAAA,CAEA,gBAAA,CACA,mCAAA,CACA,wBAAA,CACA,8BAAA,CACA,4BAAA,CAGD,sBACC,wBDDO","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `v61hfFmJ6iyoCKCmq4zG`;
export var h2 = `W_pmPBelSv6JzD_u59Ii`;
export var character = `svq4Mk3wgAJ9VcO5iG0M`;
export var h3 = `vbzSCk0nlju0b6tYcbXd`;
export var p0 = `BhfDghGIvdhWINleJpJw`;
export var p0m = `T7cUGM6WneSAJVgdFolH`;
export var p1 = `axsdyfexWqJNkay8ncoE`;
export var p1m = `zB9nUmVXotUs46_6G8Px`;
export var p2 = `bXvlrDmB2BngSBJ5cpDm`;
export var p2m = `pv64JpcA7myl9Hy9eBN6`;
export var p2n = `FB9CYdSoKF8KpuMNoZ7M`;
export var p3 = `mwV4_6rbqWvT4H72epKd`;
export var p3m = `mK_yJUpbt1hitJBebA9I`;
export var container = `PICUq2p8aQVK0syBvYVa`;
export var characterInactive = `Dw3bA88YFQnC5ky6hOto`;
export default ___CSS_LOADER_EXPORT___;
