// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.gtXP7wO0MjGbNEYorf1f{font-weight:500;font-size:24px}.TDfhY2vUx448c3KzLQ8i{font-weight:500;font-size:20px}.gGuajM34vo7VHiGqqFNA{font-weight:500;font-size:12px}.bATq8JceUUdc7cEqwMeB{font-size:18px}.kYO9HeBidL5DEeKuJYM4{font-size:18px;font-weight:500}.RsNjGvTvg2kNpB81xmKK{font-size:16px}.cxQ96CajkHs6XH9IJZWu{font-size:16px;font-weight:500}.JMTBwiIsqybXdoTeEXgH{font-size:14px}.e4J6_SlqIRCDxD1oai9c{font-size:14px;font-weight:500}.wyLSLG1SUi50X8nGxDYw{font-size:14px;font-weight:400}.LzCVYIOL5ch3ljDaQGtl{font-size:12px}.F9p4jon1w8rjVC3YDhvp{font-size:12px;font-weight:500}.fpy49qdxwnHGfx9TEXXJ{display:flex;flex-direction:row;align-items:center;height:40px;padding-bottom:20px;background-color:#e5eaf0}.cs0byXNq_TbrxF96Rrk7{flex-grow:1}.f47QTzt47hF9EPn9CeiQ{margin-left:20px}.oseWzmqMTJqgO6As5rYl{text-decoration:none}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/elements/list-header.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,WAAA,CACA,mBAAA,CACA,wBAAA,CAGD,sBACC,WAAA,CAGD,sBACC,gBAAA,CAGD,sBACC,oBAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `gtXP7wO0MjGbNEYorf1f`;
export var h2 = `TDfhY2vUx448c3KzLQ8i`;
export var h3 = `gGuajM34vo7VHiGqqFNA`;
export var p0 = `bATq8JceUUdc7cEqwMeB`;
export var p0m = `kYO9HeBidL5DEeKuJYM4`;
export var p1 = `RsNjGvTvg2kNpB81xmKK`;
export var p1m = `cxQ96CajkHs6XH9IJZWu`;
export var p2 = `JMTBwiIsqybXdoTeEXgH`;
export var p2m = `e4J6_SlqIRCDxD1oai9c`;
export var p2n = `wyLSLG1SUi50X8nGxDYw`;
export var p3 = `LzCVYIOL5ch3ljDaQGtl`;
export var p3m = `F9p4jon1w8rjVC3YDhvp`;
export var container = `fpy49qdxwnHGfx9TEXXJ`;
export var spacer = `cs0byXNq_TbrxF96Rrk7`;
export var menuSpacing = `f47QTzt47hF9EPn9CeiQ`;
export var link = `oseWzmqMTJqgO6As5rYl`;
export default ___CSS_LOADER_EXPORT___;
