import React from "react";
import { VelavuMenuFilterItem } from "../../../../../elements/velavu-menu-filter";
import * as styles from "./devices-filter.module.scss";

interface DevicesFilterProps {
	filterBattery: boolean | undefined;
	selectBattery: (filter: boolean | undefined) => void;

	filterStatus: boolean | undefined;
	selectStatus: (filter: boolean | undefined) => void;
}

export default function DevicesFilter(props: DevicesFilterProps) {
	return (
		<div className={styles.container}>
			<div className={styles.column}>
				<div className={styles.title}>Status</div>
				<VelavuMenuFilterItem
					checked={props.filterStatus === undefined}
					onClick={() => props.selectStatus(undefined)}
				>
					All
				</VelavuMenuFilterItem>
				<VelavuMenuFilterItem
					checked={props.filterStatus === true}
					onClick={() => props.selectStatus(true)}
				>
					Active
				</VelavuMenuFilterItem>
				<VelavuMenuFilterItem
					checked={props.filterStatus === false}
					onClick={() => props.selectStatus(false)}
				>
					Offline
				</VelavuMenuFilterItem>
			</div>

			<div className={styles.column}>
				<div className={styles.title}>Battery Level</div>
				<VelavuMenuFilterItem
					checked={props.filterBattery === undefined}
					onClick={() => props.selectBattery(undefined)}
				>
					All
				</VelavuMenuFilterItem>
				<VelavuMenuFilterItem
					checked={props.filterBattery === true}
					onClick={() => props.selectBattery(true)}
				>
					Low Battery
				</VelavuMenuFilterItem>
			</div>
		</div>
	);
}
