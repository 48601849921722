// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.Cb4Vz3lt0nNjmTZCXhrA{font-weight:500;font-size:24px}.b1g1nMWxWrj7PLW885N0{font-weight:500;font-size:20px}.scqZq8diziL31YU2i88i{font-weight:500;font-size:12px}.M6bZHuTjMyeSxpS0HGLc{font-size:18px}.VrLcVxQCa5ny_gms9pd1{font-size:18px;font-weight:500}.nxD2MZoGqoAE8jzUIGnB{font-size:16px}.Och9jfgvCIfpddLwikg3{font-size:16px;font-weight:500}.HOhmD22ZN5lUbqiQLHCG,.GrAsxJR8EMGVCEGS73UU{font-size:14px}.grF0DxAiqcEpY2lq_J7Q{font-size:14px;font-weight:500}.E0npVeXvJ0Il75l1pcNp{font-size:14px;font-weight:400}.CmZOuQdAj4JMUvY6v3zG{font-size:12px}.ybl3jgBTr0zZ1h7csIDw{font-size:12px;font-weight:500}.Y9MXM5FwWrVpd1hZ4Aoq{background-color:#fff;padding:4px;border-radius:20px;display:flex;flex-direction:row;align-items:center;box-shadow:2px 5px 10px 0 rgba(0,37,93,.15)}.GrAsxJR8EMGVCEGS73UU{color:#5f718c;margin-left:12px;margin-right:12px}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/components/dashboard/layers/layers.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,4CACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,qBAAA,CAEA,WAAA,CAEA,kBAAA,CAEA,YAAA,CACA,kBAAA,CACA,kBAAA,CAEA,2CAAA,CAGD,sBAEC,aDLgB,CCOhB,gBAAA,CACA,iBAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `Cb4Vz3lt0nNjmTZCXhrA`;
export var h2 = `b1g1nMWxWrj7PLW885N0`;
export var h3 = `scqZq8diziL31YU2i88i`;
export var p0 = `M6bZHuTjMyeSxpS0HGLc`;
export var p0m = `VrLcVxQCa5ny_gms9pd1`;
export var p1 = `nxD2MZoGqoAE8jzUIGnB`;
export var p1m = `Och9jfgvCIfpddLwikg3`;
export var p2 = `HOhmD22ZN5lUbqiQLHCG`;
export var label = `GrAsxJR8EMGVCEGS73UU`;
export var p2m = `grF0DxAiqcEpY2lq_J7Q`;
export var p2n = `E0npVeXvJ0Il75l1pcNp`;
export var p3 = `CmZOuQdAj4JMUvY6v3zG`;
export var p3m = `ybl3jgBTr0zZ1h7csIDw`;
export var layers = `Y9MXM5FwWrVpd1hZ4Aoq`;
export default ___CSS_LOADER_EXPORT___;
