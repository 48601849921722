import React, { CSSProperties } from "react";
import IconAdd from "../dynamicicons/icon-add";
import IconCrop from "../dynamicicons/icon-crop";
import IconRemove from "../dynamicicons/icon-remove";
import Divider from "../elements/divider";
import VelavuIconButton from "../elements/velavu-icon-button";
import * as styles from "./map-controls.module.scss";
import IconVelavuMapOutline from "../dynamicicons/icon-velavu-map-outline";

export interface ControlsProps {
	style?: CSSProperties;
	className?: string;

	labelFit?: string;
	onFit?: () => void;

	labelMapStyle?: string;
	onChangeMapStyle?: () => void;

	onZoomIn?: () => void;
	onZoomOut?: () => void;
}

export default function MapControls(props: ControlsProps) {
	return (
		<div
			style={props.style}
			className={`${styles.controls} ${props.className ?? ""}`}
		>
			<VelavuIconButton onClick={props.onFit} tooltip={props.labelFit}>
				<IconCrop color="#3A58E2" size={24} />
			</VelavuIconButton>

			<VelavuIconButton
				onClick={props.onChangeMapStyle}
				tooltip={props.labelMapStyle}
			>
				<IconVelavuMapOutline color="#3A58E2" size={24} />
			</VelavuIconButton>

			<Divider
				orientation="vertical"
				edgePadding
				style={{ marginLeft: 2, marginRight: 2 }}
			/>

			<VelavuIconButton onClick={props.onZoomIn} tooltip="Zoom in">
				<IconAdd color="#3A58E2" size={24} />
			</VelavuIconButton>
			<VelavuIconButton onClick={props.onZoomOut} tooltip="Zoom out">
				<IconRemove color="#3A58E2" size={24} />
			</VelavuIconButton>
		</div>
	);
}
