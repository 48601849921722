import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react";
import {
	VelavuAPI,
	VelavuAsset,
	VelavuInventory,
	VelavuInventoryItem,
} from "velavu-js-api";
import Divider from "../../../../elements/divider";
import AssetInventoryItem from "./asset-inventory-item";
import AssetInventoryManagement from "./asset-inventory-management";
import AssetInventoryStatus from "./asset-inventory-status";
import * as styles from "./asset-inventory.module.scss";
import iconNoInventory from "../../../../img/icons/icon-no-inventory.svg";
import SignInContext from "../../../../sign-in-context";
import { classArr } from "../../../../helper/style-helper";
import VelavuButton from "../../../../elements/velavu-button";
import {
	useVelavuModal,
	VelavuTitledModal,
} from "../../../../elements/velavu-modal";
import SettingsModal, {
	SettingsTab,
} from "../../../../components/settings/settings-modal";
import { getInventorySetting } from "../../../../helper/inventory-helper";

interface AssetInventoryProps {
	asset: VelavuAsset;
	inventory?: VelavuInventory;
	setInventory?: React.Dispatch<
		React.SetStateAction<VelavuInventory | undefined>
	>;
	onSelectAsset: (assetID: string) => void;
	onHoverAsset: (asset: VelavuAsset | undefined) => void;
}

export default function AssetInventory(props: AssetInventoryProps) {
	const [inventoryEnabled, setInventoryEnabled] = useState<
		boolean | undefined
	>(undefined);
	const [manageInventory, setManageInventory] = useState(false);
	const userContext = useContext(SignInContext);
	const organizationPreferences = userContext.organization?.settings;
	const pushModal = useVelavuModal();

	// get inventory setting
	const updateInventorySetting = useCallback(async () => {
		if (!organizationPreferences?.rules?.inventory) {
			setInventoryEnabled(false);
		} else {
			setInventoryEnabled(
				await getInventorySetting(
					organizationPreferences.rules.inventory,
				),
			);
		}
	}, [organizationPreferences]);

	useEffect(() => {
		updateInventorySetting();
	}, [updateInventorySetting]);

	const openDeviceSettings = useCallback(() => {
		pushModal(
			(resolve, onTryResolve) => (
				<VelavuTitledModal title="Settings" onClose={resolve}>
					<SettingsModal
						onTryResolve={onTryResolve}
						initialTabKey={SettingsTab.Devices}
					/>
				</VelavuTitledModal>
			),
			{ pinToTop: true },
		);
	}, [pushModal]);

	const inventoryInProximity = useMemo(() => {
		if (props.inventory?.items) {
			const inProximity = props.inventory.items.filter(
				(item) => item.proximity,
			).length;
			const total = props.inventory.items.length;

			return `${inProximity}/${total}`;
		} else {
			return "0/0";
		}
	}, [props.inventory]);

	const othersInProximity = useMemo(() => {
		if (props.inventory?.others) {
			return props.inventory.others.length.toString();
		} else {
			return "0";
		}
	}, [props.inventory]);

	const addInventory = useCallback(
		(item: VelavuInventoryItem) => {
			VelavuAPI.assetInventory
				.addInventoryItem(props.asset.id, item.asset.id)
				.then((response) => props.setInventory?.(response))
				.catch((error) => console.log(error));
		},
		[props],
	);

	const deleteInventory = useCallback(
		(item: VelavuInventoryItem) => {
			VelavuAPI.assetInventory
				.removeInventoryItem(props.asset.id, item.asset.id)
				.then(() => {
					props.setInventory?.((inventory) => {
						if (inventory === undefined) {
							return undefined;
						}

						const updatedInventory = { ...inventory };
						updatedInventory.items = updatedInventory.items?.filter(
							({ asset }) => asset.id !== item.asset.id,
						);
						return updatedInventory;
					});
				})
				.catch((error) => console.log(error));
		},
		[props],
	);

	const inventoryStatus: "complete" | "incomplete" | undefined =
		useMemo(() => {
			const inventory = props.inventory;

			if (
				!inventoryEnabled ||
				!inventory ||
				!inventory.items ||
				inventory.items.length === 0
			) {
				return undefined;
			}

			const inProximity = inventory.items.filter(
				(item) => item.proximity,
			);

			return inProximity.length !== inventory.items.length
				? "incomplete"
				: "complete";
		}, [props.inventory, inventoryEnabled]);

	if (inventoryEnabled === undefined) {
		return <div className={styles.container} />;
	}

	if (inventoryEnabled === false) {
		return (
			<div
				className={classArr(styles.container, styles.inventoryDisabled)}
			>
				<div className={styles.noInventory}>
					<img src={iconNoInventory} />
					<h5>Inventory disabled</h5>
					<p>Enable device inventory in settings.</p>
					<VelavuButton
						className={styles.button}
						label="Settings"
						fullWidth
						onClick={openDeviceSettings}
					/>
				</div>
			</div>
		);
	}

	return (
		<>
			<AssetInventoryManagement
				show={manageInventory}
				asset={props.asset}
				inventory={props.inventory}
				addInventory={addInventory}
				onClose={() => setManageInventory(false)}
				onSelectAsset={props.onSelectAsset}
				onHoverAsset={props.onHoverAsset}
			/>
			<>
				<div className={styles.header}>
					<AssetInventoryStatus
						status={
							props.inventory?.items?.length
								? inventoryStatus
								: undefined
						}
					/>
					<div className={styles.statusRow}>
						<span>
							<span className={styles.darkText}>
								{inventoryInProximity}
							</span>
							<span>Inventory</span>
						</span>
						<span>
							<span className={styles.darkText}>
								+{othersInProximity}
							</span>
							<span>Nearby</span>
						</span>
						<button
							className={styles.manageButton}
							onClick={() => setManageInventory(!manageInventory)}
						>
							Edit
						</button>
					</div>
				</div>
				<Divider />
				<div className={styles.container}>
					<div className={styles.inventoryList}>
						{props.inventory?.items?.map((item) => (
							<AssetInventoryItem
								key={item.asset.id}
								item={item}
								action={manageInventory ? "delete" : undefined}
								deleteInventory={deleteInventory}
								fill={item.proximity ? "#6681FF" : "#E98B97"}
								stroke={item.proximity ? "#3A58E2" : "#DA3E52"}
								onClick={() =>
									props.onSelectAsset(item.asset.id)
								}
								onMouseEnter={() =>
									props.onHoverAsset(item.asset)
								}
								onMouseLeave={() =>
									props.onHoverAsset(undefined)
								}
							/>
						))}
					</div>
					{!props.inventory?.items && (
						<div className={styles.noInventory}>
							<img src={iconNoInventory} />
							<h5>No inventory assigned</h5>
							<p>Click the Edit button to add inventory</p>
						</div>
					)}
					{props.inventory?.others && (
						<span className={styles.othersTitle}>
							Other assets in proximity
						</span>
					)}
					<div className={styles.othersList}>
						{props.inventory?.others?.map(
							(item) =>
								item.proximity && (
									<AssetInventoryItem
										item={item}
										key={item.asset.id}
										action={"add"}
										addInventory={addInventory}
										fill={"#FFE588"}
										stroke={"#FFD338"}
										onClick={() =>
											props.onSelectAsset(item.asset.id)
										}
										onMouseEnter={() =>
											props.onHoverAsset(item.asset)
										}
										onMouseLeave={() =>
											props.onHoverAsset(undefined)
										}
										isOther
									/>
								),
						)}
					</div>
				</div>
			</>
		</>
	);
}
