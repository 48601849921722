// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.Xm6nd7vndtHtXc39n9N1{font-weight:500;font-size:24px}.APrnMF3a4dumZN0wccqM{font-weight:500;font-size:20px}.DPgR40sInrzInzW8DUQk{font-weight:500;font-size:12px}._USZchSD4ofVbX6ZjRbH{font-size:18px}.uKDgIiaGI4CO07UoW4eJ{font-size:18px;font-weight:500}.IxjyznbUwZzghX2IZ3a4{font-size:16px}._weEvTAafqpyQgZKj7iq{font-size:16px;font-weight:500}.AahAM6auiIfAhLkmSvqn{font-size:14px}.kOayWMgwIhlkfCSnzzLe,.gp5e7UQJzBT96rOnhWdy{font-size:14px;font-weight:500}.aJ2DY26d3DRIKPiRhbF8{font-size:14px;font-weight:400}.ResQige4Nm2wwUUzCbfl{font-size:12px}.nrPgKFfZWeySok540Y1R{font-size:12px;font-weight:500}.LkaTflm5717KkFqveBn_{display:flex;flex-direction:row;flex-wrap:wrap;row-gap:12px;column-gap:10px;padding:12px;box-sizing:border-box}.rmXZOkrw0FYgKKTPSY4r{height:48px;overflow:hidden}.lbqHph4lkZ7oTrQOG5wf{width:100%;display:flex;flex-direction:row}.qcc7ypob0Pf8QVTmWHfg{flex:1;flex-basis:100%}.gp5e7UQJzBT96rOnhWdy{padding:16px}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/components/analytics/analytics-sidebar-filter.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,4CACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,YAAA,CACA,kBAAA,CACA,cAAA,CAEA,YAAA,CACA,eAAA,CAEA,YAAA,CACA,qBAAA,CAGD,sBACC,WAAA,CACA,eAAA,CAGD,sBACC,UAAA,CAEA,YAAA,CACA,kBAAA,CAGD,sBACC,MAAA,CACA,eAAA,CAGD,sBAEC,YAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `Xm6nd7vndtHtXc39n9N1`;
export var h2 = `APrnMF3a4dumZN0wccqM`;
export var h3 = `DPgR40sInrzInzW8DUQk`;
export var p0 = `_USZchSD4ofVbX6ZjRbH`;
export var p0m = `uKDgIiaGI4CO07UoW4eJ`;
export var p1 = `IxjyznbUwZzghX2IZ3a4`;
export var p1m = `_weEvTAafqpyQgZKj7iq`;
export var p2 = `AahAM6auiIfAhLkmSvqn`;
export var p2m = `kOayWMgwIhlkfCSnzzLe`;
export var optionTitle = `gp5e7UQJzBT96rOnhWdy`;
export var p2n = `aJ2DY26d3DRIKPiRhbF8`;
export var p3 = `ResQige4Nm2wwUUzCbfl`;
export var p3m = `nrPgKFfZWeySok540Y1R`;
export var container = `LkaTflm5717KkFqveBn_`;
export var containerCollapsed = `rmXZOkrw0FYgKKTPSY4r`;
export var optionContainer = `lbqHph4lkZ7oTrQOG5wf`;
export var optionColumn = `qcc7ypob0Pf8QVTmWHfg`;
export default ___CSS_LOADER_EXPORT___;
