import React, { FormEvent, useCallback, useRef, useState } from "react";
import { Link, useLocation, Location, Navigate } from "react-router-dom";
import {
	minPasswordLength,
	passwordRequirementsNotice,
} from "../../../constants";
import IconDone from "../../../dynamicicons/icon-done";
import VelavuButton from "../../../elements/velavu-button";
import VelavuInput from "../../../elements/velavu-input";
import VelavuNotice from "../../../elements/velavu-notice";
import VelavuVerificationInput from "../../../elements/velavu-verification-input";
import AuthenticationButtonBar from "../authentication-button-bar";
import * as onboardingStyles from "../authentication.module.scss";
import * as styles from "./recover-reset.module.scss";
import { confirmResetPassword, resetPassword } from "aws-amplify/auth";

interface RecoverResetHistoryState {
	email: string;
}

export default function RecoverReset() {
	const location = useLocation() as Location<RecoverResetHistoryState | null>;

	const [verificationCode, setVerificationCode] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");

	const [isComplete, setComplete] = useState(false);
	const [errorText, setErrorText] = useState<string | undefined>(undefined);

	const [isLoading, setLoading] = useState(false);

	const passwordInputRef = useRef<HTMLInputElement>(null);

	const submitResend = useCallback(() => {
		if (!location.state?.email) return;

		resetPassword({
			username: location.state.email,
		})
			//.then((data) => console.log(data))
			.catch((error) => console.log(error.response));
	}, [location.state?.email]);

	const inputOK =
		verificationCode.length === 6 &&
		password.length >= minPasswordLength &&
		password === confirmPassword;

	const submitReset = useCallback(
		(event: FormEvent<HTMLFormElement>) => {
			event.preventDefault();

			//Ignoring if we're already complete or there is no email data
			if (isComplete || !location.state?.email) return;

			//Ignoring if the user hasn't properly entered their information
			if (!inputOK) return;

			setLoading(true);

			confirmResetPassword({
				username: location.state.email,
				confirmationCode: verificationCode,
				newPassword: password,
			})
				.then(() => setComplete(true))
				.catch((error) => {
					console.log(error.response);
					setErrorText(error.message);
				})
				.finally(() => setLoading(false));
		},
		[
			isComplete,
			location.state?.email,
			inputOK,
			verificationCode,
			password,
			setErrorText,
			setComplete,
		],
	);

	//If we don't have any state, redirect back to the password recovery
	if (!location.state?.email) {
		return <Navigate to="/login/recover" replace />;
	}

	return (
		<form onSubmit={submitReset}>
			<p className={onboardingStyles.instructionsText}>
				Input verification code
			</p>

			<VelavuVerificationInput
				value={verificationCode}
				onChange={setVerificationCode}
				onComplete={() => passwordInputRef.current!.focus()}
				disabled={isLoading || isComplete}
			/>
			{isLoading || isComplete ? (
				<span className={styles.resend}>
					Didn&apos;t receive an email? Resend
				</span>
			) : (
				<span className={styles.resend}>
					Didn&apos;t receive an email?{" "}
					<button
						className={styles.resendLink}
						onClick={submitResend}
					>
						Resend
					</button>
				</span>
			)}

			<VelavuInput
				inputRef={passwordInputRef}
				value={password}
				onChange={setPassword}
				placeholder="Password"
				type="password"
				autoComplete="new-password"
				trailingIcon={
					password.length >= minPasswordLength ? (
						<IconDone color="#00B283" size={16} />
					) : undefined
				}
				disabled={isLoading || isComplete}
				fullWidth
				bottomPadding
			/>
			<VelavuInput
				value={confirmPassword}
				onChange={setConfirmPassword}
				placeholder="Confirm password"
				type="password"
				autoComplete="new-password"
				trailingIcon={
					password.length >= minPasswordLength &&
					password === confirmPassword ? (
						<IconDone color="#00B283" size={16} />
					) : undefined
				}
				disabled={isLoading || isComplete}
				fullWidth
				bottomPadding
			/>

			{isComplete ? (
				<>
					<VelavuNotice
						className={onboardingStyles.noticeMargin}
						type="confirm"
						body="Password successfully updated"
					/>

					<AuthenticationButtonBar
						primary={
							<Link className={styles.link} to="/login">
								<VelavuButton
									label="Return to login"
									fullWidth
								/>
							</Link>
						}
					/>
				</>
			) : (
				<>
					{errorText ? (
						<VelavuNotice
							className={onboardingStyles.noticeMargin}
							type="error"
							body={errorText}
						/>
					) : (
						<VelavuNotice
							className={onboardingStyles.noticeMargin}
							type="info"
							body={passwordRequirementsNotice}
						/>
					)}

					<AuthenticationButtonBar
						primary={
							<VelavuButton
								label="Reset password"
								disabled={isLoading || !inputOK}
								fullWidth
								submit
							/>
						}
						secondary={
							<Link className={styles.link} to="/login">
								Return to login
							</Link>
						}
					/>
				</>
			)}
		</form>
	);
}
