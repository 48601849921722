import React, { useCallback, useRef } from "react";
import * as styles from "./velavu-id-input.module.scss";

interface VelavuIDInputProps {
	autofocus?: boolean;
	disabled?: boolean;
	error?: boolean;

	value: string;
	onChange: React.Dispatch<React.SetStateAction<string>>;
}

const cellCount = 5;

const regexHex = /^[0-9a-zA-Z]+$/;

export default function VelavuIDInput(props: VelavuIDInputProps) {
	const splitValues = Array.from(
		{ ...props.value.split(""), length: cellCount },
		(v) => v ?? " ",
	);

	const inputRefs = [...new Array(cellCount)].map(
		() => useRef<HTMLInputElement | null>(null), // eslint-disable-line react-hooks/rules-of-hooks
	);

	const propsOnChange = props.onChange;
	const updateInputValue = useCallback(
		(event: React.FormEvent<HTMLInputElement>, inputIndex: number) => {
			const newSplitValues = [...splitValues];

			const value = event.currentTarget.value
				.split("")
				.filter((char) => regexHex.test(char))
				.join("");
			if (value.length === 0) {
				event.preventDefault();
				return;
			}

			let nextInput: HTMLInputElement | undefined;
			if (value.length === 1) {
				//Set the value and move the the next input
				newSplitValues[inputIndex] = value;
				nextInput = inputRefs[inputIndex + 1]?.current ?? undefined;
			} else {
				//Split the value across the following inputs
				const divisions = value.split("");
				for (
					let i = 0;
					i <
					Math.min(
						divisions.length,
						newSplitValues.length - inputIndex,
					);
					i++
				) {
					newSplitValues[inputIndex + i] = divisions[i];
				}

				nextInput =
					inputRefs[
						Math.min(
							inputIndex + divisions.length,
							inputRefs.length - 1,
						)
					]?.current ?? undefined;
			}

			//Focusing the next input field
			if (nextInput) {
				nextInput.focus();
				nextInput.select();
			}

			//Updating the state
			propsOnChange(newSplitValues.join(""));
		},
		[propsOnChange, splitValues, inputRefs],
	);
	const handleKeyDown = useCallback(
		(event: React.KeyboardEvent<HTMLInputElement>, inputIndex: number) => {
			if (event.key === "Backspace") {
				event.preventDefault();

				if (splitValues[inputIndex] === " ") {
					if (inputIndex - 1 >= 0) {
						//Removing the previous character
						const newSplitValues = [...splitValues];
						newSplitValues[inputIndex - 1] = " ";
						propsOnChange(newSplitValues.join(""));

						//Focusing the previous input
						const prevInput = inputRefs[inputIndex - 1].current!;
						prevInput.focus();
					}
				} else {
					//Removing the current character
					const newSplitValues = [...splitValues];
					newSplitValues[inputIndex] = " ";
					propsOnChange(newSplitValues.join(""));
				}
			} else if (event.key === "ArrowLeft") {
				if (
					event.currentTarget!.selectionStart !== null &&
					inputIndex - 1 >= 0
				) {
					event.preventDefault();

					//Focusing the previous input
					const prevInput = inputRefs[inputIndex - 1].current!;
					prevInput.focus();
					prevInput.select();
				}
			} else if (event.key === "ArrowRight") {
				if (
					event.currentTarget!.selectionEnd !== null &&
					inputIndex + 1 < cellCount
				) {
					event.preventDefault();

					//Focusing the next input
					const nextInput = inputRefs[inputIndex + 1].current!;
					nextInput.focus();
					nextInput.select();
				}
			}
		},
		[splitValues, propsOnChange, inputRefs],
	);

	const selectOnFocus = useCallback(
		(event: React.FocusEvent<HTMLInputElement>) => event.target.select(),
		[],
	);

	return (
		<div className={styles.container}>
			{[...new Array(cellCount)].map((_, i) => {
				const value = splitValues[i];
				const valuePresent = value !== " ";

				return (
					<React.Fragment key={i}>
						{i > 0 && <div className={styles.dash} />}
						<div
							className={`${styles.cell} ${
								props.error ? styles.cellError : ""
							} ${props.disabled ? styles.cellDisable : ""}`}
						>
							<div className={styles.inputWrapper}>
								{!valuePresent && (
									<div className={styles.inputPlaceholder} />
								)}

								<input
									ref={inputRefs[i]}
									className={styles.input}
									autoFocus={props.autofocus && i === 0}
									value={valuePresent ? value : ""}
									onFocus={selectOnFocus}
									onInput={(event) =>
										updateInputValue(event, i)
									}
									onKeyDown={(event) =>
										handleKeyDown(event, i)
									}
									disabled={props.disabled}
								/>
							</div>
						</div>
					</React.Fragment>
				);
			})}
		</div>
	);
}
