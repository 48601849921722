import React, { useMemo, useState } from "react";
import * as styles from "./geofence-detail-events.module.scss";
import {
	AnyVelavuEvent,
	BaseVelavuEvent,
	EventCategory,
	EventCategoryMap,
	VelavuAPI,
	VelavuEvent,
} from "velavu-js-api";
import DatePicker from "../../../../elements/date-picker";
import { useDateRange } from "../../../../data/date-range";
import { useCancellableEffect } from "../../../../helper/hook-helper";
import Divider from "../../../../elements/divider";
import EventList from "../../../../elements/event-list";
import {
	compareEventAsc,
	compareEventDesc,
} from "../../../../helper/events-helper";

export interface GeofenceDetailEventsProps {
	geofenceID: string;
}

const GEOFENCE_EVENT_CATEGORIES: readonly EventCategory[] = [
	EventCategory.GeofenceEntered,
	EventCategory.GeofenceExited,
];

export default function GeofenceDetailEvents(props: GeofenceDetailEventsProps) {
	//Last week -> Today
	const [dateRange, setDateRange] = useDateRange(7);
	const [sortNew, setSortNew] = useState(true);

	//Load the events
	const [events, setEvents] = useState<AnyVelavuEvent[] | undefined>(
		undefined,
	);
	useCancellableEffect(
		(addPromise) => {
			const promises = GEOFENCE_EVENT_CATEGORIES.map((eventCategory) => {
				return VelavuAPI.events.getAllEvents({
					category: eventCategory,
					since: dateRange.start,
					until: dateRange.end,
					geofenceID: props.geofenceID,
				});
			});
			addPromise(Promise.all(promises))
				.then((results) => {
					const events = results
						.map((paginatedResult) => paginatedResult.data)
						.flat();
					setEvents(events);
				})
				.catch(console.warn);
		},
		[setEvents, props.geofenceID, dateRange],
	);

	//Sort the events
	const sortedEvents = useMemo(() => {
		return events?.sort(sortNew ? compareEventDesc : compareEventAsc);
	}, [events, sortNew]);

	return (
		<>
			<DatePicker
				range={dateRange}
				onUpdate={setDateRange}
				sortNew={sortNew}
				setSortNew={setSortNew}
			/>

			<Divider />

			<EventList
				className={styles.list}
				events={sortedEvents}
				includeEventOrigin
			/>
		</>
	);
}
