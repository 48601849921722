import React, { useMemo } from "react";
import * as styles from "./geofence-create.module.scss";
import PinnedList from "../pinned-list";
import PinnedListHeader from "../pinned-list-header";
import VelavuInput from "../../../../elements/velavu-input";
import VelavuNotice from "../../../../elements/velavu-notice";
import VelavuButton from "../../../../elements/velavu-button";

export interface GeofenceCreateProps {
	name: string;
	onChangeName: (name: string) => void;

	onClose?: () => void;
	onSubmit?: () => void;
	disabled?: boolean;
	isEditing?: boolean;
}

export default function GeofenceCreate(props: GeofenceCreateProps) {
	const nameOK = useMemo(() => props.name.trim().length > 0, [props.name]);

	return (
		<PinnedList>
			<PinnedListHeader
				label={props.isEditing ? "Edit geofence" : "New geofence"}
				onClose={props.onClose}
			/>

			<div className={styles.container}>
				<VelavuInput
					value={props.name}
					onChange={props.onChangeName}
					fullWidth
					titleText="Name"
				/>

				<VelavuNotice type="info">
					Draw the the geofence on the map.
					<br />
					Double-click to complete the boundary.
				</VelavuNotice>

				<VelavuButton
					label="Finish"
					fullWidth
					disabled={props.disabled || !nameOK}
					onClick={props.onSubmit}
				/>
			</div>
		</PinnedList>
	);
}
