// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.unucBP9lhZK3VtdhasFd{font-weight:500;font-size:24px}.on7wibf7X0LhgXTy5zmm,.bx08t8len5lvEcu97XoT{font-weight:500;font-size:20px}.u5hf9fqnd_emmD2Ms32S{font-weight:500;font-size:12px}.SnW3G046IQJJYcJvwEjY{font-size:18px}.AeBJf3BLCAv8be3yIDAP{font-size:18px;font-weight:500}.JKXd6iSXlvDNSUxSGsZp{font-size:16px}.q4o_ejMxUNOKytmfWCKM{font-size:16px;font-weight:500}.gEtiB6TVSjDLer3_aOG0{font-size:14px}.Du5dStHYUyxNGzBe8Pmo{font-size:14px;font-weight:500}.cX1WolvwCUNY_vyoxtzL{font-size:14px;font-weight:400}.uBsCgf2r49CkPh7aBpkS{font-size:12px}.L3lbxtlDfM2pWAdjOxKU{font-size:12px;font-weight:500}.VSL6AHd3rXd8WOMpjhZI{padding-right:4px;box-sizing:border-box;display:flex;flex-direction:row;align-items:center;border-radius:32px;transition:background-color 250ms ease}.VSL6AHd3rXd8WOMpjhZI:hover{background-color:#f5f8f9}.bx08t8len5lvEcu97XoT{margin-left:8px}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/components/dashboard/dropdown/map-list/map-list-selector-dropdown.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,4CACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,iBAAA,CACA,qBAAA,CAEA,YAAA,CACA,kBAAA,CACA,kBAAA,CAEA,kBAAA,CACA,sCAAA,CAEA,4BACC,wBDKM,CCDR,sBAEC,eAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `unucBP9lhZK3VtdhasFd`;
export var h2 = `on7wibf7X0LhgXTy5zmm`;
export var title = `bx08t8len5lvEcu97XoT`;
export var h3 = `u5hf9fqnd_emmD2Ms32S`;
export var p0 = `SnW3G046IQJJYcJvwEjY`;
export var p0m = `AeBJf3BLCAv8be3yIDAP`;
export var p1 = `JKXd6iSXlvDNSUxSGsZp`;
export var p1m = `q4o_ejMxUNOKytmfWCKM`;
export var p2 = `gEtiB6TVSjDLer3_aOG0`;
export var p2m = `Du5dStHYUyxNGzBe8Pmo`;
export var p2n = `cX1WolvwCUNY_vyoxtzL`;
export var p3 = `uBsCgf2r49CkPh7aBpkS`;
export var p3m = `L3lbxtlDfM2pWAdjOxKU`;
export var dropdown = `VSL6AHd3rXd8WOMpjhZI`;
export default ___CSS_LOADER_EXPORT___;
