// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.INhsCSfWFydYjvfQPZsV,.uLQHyZR4udZrx9oIwmK6{font-weight:500;font-size:24px}.GQFzJYMcv3oDZawZZ3L9{font-weight:500;font-size:20px}.KOCMq6a3A1b5gU_kHRse{font-weight:500;font-size:12px}.S1rMPqvk4VHfWz2YXuaL{font-size:18px}.Sav5CdfUilTV3JEFijel{font-size:18px;font-weight:500}.FAzSCEL4cBwOM3tVMfa9{font-size:16px}.TSjO9Nqaj98XUOcQtDga{font-size:16px;font-weight:500}.P70TxqNStZ2HKNk7XGgk,.SdLXBCzINhbYrsxfHWU0{font-size:14px}.LYt1rQDMAYnDzQe0J2Us{font-size:14px;font-weight:500}.W8PapXXSz7gRlNWrFchp{font-size:14px;font-weight:400}.y8SJHjpmtGDf2uFwCKdH{font-size:12px}.dHhTT59EIZ9_nqe9NhOG{font-size:12px;font-weight:500}.D6NxfRfWdEekL4wE7mZ3{display:flex;flex-direction:column;padding-top:20px;padding-left:20px;padding-right:20px;box-sizing:border-box}.D6NxfRfWdEekL4wE7mZ3>*{flex-shrink:0}.uLQHyZR4udZrx9oIwmK6{margin-bottom:8px}.SdLXBCzINhbYrsxfHWU0{margin-bottom:20px}.oQygEabjVu5Dgf7Ti8WQ{flex-grow:1}._rzG1JbWAupMryxNFwJF{justify-content:center}.NjmpZDvh3M248OMZjjv0{margin-top:8px}.JYl_iod3QrWHEvKydXvy{display:flex;flex-direction:row;align-items:center;justify-content:stretch;margin-top:16px}.wOwII07uS7vmXOxXGhRz{width:8px;flex-shrink:0}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/components/onboarding/onboarding-frame.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,4CACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,4CACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,YAAA,CACA,qBAAA,CAEA,gBAAA,CACA,iBAAA,CACA,kBAAA,CACA,qBAAA,CAEA,wBACC,aAAA,CAIF,sBAEC,iBAAA,CAGD,sBAEC,kBAAA,CAGD,sBACC,WAAA,CAGD,sBACC,sBAAA,CAGD,sBACC,cAAA,CAGD,sBACC,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,uBAAA,CAEA,eAAA,CAGD,sBACC,SAAA,CACA,aAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `INhsCSfWFydYjvfQPZsV`;
export var title = `uLQHyZR4udZrx9oIwmK6`;
export var h2 = `GQFzJYMcv3oDZawZZ3L9`;
export var h3 = `KOCMq6a3A1b5gU_kHRse`;
export var p0 = `S1rMPqvk4VHfWz2YXuaL`;
export var p0m = `Sav5CdfUilTV3JEFijel`;
export var p1 = `FAzSCEL4cBwOM3tVMfa9`;
export var p1m = `TSjO9Nqaj98XUOcQtDga`;
export var p2 = `P70TxqNStZ2HKNk7XGgk`;
export var description = `SdLXBCzINhbYrsxfHWU0`;
export var p2m = `LYt1rQDMAYnDzQe0J2Us`;
export var p2n = `W8PapXXSz7gRlNWrFchp`;
export var p3 = `y8SJHjpmtGDf2uFwCKdH`;
export var p3m = `dHhTT59EIZ9_nqe9NhOG`;
export var container = `D6NxfRfWdEekL4wE7mZ3`;
export var content = `oQygEabjVu5Dgf7Ti8WQ`;
export var contentCenter = `_rzG1JbWAupMryxNFwJF`;
export var notice = `NjmpZDvh3M248OMZjjv0`;
export var buttonBar = `JYl_iod3QrWHEvKydXvy`;
export var buttonBarSpacer = `wOwII07uS7vmXOxXGhRz`;
export default ___CSS_LOADER_EXPORT___;
