import { subDays } from "date-fns";
import React, {
	CSSProperties,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from "react";
import { DayPicker, DateRange as RDPDateRange } from "react-day-picker";
import { DateRange } from "../data/date-range";
import { adjustDateEnd, adjustDateStart } from "../helper/date-helper";
import { classArr } from "../helper/style-helper";
import * as styles from "./date-range-selector.module.scss";
import VelavuButton from "./velavu-button";

const PICKER_START_MONTH = new Date(2020, 0);

/**
 * A calendar that allows the user to select a range of dates
 */
export default function DateRangeSelector(props: {
	style?: CSSProperties;
	className?: string;

	range: DateRange;
	onUpdate: (range: DateRange) => void;

	onClose?: VoidFunction;
}) {
	const { onUpdate: propsOnUpdate, onClose: propsOnClose } = props;
	const setRangeLastFifteenMins = useCallback(() => {
		const start = new Date();
		start.setMinutes(start.getMinutes() - 15);

		propsOnUpdate({
			start,
			end: new Date(),
		});
		propsOnClose?.();
	}, [propsOnUpdate, propsOnClose]);
	const setRangeLastHour = useCallback(() => {
		const start = new Date();
		start.setHours(start.getHours() - 1);

		propsOnUpdate({
			start,
			end: new Date(),
		});
		propsOnClose?.();
	}, [propsOnUpdate, propsOnClose]);
	const setRangeLastDay = useCallback(() => {
		const start = new Date();
		start.setDate(start.getDate() - 1);

		propsOnUpdate({
			start,
			end: new Date(),
		});
		propsOnClose?.();
	}, [propsOnUpdate, propsOnClose]);
	const setRangeLastWeek = useCallback(() => {
		const today = new Date();

		propsOnUpdate({
			start: adjustDateStart(subDays(today, 7)),
			end: adjustDateEnd(today),
		});
		propsOnClose?.();
	}, [propsOnUpdate, propsOnClose]);
	const setRangeLastMonth = useCallback(() => {
		const start = new Date();
		start.setMonth(start.getMonth() - 1);
		const end = new Date();

		propsOnUpdate({
			start: adjustDateStart(start),
			end: adjustDateEnd(end),
		});
		propsOnClose?.();
	}, [propsOnUpdate, propsOnClose]);

	//Sync internal state
	const [internalRange, setInternalRange] = useState<
		RDPDateRange | undefined
	>();
	useEffect(() => {
		setInternalRange({
			from: props.range.start,
			to: props.range.end,
		});
	}, [props.range]);

	const onSelect = useCallback(
		(range: RDPDateRange | undefined) => {
			if (
				range !== undefined &&
				range.from !== undefined &&
				range.to !== undefined
			) {
				propsOnUpdate({
					start: adjustDateStart(range.from),
					end: adjustDateEnd(range.to),
				});
			} else {
				setInternalRange(range);
			}
		},
		[propsOnUpdate, setInternalRange],
	);
	const maxDate = useMemo(() => new Date(), []);

	return (
		<div
			style={props.style}
			className={classArr(styles.calendar, props.className)}
		>
			<DayPicker
				mode="range"
				selected={internalRange}
				onSelect={onSelect}
				fixedWeeks
				showOutsideDays
				captionLayout="dropdown"
				startMonth={PICKER_START_MONTH}
				today={maxDate}
				disabled={{ after: maxDate }}
			/>

			<div className={styles.footer}>
				<div className={styles.footerLabel}>Last:</div>
				<button
					className={styles.footerLink}
					onClick={setRangeLastFifteenMins}
				>
					15 min
				</button>
				<button
					className={styles.footerLink}
					onClick={setRangeLastHour}
				>
					1 hr
				</button>
				<button className={styles.footerLink} onClick={setRangeLastDay}>
					Day
				</button>
				<button
					className={styles.footerLink}
					onClick={setRangeLastWeek}
				>
					Week
				</button>
				<button
					className={styles.footerLink}
					onClick={setRangeLastMonth}
				>
					Month
				</button>

				{propsOnClose !== undefined && (
					<VelavuButton label="Close" onClick={propsOnClose} />
				)}
			</div>
		</div>
	);
}
