import React, { useMemo } from "react";
import IconCheckCircle from "../../../../dynamicicons/icon-check-circle";
import IconError from "../../../../dynamicicons/icon-error";
import * as styles from "./asset-inventory-status.module.scss";

export default function AssetInventoryStatus(props: {
	status: "complete" | "incomplete" | undefined;
}) {
	const statusCard = useMemo(() => {
		switch (props.status) {
			case "complete":
				return styles.statusCardGreen;
			case "incomplete":
				return styles.statusCardRed;
			default:
				return styles.statusCardNeutral;
		}
	}, [props.status]);

	return (
		<div className={`${statusCard} ${styles.statusCardHorizontal}`}>
			{!props.status && (
				<>
					<IconError style={{ marginRight: 4 }} color={undefined} />
					<span>No Inventory</span>
				</>
			)}
			{props.status === "incomplete" && (
				<>
					<IconError style={{ marginRight: 4 }} color={undefined} />
					<span>Missing Inventory</span>
				</>
			)}
			{props.status === "complete" && (
				<>
					<IconCheckCircle
						style={{ marginRight: 4 }}
						color={undefined}
					/>
					<span>Inventory Complete</span>
				</>
			)}
		</div>
	);
}
