import React from "react";
import * as styles from "./floating-popup-panel.module.scss";
import { classArr } from "../helper/style-helper";

interface FloatingPopupPanelProps {
	show: boolean;
	children?: React.ReactNode;
}

/// A toast element that appears from the top of its container
export default function FloatingPopupPanel(props: FloatingPopupPanelProps) {
	return (
		<div
			className={classArr(
				styles.panel,
				props.show ? styles.panel__show : styles.panel__hide,
			)}
		>
			{props.children}
		</div>
	);
}
