import React, { CSSProperties } from "react";

export default function IconSiteEntered(props: {
	className?: string;
	style?: CSSProperties;
	color?: string;
	size?: number;
}) {
	return (
		<svg
			className={props.className}
			style={props.style}
			width={props.size ?? 24}
			height={props.size ?? 24}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M13.3557 6.03424C12.5899 5.32192 11.4101 5.32192 10.6442 6.03424L6.64425 9.75441C6.23432 10.1357 6 10.6731 6 11.2375V16.4827C6 17.6025 6.90108 18.5 8 18.5H16C17.0989 18.5 18 17.6025 18 16.4827C18 15.94 18.44 15.5 18.9827 15.5H19.0173C19.56 15.5 20 15.94 20 16.4827C20 18.6965 18.214 20.5 16 20.5H8C5.78603 20.5 4 18.6965 4 16.4827V11.2375C4 10.1194 4.464 9.05085 5.28219 8.2899L9.28219 4.56973C10.8158 3.14343 13.1842 3.14342 14.7178 4.56972L18.7178 8.28989C19.043 8.59236 19.3123 8.94342 19.5186 9.32681C19.7804 9.81311 19.4447 10.3657 18.909 10.5C18.3733 10.6343 17.8435 10.2787 17.4711 9.87084C17.4344 9.83061 17.3959 9.79176 17.3557 9.75441L13.3557 6.03424Z"
				fill={props.color ?? "black"}
			/>
			<path
				d="M21 14C21.5523 14 22 13.5523 22 13C22 12.4477 21.5523 12 21 12H13.3992V9L9.37531 12.2191C8.8749 12.6195 8.8749 13.3805 9.37531 13.7809L13.3992 17V14H21Z"
				fill={props.color ?? "black"}
			/>
		</svg>
	);
}
