// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.qNnSzzCOnsl_QcRAHZ3I{font-weight:500;font-size:24px}.pKFDvgK1QmEIwncjy8Jt{font-weight:500;font-size:20px}.EFQyJerHx2CLfZp9dBry{font-weight:500;font-size:12px}.X97Zmnn22d7tfwwhjD_r{font-size:18px}.BJbCn3v_vgG3dXGyyEk8{font-size:18px;font-weight:500}.mJ0iwzpKwJnhFhmM7FpA{font-size:16px}.erETmypbwQoWWB31WRN8{font-size:16px;font-weight:500}.RZJr3wOGyzoCuI3kOPjg,.TaJPFoX6AnpKL55dDNrk{font-size:14px}.AnAHZvXlrnn10vH0h5LU{font-size:14px;font-weight:500}.z5oK0zWB898Q_BlFvl_B{font-size:14px;font-weight:400}.wHn5BgMsZOEmc4cgNr2I{font-size:12px}.rIzNMoTbfD5lHKKFYCJs{font-size:12px;font-weight:500}.YSOv70RFIBH0TShgguW1{padding:20px;box-sizing:border-box;display:flex;flex-direction:column;gap:32px}.bf9fmWz4Qyb1ale5K7iO{border-radius:8px;border:1px solid #e2e4e5}.gGzfVuCBnTgZBcHfml6u{width:100%;height:160px;border-bottom:1px solid #e2e4e5;box-sizing:border-box;object-fit:contain}.tes6aHxW7Jr1Nxe6zWfy{height:40px;padding-left:12px;padding-right:12px;display:flex;flex-direction:row;align-items:center}.TaJPFoX6AnpKL55dDNrk{width:100%;overflow:hidden;text-overflow:ellipsis}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/components/dashboard/dropdown/floor-create/floor-create-import-review.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,4CACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,YAAA,CACA,qBAAA,CAEA,YAAA,CACA,qBAAA,CACA,QAAA,CAGD,sBACC,iBAAA,CACA,wBAAA,CAGD,sBACC,UAAA,CACA,YAAA,CACA,+BAAA,CACA,qBAAA,CAEA,kBAAA,CAGD,sBACC,WAAA,CACA,iBAAA,CACA,kBAAA,CAEA,YAAA,CACA,kBAAA,CACA,kBAAA,CAGD,sBAGC,UAAA,CACA,eAAA,CACA,sBAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `qNnSzzCOnsl_QcRAHZ3I`;
export var h2 = `pKFDvgK1QmEIwncjy8Jt`;
export var h3 = `EFQyJerHx2CLfZp9dBry`;
export var p0 = `X97Zmnn22d7tfwwhjD_r`;
export var p0m = `BJbCn3v_vgG3dXGyyEk8`;
export var p1 = `mJ0iwzpKwJnhFhmM7FpA`;
export var p1m = `erETmypbwQoWWB31WRN8`;
export var p2 = `RZJr3wOGyzoCuI3kOPjg`;
export var floorPreviewFileName = `TaJPFoX6AnpKL55dDNrk`;
export var p2m = `AnAHZvXlrnn10vH0h5LU`;
export var p2n = `z5oK0zWB898Q_BlFvl_B`;
export var p3 = `wHn5BgMsZOEmc4cgNr2I`;
export var p3m = `rIzNMoTbfD5lHKKFYCJs`;
export var content = `YSOv70RFIBH0TShgguW1`;
export var floorPreview = `bf9fmWz4Qyb1ale5K7iO`;
export var floorPreviewImage = `gGzfVuCBnTgZBcHfml6u`;
export var floorPreviewFile = `tes6aHxW7Jr1Nxe6zWfy`;
export default ___CSS_LOADER_EXPORT___;
