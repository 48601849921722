// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.otw5Ca0J3unEsw3PBKEp{font-weight:500;font-size:24px}.jUHZHs7T5dv3wBM1F20m{font-weight:500;font-size:20px}.GahThgNt1EIVfrqFp5xx{font-weight:500;font-size:12px}.uPDYTHTIcJPk4Txk2IGI{font-size:18px}.b4bgs671CmUf2wPIWGwD{font-size:18px;font-weight:500}.fdM7bBKu2Ja1if27sO3g{font-size:16px}.H2UVqOGtm3siuvKwma9w{font-size:16px;font-weight:500}.aTh1f9WKrUSIO52gv08A,.gGlvtj7IEodIfg9ydD3t{font-size:14px}.ZA9rOTbnr0z6biAWoH4f{font-size:14px;font-weight:500}.SFZLYJImMAPnoO_wxhjM{font-size:14px;font-weight:400}.yOpbFMcuUazlFFKf8zyA{font-size:12px}.NFaUCCOlS2CXU7evFXog{font-size:12px;font-weight:500}.QwE47D76e8TL0RgCEWjK{width:640px;max-height:75vh;display:flex;flex-direction:column}.QwE47D76e8TL0RgCEWjK>*{flex-shrink:0}.dnrWEj_whB5HQMgWj7lQ{flex:1;overflow-y:auto}.gGlvtj7IEodIfg9ydD3t{display:flex;align-items:center;justify-content:center;height:100px;color:#5f718c}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/components/asset-tag-detail/detail-modal.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,4CACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,WAAA,CACA,eAAA,CAEA,YAAA,CACA,qBAAA,CAEA,wBACC,aAAA,CAIF,sBACC,MAAA,CACA,eAAA,CAGD,sBACC,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,YAAA,CAGA,aDbgB","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `otw5Ca0J3unEsw3PBKEp`;
export var h2 = `jUHZHs7T5dv3wBM1F20m`;
export var h3 = `GahThgNt1EIVfrqFp5xx`;
export var p0 = `uPDYTHTIcJPk4Txk2IGI`;
export var p0m = `b4bgs671CmUf2wPIWGwD`;
export var p1 = `fdM7bBKu2Ja1if27sO3g`;
export var p1m = `H2UVqOGtm3siuvKwma9w`;
export var p2 = `aTh1f9WKrUSIO52gv08A`;
export var tabBlank = `gGlvtj7IEodIfg9ydD3t`;
export var p2m = `ZA9rOTbnr0z6biAWoH4f`;
export var p2n = `SFZLYJImMAPnoO_wxhjM`;
export var p3 = `yOpbFMcuUazlFFKf8zyA`;
export var p3m = `NFaUCCOlS2CXU7evFXog`;
export var container = `QwE47D76e8TL0RgCEWjK`;
export var content = `dnrWEj_whB5HQMgWj7lQ`;
export default ___CSS_LOADER_EXPORT___;
