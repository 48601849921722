import React, { useState } from "react";
import {
	normalizeDeviceHardware,
	VelavuDevice,
	VelavuSite,
} from "velavu-js-api";
import DropdownPanelToolbar from "../../../../elements/dropdown-panel-toolbar";
import VelavuItemIcon from "../../../../elements/velavu-item-icon";
import VelavuTabSwitcher from "../../../../elements/velavu-tab-switcher";
import * as styles from "./gateway-detail.module.scss";
import GatewayProperties from "./gateway-properties";
import EventsDetailList from "../../../../elements/events-detail-list";
import { EventGroup } from "../../../../data/event-group";

enum GatewayDetailTab {
	Info,
	Events,
}

interface GatewayDetailProps {
	site: VelavuSite;
	gateway: VelavuDevice;
	onBack: () => void;
	onClose: () => void;
}

const EVENT_GROUPS: readonly EventGroup[] = [
	EventGroup.All,
	EventGroup.LowBattery,
	EventGroup.Connectivity,
	EventGroup.Custom,
];

export default function GatewayDetail(props: GatewayDetailProps) {
	const [selectedTab, setSelectedTab] = useState(GatewayDetailTab.Info);

	return (
		<div className={styles.container}>
			<DropdownPanelToolbar
				onBack={props.onBack}
				onClose={props.onClose}
			/>
			<div className={styles.header}>
				<VelavuItemIcon
					data={normalizeDeviceHardware(props.gateway.hardware)}
				/>
				<div className={styles.headerText}>
					<span className={styles.headerID}>{props.gateway.id}</span>
					<span className={styles.headerLocation}>
						{props.site.name}
					</span>
				</div>
			</div>
			<VelavuTabSwitcher
				selectedKey={selectedTab}
				onSelectKey={setSelectedTab}
				labels={{
					[GatewayDetailTab.Info]: "Info",
					[GatewayDetailTab.Events]: "Events",
				}}
			>
				{selectedTab === GatewayDetailTab.Info && (
					<div className={styles.tables}>
						<GatewayProperties
							gateway={props.gateway}
							site={props.site}
						/>
					</div>
				)}

				{selectedTab === GatewayDetailTab.Events && (
					<EventsDetailList
						deviceID={props.gateway.id}
						groups={EVENT_GROUPS}
					/>
				)}
			</VelavuTabSwitcher>
		</div>
	);
}
