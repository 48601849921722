// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.GoJB5bIFOQwUZlXQZRZy{font-weight:500;font-size:24px}.nXkKLVaEFUsqMz94jkOj{font-weight:500;font-size:20px}.ti9KcAyUzvjkv2Bn8oQ3{font-weight:500;font-size:12px}.CUNbuIbfJWOdLkiPDoUg{font-size:18px}.hu4u1JETx0DGMZ4GnBRw{font-size:18px;font-weight:500}.NMD4JN8w1Y064ZO0kwaF{font-size:16px}.p3HR8DjgzP4zBe7ickb4,.u4wPxEglQtKGBryibIfQ{font-size:16px;font-weight:500}.TlZwlsLM6rnP0Y3w7EE5{font-size:14px}.B9efiyKuAaV7sGuMIVQk{font-size:14px;font-weight:500}.LCfuECwsQrV6o5ptWYPR{font-size:14px;font-weight:400}.c3tTIn8zEFZlc8X6XXMF{font-size:12px}.hK7vgAHonFg_eOXF35CA{font-size:12px;font-weight:500}.LfStJ6eok0SnLXcLM7T6{width:100%;height:64px;flex-shrink:0;padding:0 20px;box-sizing:border-box;display:flex;flex-direction:row;align-items:center;justify-content:space-between}.u4wPxEglQtKGBryibIfQ{color:#00255d;flex-grow:1;flex-shrink:1;flex-basis:0}.y0rMBPjEBdG258vKOs7y{display:flex;flex-direction:row;align-items:center;gap:10px;flex-shrink:0}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/components/analytics/analytics-card-header.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,4CACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,UAAA,CACA,WAAA,CACA,aAAA,CAEA,cAAA,CACA,qBAAA,CAEA,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,6BAAA,CAGD,sBAEC,aDNc,CCQd,WAAA,CACA,aAAA,CACA,YAAA,CAGD,sBACC,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,QAAA,CAEA,aAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `GoJB5bIFOQwUZlXQZRZy`;
export var h2 = `nXkKLVaEFUsqMz94jkOj`;
export var h3 = `ti9KcAyUzvjkv2Bn8oQ3`;
export var p0 = `CUNbuIbfJWOdLkiPDoUg`;
export var p0m = `hu4u1JETx0DGMZ4GnBRw`;
export var p1 = `NMD4JN8w1Y064ZO0kwaF`;
export var p1m = `p3HR8DjgzP4zBe7ickb4`;
export var title = `u4wPxEglQtKGBryibIfQ`;
export var p2 = `TlZwlsLM6rnP0Y3w7EE5`;
export var p2m = `B9efiyKuAaV7sGuMIVQk`;
export var p2n = `LCfuECwsQrV6o5ptWYPR`;
export var p3 = `c3tTIn8zEFZlc8X6XXMF`;
export var p3m = `hK7vgAHonFg_eOXF35CA`;
export var container = `LfStJ6eok0SnLXcLM7T6`;
export var children = `y0rMBPjEBdG258vKOs7y`;
export default ___CSS_LOADER_EXPORT___;
