// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.vJR7ajEFTJIpd3ct_s1w{font-weight:500;font-size:24px}.hkZxpbbbqyC8MbrWDPhS{font-weight:500;font-size:20px}.MBmdMNCAsje2J06T_APa{font-weight:500;font-size:12px}.PFK17FITTqnSAPBKmt4k{font-size:18px}.E6xj4twBxv5ABF2Gm9AM{font-size:18px;font-weight:500}.dZnddWMqPDRHXisGQuqk{font-size:16px}.iIuTTJKBhvSeuD9OzbaA,.wVze2dUFA9rsW5GB3h8r{font-size:16px;font-weight:500}.QxBX4Xb_k6bMffQfs50w{font-size:14px}.hv7LwclvpxPtOUSe8DuF{font-size:14px;font-weight:500}.j085yYU0t4n0EWW01KFz{font-size:14px;font-weight:400}.rMicabcS2I0yRBSXw4Bs{font-size:12px}._nLpJNSJI2p8Fsjgpf9q{font-size:12px;font-weight:500}.fDFMGzObgmMYEOCDI578{padding:20px;box-sizing:border-box;display:flex;flex-direction:column;gap:20px}.HsPpoDIAOjWKcYyiztw6{display:flex;flex-direction:row}.E6S_ZxSFBdXOjhJYyRq3{width:68px;flex-shrink:0;margin-right:8px}.oRt0iWKyvhrdmau3QNBH{flex:1 1 0}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/components/dashboard/dropdown/floor-create/floor-create-details.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,4CACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,YAAA,CACA,qBAAA,CAEA,YAAA,CACA,qBAAA,CACA,QAAA,CAOD,sBACC,YAAA,CACA,kBAAA,CAGD,sBACC,UAAA,CACA,aAAA,CACA,gBAAA,CAGD,sBACC,UAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `vJR7ajEFTJIpd3ct_s1w`;
export var h2 = `hkZxpbbbqyC8MbrWDPhS`;
export var h3 = `MBmdMNCAsje2J06T_APa`;
export var p0 = `PFK17FITTqnSAPBKmt4k`;
export var p0m = `E6xj4twBxv5ABF2Gm9AM`;
export var p1 = `dZnddWMqPDRHXisGQuqk`;
export var p1m = `iIuTTJKBhvSeuD9OzbaA`;
export var label = `wVze2dUFA9rsW5GB3h8r`;
export var p2 = `QxBX4Xb_k6bMffQfs50w`;
export var p2m = `hv7LwclvpxPtOUSe8DuF`;
export var p2n = `j085yYU0t4n0EWW01KFz`;
export var p3 = `rMicabcS2I0yRBSXw4Bs`;
export var p3m = `_nLpJNSJI2p8Fsjgpf9q`;
export var content = `fDFMGzObgmMYEOCDI578`;
export var inputBar = `HsPpoDIAOjWKcYyiztw6`;
export var inputFloorNum = `E6S_ZxSFBdXOjhJYyRq3`;
export var inputFloorName = `oRt0iWKyvhrdmau3QNBH`;
export default ___CSS_LOADER_EXPORT___;
