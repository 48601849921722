import React, { CSSProperties } from "react";
import { classArr } from "../helper/style-helper";
import { useDelayHover } from "../helper/hook-helper";
import * as styles from "./velavu-icon-button.module.scss";

interface VelavuIconButtonProps {
	className?: string;
	style?: CSSProperties;
	children?: React.ReactNode;
	tooltip?: string;
	tooltipType?: "singleLine" | "paragraph";
	tooltipStyle?: CSSProperties;
	disabled?: boolean;
	onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	light?: boolean;
	small?: boolean;
	reallySmall?: boolean;
	focus?: boolean;
	highlight?: boolean;
}

const VelavuIconButton = React.forwardRef<
	HTMLButtonElement,
	VelavuIconButtonProps
>((props, ref) => {
	const [isHover, enableHover, disableHover] = useDelayHover();

	return (
		<button
			ref={ref}
			className={`${styles.main} ${
				props.small
					? styles.sizeSmall
					: props.reallySmall
					? styles.sizeReallySmall
					: styles.sizeNormal
			} ${props.light ? styles.light : ""} ${
				props.focus ? styles.focus : ""
			} ${props.highlight ? styles.highlight : ""} ${
				props.className ? props.className : ""
			}`}
			style={props.style}
			disabled={props.disabled}
			onClick={props.onClick}
			onMouseEnter={enableHover}
			onMouseLeave={disableHover}
		>
			{props.children}
			{isHover && !props.disabled && props.tooltip && (
				<Tooltip
					label={props.tooltip}
					type={props.tooltipType}
					style={props.tooltipStyle}
				/>
			)}
		</button>
	);
});
export default VelavuIconButton;

function Tooltip(props: {
	label: string;
	type?: "singleLine" | "paragraph";
	style?: CSSProperties;
}) {
	return (
		<div className={styles.tooltip} style={props.style}>
			<svg width="8" height="4" viewBox="0 0 2 1">
				<polygon points="0,1 1,0 2,1" fill="#00255D" />
			</svg>
			<span
				className={classArr(
					styles.tooltipContainer,
					props.type === "paragraph" &&
						styles.tooltipContainerParagraph,
				)}
			>
				{props.label}
			</span>
		</div>
	);
}
