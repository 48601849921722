// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.eWIqmWyETfaR02yvNVo1{font-weight:500;font-size:24px}.xQLrTWA8Vk2nh8MLQ7pU{font-weight:500;font-size:20px}.H3xXbFh5z8wjMrpE1BSG{font-weight:500;font-size:12px}.XG2n3WxF82K9qcm7SuYx{font-size:18px}.MGaHoUoczP53K9uyu6gE{font-size:18px;font-weight:500}.CqQKy3FfDyMFxBt1ptxu{font-size:16px}.bFeBHqum5z0OCo1ymq6P{font-size:16px;font-weight:500}.Q4zOrvTSdQB5tVmrmyLs{font-size:14px}.FhXpHdBBZGhJPJuDzecQ{font-size:14px;font-weight:500}.HFFsQ2w2d6826U93xr66{font-size:14px;font-weight:400}.LIEXLZfg4EGntF5LE0u0{font-size:12px}.O6ihBOY8PZcWReDqjNA9{font-size:12px;font-weight:500}.m_FOwbHw_i2reH5EcGxK{position:absolute;background:#fff;border-radius:8px;box-shadow:2px 5px 10px 0 rgba(0,37,93,.15);font-weight:500;padding:4px;width:200px;display:flex;flex-direction:column}.JWkhENTlJejNGQ2kMwPJ{display:flex;flex-direction:row;align-items:center;gap:12px;padding:0 16px;box-sizing:border-box;border-radius:4px;width:100%;height:40px;color:#00255d;font-size:16px;cursor:pointer}.JWkhENTlJejNGQ2kMwPJ:hover{background-color:#f5f8f9}.utAm6cDs98D07N0RC5Wp{width:16px;height:16px}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/components/map-context-menu/map-context-menu.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,iBAAA,CAEA,eAAA,CACA,iBAAA,CACA,2CAAA,CACA,eAAA,CACA,WAAA,CACA,WAAA,CAEA,YAAA,CACA,qBAAA,CAGD,sBACC,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,QAAA,CACA,cAAA,CACA,qBAAA,CACA,iBAAA,CAEA,UAAA,CACA,WAAA,CACA,aDfc,CCiBd,cAAA,CACA,cAAA,CAEA,4BACC,wBDdM,CCkBR,sBACC,UAAA,CACA,WAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `eWIqmWyETfaR02yvNVo1`;
export var h2 = `xQLrTWA8Vk2nh8MLQ7pU`;
export var h3 = `H3xXbFh5z8wjMrpE1BSG`;
export var p0 = `XG2n3WxF82K9qcm7SuYx`;
export var p0m = `MGaHoUoczP53K9uyu6gE`;
export var p1 = `CqQKy3FfDyMFxBt1ptxu`;
export var p1m = `bFeBHqum5z0OCo1ymq6P`;
export var p2 = `Q4zOrvTSdQB5tVmrmyLs`;
export var p2m = `FhXpHdBBZGhJPJuDzecQ`;
export var p2n = `HFFsQ2w2d6826U93xr66`;
export var p3 = `LIEXLZfg4EGntF5LE0u0`;
export var p3m = `O6ihBOY8PZcWReDqjNA9`;
export var list = `m_FOwbHw_i2reH5EcGxK`;
export var button = `JWkhENTlJejNGQ2kMwPJ`;
export var icon = `utAm6cDs98D07N0RC5Wp`;
export default ___CSS_LOADER_EXPORT___;
