import React, { CSSProperties } from "react";

export default function IconDeviceBoot(props: {
	className?: string;
	style?: CSSProperties;
	color?: string;
	size?: number;
}) {
	return (
		<svg
			className={props.className}
			style={props.style}
			xmlns="http://www.w3.org/2000/svg"
			width={props.size ?? 24}
			height={props.size ?? 24}
			viewBox="0 0 24 24"
		>
			<path
				d="M23 7a5.97 5.97 0 0 1-6.99 5.92H16a6 6 0 0 1-4.92-6.9V6a5.96 5.96 0 0 1 2.57-3.98l.03-.02A6 6 0 0 1 23 7Zm-3.75 0a2.25 2.25 0 1 1-4.37-.75.75.75 0 1 0-1.42-.5 3.76 3.76 0 1 0 7.08 0 .75.75 0 1 0-1.42.5c.09.24.13.5.13.75ZM17 3a.75.75 0 0 0-.75.75v1.5a.75.75 0 0 0 1.5 0v-1.5A.75.75 0 0 0 17 3Z"
				fill={props.color ?? "black"}
			/>

			<path
				d="M11.41 2H8a6 6 0 0 0-6 6v8a6 6 0 0 0 6 6h8a6 6 0 0 0 6-6v-3.41a7.5 7.5 0 0 1-2 1.29V16a4 4 0 0 1-4 4H8a4 4 0 0 1-4-4V8a4 4 0 0 1 4-4h2.12a7.5 7.5 0 0 1 1.29-2Z"
				fill={props.color ?? "black"}
			/>

			<path
				d="M18 14.43V16a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2V8c0-1.1.9-2 2-2h1.57a7.57 7.57 0 0 0 0 2H8v8h8v-1.57a7.57 7.57 0 0 0 2 0Z"
				fill={props.color ?? "black"}
			/>
		</svg>
	);
}
