// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.NY4xbvDEJrLFHBT63U81{font-weight:500;font-size:24px}.lKcmMAIgJmCC4bhXS2wm{font-weight:500;font-size:20px}._R8fhCCP4_4hade6gTTu{font-weight:500;font-size:12px}.ZDJg4OnrGP5bkkVEwXya{font-size:18px}.uTpyrjOsaZ0Cb7d9FApI{font-size:18px;font-weight:500}.g9Fv4LRIeCbKjW_whRST{font-size:16px}.Ct42n5vDs8ktB2ke904w{font-size:16px;font-weight:500}.j7vki2xRyI4FFKWyyalC,.yx5SurHwZLZZAfwYwxSJ{font-size:14px}.TkLmqN0NiU9_i4aXfhtA,.jkPftlSrxiCDGqkzHo6k{font-size:14px;font-weight:500}.ZNd4lXlkhB8WM7PXeIKP{font-size:14px;font-weight:400}.VC2k4rdMK9Nhs8rRGMgj{font-size:12px}.kLgsdL0g95zmYRxcjpvE{font-size:12px;font-weight:500}.JnYLoy3CYnGcYRZ6BxXq{display:flex;flex-direction:row;align-items:flex-start;padding:7px 4px}.GK18mrwkcbpQBJEK4fTn{margin-top:1px;margin-right:8px}.lyr3n8zGqJ_QrRxB14kh{display:flex;flex-direction:column}.yx5SurHwZLZZAfwYwxSJ{color:#5f718c}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/injectable/mapbox/mapbox-injectable-route.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,4CACC,cAAA,CAGD,4CACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,YAAA,CACA,kBAAA,CACA,sBAAA,CAEA,eAAA,CAGD,sBACC,cAAA,CACA,gBAAA,CAGD,sBACC,YAAA,CACA,qBAAA,CAOD,sBAEC,aDbgB","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `NY4xbvDEJrLFHBT63U81`;
export var h2 = `lKcmMAIgJmCC4bhXS2wm`;
export var h3 = `_R8fhCCP4_4hade6gTTu`;
export var p0 = `ZDJg4OnrGP5bkkVEwXya`;
export var p0m = `uTpyrjOsaZ0Cb7d9FApI`;
export var p1 = `g9Fv4LRIeCbKjW_whRST`;
export var p1m = `Ct42n5vDs8ktB2ke904w`;
export var p2 = `j7vki2xRyI4FFKWyyalC`;
export var eventPopupSubtitle = `yx5SurHwZLZZAfwYwxSJ`;
export var p2m = `TkLmqN0NiU9_i4aXfhtA`;
export var eventPopupTitle = `jkPftlSrxiCDGqkzHo6k`;
export var p2n = `ZNd4lXlkhB8WM7PXeIKP`;
export var p3 = `VC2k4rdMK9Nhs8rRGMgj`;
export var p3m = `kLgsdL0g95zmYRxcjpvE`;
export var eventPopupContainer = `JnYLoy3CYnGcYRZ6BxXq`;
export var eventPopupIcon = `GK18mrwkcbpQBJEK4fTn`;
export var eventPopupText = `lyr3n8zGqJ_QrRxB14kh`;
export default ___CSS_LOADER_EXPORT___;
