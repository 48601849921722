// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.sEP1MY2mrY6NIOiPkB6p{font-weight:500;font-size:24px}.W5rn_WKBGfPMdDVVHpWr{font-weight:500;font-size:20px}.yAihlMnulwhY0boQzssl{font-weight:500;font-size:12px}.igAFhiRvsSo8DyZ1bXWS{font-size:18px}.OTiLQwvlcsB6MPg088VX{font-size:18px;font-weight:500}.RMj3phwcWNkauwzsBurs{font-size:16px}.w2ZTNeN7f7SLPU1r2vfC,.SMWIS746MbuErHSNQlMB{font-size:16px;font-weight:500}.rsijKUKGbxgzJwB__T_c,.pT4XqyKW9XaJ4FEmT9tH{font-size:14px}.TQr8gs6J49wEU0T9uvWl{font-size:14px;font-weight:500}.bEq72B9ej2PdPLPBvunw{font-size:14px;font-weight:400}.OafwRXlSMZDYjJecjYZv{font-size:12px}.Puh10eXPDiucHN8vfDEN{font-size:12px;font-weight:500}.YsQYYzKOH_0bb58NFqxm{padding:20px;flex-grow:1}.SMWIS746MbuErHSNQlMB{margin-top:0px}.pT4XqyKW9XaJ4FEmT9tH{color:#5f718c}.FC8o6H_duBiEuPeK4AdY{margin-bottom:20px}.uHrRWbp6LBKQ68RYVaMI{display:flex;flex-direction:row;align-items:flex-start;margin-top:16px}.tr9oWt0n6a8_upyEdRbN{flex:1 1 0;display:flex;flex-direction:column}.Fh49etsf20O3536ZToXs{width:16px;flex-shrink:0}.Hx8XmmjggeP6PGNwhY_4{margin-bottom:4px}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/components/settings/settings-alerts.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,4CACC,cAAA,CACA,eAAA,CAGD,4CACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,YAAA,CACA,WAAA,CAGD,sBAEC,cAAA,CAGD,sBAEC,aDDgB,CCIjB,sBACC,kBAAA,CAGD,sBACC,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,eAAA,CAGD,sBACC,UAAA,CAEA,YAAA,CACA,qBAAA,CAGD,sBACC,UAAA,CACA,aAAA,CAGD,sBACC,iBAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `sEP1MY2mrY6NIOiPkB6p`;
export var h2 = `W5rn_WKBGfPMdDVVHpWr`;
export var h3 = `yAihlMnulwhY0boQzssl`;
export var p0 = `igAFhiRvsSo8DyZ1bXWS`;
export var p0m = `OTiLQwvlcsB6MPg088VX`;
export var p1 = `RMj3phwcWNkauwzsBurs`;
export var p1m = `w2ZTNeN7f7SLPU1r2vfC`;
export var title = `SMWIS746MbuErHSNQlMB`;
export var p2 = `rsijKUKGbxgzJwB__T_c`;
export var info = `pT4XqyKW9XaJ4FEmT9tH`;
export var p2m = `TQr8gs6J49wEU0T9uvWl`;
export var p2n = `bEq72B9ej2PdPLPBvunw`;
export var p3 = `OafwRXlSMZDYjJecjYZv`;
export var p3m = `Puh10eXPDiucHN8vfDEN`;
export var container = `YsQYYzKOH_0bb58NFqxm`;
export var notice = `FC8o6H_duBiEuPeK4AdY`;
export var inputSection = `uHrRWbp6LBKQ68RYVaMI`;
export var inputColumn = `tr9oWt0n6a8_upyEdRbN`;
export var inputSpacer = `Fh49etsf20O3536ZToXs`;
export var switchBlock = `Hx8XmmjggeP6PGNwhY_4`;
export default ___CSS_LOADER_EXPORT___;
