import React from "react";
import * as styles from "./asset-add-wizard.module.scss";

interface AssetAddWizardProps {
	previous?: React.ReactNode; //The component to be displayed as the back button
	next?: React.ReactNode; //The component to be displayed as the forward button
	children?: React.ReactNode; //The component to be displayed as the child
	stageCount: number; //The total number of stages
	currentStage: number; //The current 0-based stage index
}

//A fullscreen wizard that can be used to direct users through the asset setup process
export default function AssetAddWizard(props: AssetAddWizardProps) {
	return (
		<div className={styles.page}>
			{props.previous}

			<div>
				<div className={styles.sheet}>{props.children}</div>

				<div className={styles.ellipses}>
					{[...Array(props.stageCount)].map((element, index) => (
						<span
							key={`stage-${index}`}
							className={`${styles.ellipse} ${
								index === props.currentStage
									? styles.ellipseSelected
									: ""
							}`}
						/>
					))}
				</div>
			</div>

			{props.next}
		</div>
	);
}
