// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.cU5gL3R13rKBhOV4rtB2{font-weight:500;font-size:24px}.njzLgCmkzgzZIRrjpwN8{font-weight:500;font-size:20px}.b61io8YbavRrQl3RBDKE{font-weight:500;font-size:12px}.MrQSbjQAxrOQ0BGlGrYd{font-size:18px}.fY6OjUT8y6Re9OI2teNw{font-size:18px;font-weight:500}.Nn7MqAdKhrPq1VagGL04{font-size:16px}.CbMuz4JwdzfBYNGU4VbE{font-size:16px;font-weight:500}.ql48jjx49SJ64QdX4b5d{font-size:14px}.UG2qxq8IVrBYnMq5CXy0{font-size:14px;font-weight:500}._jwXHsEPkgIsYuwLenTS{font-size:14px;font-weight:400}.ndqVIhQmsmgQrRFb3nlF{font-size:12px}.EqpvP3fw1TdLnDSr85aw{font-size:12px;font-weight:500}.kIqUrPuw9HSuEoXN6cCx{display:flex;flex-direction:row;align-items:center;padding:12px 24px}.qrSbtBsYNz7v5w579hXA{flex:1;margin-right:12px}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/components/shared/assets-navigation-header.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,iBAAA,CAGD,sBACC,MAAA,CACA,iBAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `cU5gL3R13rKBhOV4rtB2`;
export var h2 = `njzLgCmkzgzZIRrjpwN8`;
export var h3 = `b61io8YbavRrQl3RBDKE`;
export var p0 = `MrQSbjQAxrOQ0BGlGrYd`;
export var p0m = `fY6OjUT8y6Re9OI2teNw`;
export var p1 = `Nn7MqAdKhrPq1VagGL04`;
export var p1m = `CbMuz4JwdzfBYNGU4VbE`;
export var p2 = `ql48jjx49SJ64QdX4b5d`;
export var p2m = `UG2qxq8IVrBYnMq5CXy0`;
export var p2n = `_jwXHsEPkgIsYuwLenTS`;
export var p3 = `ndqVIhQmsmgQrRFb3nlF`;
export var p3m = `EqpvP3fw1TdLnDSr85aw`;
export var header = `kIqUrPuw9HSuEoXN6cCx`;
export var search = `qrSbtBsYNz7v5w579hXA`;
export default ___CSS_LOADER_EXPORT___;
