// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.wqHpThapECLVqbM6Hd2_{font-weight:500;font-size:24px}._7DrmNj6A1fwYnE_l82J{font-weight:500;font-size:20px}.shHJaTIF5yrWkzQpxp9C{font-weight:500;font-size:12px}.haxmxbE9kSrlJTr7HTFm{font-size:18px}.F3yJ3Ju7wlJwioKXMqxF{font-size:18px;font-weight:500}.L1MyntySOojKr2OdYj2H{font-size:16px}.pALa5x0GfImQBlnNzi_3{font-size:16px;font-weight:500}.WcRifu2W9b7wH6CIWzdr{font-size:14px}.zQX1XznGcANwks4gc431{font-size:14px;font-weight:500}.CPKf9rr8Ob_EMlKGXyFm{font-size:14px;font-weight:400}.mw5yOvPZhTTcEX4rfVWZ{font-size:12px}.eWwqxK5Yty0eePPEpgMY{font-size:12px;font-weight:500}.zdrHaKUAw1JoZHhc0Ock{background-color:#e2e4e5;flex-shrink:0}.l0FsJ0t6qnATboyA3XA8{width:100%;height:1px}.l0FsJ0t6qnATboyA3XA8.r7CO_pzQj2BJzAx8P6do{width:calc(100% - 16px);margin-left:8px;margin-right:8px}.l0FsJ0t6qnATboyA3XA8.FAPJAFRmS7hCj0eB_u8l{margin-top:8px;margin-bottom:8px}.x9xOgA1gtF9ZJ3t7Bolr{width:1px}.x9xOgA1gtF9ZJ3t7Bolr.r7CO_pzQj2BJzAx8P6do{margin-top:8px;margin-bottom:8px}.x9xOgA1gtF9ZJ3t7Bolr.FAPJAFRmS7hCj0eB_u8l{margin-left:8px;margin-right:8px}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/elements/divider.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,wBDYM,CCXN,aAAA,CAGD,sBACC,UAAA,CACA,UAAA,CAEA,2CACC,uBAAA,CACA,eAAA,CACA,gBAAA,CAGD,2CACC,cAAA,CACA,iBAAA,CAIF,sBACC,SAAA,CAEA,2CACC,cAAA,CACA,iBAAA,CAGD,2CACC,eAAA,CACA,gBAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `wqHpThapECLVqbM6Hd2_`;
export var h2 = `_7DrmNj6A1fwYnE_l82J`;
export var h3 = `shHJaTIF5yrWkzQpxp9C`;
export var p0 = `haxmxbE9kSrlJTr7HTFm`;
export var p0m = `F3yJ3Ju7wlJwioKXMqxF`;
export var p1 = `L1MyntySOojKr2OdYj2H`;
export var p1m = `pALa5x0GfImQBlnNzi_3`;
export var p2 = `WcRifu2W9b7wH6CIWzdr`;
export var p2m = `zQX1XznGcANwks4gc431`;
export var p2n = `CPKf9rr8Ob_EMlKGXyFm`;
export var p3 = `mw5yOvPZhTTcEX4rfVWZ`;
export var p3m = `eWwqxK5Yty0eePPEpgMY`;
export var divider = `zdrHaKUAw1JoZHhc0Ock`;
export var horizontal = `l0FsJ0t6qnATboyA3XA8`;
export var edgePadding = `r7CO_pzQj2BJzAx8P6do`;
export var crossPadding = `FAPJAFRmS7hCj0eB_u8l`;
export var vertical = `x9xOgA1gtF9ZJ3t7Bolr`;
export default ___CSS_LOADER_EXPORT___;
