import React from "react";
import * as styles from "./event-card.module.scss";
import { AnyVelavuEvent } from "velavu-js-api";
import AlertIcon from "./alert-icon";
import {
	buildEventDescription,
	buildEventDescriptionWithOrigin,
} from "../helper/language-helper";
import { DateTime } from "luxon";
import SelectableCard from "./selectable-card";
import * as globals from "../styles/global.icss.scss";

export interface EventCardProps {
	event: AnyVelavuEvent;
	includeEventOrigin?: boolean;
}

export default function EventCard(props: EventCardProps) {
	let description: string;
	if (props.includeEventOrigin) {
		description = buildEventDescriptionWithOrigin(props.event);
	} else {
		description = buildEventDescription(props.event);
	}

	return (
		<SelectableCard
			key={props.event.id}
			className={styles.eventCard}
			disableHover
		>
			<AlertIcon
				type={props.event.category}
				size={24}
				color={globals.textPrimary}
			/>

			<div className={styles.eventCardText}>
				<span className={styles.eventCardTitle}>{description}</span>

				<span className={styles.eventCardSubtitle}>
					<DateText
						dateTime={DateTime.fromISO(props.event.timestamp)}
					/>
				</span>
			</div>
		</SelectableCard>
	);
}

function DateText(props: { dateTime: DateTime }) {
	const dateTime = props.dateTime;

	if (DateTime.now().year === dateTime.year) {
		return (
			<>
				<span>{dateTime.toFormat("MMM d")}</span>
				<span>{dateTime.toFormat("t")}</span>
			</>
		);
	} else {
		return <span>{dateTime.toLocaleString(DateTime.DATETIME_MED)}</span>;
	}
}
