import React, { CSSProperties, useCallback, useRef } from "react";
import IconFloors from "../dynamicicons/icon-floors";
import IconPlace from "../dynamicicons/icon-place";
import Divider from "../elements/divider";
import VelavuMenu from "../elements/velavu-menu";
import { useToggleable } from "../helper/hook-helper";
import { mapFloorNumber } from "../helper/language-helper";
import * as styles from "./floor-selector.module.scss";
import { VelavuFloor, VelavuSite } from "velavu-js-api";

interface FloorSelectorProps {
	site: VelavuSite;
	style?: CSSProperties;
	className?: string;
	selectedFloor?: VelavuFloor;
	onSelectFloor: (floorID: string | undefined) => void;
	onFloorClick: VoidFunction;
}

export default function FloorSelector(props: FloorSelectorProps) {
	const buttonRef = useRef<HTMLButtonElement | null>(null);
	const [isFloorMenu, openFloorMenu, closeFloorMenu] = useToggleable();

	const selectFloor = useCallback(
		(floorID: string | undefined) => {
			closeFloorMenu();
			props.onSelectFloor(floorID);
		},
		[closeFloorMenu, props],
	);

	return (
		<div className={`${styles.container} ${props.className ?? ""}`}>
			<div className={styles.content}>
				<div className={styles.site}>
					<IconPlace
						className={styles.pinIcon}
						color="#3A58E2"
						size={16}
					/>
					<span
						onClick={props.onFloorClick}
						className={styles.siteName}
					>
						{props.site.name}
					</span>
				</div>

				{props.site.floors && props.site.floors.length > 1 && (
					<>
						<Divider
							orientation="vertical"
							edgePadding
							style={{ marginLeft: 12, marginRight: 12 }}
						/>

						<button
							className={styles.floor}
							ref={buttonRef}
							onClick={openFloorMenu}
						>
							<IconFloors
								className={styles.floorIcon}
								color="#3A58E2"
							/>
							<span>
								{mapFloorNumber(props.selectedFloor?.level)}
							</span>
						</button>

						<VelavuMenu
							className={styles.floorMenu}
							open={isFloorMenu}
							onClose={closeFloorMenu}
							refPosition={buttonRef.current}
							style={{ marginTop: 2 }}
						>
							{props.site.floors.map((floor) => (
								<FloorMenuItem
									key={floor.id}
									label={mapFloorNumber(floor.level)}
									onClick={() => {
										selectFloor(floor.id);
									}}
								/>
							))}
						</VelavuMenu>
					</>
				)}
			</div>
		</div>
	);
}

function FloorMenuItem(props: { label: string; onClick?: VoidFunction }) {
	return (
		<button className={styles.menuItem} onClick={props.onClick}>
			<span className={styles.menuItemLabel}>{props.label}</span>
		</button>
	);
}
