// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.XKh2UGa4yFZCRZo6WNAh{font-weight:500;font-size:24px}.xxQ3HESOzTeBXPoLNgPn{font-weight:500;font-size:20px}.iDXtGonXM8_g_ZTZID3A{font-weight:500;font-size:12px}.W0Ggj8wyRNGjIHBvntY_{font-size:18px}.lTQg3421rNGX3MLUt30P{font-size:18px;font-weight:500}.a54Qvw4Wh03WJzNTPxDM{font-size:16px}.EztDNOvN1fos9W5xXV78{font-size:16px;font-weight:500}.P16G5mG6tjGl99jEl4WW{font-size:14px}.Z7Tb4Mqo9q_yc6LDwVCy{font-size:14px;font-weight:500}.R43liDz0YcWawYHvVBZw{font-size:14px;font-weight:400}.MjMk3ScO3tbuODheqiks{font-size:12px}.KXHLtsEdnGWm6A_EDlGg{font-size:12px;font-weight:500}.lRJcNi6G5tHjUCZm4cvl{width:100%;height:100%;padding:20px;box-sizing:border-box;display:flex;flex-direction:column}.uuB9mhS1BdVuIUVBnW29{flex-grow:1;display:flex;flex-direction:column;align-items:center;justify-content:center}.JsIwWNeD44i_ETiC6fJQ{margin-top:16px}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/components/devices/register/modal-register-tag-id.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,UAAA,CACA,WAAA,CAEA,YAAA,CACA,qBAAA,CAEA,YAAA,CACA,qBAAA,CAGD,sBACC,WAAA,CAEA,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,sBAAA,CAGD,sBACC,eAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `XKh2UGa4yFZCRZo6WNAh`;
export var h2 = `xxQ3HESOzTeBXPoLNgPn`;
export var h3 = `iDXtGonXM8_g_ZTZID3A`;
export var p0 = `W0Ggj8wyRNGjIHBvntY_`;
export var p0m = `lTQg3421rNGX3MLUt30P`;
export var p1 = `a54Qvw4Wh03WJzNTPxDM`;
export var p1m = `EztDNOvN1fos9W5xXV78`;
export var p2 = `P16G5mG6tjGl99jEl4WW`;
export var p2m = `Z7Tb4Mqo9q_yc6LDwVCy`;
export var p2n = `R43liDz0YcWawYHvVBZw`;
export var p3 = `MjMk3ScO3tbuODheqiks`;
export var p3m = `KXHLtsEdnGWm6A_EDlGg`;
export var container = `lRJcNi6G5tHjUCZm4cvl`;
export var inputContainer = `uuB9mhS1BdVuIUVBnW29`;
export var button = `JsIwWNeD44i_ETiC6fJQ`;
export default ___CSS_LOADER_EXPORT___;
