import React from "react";
import * as styles from "./floor-create-place-image.module.scss";
import PinnedList from "../pinned-list";
import PinnedListHeader from "../pinned-list-header";
import VelavuNotice from "../../../../elements/velavu-notice";
import VelavuButton from "../../../../elements/velavu-button";

export interface FloorCreatePlaceImageProps {
	onClose?: () => void;
	onNext?: () => void;
}

export default function FloorCreatePlaceImage(
	props: FloorCreatePlaceImageProps,
) {
	return (
		<PinnedList>
			<PinnedListHeader
				label="Place floor plan"
				onClose={props.onClose}
			/>

			<div className={styles.content}>
				<VelavuNotice
					type="info"
					body="Scale, rotate and move the floor plan into the correct location on the map"
				/>

				<VelavuButton
					label="Confirm placement"
					onClick={props.onNext}
					fullWidth
				/>
			</div>
		</PinnedList>
	);
}
