// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.fPcW9lwgY0JqkUOpf4Y5{font-weight:500;font-size:24px}.hrBPLseyVwb8bZrGWNxy{font-weight:500;font-size:20px}.Eef4vkPPLNIAv5a29yp3{font-weight:500;font-size:12px}.GXpQxo6gyEyzFXVa7O83{font-size:18px}.IOcjspxOwjSOYncbTPTQ{font-size:18px;font-weight:500}.HBk8goXcHq2cFjv9fLFt{font-size:16px}.C99PhWjgRiY2r5VQ4mSG{font-size:16px;font-weight:500}.Oh3gYveRdodutTEZmoj3,.E4dPpMyCqhHbGEV6F4ii{font-size:14px}.nSKrsxHuZp2e_RBllGZo{font-size:14px;font-weight:500}.YOqX4A7Ivqf4LjAjL6bs{font-size:14px;font-weight:400}.BaZFbPzRhdZRfQEzPaRZ{font-size:12px}.sXtxIrwv75yK7vOEqayg{font-size:12px;font-weight:500}.hoKKBBmKMbJYzwEcVLjJ{flex-grow:1;flex-shrink:1;overflow-y:auto;background-color:#f5f8f9;border-bottom-left-radius:20px;border-bottom-right-radius:20px}.o053IZbCWcAPPpq2rJXH{margin:0 12px 12px 12px}.o053IZbCWcAPPpq2rJXH:first-child{margin-top:12px}.E4dPpMyCqhHbGEV6F4ii{color:#5f718c;padding:32px 0;text-align:center}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/components/analytics/analytics-sidebar-devices.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,4CACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,WAAA,CACA,aAAA,CACA,eAAA,CACA,wBDaO,CCZP,8BAAA,CACA,+BAAA,CAGD,sBACC,uBAAA,CAEA,kCACC,eAAA,CAIF,sBAEC,aDRgB,CCShB,cAAA,CACA,iBAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `fPcW9lwgY0JqkUOpf4Y5`;
export var h2 = `hrBPLseyVwb8bZrGWNxy`;
export var h3 = `Eef4vkPPLNIAv5a29yp3`;
export var p0 = `GXpQxo6gyEyzFXVa7O83`;
export var p0m = `IOcjspxOwjSOYncbTPTQ`;
export var p1 = `HBk8goXcHq2cFjv9fLFt`;
export var p1m = `C99PhWjgRiY2r5VQ4mSG`;
export var p2 = `Oh3gYveRdodutTEZmoj3`;
export var emptyLabel = `E4dPpMyCqhHbGEV6F4ii`;
export var p2m = `nSKrsxHuZp2e_RBllGZo`;
export var p2n = `YOqX4A7Ivqf4LjAjL6bs`;
export var p3 = `BaZFbPzRhdZRfQEzPaRZ`;
export var p3m = `sXtxIrwv75yK7vOEqayg`;
export var list = `hoKKBBmKMbJYzwEcVLjJ`;
export var item = `o053IZbCWcAPPpq2rJXH`;
export default ___CSS_LOADER_EXPORT___;
