// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.yfU0FpYfSjErxBwonZnu{font-weight:500;font-size:24px}.Pdb7hcPK7hOrpFyhJUbj{font-weight:500;font-size:20px}.CSc1M8rhksS238DfRvSm{font-weight:500;font-size:12px}.Pg0RUOoH_tPtUUfCLgPf{font-size:18px}.z34dNiQLFAaqrFcsuSZh{font-size:18px;font-weight:500}.vlkcs3muAesikZmgLCfF{font-size:16px}.OlLqX5sks83vi10qdY6Q{font-size:16px;font-weight:500}.cSTktQBaRZvneWxSE_Iu{font-size:14px}.kaRCCfraNfkOd0plkwQ8{font-size:14px;font-weight:500}.nJcl3n7tmCZL3lSlw24V{font-size:14px;font-weight:400}.g78YhKqW_s4tkQ39sLz3{font-size:12px}.X4kEGMz_v7kxD5an5ufr{font-size:12px;font-weight:500}.bMG6DTwZoPrTF76WDicD{width:400px;height:500px;overflow-x:hidden}.OTaQOvKMNkI4JdFrJZto{height:100%;width:auto;display:flex;flex-direction:row;transition:transform 250ms ease}.jlmXYh4jk0qv12FHKiIK{flex-shrink:0;width:100%;height:100%}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/components/devices/register/modal-register-tag.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,WAAA,CACA,YAAA,CAEA,iBAAA,CAGD,sBACC,WAAA,CACA,UAAA,CACA,YAAA,CACA,kBAAA,CAEA,+BAAA,CAGD,sBACC,aAAA,CACA,UAAA,CACA,WAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `yfU0FpYfSjErxBwonZnu`;
export var h2 = `Pdb7hcPK7hOrpFyhJUbj`;
export var h3 = `CSc1M8rhksS238DfRvSm`;
export var p0 = `Pg0RUOoH_tPtUUfCLgPf`;
export var p0m = `z34dNiQLFAaqrFcsuSZh`;
export var p1 = `vlkcs3muAesikZmgLCfF`;
export var p1m = `OlLqX5sks83vi10qdY6Q`;
export var p2 = `cSTktQBaRZvneWxSE_Iu`;
export var p2m = `kaRCCfraNfkOd0plkwQ8`;
export var p2n = `nJcl3n7tmCZL3lSlw24V`;
export var p3 = `g78YhKqW_s4tkQ39sLz3`;
export var p3m = `X4kEGMz_v7kxD5an5ufr`;
export var container = `bMG6DTwZoPrTF76WDicD`;
export var contentContainer = `OTaQOvKMNkI4JdFrJZto`;
export var contentSlide = `jlmXYh4jk0qv12FHKiIK`;
export default ___CSS_LOADER_EXPORT___;
