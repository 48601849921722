import mapboxgl, {
	AnySourceData,
	EventData,
	MapEventType,
	MapLayerEventType,
} from "mapbox-gl";

import inventoryIcon from "../img/icons/icon-inventory.png";
import routingArrowIcon from "../img/icons/icon-routing-arrow.png";
import routingArrowHoverIcon from "../img/icons/icon-routing-arrow-hover.png";
import { useCallback, useEffect, useMemo, useState } from "react";
import EventEmitter from "eventemitter3";
import mapboxPulsingDot from "../elements/mapbox-pulsing-dot";
import MapImageReference from "../data/map-image-reference";
import { mapDeviceSymbolImages } from "./map-device-symbols";

export const deviceSymbolPrefix = "velavu-device-symbol";

export enum DeviceSymbolType {
	Anchor = "anchor",
	GPS = "gps",
	BLE = "ble",
}

export enum DeviceSymbolVariant {
	Mobile = "mobile",
	Fixed = "fixed",
	Offline = "offline",
	Registered = "registered",
	EffectHover = "effect-hover",
	EffectFocus = "effect-focus",
}

export function buildImageName(
	type: DeviceSymbolType,
	variant: DeviceSymbolVariant,
): string {
	return `${deviceSymbolPrefix}-${type}-${variant}`;
}

const mapImages: Readonly<MapImageReference>[] = [
	...mapDeviceSymbolImages,

	//ANCHOR ROUTING
	{
		src: routingArrowIcon,
		name: "icon-routing-arrow",
		options: {
			sdf: true,
		},
	},
	{
		src: routingArrowHoverIcon,
		name: "icon-routing-arrow-hover",
	},

	//OTHER
	{
		src: inventoryIcon,
		name: "icon-inventory",
	},
];

export async function addImagesToMapboxStyle(map: mapboxgl.Map) {
	const addImage = ({ src, name, options }: MapImageReference) => {
		return new Promise<HTMLImageElement | ImageBitmap>(function (
			resolve,
			reject,
		) {
			map.loadImage(src, (error, result) => {
				if (error) {
					reject(error);
				} else {
					resolve(result!);
				}
			});
		}).then((image) => {
			map.addImage(name, image, options);
		});
	};
	for (const image of mapImages) {
		await addImage(image);
	}
	map.addImage("pulsing-dot", mapboxPulsingDot(map), { pixelRatio: 2 });
}

///A Mapbox Box is a self-contained wrapper around a Mapbox map instance
//that tracks calls to addSource and addLayer, and automatically removes
//them when the box is disposed.
export class MapboxListenerBox {
	private readonly map: mapboxgl.Map;
	private listeners: (
		| [string, (ev: unknown) => void]
		| [string, string | ReadonlyArray<string>, (ev: unknown) => void]
	)[] = [];

	constructor(map: mapboxgl.Map) {
		this.map = map;
	}

	///Registers a new event listener
	public on<T extends keyof MapLayerEventType>(
		type: T,
		layer: string | ReadonlyArray<string>,
		listener: (ev: MapLayerEventType[T] & EventData) => void,
	): void;
	public on<T extends keyof MapEventType>(
		type: T,
		listener: (ev: MapEventType[T] & EventData) => void,
	): void;
	public on(type: string, listener: () => void): void;
	public on(
		type: string,
		param1: ((ev: unknown) => void) | string | ReadonlyArray<string>,
		param2?: (ev: unknown) => void,
	): void {
		if (typeof param1 === "function") {
			this.map.on(type, param1);
			this.listeners.push([type, param1]);
		} else {
			this.map.on(type as keyof MapLayerEventType, param1, param2!);
			this.listeners.push([type, param1, param2!]);
		}
	}

	public dispose() {
		for (const arr of this.listeners) {
			if (arr.length === 2) {
				const [type, listener] = arr;
				this.map.off(type, listener);
			} else {
				const [type, layer, listener] = arr;
				this.map.off(type as keyof MapLayerEventType, layer, listener);
			}
		}
		this.listeners = [];
	}
}

export class MapboxOverlayBox {
	private readonly map: mapboxgl.Map;

	private sources: string[] = [];
	private layers: string[] = [];
	private readonly listenerBox: MapboxListenerBox;

	constructor(map: mapboxgl.Map) {
		this.map = map;
		this.listenerBox = new MapboxListenerBox(map);
	}

	public addSource(id: string, source: AnySourceData) {
		this.map.addSource(id, source);
		this.sources.push(id);
	}

	public addLayer(layer: mapboxgl.AnyLayer, before?: string) {
		this.map.addLayer(layer, before);
		this.layers.push(layer.id);
	}

	public on<T extends keyof MapLayerEventType>(
		type: T,
		layer: string | ReadonlyArray<string>,
		listener: (ev: MapLayerEventType[T] & EventData) => void,
	): void;
	public on<T extends keyof MapEventType>(
		type: T,
		listener: (ev: MapEventType[T] & EventData) => void,
	): void;
	public on(type: string, listener: () => void): void;
	public on(
		type: string,
		param1: ((ev: unknown) => void) | string | ReadonlyArray<string>,
		param2?: (ev: unknown) => void,
	): void {
		//Force type match
		this.listenerBox.on(type as any, param1 as any, param2 as any);
	}

	public dispose() {
		for (const layer of this.layers) {
			if (this.map.getLayer(layer)) {
				this.map.removeLayer(layer);
			}
		}
		this.layers = [];

		for (const source of this.sources) {
			if (this.map.getSource(source)) {
				this.map.removeSource(source);
			}
		}
		this.sources = [];

		this.listenerBox.dispose();
	}
}

/**
 * Runs a setup function when a map exists.
 * @param map The map instance to apply
 * @param setup The setup function to run
 * @param deps The dependencies to run the effect with
 */
export function useListenerSetupMapEffect(
	map: mapboxgl.Map | undefined,
	setup: (map: mapboxgl.Map, box: MapboxListenerBox) => void | (() => void),
	deps: unknown[],
) {
	useEffect(() => {
		if (map !== undefined) {
			const box = new MapboxListenerBox(map);
			const cleanup = setup(map, box);

			return () => {
				cleanup?.();
				box.dispose();
			};
		}
	}, [map, ...deps]); //eslint-disable-line react-hooks/exhaustive-deps
}

/**
 * Runs a setup function when the map style is loaded.
 * Used to set up things like sources and layers.
 * @param mapInstance The map instance to apply
 * @param setup The setup function to run
 */
export function useStyleSetupMapEffect(
	mapInstance: MapHelperInstance,
	setup: (map: mapboxgl.Map, box: MapboxOverlayBox) => void | (() => void),
) {
	const { map: mapInstanceMap, readyEmitter: emitter } = mapInstance;

	useEffect(() => {
		if (mapInstanceMap !== undefined) {
			const map = mapInstanceMap;

			const box = new MapboxOverlayBox(map);
			let cleanup: void | (() => void) = undefined;

			//Run on style.load
			const onReady = () => {
				cleanup?.();
				box.dispose();
				cleanup = setup(map, box);
			};
			emitter.on(MapReadyListenerPriority.Low, onReady);

			return () => {
				cleanup?.();
				box.dispose();
				emitter.off(MapReadyListenerPriority.High, onReady);
			};
		}
	}, [mapInstanceMap, emitter]); //eslint-disable-line react-hooks/exhaustive-deps
}

/**
 * Registers an effect, only while the map is valid and has a loaded style.
 * @param mapInstance The map instance to apply
 * @param effect The effect to run
 * @param deps The dependencies to run the effect with
 * @param priority The priority to listen to ready callbacks at
 */
export function useStyleDependantMapEffect(
	mapInstance: MapHelperInstance,
	effect: (map: mapboxgl.Map, box: MapboxOverlayBox) => void | (() => void),
	deps: unknown[],
	priority?: MapReadyListenerPriority,
) {
	//Default to low priority
	let resolvedPriority: MapReadyListenerPriority;
	if (priority === undefined) {
		resolvedPriority = MapReadyListenerPriority.Low;
	} else {
		resolvedPriority = priority;
	}

	useEffect(() => {
		if (mapInstance.map !== undefined) {
			const map = mapInstance.map;

			const box = new MapboxOverlayBox(map);
			let cleanup: void | (() => void) = undefined;

			//Run immediately
			if (mapInstance.isReady) {
				cleanup = effect(map, box);
			}

			//Re-run on style.load
			const onReady = () => {
				cleanup?.();
				box.dispose();
				cleanup = effect(map, box);
			};
			mapInstance.readyEmitter.on(resolvedPriority, onReady);

			return () => {
				cleanup?.();
				box.dispose();
				mapInstance.readyEmitter.off(resolvedPriority, onReady);
			};
		}
	}, [mapInstance.map, mapInstance.readyEmitter, priority, ...deps]); //eslint-disable-line react-hooks/exhaustive-deps
}

export enum MapReadyListenerPriority {
	High = "high",
	Medium = "medium",
	Low = "low",
}

export interface MapHelperInstance {
	map: mapboxgl.Map | undefined;
	isReady: boolean;
	readyEmitter: EventEmitter<MapReadyListenerPriority>;
	invalidate: () => void;
}

export function useMapReadyEmitter(
	map: mapboxgl.Map | undefined,
	mapModifier: (map: mapboxgl.Map) => Promise<unknown>,
): MapHelperInstance {
	const emitter = useMemo(
		() => new EventEmitter<MapReadyListenerPriority>(),
		[],
	);
	const [isReady, setIsReady] = useState(false);

	useEffect(() => {
		if (map !== undefined) {
			const onStyleLoad = () => {
				mapModifier(map).then(() => {
					emitter.emit(MapReadyListenerPriority.High);
					emitter.emit(MapReadyListenerPriority.Medium);
					emitter.emit(MapReadyListenerPriority.Low);
					setIsReady(true);
				});
			};
			map.on("style.load", onStyleLoad);

			return () => {
				map.off("style.load", onStyleLoad);
			};
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [map, emitter, setIsReady]);

	const invalidate = useCallback(() => {
		setIsReady(false);
	}, [setIsReady]);

	return useMemo(
		() => ({
			map: map,
			isReady: isReady,
			readyEmitter: emitter,
			invalidate: invalidate,
		}),
		[map, isReady, emitter, invalidate],
	);
}
