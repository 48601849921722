import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { GetAllAssetsParams, VelavuAPI, VelavuAsset } from "velavu-js-api";
import useDeviceOnlineCallback from "../hook/use-device-online-callback";

interface InternalListAssetsState {
	assets: VelavuAsset[];
	isLoading: boolean;
	error: unknown | null;
	continuationToken: string | null;
}

export interface ListAssetsState {
	assets: VelavuAsset[];
	isLoading: boolean;
	error: unknown | null;
	hasMore: boolean;
}

function internalListAssetsStateToSuccessState(
	state: InternalListAssetsState,
): ListAssetsState {
	return {
		assets: state.assets,
		isLoading: state.isLoading,
		error: state.error,
		hasMore: state.continuationToken !== null,
	};
}

export interface ListAssetsActions {
	loadAssets: (params?: GetAllAssetsParams) => void;
	loadMore: () => void;
	deleteAsset: (assetID: string) => void;
	updateAsset: (
		assetID: string,
		updater: (asset: VelavuAsset) => VelavuAsset,
	) => void;
}

export function useListAssetsState(): [ListAssetsState, ListAssetsActions] {
	const [assetsState, setAssetsState] = useState<InternalListAssetsState>({
		assets: [],
		isLoading: false,
		error: null,
		continuationToken: null,
	});

	const activeRequestID = useRef(0);
	const initialRequestParams = useRef<GetAllAssetsParams | undefined | null>(
		null,
	);

	const loadAssets = useCallback(
		async (params?: GetAllAssetsParams) => {
			//Get a request ID
			activeRequestID.current += 1;
			const requestID = activeRequestID.current;

			//Reset the assets state
			setAssetsState((state) => ({
				...state,
				isLoading: true,
			}));

			//Store the params
			initialRequestParams.current = params;

			//Load the assets
			try {
				const allAssets = await VelavuAPI.assets.getAllAssets(params);

				//Make sure our request is still active
				if (activeRequestID.current === requestID) {
					setAssetsState({
						assets: allAssets.data,
						isLoading: false,
						error: null,
						continuationToken: allAssets.continuationToken ?? null,
					});
				}
			} catch (error) {
				//Make sure our request is still active
				if (activeRequestID.current === requestID) {
					setAssetsState({
						assets: [],
						isLoading: false,
						error,
						continuationToken: null,
					});
				}
			}
		},
		[setAssetsState],
	);

	const loadMore = useCallback(async () => {
		//Check state
		if (assetsState.isLoading) {
			console.warn("Attempted to load more assets while already loading");
			return;
		}
		if (assetsState.continuationToken === null) {
			console.warn(
				"Attempted to load more assets with no continuation token",
			);
			return;
		}
		if (initialRequestParams.current === null) {
			console.warn(
				"Attempted to load more assets without initial request params",
			);
			return;
		}

		//Reset the assets state
		const newAssetsState: Readonly<InternalListAssetsState> = {
			...assetsState,
			isLoading: true,
			error: null,
		};
		setAssetsState(newAssetsState);

		//Load the assets
		try {
			const moreAssets = await VelavuAPI.assets.getAllAssets(
				initialRequestParams.current,
				{
					continuationToken: assetsState.continuationToken,
				},
			);
			setAssetsState({
				...newAssetsState,
				isLoading: false,
				assets: [...newAssetsState.assets, ...moreAssets.data],
				continuationToken: moreAssets.continuationToken ?? null,
			});
		} catch (error) {
			setAssetsState({
				...newAssetsState,
				isLoading: false,
				error,
			});
		}
	}, [assetsState, setAssetsState]);

	const updateAsset = useCallback(
		(assetID: string, updater: (asset: VelavuAsset) => VelavuAsset) => {
			setAssetsState((assetsState) => {
				//Copy the current state
				const updatedAssetsState = { ...assetsState };
				const updatedAssets = [...updatedAssetsState.assets];

				//Update the asset with the matching ID
				const assetIndex = updatedAssets.findIndex(
					(asset) => asset.id === assetID,
				);
				if (assetIndex !== -1) {
					updatedAssets[assetIndex] = updater(
						updatedAssets[assetIndex],
					);
				}

				//Return the updated state
				updatedAssetsState.assets = updatedAssets;
				return updatedAssetsState;
			});
		},
		[setAssetsState],
	);

	const deleteAsset = useCallback(
		async (assetID: string) => {
			try {
				await VelavuAPI.assets.deleteSpecificAsset(assetID);
				setAssetsState((assetsState) => {
					//Copy the current state
					const updatedAssetsState = { ...assetsState };
					const updatedAssets = [...updatedAssetsState.assets];

					//Remove the asset with the matching ID
					const assetIndex = updatedAssets.findIndex(
						(asset) => asset.id === assetID,
					);
					if (assetIndex !== -1) {
						updatedAssets.splice(assetIndex, 1);
					}

					//Return the updated state
					updatedAssetsState.assets = updatedAssets;
					return updatedAssetsState;
				});
			} catch (error) {
				console.log(error);
			}
		},
		[setAssetsState],
	);

	useDeviceOnlineCallback(
		useCallback(
			(deviceID, online) => {
				setAssetsState((assetsState) => {
					//Copy the current state
					const updatedAssetsState = { ...assetsState };
					const updatedAssets = [...updatedAssetsState.assets];

					//Replace the asset with the matching ID's online state
					const assetIndex = updatedAssets.findIndex(
						(asset) => asset.device_id === deviceID,
					);
					if (assetIndex !== -1) {
						updatedAssets[assetIndex] = {
							...updatedAssets[assetIndex],
							online,
						};
					}

					//Return the updated state
					updatedAssetsState.assets = updatedAssets;
					return updatedAssetsState;
				});
			},
			[setAssetsState],
		),
	);

	useEffect(() => {
		//Load the initial assets
		loadAssets();
	}, []); //eslint-disable-line react-hooks/exhaustive-deps

	//Convert the internal state to public state
	const publicAssetssState = useMemo(() => {
		return internalListAssetsStateToSuccessState(assetsState);
	}, [assetsState]);

	//Memoize the actions
	const actions = useMemo<ListAssetsActions>(() => {
		return {
			loadAssets,
			loadMore,
			deleteAsset,
			updateAsset,
		};
	}, [deleteAsset, loadAssets, loadMore, updateAsset]);

	return [publicAssetssState, actions];
}
