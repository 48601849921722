import React, { useCallback, useState } from "react";
import * as styles from "./devices-toolbar.module.scss";
import IconSearch from "../../../../../dynamicicons/icon-search";
import VelavuInput from "../../../../../elements/velavu-input";
import IconClose from "../../../../../dynamicicons/icon-close";
import IconFilterList from "../../../../../dynamicicons/icon-filter-list";
import VelavuIconButton from "../../../../../elements/velavu-icon-button";
import FloorFilter from "../../../../../data/floor-filter";
import { mapFloorFilter } from "../../../../../helper/language-helper";
import IconFloors from "../../../../../dynamicicons/icon-floors";

export interface DevicesToolbarProps {
	searchText: string;
	setSearchText: (searchText: string) => void;

	floorSelection: FloorFilter;
	onChangeFloor: (filter: FloorFilter) => void;

	highlightFilter: boolean;
	showFilterMenu: boolean;
	onClickShowFilterMenu: () => void;
}

export default function DevicesToolbar(props: DevicesToolbarProps) {
	const filterColor = props.highlightFilter ? "#3A58E2" : "#5F718C";

	return (
		<div className={styles.toolbar}>
			<VelavuInput
				className={styles.searchBar}
				leadingIcon={<IconSearch size={16} color="#00255D" />}
				type={"search"}
				placeholder="Search"
				value={props.searchText}
				onChange={props.setSearchText}
				fullWidth
			/>

			<FloorPicker
				selection={props.floorSelection}
				onChange={props.onChangeFloor}
			/>

			<VelavuIconButton
				highlight={props.highlightFilter}
				onClick={props.onClickShowFilterMenu}
			>
				{props.showFilterMenu ? (
					<IconClose color={filterColor} />
				) : (
					<IconFilterList color={filterColor} />
				)}
			</VelavuIconButton>
		</div>
	);
}

interface FloorPickerProps {
	selection: FloorFilter;
	onChange: (filter: FloorFilter) => void;
}

function FloorPicker(props: FloorPickerProps) {
	const [showDropdown, setShowDropdown] = useState(false);
	const toggleDropdown = useCallback(() => {
		setShowDropdown((value) => !value);
	}, [setShowDropdown]);

	const propsOnChange = props.onChange;
	const selectValue = useCallback(
		(filter: FloorFilter) => {
			propsOnChange(filter);
			setShowDropdown(false);
		},
		[propsOnChange, setShowDropdown],
	);

	return (
		<div className={styles.floorButtonWrapper}>
			<button className={styles.floorButton} onClick={toggleDropdown}>
				<IconFloors color="#5F718C" />
				<span>{mapFloorFilter(props.selection)}</span>
			</button>

			{showDropdown && (
				<FloorPickerDropdown
					selection={props.selection}
					onChange={selectValue}
				/>
			)}
		</div>
	);
}

type FloorPickerDropdownProps = FloorPickerProps;

function FloorPickerDropdown(props: FloorPickerDropdownProps) {
	return (
		<div className={styles.selector}>
			<div className={styles.floor}>
				<span onClick={() => props.onChange(FloorFilter.All)}>
					{mapFloorFilter(FloorFilter.All)}
				</span>
			</div>
			<div className={styles.floor}>
				<span onClick={() => props.onChange(FloorFilter.Current)}>
					{mapFloorFilter(FloorFilter.Current)}
				</span>
			</div>
		</div>
	);
}
