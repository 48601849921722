import React from "react";
import * as styles from "./floor-create-import-review.module.scss";
import PinnedList from "../pinned-list";
import PinnedListHeader from "../pinned-list-header";
import VelavuIconButton from "../../../../elements/velavu-icon-button";
import IconClose from "../../../../dynamicicons/icon-close";
import VelavuButton from "../../../../elements/velavu-button";
import PlaceableFloorImage, {
	usePlaceableFloorImageURL,
} from "../../../../data/placeable-floor-image";

export interface FloorCreateImportReviewProps {
	image: PlaceableFloorImage;
	onNext?: () => void;
	onBack?: () => void;
	onClose?: () => void;
}

export function FloorCreateImportReview(props: FloorCreateImportReviewProps) {
	const imageURL = usePlaceableFloorImageURL(props.image.renderable);

	return (
		<PinnedList>
			<PinnedListHeader
				label="Upload floor plan"
				onClose={props.onClose}
			/>

			<div className={styles.content}>
				<div className={styles.floorPreview}>
					<img
						className={styles.floorPreviewImage}
						src={imageURL}
						alt={props.image.name}
					/>

					<div className={styles.floorPreviewFile}>
						<span className={styles.floorPreviewFileName}>
							{props.image.name}
						</span>

						<VelavuIconButton small onClick={props.onBack}>
							<IconClose size={16} color="#A3B3CC" />
						</VelavuIconButton>
					</div>
				</div>

				<VelavuButton
					label="Place on map"
					onClick={props.onNext}
					fullWidth
				/>
			</div>
		</PinnedList>
	);
}
