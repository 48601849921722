// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.NMLBHgEKEOxQd1Bvx0s4{font-weight:500;font-size:24px}.x9ioUzrU8c2aoDhZjlDw{font-weight:500;font-size:20px}.oGr5uV6m5DzkT1bgy7a6{font-weight:500;font-size:12px}.AlBlIy48Q063yBaT0w4C{font-size:18px}.xpw7Lf9hCqleyfoMVHvQ{font-size:18px;font-weight:500}.DWRuFK1UFKd93wLaYedE{font-size:16px}.SitEGcf3Jb6Z_A4N_p3n{font-size:16px;font-weight:500}.Jr8YUiMZrSZFFLo8zFDu{font-size:14px}.jmTm65yNBvPhzl6PMQsR,.DaQHhZ7EsvjCzPnexrdj{font-size:14px;font-weight:500}.Hl7YxvsT1MyhwaIygnBL{font-size:14px;font-weight:400}.ZUyzEZyNhIEDqPfg16Fw{font-size:12px}.V8UrkhrtRwf6XXYnH3Vp{font-size:12px;font-weight:500}.WtjS1sbQrdgBeJSTzbvu{display:flex;flex-direction:row;gap:20px}.WtjS1sbQrdgBeJSTzbvu>*{flex:1 1 0}.jhpMzyzh4qGRTWu1Kvhj{opacity:20%}.tep_9dufko7HwHPRRWF0{height:200px;color:#00255d}.DaQHhZ7EsvjCzPnexrdj{color:#3a58e2;margin-bottom:8px;display:block}.ww8AEf1ZYjCDZ2t0QXeQ{color:#00b283}.Z2JdD1OapWmQXBJb8VaZ{color:#da3e52}.MnAJJyvrge01QM4zdFXM:hover{background-color:#f5f8f9}.rEWM3LZEOg6LKEBpZgUQ{transition:opacity 250ms ease;opacity:0}.r5pLsdzESpnxEwciqugt{opacity:1}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/components/settings/settings-advanced/backend-device-table.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,4CACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,YAAA,CACA,kBAAA,CACA,QAAA,CAEA,wBACC,UAAA,CAIF,sBACC,WAAA,CAGD,sBACC,YAAA,CACA,aDRW,CCWZ,sBAEC,aDhBS,CCiBT,iBAAA,CACA,aAAA,CAGD,sBACC,aDJa,CCOd,sBACC,aDLY,CCSZ,4BACC,wBDnBM,CCuBR,sBACC,6BAAA,CACA,SAAA,CAGD,sBACC,SAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `ww8AEf1ZYjCDZ2t0QXeQ`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `NMLBHgEKEOxQd1Bvx0s4`;
export var h2 = `x9ioUzrU8c2aoDhZjlDw`;
export var h3 = `oGr5uV6m5DzkT1bgy7a6`;
export var p0 = `AlBlIy48Q063yBaT0w4C`;
export var p0m = `xpw7Lf9hCqleyfoMVHvQ`;
export var p1 = `DWRuFK1UFKd93wLaYedE`;
export var p1m = `SitEGcf3Jb6Z_A4N_p3n`;
export var p2 = `Jr8YUiMZrSZFFLo8zFDu`;
export var p2m = `jmTm65yNBvPhzl6PMQsR`;
export var title = `DaQHhZ7EsvjCzPnexrdj`;
export var p2n = `Hl7YxvsT1MyhwaIygnBL`;
export var p3 = `ZUyzEZyNhIEDqPfg16Fw`;
export var p3m = `V8UrkhrtRwf6XXYnH3Vp`;
export var twoColumn = `WtjS1sbQrdgBeJSTzbvu`;
export var disabled = `jhpMzyzh4qGRTWu1Kvhj`;
export var tableBody = `tep_9dufko7HwHPRRWF0`;
export var inactive = `Z2JdD1OapWmQXBJb8VaZ`;
export var tableRow = `MnAJJyvrge01QM4zdFXM`;
export var tableButton = `rEWM3LZEOg6LKEBpZgUQ`;
export var tableButtonHover = `r5pLsdzESpnxEwciqugt`;
export default ___CSS_LOADER_EXPORT___;
