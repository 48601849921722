// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.PXW6RdJPugfT_0KTsT87{font-weight:500;font-size:24px}.fDPWgvnOTVnfKtS3E49t{font-weight:500;font-size:20px}.D7M1cmIG9dZXH8Y2CSZU{font-weight:500;font-size:12px}.QPF4Q6EM3MVLi0zsEwVA{font-size:18px}._bgRbhlDIH_M_MsOt6X8{font-size:18px;font-weight:500}.CrK1cgv0QkE3VkMMiMdd{font-size:16px}.W3x8iIgCveLqU8_X6uaQ{font-size:16px;font-weight:500}.Vy9s0SXPhTAOoLjN3Xa3,.xkZUd_AtbllugcUh1AAD{font-size:14px}.MDLq5Hao_nlDaSXSAscO{font-size:14px;font-weight:500}.mLRY4ET5xk0Q4aN9ZFDn{font-size:14px;font-weight:400}.R3Qd4WKZzjCXsMVxGnpU{font-size:12px}.fFvAMxessuFNvLoK4k2H{font-size:12px;font-weight:500}.EWUrCzir0OKyUdXyIUyj{display:flex;flex-direction:row;align-items:center;justify-content:space-between;padding:6px 20px 6px 8px}.xkZUd_AtbllugcUh1AAD{color:#5f718c;margin-left:16px;text-align:right}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/components/analytics/analytics-status-bar.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,4CACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,6BAAA,CAEA,wBAAA,CAGD,sBAEC,aAAA,CACA,gBAAA,CACA,gBAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `PXW6RdJPugfT_0KTsT87`;
export var h2 = `fDPWgvnOTVnfKtS3E49t`;
export var h3 = `D7M1cmIG9dZXH8Y2CSZU`;
export var p0 = `QPF4Q6EM3MVLi0zsEwVA`;
export var p0m = `_bgRbhlDIH_M_MsOt6X8`;
export var p1 = `CrK1cgv0QkE3VkMMiMdd`;
export var p1m = `W3x8iIgCveLqU8_X6uaQ`;
export var p2 = `Vy9s0SXPhTAOoLjN3Xa3`;
export var selectionLabel = `xkZUd_AtbllugcUh1AAD`;
export var p2m = `MDLq5Hao_nlDaSXSAscO`;
export var p2n = `mLRY4ET5xk0Q4aN9ZFDn`;
export var p3 = `R3Qd4WKZzjCXsMVxGnpU`;
export var p3m = `fFvAMxessuFNvLoK4k2H`;
export var content = `EWUrCzir0OKyUdXyIUyj`;
export default ___CSS_LOADER_EXPORT___;
