import React, { useCallback, useContext, useRef, useState } from "react";
import VelavuSlider from "../../../../elements/velavu-slider";
import { VelavuAPI, VelavuSite } from "velavu-js-api";
import VelavuButton from "../../../../elements/velavu-button";
import VelavuInput from "../../../../elements/velavu-input";
import { requestGeocoding } from "../../../../helper/api-helper";
import SignInContext from "../../../../sign-in-context";
import PinnedList from "../pinned-list";
import PinnedListHeader from "../pinned-list-header";
import SiteCreateSearch, {
	SiteCreateAddressResult,
} from "./site-create-search";
import * as styles from "./site-create.module.scss";
import VelavuNotice from "../../../../elements/velavu-notice";
import IconInfo from "../../../../dynamicicons/icon-info";
import VelavuIconButton from "../../../../elements/velavu-icon-button";

interface SiteCreateProps {
	from: string;
	//Pass if editing an existing site
	initialEdit?: VelavuSite;
	//Pass if creating new site by clicking on map
	initialCoords?: [number, number];
	onClose?: VoidFunction;
	onPreview?: (coords: [number, number]) => void;
	onResult?: (site: VelavuSite) => void;
}

export default function SiteCreate(props: SiteCreateProps) {
	const [inputSiteName, setInputSiteName] = useState(
		props.initialEdit?.name ?? "",
	);
	const [inputAddress, setInputAddress] = useState<
		SiteCreateAddressResult | undefined
	>(undefined);
	const inputGeocodingRequested = useRef(false);
	const [siteBoundary, setSiteBoundary] = useState(
		((props.initialEdit?.boundary_factor ?? 1.5) / 3) * 4,
	);

	const coords = props.initialCoords ?? props.initialEdit?.coordinates;
	const user = useContext(SignInContext);
	if (!inputGeocodingRequested.current && coords !== undefined) {
		const mapboxToken = user.velavuUser?.mapbox_token;
		if (mapboxToken !== undefined) {
			requestGeocoding(mapboxToken, coords).then((address) => {
				setInputAddress({
					id: "",
					name: address,
					position: coords,
				});
			});
			inputGeocodingRequested.current = true;
		}
	}

	const propsOnPreview = props.onPreview;
	const updateInputAddress = useCallback(
		(address: SiteCreateAddressResult | undefined) => {
			setInputAddress(address);
			if (address !== undefined && propsOnPreview !== undefined)
				propsOnPreview(address.position);
		},
		[setInputAddress, propsOnPreview],
	);

	const [isLoading, setLoading] = useState(false);

	const inputValid =
		inputSiteName.trim().length > 0 && inputAddress !== undefined;

	const propsInitialEdit = props.initialEdit;
	const propsOnResult = props.onResult;
	const submitSite = useCallback(() => {
		setLoading(true);

		const boundary_factor = (siteBoundary / 4) * 3;

		(propsInitialEdit
			? VelavuAPI.sites.modifyExistingSite(propsInitialEdit.id, {
					name: inputSiteName,
					coordinates: inputAddress!.position,
					boundary_factor,
			  })
			: VelavuAPI.sites.createNewSite({
					name: inputSiteName,
					coordinates: inputAddress!.position,
					boundary_factor,
			  })
		)
			.then(propsOnResult ?? (() => {}))
			.catch((error) => {
				console.log(error.response);
				setLoading(false);
			});
	}, [
		propsInitialEdit,
		inputSiteName,
		inputAddress,
		siteBoundary,
		setLoading,
		propsOnResult,
	]);

	return (
		<PinnedList>
			<PinnedListHeader
				label={props.initialEdit ? "Edit site" : "New site"}
				onClose={props.onClose}
			/>

			<div className={styles.container}>
				<span className={styles.label}>Name</span>
				<VelavuInput
					value={inputSiteName}
					onChange={setInputSiteName}
					bottomPadding
					fullWidth
				/>

				<span className={styles.label}>Address</span>
				<SiteCreateSearch
					from={props.from}
					address={inputAddress}
					onSetAddress={updateInputAddress}
				/>

				<div className={styles.labelRow}>
					<span>Site boundary</span>
					<VelavuIconButton
						tooltip="Assets and devices within the site boundary will be displayed as being at this site."
						tooltipStyle={{
							top: 30,
						}}
						tooltipType="paragraph"
					>
						<IconInfo size={16} color="#6681FF" />
					</VelavuIconButton>
				</div>
				<VelavuSlider
					min={0}
					max={4}
					value={siteBoundary}
					onChange={setSiteBoundary}
					variant="marked"
				/>
				<span className={styles.sliderLabel}>default</span>
				<VelavuNotice
					style={{ marginTop: 6 }}
					type="info"
					body="A larger site boundary will increase the threshold for displaying if an asset or device is at a site."
				/>

				{props.initialEdit ? (
					<div className={styles.buttonBar}>
						<VelavuButton
							label="Cancel"
							fullWidth
							outlined
							disabled={isLoading}
							onClick={props.onClose}
						/>

						<VelavuButton
							label="Save"
							fullWidth
							disabled={!inputValid || isLoading}
							onClick={submitSite}
						/>
					</div>
				) : (
					<VelavuButton
						className={styles.buttonSubmit}
						label="Create site"
						fullWidth
						disabled={!inputValid || isLoading}
						onClick={submitSite}
					/>
				)}
			</div>
		</PinnedList>
	);
}
