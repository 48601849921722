import iconAnchorMobile from "../img/device-symbols/anchor-mobile.png";
import iconAnchorFixed from "../img/device-symbols/anchor-fixed.png";
import iconAnchorOffline from "../img/device-symbols/anchor-offline.png";
import iconAnchorRegistered from "../img/device-symbols/anchor-registered.png";
import iconAnchorEffectHover from "../img/device-symbols/anchor-effect-hover.png";
import iconAnchorEffectFocus from "../img/device-symbols/anchor-effect-focus.png";
import iconGPSMobile from "../img/device-symbols/gps-mobile.png";
import iconGPSFixed from "../img/device-symbols/gps-fixed.png";
import iconGPSOffline from "../img/device-symbols/gps-offline.png";
import iconGPSRegistered from "../img/device-symbols/gps-registered.png";
import iconGPSEffectHover from "../img/device-symbols/gps-effect-hover.png";
import iconGPSEffectFocus from "../img/device-symbols/gps-effect-focus.png";
import iconBLEMobile from "../img/device-symbols/ble-mobile.png";
import iconBLEFixed from "../img/device-symbols/ble-fixed.png";
import iconBLEOffline from "../img/device-symbols/ble-offline.png";
import iconBLERegistered from "../img/device-symbols/ble-registered.png";
import iconBLEEffectHover from "../img/device-symbols/ble-effect-hover.png";
import iconBLEEffectFocus from "../img/device-symbols/ble-effect-focus.png";
import MapImageReference from "../data/map-image-reference";
import { Expression } from "mapbox-gl";
import {
	LocationType,
	NormalizedDeviceHardware,
	normalizeDeviceHardware,
	VelavuDevice,
} from "velavu-js-api";

/*
 * This file manages image references for device symbols.
 */

/// The prefix for all device symbol image names.
export const deviceSymbolPrefix = "velavu-device-symbol";

/// The types of devices that can be represented by a symbol.
export enum DeviceSymbolType {
	Anchor = "anchor",
	GPS = "gps",
	BLE = "ble",
}

/// The variants of a device symbol.
export enum DeviceSymbolVariant {
	Mobile = "mobile",
	Fixed = "fixed",
	Offline = "offline",
	Registered = "registered",
	EffectHover = "effect-hover",
	EffectFocus = "effect-focus",
}

export enum DeviceSymbolEffect {
	Hover = DeviceSymbolVariant.EffectHover,
	Focus = DeviceSymbolVariant.EffectFocus,
}

/// Builds the name of an image for a device symbol,
/// that can be used to reference the image in the map.
export function buildImageName(
	type: DeviceSymbolType,
	variant: DeviceSymbolVariant,
): string {
	return `${deviceSymbolPrefix}-${type}-${variant}`;
}

/**
 * Builds a Mapbox expression that resolves to a {@link DeviceSymbolType}
 * type based on a hardware value
 * @param getHardware An expression that resolves to a {@link NormalizedDeviceHardware}
 */
export function buildDeviceSymbolTypeExpression(
	getHardware: Expression,
): Expression {
	// prettier-ignore
	return [
		"match",
		getHardware,
		[NormalizedDeviceHardware.Juno], DeviceSymbolType.BLE,
		[NormalizedDeviceHardware.Argo], DeviceSymbolType.GPS,
		DeviceSymbolType.Anchor,
	];
}

/**
 * Builds a Mapbox expression that resolves to a device symbol image name
 * @param getHardware An expression that resolves to a {@link NormalizedDeviceHardware}
 * @param getOnline An expression that resolves to the `online` property of a device
 * @param getIsFixed An expression that resolves to a boolean that represents whether the device has a fixed location
 */
export function buildDeviceSymbolExpression(
	getHardware: Expression,
	getOnline: Expression,
	getIsFixed: Expression,
): Expression {
	// prettier-ignore
	return [
		"concat",
		deviceSymbolPrefix,
		"-",
		//Map hardware
		buildDeviceSymbolTypeExpression(getHardware),
		"-",
		//Check online state
		[
			"case",
			["==", getOnline, true], [
				"case",
				getIsFixed, DeviceSymbolVariant.Fixed,
				DeviceSymbolVariant.Mobile,
			],
			["==", getOnline, false], DeviceSymbolVariant.Offline,
			DeviceSymbolVariant.Registered,
		]
	];
}

/**
 * Builds a Mapbox expression that resolves to a device symbol effect image name
 * @param getHardware An expression that resolves to a {@link NormalizedDeviceHardware}
 * @param getEffect An expression that resolves to a {@link DeviceSymbolEffect}
 */
export function buildDeviceEffectExpression(
	getHardware: Expression,
	getEffect: Expression | DeviceSymbolEffect,
): Expression {
	// prettier-ignore
	return [
		"concat",
		deviceSymbolPrefix,
		"-",
		buildDeviceSymbolTypeExpression(getHardware),
		"-",
		getEffect
	];
}

const symbolSizeBase = 18;
const symbolSizeEffect = 30;

/// All available device symbol references
export const mapDeviceSymbolImages: Readonly<MapImageReference>[] = [
	//ANCHOR
	{
		src: iconAnchorMobile,
		name: buildImageName(
			DeviceSymbolType.Anchor,
			DeviceSymbolVariant.Mobile,
		),
	},
	{
		src: iconAnchorFixed,
		name: buildImageName(
			DeviceSymbolType.Anchor,
			DeviceSymbolVariant.Fixed,
		),
	},
	{
		src: iconAnchorOffline,
		name: buildImageName(
			DeviceSymbolType.Anchor,
			DeviceSymbolVariant.Offline,
		),
	},
	{
		src: iconAnchorRegistered,
		name: buildImageName(
			DeviceSymbolType.Anchor,
			DeviceSymbolVariant.Registered,
		),
	},
	{
		src: iconAnchorEffectHover,
		name: buildImageName(
			DeviceSymbolType.Anchor,
			DeviceSymbolVariant.EffectHover,
		),
	},
	{
		src: iconAnchorEffectFocus,
		name: buildImageName(
			DeviceSymbolType.Anchor,
			DeviceSymbolVariant.EffectFocus,
		),
	},

	//GPS
	{
		src: iconGPSMobile,
		name: buildImageName(DeviceSymbolType.GPS, DeviceSymbolVariant.Mobile),
	},
	{
		src: iconGPSFixed,
		name: buildImageName(DeviceSymbolType.GPS, DeviceSymbolVariant.Fixed),
	},
	{
		src: iconGPSOffline,
		name: buildImageName(DeviceSymbolType.GPS, DeviceSymbolVariant.Offline),
	},
	{
		src: iconGPSRegistered,
		name: buildImageName(
			DeviceSymbolType.GPS,
			DeviceSymbolVariant.Registered,
		),
	},
	{
		src: iconGPSEffectHover,
		name: buildImageName(
			DeviceSymbolType.GPS,
			DeviceSymbolVariant.EffectHover,
		),
	},
	{
		src: iconGPSEffectFocus,
		name: buildImageName(
			DeviceSymbolType.GPS,
			DeviceSymbolVariant.EffectFocus,
		),
	},

	//BLE
	{
		src: iconBLEMobile,
		name: buildImageName(DeviceSymbolType.BLE, DeviceSymbolVariant.Mobile),
	},
	{
		src: iconBLEFixed,
		name: buildImageName(DeviceSymbolType.BLE, DeviceSymbolVariant.Fixed),
	},
	{
		src: iconBLEOffline,
		name: buildImageName(DeviceSymbolType.BLE, DeviceSymbolVariant.Offline),
	},
	{
		src: iconBLERegistered,
		name: buildImageName(
			DeviceSymbolType.BLE,
			DeviceSymbolVariant.Registered,
		),
	},
	{
		src: iconBLEEffectHover,
		name: buildImageName(
			DeviceSymbolType.BLE,
			DeviceSymbolVariant.EffectHover,
		),
	},
	{
		src: iconBLEEffectFocus,
		name: buildImageName(
			DeviceSymbolType.BLE,
			DeviceSymbolVariant.EffectFocus,
		),
	},
];

const MAP_DEVICE_SYMBOL_IMAGES_MAP: ReadonlyMap<string, MapImageReference> =
	new Map(mapDeviceSymbolImages.map((img) => [img.name, img]));

/// Gets a device image URL by its type and variant
export function mapDeviceSymbolURLForParameters(
	type: DeviceSymbolType,
	variant: DeviceSymbolVariant,
): string {
	return MAP_DEVICE_SYMBOL_IMAGES_MAP.get(buildImageName(type, variant))!.src;
}

/// Gets a device image URL for a device
export function mapDeviceSymbolURLForDevice(device: VelavuDevice): string {
	//Resolve the device type
	let type: DeviceSymbolType;
	switch (normalizeDeviceHardware(device.hardware)) {
		case NormalizedDeviceHardware.Juno:
			type = DeviceSymbolType.BLE;
			break;
		case NormalizedDeviceHardware.Argo:
			type = DeviceSymbolType.GPS;
			break;
		default:
			type = DeviceSymbolType.Anchor;
			break;
	}

	//Resolve the device variant
	let variant: DeviceSymbolVariant;
	if (device.online === true) {
		if (device.location?.location_type === LocationType.Fixed) {
			variant = DeviceSymbolVariant.Fixed;
		} else {
			variant = DeviceSymbolVariant.Mobile;
		}
	} else if (device.online === false) {
		variant = DeviceSymbolVariant.Offline;
	} else {
		variant = DeviceSymbolVariant.Registered;
	}

	return mapDeviceSymbolURLForParameters(type, variant);
}
