import {
	MapHelperInstance,
	useStyleDependantMapEffect,
	useStyleSetupMapEffect,
} from "../../helper/map-helper";
import { VelavuGeofence } from "velavu-js-api";
import { geoJSONBlankCollection } from "./mapbox-injectable";
import { GeoJSONSource } from "mapbox-gl";

const mapboxIDGeofencesSource = "geofence-source";
const mapboxIDGeofencesFill = "geofence-fill";
const mapboxIDGeofencesLine = "geofence-line";

export interface MapboxInjectableGeofencesParams {
	mapInstance: MapHelperInstance;
	geofences: VelavuGeofence[];
	belowLayer?: string;
}

export default function useMapboxInjectableGeofences(
	params: MapboxInjectableGeofencesParams,
): string {
	useStyleSetupMapEffect(params.mapInstance, (_, box) => {
		box.addSource(mapboxIDGeofencesSource, {
			type: "geojson",
			data: geoJSONBlankCollection,
		});
		box.addLayer(
			{
				id: mapboxIDGeofencesLine,
				type: "line",
				source: mapboxIDGeofencesSource,
				paint: {
					"line-color": "#A3B3CC",
					"line-width": 2,
				},
			},
			params.belowLayer,
		);
		box.addLayer(
			{
				id: mapboxIDGeofencesFill,
				type: "fill",
				source: mapboxIDGeofencesSource,
				paint: {
					"fill-color": "#A3B3CC",
					"fill-opacity": 0.3,
				},
			},
			params.belowLayer,
		);
	});

	useStyleDependantMapEffect(
		params.mapInstance,
		(map, _) => {
			const geofencesSource = map.getSource(
				mapboxIDGeofencesSource,
			) as GeoJSONSource;

			geofencesSource.setData({
				type: "FeatureCollection",
				features: params.geofences.map((geofence) => ({
					type: "Feature",
					id: geofence.id,
					geometry: geofence.boundary,
					properties: {},
				})),
			});
		},
		[params.geofences],
	);

	return mapboxIDGeofencesFill;
}
