import React, { CSSProperties } from "react";

export default function IconFloorplan2D(props: {
	className?: string;
	style?: CSSProperties;
	color?: string;
	size?: number;
}) {
	return (
		<svg
			width={props.size ?? "56"}
			height={props.size ?? "45"}
			style={props.style}
			viewBox="0 0 56 45"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_9390_178174)">
				<rect
					width="29.6429"
					height="29.6429"
					transform="matrix(0.866025 0.5 -0.866025 0.5 28 7.67773)"
					fill="#F0F5FD"
				/>
				<path
					d="M9.59363 27.7315L24.1758 19.3125"
					stroke="black"
					strokeWidth="1.5"
					strokeLinejoin="round"
				/>
				<path
					d="M39.4563 31.2002L21.0583 20.5781"
					stroke="black"
					strokeWidth="1.5"
					strokeLinejoin="round"
				/>
				<path
					d="M33.8493 13.5082L36.8101 11.7988"
					stroke="black"
					strokeWidth="1.5"
					strokeLinejoin="round"
				/>
			</g>
			<rect
				x="4.47035e-08"
				y="-0.75"
				width="31.1429"
				height="31.1429"
				transform="matrix(0.866025 0.5 -0.866025 0.5 27.3505 7.30273)"
				stroke="black"
				strokeWidth="1.5"
				strokeLinejoin="round"
			/>
			<defs>
				<clipPath id="clip0_9390_178174">
					<rect
						width="29.6429"
						height="29.6429"
						transform="matrix(0.866025 0.5 -0.866025 0.5 28 7.67773)"
						fill="white"
					/>
				</clipPath>
			</defs>
		</svg>
	);
}
