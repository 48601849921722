// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.ohUMB1X4GC28OXOUKFRG{font-weight:500;font-size:24px}.kH4yqyYfKgBrWKnLOQE2{font-weight:500;font-size:20px}.LU35xxMoJU8MWNWRN84z{font-weight:500;font-size:12px}.cPO0y3baLNeszi1NT_4f{font-size:18px}.aGiGnWQLBOiWLWZMPfNY{font-size:18px;font-weight:500}.l78Pjpo4bmX8xtnFaVa3{font-size:16px}.HtPaBM6Y4RSaxr_Jen0x{font-size:16px;font-weight:500}.Y2vO8LtAVOTJMEjiu7kA{font-size:14px}.boGtiDXC9WLZRj5jLdTe,.OMuPn9m0CsjfSREuBrZJ{font-size:14px;font-weight:500}.shsdqfaI5FsmVkuvHMYt{font-size:14px;font-weight:400}.OdNFFW7ul5ZUoUfXtOHB{font-size:12px}.eOYLkc8s41faP0GHIM9m{font-size:12px;font-weight:500}.cN2XQCRjFJJGND6kIrrw{width:100%;background:#fff;display:flex;flex-direction:row}.dJ3qNflzd1XRVjnoljQt{flex:1;flex-basis:100%}.OMuPn9m0CsjfSREuBrZJ{padding:16px}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/components/dashboard/dropdown/site-detail/devices-filter/devices-filter.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,4CACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,UAAA,CACA,eDmBO,CCjBP,YAAA,CACA,kBAAA,CAGD,sBACC,MAAA,CACA,eAAA,CAGD,sBAEC,YAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `ohUMB1X4GC28OXOUKFRG`;
export var h2 = `kH4yqyYfKgBrWKnLOQE2`;
export var h3 = `LU35xxMoJU8MWNWRN84z`;
export var p0 = `cPO0y3baLNeszi1NT_4f`;
export var p0m = `aGiGnWQLBOiWLWZMPfNY`;
export var p1 = `l78Pjpo4bmX8xtnFaVa3`;
export var p1m = `HtPaBM6Y4RSaxr_Jen0x`;
export var p2 = `Y2vO8LtAVOTJMEjiu7kA`;
export var p2m = `boGtiDXC9WLZRj5jLdTe`;
export var title = `OMuPn9m0CsjfSREuBrZJ`;
export var p2n = `shsdqfaI5FsmVkuvHMYt`;
export var p3 = `OdNFFW7ul5ZUoUfXtOHB`;
export var p3m = `eOYLkc8s41faP0GHIM9m`;
export var container = `cN2XQCRjFJJGND6kIrrw`;
export var column = `dJ3qNflzd1XRVjnoljQt`;
export default ___CSS_LOADER_EXPORT___;
