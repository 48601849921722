import React, {
	Dispatch,
	SetStateAction,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from "react";
import {
	MeasurementSystem,
	VelavuAsset,
	VelavuDevice,
	VelavuSite,
} from "velavu-js-api";
import AnalyticsStatType from "../../data/analytics-stat-type";
import { serializeAssetCategorization } from "../../data/asset-categorization";
import IconVelavuAnalytics from "../../dynamicicons/icon-velavu-analytics";
import LoadingDots from "../../elements/loading-dots";
import SectionTitle from "../../elements/section-title";
import VelavuTabSwitcher from "../../elements/velavu-tab-switcher";
import { getLocationFloorID } from "../../helper/floor-helper";
import { useToggleable } from "../../helper/hook-helper";
import { makeStyleable } from "../../helper/icon-helper";
import { compoundSearchTags } from "../../helper/search-helper";
import AssetsNavigationHeader from "../shared/assets-navigation-header";
import { AnchorList, TagList } from "./analytics-sidebar-devices";
import AnalyticsSidebarFilter from "./analytics-sidebar-filter";
import AnalyticsSidebarSites from "./analytics-sidebar-sites";
import styles from "./analytics-sidebar.module.scss";

enum Tabs {
	assets,
	anchors,
}

function filterAssets(
	assets: VelavuDevice[],
	sites: VelavuSite[],
	filterCats: string[],
	selSiteId?: string,
	selFloor?: number,
	filterStatus?: boolean,
) {
	// Filter by site
	if (selSiteId !== undefined) {
		assets = assets.filter((asset) => asset.site_id === selSiteId);
		if (selFloor !== undefined) {
			const floorId = sites?.find((site) => site.id === selSiteId)
				?.floors?.[selFloor]?.id;
			if (floorId !== undefined) {
				assets = assets.filter(
					(asset) => getLocationFloorID(asset?.location) === floorId,
				);
			}
		}
	}

	// Filter by status
	if (filterStatus !== undefined) {
		assets = assets.filter((asset) => asset.online === filterStatus);
	}

	// Filter by tag
	if (filterCats.length) {
		assets = assets.filter((asset) =>
			filterCats.includes(serializeAssetCategorization(asset.asset!)),
		);
	}

	return assets;
}

function filterAnchors(
	anchors: VelavuDevice[],
	sites: VelavuSite[],
	search: string,
	selSite?: string,
	selFloor?: number,
	filterStatus?: boolean,
) {
	// Filter by site
	if (selSite !== undefined) {
		anchors = anchors.filter((anchor) => anchor.site_id === selSite);
		if (selFloor !== undefined) {
			const floorID = sites?.find((site) => site.id === selSite)
				?.floors?.[selFloor]?.id;
			if (floorID !== undefined) {
				anchors = anchors.filter(
					(anchor) => getLocationFloorID(anchor.location) === floorID,
				);
			}
		}
	}

	// Filter by status
	if (filterStatus !== undefined) {
		anchors = anchors.filter((anchor) => anchor.online === filterStatus);
	}

	// Filter by search
	anchors = compoundSearchTags(anchors, search);

	return anchors;
}

function SiteHeader(props: {
	sites: VelavuSite[];
	setId: (id?: string) => void;
	hideSelector: VoidFunction;
	clearFloor: VoidFunction;
}) {
	return (
		<AnalyticsSidebarSites
			sites={props.sites ?? []}
			onSelectSite={(id) => {
				if (id === undefined) {
					props.setId(); // May need explicit undefined passed?
					props.hideSelector();
				} else {
					props.setId(id);
					props.hideSelector();
				}

				props.clearFloor();
			}}
		/>
	);
}

function AssetHeader(props: {
	children: React.ReactNode;
	sites: VelavuSite[];
	assets: VelavuAsset[];
	selSiteId?: string;
	showSel: VoidFunction;
	selTab: Tabs;
	setSelTab: (tab: Tabs) => void;
	selFloor?: number;
	setSelFloor: (floor?: number) => void;
	filterStatus?: boolean;
	setFilterStatus: (filter?: boolean) => void;
	search: string;
	setSearch: (search: string) => void;
	filterCats: string[];
	setFilterCats: Dispatch<SetStateAction<string[]>>;
}) {
	const [isSearching, openSearch, finishSearch] = useToggleable();
	const [showFilterMenu, setShowFilterMenu] = useState(false);
	const toggleFilterMenu = useCallback(
		() => setShowFilterMenu((it) => !it),
		[setShowFilterMenu],
	);

	const { setFilterCats } = props;

	// An array of serialized asset categorizations to the number of times they are used
	const allCategorizations = useMemo((): [string, number][] | undefined => {
		if (props.assets === undefined) return undefined;

		return Object.entries(
			props.assets.reduce<{ [key: string]: number }>(
				(accumulator, asset) => {
					if (asset === undefined) return accumulator;

					const key = serializeAssetCategorization(asset);
					accumulator[key] = (accumulator[key] ?? 0) + 1;
					return accumulator;
				},
				{},
			),
		);
	}, [props.assets]);

	useEffect(() => {
		if (allCategorizations === undefined) return;
		const categorizationKeys = allCategorizations.map(
			([categorization]) => categorization,
		);

		// Remove categorizations that aren't available anymore
		setFilterCats((filteredCategorizations) => {
			const filter = filteredCategorizations.filter((categorization) =>
				categorizationKeys.includes(categorization),
			);

			if (filteredCategorizations.every((f) => filter.includes(f))) {
				return filteredCategorizations;
			}
			return filter;
		});
	}, [setFilterCats, allCategorizations]);

	const sortedCategorizations = useMemo(() => {
		return allCategorizations
			?.sort(([keyA, countA], [keyB, countB]) => {
				const aSelected = props.filterCats.includes(keyA);
				const bSelected = props.filterCats.includes(keyB);

				if (aSelected && !bSelected) return -1;
				else if (bSelected && !aSelected) return 1;

				const countDiff = countB - countA;
				if (countDiff !== 0) return countDiff;
				else return keyA.localeCompare(keyB);
			})
			.map(([key]) => key);
	}, [allCategorizations, props.filterCats]);

	return (
		<>
			<AssetsNavigationHeader
				isSearching={isSearching}
				openSearch={openSearch}
				finishSearch={finishSearch}
				searchText={props.search}
				setSearchText={props.setSearch}
				selectedSite={props.sites?.find(
					(site) => site.id === props.selSiteId,
				)}
				onOpenSiteSelector={props.showSel}
				selectedFloor={props.selFloor}
				onSelectFloor={props.setSelFloor}
				isFiltering={false}
				isShowFilterMenu={showFilterMenu}
				onToggleFilterMenu={toggleFilterMenu}
			/>
			<VelavuTabSwitcher
				selectedKey={props.selTab}
				onSelectKey={props.setSelTab}
				labels={{
					[Tabs.assets]: "Assets",
					[Tabs.anchors]: "Anchors",
				}}
			>
				{props.selTab === Tabs.assets && (
					<AnalyticsSidebarFilter
						expanded={showFilterMenu}
						allCategorizations={sortedCategorizations ?? []}
						selectedCategorizations={props.filterCats}
						onSetSelectedCategorizations={props.setFilterCats}
						filterStatus={props.filterStatus}
						setFilterStatus={props.setFilterStatus}
					/>
				)}
				{props.children}
			</VelavuTabSwitcher>
		</>
	);
}

function Sidebar(props: {
	children: React.ReactNode;
	sites: VelavuSite[];
	selTab: Tabs;
	setSelTab: (tab: Tabs) => void;
	assets: VelavuAsset[];
	anchors: VelavuDevice[];
	selSiteId?: string;
	selFloor?: number;
	setSelSiteId: (id?: string) => void;
	setSelFloor: (id?: number) => void;
	search: string;
	setSearch: (search: string) => void;
	filterStatus?: boolean;
	setFilterStatus: (status?: boolean) => void;
	filterCats: string[];
	setFilterCats: Dispatch<SetStateAction<string[]>>;
}) {
	const [isSiteSelector, showSiteSelector, hideSiteSelector] =
		useToggleable();

	return (
		<>
			<div className={styles.header}>
				<SectionTitle
					icon={makeStyleable(IconVelavuAnalytics)}
					label="Analytics"
				/>
			</div>
			{isSiteSelector ? (
				<SiteHeader
					sites={props.sites}
					setId={props.setSelSiteId}
					hideSelector={hideSiteSelector}
					clearFloor={() => props.setSelFloor(undefined)}
				/>
			) : (
				<AssetHeader
					sites={props.sites}
					assets={props.assets}
					selSiteId={props.selSiteId}
					showSel={showSiteSelector}
					selTab={props.selTab}
					setSelTab={props.setSelTab}
					selFloor={props.selFloor}
					setSelFloor={props.setSelFloor}
					filterStatus={props.filterStatus}
					setFilterStatus={props.setFilterStatus}
					search={props.search}
					setSearch={props.setSearch}
					filterCats={props.filterCats}
					setFilterCats={props.setFilterCats}
				>
					{props.children}
				</AssetHeader>
			)}
		</>
	);
}

export function LoadingSidebar() {
	return (
		<Sidebar
			sites={[]}
			assets={[]}
			anchors={[]}
			selTab={Tabs.assets}
			setSelTab={() => {}}
			selFloor={0}
			selSiteId={""}
			setSelSiteId={() => {}}
			setSelFloor={() => {}}
			search={""}
			setSearch={() => {}}
			filterStatus={false}
			setFilterStatus={() => {}}
			setFilterCats={() => {}}
			filterCats={[]}
		>
			{
				<div style={{ background: "#f5f8f9" }} className={styles.empty}>
					<LoadingDots size={40} color={"#a3b3cc"} />
				</div>
			}
		</Sidebar>
	);
}

export default function AnalyticsSidebar(props: {
	sites: VelavuSite[];
	statType: AnalyticsStatType;
	measurementSystem: MeasurementSystem;

	setSelectedTab: React.Dispatch<React.SetStateAction<number>>;

	assets: VelavuDevice[];
	selectedAssets: string[];
	setSelectedAssets: React.Dispatch<React.SetStateAction<string[]>>;

	anchors: VelavuDevice[];
	selectedAnchors: string[];
	setSelectedAnchors: React.Dispatch<React.SetStateAction<string[]>>;
	selectedSiteID?: string;
	setSelectedSiteID: Dispatch<SetStateAction<string | undefined>>;
}) {
	const [selectedTab, setSelectedTab] = useState<Tabs>(Tabs.assets);
	const propsSetSelectedTab = props.setSelectedTab;
	useEffect(() => {
		propsSetSelectedTab(selectedTab);
	}, [propsSetSelectedTab, selectedTab]);

	const [filterCategorizations, setFilterCategorizations] = useState<
		string[]
	>([]);

	const [filterStatus, setFilterStatus] = useState<boolean | undefined>(
		undefined,
	);
	const [searchText, setSearchText] = useState("");
	const [selectedFloor, setSelectedFloor] = useState<number>();
	// const [selectedSiteID, setSelectedSiteID] = useState<string | undefined>(
	// 	undefined
	// );

	const filteredAssets = useMemo((): VelavuDevice[] => {
		let assets = filterAssets(
			props.assets,
			props.sites,
			filterCategorizations,
			props.selectedSiteID,
			selectedFloor,
			filterStatus,
		);

		// Filter by search
		assets = compoundSearchTags(assets, searchText);

		return assets;
	}, [
		filterStatus,
		props.assets,
		props.sites,
		searchText,
		selectedFloor,
		props.selectedSiteID,
		filterCategorizations,
	]);

	const filteredAnchors = useMemo((): VelavuDevice[] => {
		return filterAnchors(
			props.anchors,
			props.sites,
			searchText,
			props.selectedSiteID,
			selectedFloor,
			filterStatus,
		);
	}, [
		props.anchors,
		props.sites,
		props.selectedSiteID,
		searchText,
		selectedFloor,
		filterStatus,
	]);

	let list;
	if (selectedTab) {
		list = (
			<AnchorList
				selectedAnchors={props.selectedAnchors}
				setSelectedAnchors={props.setSelectedAnchors}
				anchors={filteredAnchors}
				statType={props.statType}
				measurementSystem={props.measurementSystem}
			/>
		);
	} else {
		list = (
			<TagList
				className={styles.list}
				assets={filteredAssets}
				selectedAssets={props.selectedAssets}
				setSelectedAssets={props.setSelectedAssets}
				statType={props.statType}
				measurementSystem={props.measurementSystem}
			/>
		);
	}

	return (
		<>
			<Sidebar
				sites={props.sites}
				assets={filteredAssets.map((a) => a.asset!)}
				anchors={filteredAnchors}
				selTab={selectedTab}
				setSelTab={setSelectedTab}
				selFloor={selectedFloor}
				selSiteId={props.selectedSiteID}
				setSelSiteId={props.setSelectedSiteID}
				setSelFloor={setSelectedFloor}
				search={searchText}
				setSearch={setSearchText}
				filterStatus={filterStatus}
				setFilterStatus={setFilterStatus}
				setFilterCats={setFilterCategorizations}
				filterCats={filterCategorizations}
			>
				{list}
			</Sidebar>
		</>
	);
}
