// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea{font-family:"Poppins",sans-serif}.qZGgiR7T_s2fAO8Hhs4z{font-weight:500;font-size:24px}.ZKnNKYxvS0CNwErUq8xv{font-weight:500;font-size:20px}.WqMgcTiLsB6aOgzlsrRM{font-weight:500;font-size:12px}.CxQ8qgY35pg0r4N0WFP6{font-size:18px}.G7mAzzasZEqHyNse6Ytm{font-size:18px;font-weight:500}.y1QZ6iepgXQj2Vgux3wy{font-size:16px}.MhqnXBuwqrlsSngoXdvJ{font-size:16px;font-weight:500}.dHDcxpsgFKWeok_af3KC{font-size:14px}.OMhHD8wIFG_v0RoHJAYZ{font-size:14px;font-weight:500}.gaUrCToHrwAZihOBBPie{font-size:14px;font-weight:400}.BLvsF0PIhzJgaS_vUIr8{font-size:12px}.DTfVygWONLeLaUX4e2G9{font-size:12px;font-weight:500}.rRVvKhc3np0YF7HE1MQ0{border:.5px solid #e2e4e5;margin:0 12px}`, "",{"version":3,"sources":["webpack://./src/styles/global.icss.scss","webpack://./src/elements/velavu-autocomplete-divider.module.scss"],"names":[],"mappings":"AAmDA,SACC,gCAAA,CAID,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,eAAA,CACA,cAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CACA,eAAA,CAGD,sBACC,cAAA,CAGD,sBACC,cAAA,CACA,eAAA,CC3GD,sBACC,yBAAA,CACA,aAAA","sourceRoot":""}]);
// Exports
export var textPrimary = `#00255d`;
export var textSecondary = `#5f718c`;
export var textTertiary = `#a3b3cc`;
export var accent = `#3a58e2`;
export var accentBackground = `#e5eaf0`;
export var active = `#00b283`;
export var activeBackground = `#f0fcf8`;
export var error = `#da3e52`;
export var errorBackground = `#fceff1`;
export var h1 = `qZGgiR7T_s2fAO8Hhs4z`;
export var h2 = `ZKnNKYxvS0CNwErUq8xv`;
export var h3 = `WqMgcTiLsB6aOgzlsrRM`;
export var p0 = `CxQ8qgY35pg0r4N0WFP6`;
export var p0m = `G7mAzzasZEqHyNse6Ytm`;
export var p1 = `y1QZ6iepgXQj2Vgux3wy`;
export var p1m = `MhqnXBuwqrlsSngoXdvJ`;
export var p2 = `dHDcxpsgFKWeok_af3KC`;
export var p2m = `OMhHD8wIFG_v0RoHJAYZ`;
export var p2n = `gaUrCToHrwAZihOBBPie`;
export var p3 = `BLvsF0PIhzJgaS_vUIr8`;
export var p3m = `DTfVygWONLeLaUX4e2G9`;
export var divider = `rRVvKhc3np0YF7HE1MQ0`;
export default ___CSS_LOADER_EXPORT___;
